import React from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface SimpleFigureProps extends DnumProps {
    image: string
    alt?: string
    styleFigure?: React.CSSProperties
}

export default class SimpleFigureComponent extends DnumComponent<SimpleFigureProps, any> {
    constructor(props: SimpleFigureProps) {
        super(props)
    }

    getImage() {
        if (this.props.style) {
            return <img src={this.props.image} id={"img-" + this.props.id} alt={this.props.alt ? this.props.alt : "simple figure"} style={this.getStyle()} />
        } else {
            return <img src={this.props.image} id={"img-" + this.props.id} alt={this.props.alt ? this.props.alt : "simple figure"} />
        }
    }

    render() {
        return (
            <figure className="fr-content-media" role="group" id={"figure-" + this.props.id} style={this.props.styleFigure}>
                {this.getImage()}
            </figure>
        )
    }
}
