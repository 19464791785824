import React from "react"

import { DnumProps } from "../../../generic/component/DnumComponent"
import AlertErrorComponent from "../../../generic/component/alert/AlertErrorComponent"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"

import { Constant } from "../../constant/Constant"
import FrontApplicationService from "../../service/application/FrontApplicationService"
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import FrontPage from "../FrontPage"
import FrontInformationPanelComponent from "./FrontInformationPanelComponent"
import FrontLoginFormComponent from "./FrontLoginFormComponent"
import FrontNewAccountLinkComponent from "./FrontNewAccountLinkComponent"

interface FrontHomePageProps extends DnumProps {
    cause?: "session-expired" | null | undefined
}

export default class FrontHomePage extends FrontPage<FrontHomePageProps, any> {
    constructor(props: FrontHomePageProps) {
        super(props)
    }

    getCause() {
        if (this.props.cause != null) {
            if (this.props.cause == "session-expired") {
                new FrontApplicationService().logout()
                return (
                    <div style={{ height: "120px" }}>
                        <AlertErrorComponent small title="Session expirée" description="Votre session a expiré, vous devez vous reconnecter." />
                    </div>
                )
            }
        }
    }

    render() {
        return (
            <PrimaryContentBoxComponent title={`Bienvenue sur ${Constant.TEXT.FRONT.APPLICATION_TITLE}`}>
                <RowComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={8}>{this.getCause()}</ColumnComponent>
                    <ColumnComponent size={1} />
                </RowComponent>
                <RowComponent>
                    <ColumnComponent size={8}>
                        <FrontInformationPanelComponent />
                    </ColumnComponent>
                    <ColumnComponent size={4}>
                        <RowComponent center>
                            <ColumnComponent size={2} />
                            <ColumnComponent size={9}>
                                <ContainerComponent style={{ border: "1px dotted" }}>
                                    <FrontLoginFormComponent />
                                </ContainerComponent>
                                <ContainerComponent style={{ marginTop: "20px" }}>
                                    <FrontNewAccountLinkComponent />
                                </ContainerComponent>
                            </ColumnComponent>
                            <ColumnComponent size={1} />
                        </RowComponent>
                    </ColumnComponent>
                </RowComponent>
            </PrimaryContentBoxComponent>
        )
    }
}
