import BackAccount from "../model/BackAccount"

import { Constant } from "../constant/Constant"
import Role from "../constant/role/Role"
import IPageCoordinator from "./contract/IPageCoordinator"
import LocalStorageService from "./storage/LocalStorageService"

export default class BackOfficeService {
    BACK_ACCOUNT_LOCALE_STORAGE_KEY = "backAccountLocaleStorageKey"

    private static instance: BackOfficeService

    public switchPage: any
    public isUserConnected: any

    public static getInstance(): BackOfficeService {
        if (!BackOfficeService.instance) {
            this.instance = new BackOfficeService()
        }
        return this.instance
    }

    public setCoordinatorPage(page: IPageCoordinator) {
        this.switchPage = page.getPageSwitcher()
        this.isUserConnected = page.isUserConnected()
    }

    public set setBackAccount(account: BackAccount) {
        new LocalStorageService().setItem(this.BACK_ACCOUNT_LOCALE_STORAGE_KEY, JSON.stringify(account))
    }

    public logout() {
        new LocalStorageService().removeItem(this.BACK_ACCOUNT_LOCALE_STORAGE_KEY)
        BackOfficeService.instance.switchPage(Constant.DOM.BACK_OFFICE.LOGIN_PAGE)
    }

    public get getBackAccount(): BackAccount | null {
        const json = new LocalStorageService().getItem(this.BACK_ACCOUNT_LOCALE_STORAGE_KEY)
        if (json != null) {
            return JSON.parse(json)
        } else {
            return null
        }
    }

    public getRole(): string | null {
        const account = BackOfficeService.instance.getBackAccount
        if (account != undefined) {
            switch (account.type) {
                case "ADMINISTRATEUR":
                    return Role.BACK.ADMIN
                case "GESTIONNAIRE":
                    return Role.BACK.GESTIONNAIRE
                case "MEMBRE_CNA":
                    return Role.BACK.MEMBRE_CNA
                default:
                    return null
            }
        } else {
            return null
        }
    }
}
