import React from "react"

import PageComponent, { PageProps } from "../../generic/component/grid/page/PageComponent"

import BackOfficeComponent from "./BackOfficeComponent"

export default class BackOfficePage<P extends PageProps, S> extends BackOfficeComponent<P, S> {
    render() {
        return (
            <PageComponent style={this.props.style} fluid={null}>
                {this.props.children}
            </PageComponent>
        )
    }
}
