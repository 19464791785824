export default class Cout {
    a: number | null = null

    // organisme financeur ID
    id: number | null = null

    rp: number | null = null
    ds: number | null = null
    mn: number | null = null

    picf: number | null = null
    picv: number | null = null

    public setId(value: number) {
        this.id = value
    }

    public setAnnee(value: number) {
        this.a = value
    }

    public setResourcesPropres(value: number | null) {
        this.rp = value
    }

    public setDotationsSubventions(value: number | null) {
        this.ds = value
    }

    public setMesuresNouvelles(value: number | null) {
        this.mn = value
    }

    public setPourInfoCaf(value: number | null) {
        this.picf = value
    }

    public setPourInfoCnav(value: number | null) {
        this.picv = value
    }
}
