export default class DomBackOffice {
    // liste des écrans du back office

    // specifique
    static ADMIN_HOME_PAGE = "backOfficeHomePage"
    static LOGIN_PAGE = "backLoginPage"

    // instruction agrements
    static AGREMENT__TOUTES_LES_DEMANDES = "backOfficeToutesLesDemandesAgrement"
    static AGREMENT__DEMANDES_A_TRAITER = "backOfficeDemandesAgrementATraiter"
    static AGREMENT__EN_ATTENTE_AVIS_FINANCEUR = "backOfficeDemandesAgrementEnAttenteAvisFinanceur"
    static AGREMENT__DEMANDES_EN_ATTENTE_CNA = "backOfficeDemandesAgrementEnAttenteCNA"
    static AGREMENT__DEMANDES_PROCHAINE_CNA = "backOfficeDemandesAgrementProchaineCNA"
    static AGREMENT__EXTRACTION = "backOfficeDemandesAgrementExtraction"

    static AGREMENT__SHOW_DEMANDE = "backOfficeShowDemandeAgrement"

    // CNA
    static CNA__LIST = "backOfficeListCna"
    static CNA__MEMBRE = "backOfficeMembreCna"

    // financeurs
    static FINANCEUR__SUIVI_AVIS = "backOfficeSuiviAvis"
    static FINANCEUR__GESTION_FINANCEUR = "backOfficeContactFinanceur"

    // referentiels
    static REFERENTIEL__TYPE_ACCORD = "backOfficeReferentielTypeAccord"
    static REFERENTIEL__MOTIF_REFUS = "backOfficeReferentielMotifRefus"
    static REFERENTIEL__PIECES_EXIGIBLES = "backOfficeReferentielPiecesExigibles"
    static REFERENTIEL__TYPOLOGIE_ACCORD = "backOfficeReferentielTypologieAccords"
    static REFERENTIEL__TEMPLATE_COURRIEL_DOCUMENT = "backOfficeReferentielTemplateCourrielDocument"
    static REFERENTIEL__TYPE_FINANCEUR = "backOfficeReferentielTypeFinanceur"
    static REFERENTIEL__ORGANISME_FINANCEUR = "backOfficeReferentielOrganismeFinanceur"
    static REFERENTIEL__CONTACT_FINANCEUR = "backOfficeReferentielContactFinanceur"
    static REFERENTIEL__ESMS = "backOfficeReferentielEsms"
    static REFERENTIEL__IDCC = "backofficeReferentielIdcc"
    static REFERENTIEL__SIGNATURE = "backOfficeReferentielSignatures"

    // gestions comptes utilisateurs
    static UTILISATEUR__LIST_ASSOCIATION = "backOfficeListAssociation"
    static UTILISATEUR__LIST_FINANCEUR = "backOfficeListFinanceur"
    static UTILISATEUR__LIST_MEMBRE_CNA = "backOfficeListMembreCna"

    // mainteance
    static MAINTENANCE_MENU = "maintenance-menu"
}
