import React, { ChangeEvent, Fragment } from "react"

import File from "../../../model/File"

import BadgeSuccessComponent from "../../../../generic/component/badge/BadgeSuccessComponent"
import Button from "../../../../generic/component/button/Button"
import FileUploadComponent from "../../../../generic/component/fileupload/FileUploadComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import AccoladeComponent from "../../../../accolade/AccoladeComponent"

import { Constant } from "../../../constant/Constant"
import VerticalSpacing from "../space/VerticalSpacing"

interface FileUploaderProps<TYPE> {
    labelAdd: string
    labelDocument: string
    informations?: string
    acceptString?: string
    file: File | undefined
    deleteFileFunction: () => void
    errors: Map<string, string> | undefined
    current: TYPE | undefined
    id: string
    onChangeHandler?: (event: ChangeEvent) => void
    sendFileDirectly?: (input: HTMLInputElement) => void
}

export default class FileUploaderComponent<TYPE> extends AccoladeComponent<FileUploaderProps<TYPE>, any> {
    static TOOLTIP =
        "<span>Vous avez la possibilité d'ajouter toute pièce jointe permettant de faciliter le traitement de votre demande. <br/> Ces documents peuvent aussi réduire le temps d'instruction par nos services</span>"

    constructor(props: FileUploaderProps<TYPE>) {
        super(props)
    }

    getUploadedFile() {
        if (this.props.file) {
            return (
                <span>
                    <ul>
                        <li>
                            <a href={process.env.REACT_APP_CONTEXT_PATH + "/front/file/download/" + this.props.file.key} target="_blank">
                                {this.props.file.originalName + "." + this.props.file.extension}
                            </a>
                        </li>
                    </ul>
                </span>
            )
        }
    }

    sendFileDirectlyWrapped(input: HTMLInputElement) {
        if (this.props.sendFileDirectly) {
            this.props.sendFileDirectly(input)
        }
    }

    render() {
        if (this.props.file) {
            return (
                <RowComponent>
                    <ColumnComponent size={12}>
                        <BadgeSuccessComponent label={this.props.labelDocument} noIcon={true} />
                        <br />
                        <RowComponent>
                            <ColumnComponent size={9}>{this.getUploadedFile()}</ColumnComponent>
                            <ColumnComponent size={3}>
                                <Button small iconLeft={Constant.ICON.DELETE_LINE} onClick={() => this.props.deleteFileFunction()}>
                                    Supprimer
                                </Button>
                            </ColumnComponent>
                        </RowComponent>
                        <VerticalSpacing height={"15px"} />
                    </ColumnComponent>
                </RowComponent>
            )
        } else {
            let accept = {}
            if (this.props.acceptString) {
                accept = { acceptString: this.props.acceptString }
            }
            return (
                <Fragment>
                    <FileUploadComponent
                        maxSizeInMo={12}
                        label={this.props.labelAdd}
                        sendFileDirectly={(input) => this.sendFileDirectlyWrapped(input)}
                        onChangeHandler={(event) => (this.props.onChangeHandler != undefined ? this.props.onChangeHandler(event) : "")}
                        {...accept}
                        informations={this.props.informations}
                        error={this.props.errors?.has(this.props.id + "-error")}
                        errorMessage={this.props.errors?.get(this.props.id + "-error")}
                        id={this.props.id}
                    />
                    <VerticalSpacing height={"20px"} />
                </Fragment>
            )
        }
    }
}
