import { Fragment, ReactElement } from "react"

import { decode } from "html-entities"

import AccountData from "../../../../model/AccountData"
import FinessEtablissement from "../../../../model/FinessEtablissement"
import FinessJuridique from "../../../../model/FinessJuridique"

import BadgeChooseColorsComponent from "../../../../../generic/component/BadgeChooseColorsComponent"
import { DnumProps } from "../../../../../generic/component/DnumComponent"
import AccordionComponent from "../../../../../generic/component/accordion/AccordionComponent"
import AlertWarningComponent from "../../../../../generic/component/alert/AlertWarningComponent"
import Button from "../../../../../generic/component/button/Button"
import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"
import DotStatus from "../../../../../generic/component/status/DotStatus"
import QuadrilateralStatus from "../../../../../generic/component/status/QuadrilateralStatus"

import BackOfficeModalActionComponent from "../../../../back/referentiel/BackOfficeModalActionComponent"
import { Constant } from "../../../../constant/Constant"
import FrontService from "../../../../service/FrontService"
import HttpAccountService from "../../../../service/http/HttpAccountService"
import HttpService from "../../../../service/http/HttpService"
import Response from "../../../../service/http/response/Response"
import UiService from "../../../../service/ui/UiService"
import VerticalSpacing from "../../../../shared/component/space/VerticalSpacing"
import FrontComponent from "../../../FrontComponent"
import FrontDemandeAgrementForm from "../../../demande/FrontDemandeAgrementForm"

interface FrontAccountDemandesComponentProps extends DnumProps {
    entiteJuridique: FinessJuridique
    etablissements: FinessEtablissement[]
    refreshFunction: () => void
    accountData: AccountData
}

interface FrontAccountDemandesComponentState {
    demandes: FrontDemandeAgrementForm[] | null | undefined
}

export default class FrontAccountDemandesComponent extends FrontComponent<FrontAccountDemandesComponentProps, FrontAccountDemandesComponentState> {
    static MAX_DEMANDE_IN_PROGRESS = 20

    countEtablissementAvailable = 0
    countEtablissementFinanceNotDefined = 0

    constructor(props: FrontAccountDemandesComponentProps) {
        super(props)

        this.props.etablissements?.forEach((etablissement) => {
            if (etablissement.cpomValue != null && etablissement.categorieValide && etablissement.etat === "ACTUEL") {
                if (etablissement.cpomValue === "NON" || etablissement.cpomValue === "SOUS_CPOM_CD") {
                    this.countEtablissementAvailable++
                }
            } else {
                this.countEtablissementFinanceNotDefined++
            }
        })
    }

    componentDidUpdate(prevProps: Readonly<FrontAccountDemandesComponentProps>, prevState: Readonly<any>, snapshot?: any) {
        const oldCount = this.countEtablissementAvailable
        this.countEtablissementAvailable = 0
        this.countEtablissementFinanceNotDefined = 0
        this.props.etablissements?.forEach((etablissement, index) => {
            if (etablissement.cpomValue != null && etablissement.categorieValide && etablissement.etat === "ACTUEL") {
                if (etablissement.cpomValue === "NON" || etablissement.cpomValue === "SOUS_CPOM_CD") {
                    this.countEtablissementAvailable++
                    // attention, modification de l'état dans componentDidUpdate, condition obligatoire

                    if (oldCount < this.countEtablissementAvailable) {
                        this.setState({})
                    }
                }
            } else {
                this.countEtablissementFinanceNotDefined++
            }
        })
    }

    componentDidMount() {
        new HttpAccountService().getDemandes().then((response) => {
            const cast = new Response<FrontDemandeAgrementForm[]>(response)
            if (cast.success) {
                this.setState({ demandes: cast.content })
            } else {
                console.log("nothing")
            }
        })
    }

    createNewDemande() {
        FrontService.getInstance().switchPage(Constant.DOM.FRONT.DEMANDE_AGREMENT_ETABLISSEMENTS_PAGE)
    }

    getButtonNewDemande(countEtablissementAvailable: number) {
        if (countEtablissementAvailable > 0) {
            return (
                <Button iconLeft={Constant.ICON.ARROW_RIGHT_LINE} onClick={() => this.createNewDemande()}>
                    Faire une demande
                </Button>
            )
        }
    }

    editDemande(id: number) {
        const params = new Map()
        params.set(Constant.KEY.FRONT.DEMANDE_ID_PARAM, id)
        params.set(Constant.KEY.FRONT.EDITION_PARAM, true)
        FrontService.getInstance().switchPage(Constant.DOM.FRONT.DEMANDE_AGREMENT_FORM_PAGE, params)
    }

    showDemande(demande: FrontDemandeAgrementForm, informations: AccountData) {
        const params = new Map()
        params.set(Constant.KEY.FRONT.DEMANDE_OBJECT_PARAM, demande)
        params.set(Constant.KEY.FRONT.ACCOUNT_INFORMATIONS_PARAM, informations)
        FrontService.getInstance().switchPage(Constant.DOM.FRONT.SHOW_DEMANDE_AGREMENT_PAGE, params)
    }

    getStatut(demande: FrontDemandeAgrementForm): ReactElement | undefined {
        switch (demande.etat) {
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__EN_COURS_DE_DEPOT:
                return (
                    <span>
                        Demande non transmise,
                        <br />
                        débutée le {UiService.formatServerDateToHumanDate(demande.dateUpdateFromFront)}
                    </span>
                )
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__SOUMISE_A_INSTRUCTION:
                return (
                    <span>
                        Demande soumise le {UiService.formatServerDateToHumanDate(demande.dateUpdateFromFront)},<br /> prochainement en cours d'instruction
                    </span>
                )

            case Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_AGREE:
                return <span>Décision prise en CNA : agréé</span>
            case Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_REFUSEE:
                return <span>Décision prise en CNA : refusé</span>
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__SUPPRIMER:
                return <span>Demande hors-champ / supprimée</span>
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR:
                return (
                    <span>
                        En attente d'informations complémentaires de votre part: <i>"{demande.commentaireCompletude}"</i>
                    </span>
                )

            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR:
                return <span>En attente d'avis financeur</span>

            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA:
                return <span>En attente CNA</span>

            case Constant.STATUS.DEMANDE.DEMANDE__SYSTEM__AGREEE_IMPLICITEMENT:
                return <span>Agrément accepté implicitement</span>
        }
    }

    getStatutIndicator(demande: FrontDemandeAgrementForm) {
        switch (demande.etat) {
            // RED
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__SUPPRIMER:
            case Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_REFUSEE:
                return <QuadrilateralStatus width="3px" height="100%" color="red" />
            // PURPLE
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR:
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__EN_COURS_DE_DEPOT:
                return <QuadrilateralStatus width="3px" height="100%" color="purple" />
            // ORANGE
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__A_TRAITER:
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA:
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA:
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR:
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__SOUMISE_A_INSTRUCTION:
                return <QuadrilateralStatus width="3px" height="100%" color="orange" />
            // GREEN
            case Constant.STATUS.DEMANDE.DEMANDE__SYSTEM__AGREEE_IMPLICITEMENT:
            case Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_AGREE:
                return <QuadrilateralStatus width="3px" height="100%" color="green" />
            default:
                return <DotStatus size={10} color="black" />
        }
    }

    getTitleDemande(demande: FrontDemandeAgrementForm): ReactElement {
        let title = "Demande d'agrément sur "
        if (demande.typeDemande === "DUE") {
            title += " DUE "
        } else {
            title += "l'accord "
        }
        if (demande.numeroDossier != null && !(demande.numeroDossier === "")) {
            title += decode(demande.numeroDossier)
        }
        return (
            <RowComponent style={{ width: "100%" }}>
                <ColumnComponent size={1} style={{ verticalAlign: "middle" }}>
                    {this.getStatutIndicator(demande)}
                </ColumnComponent>
                <ColumnComponent size={11}>
                    {title}
                    <br />
                    <div>
                        <small>(dernière modification le {UiService.formatServerDateToHumanDate(demande.dateUpdateFromFront)})</small>
                    </div>
                </ColumnComponent>
            </RowComponent>
        )
    }

    getDeleteButton(demande: FrontDemandeAgrementForm): ReactElement | undefined {
        if (demande.etat == Constant.STATUS.DEMANDE.DEMANDE__FRONT__EN_COURS_DE_DEPOT || demande.etat == Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR) {
            return (
                <div>
                    <Button modal={"demande-delete-" + demande.id} style={{ marginTop: "5px", background: "red", width: "100%" }} iconLeft={Constant.ICON.DELETE_FILL}>
                        Supprimer
                    </Button>
                </div>
            )
        }
    }

    removeDemande(demande: FrontDemandeAgrementForm) {
        const data = this.state.demandes!
        const index = data.indexOf(demande)
        this.setState({
            demandes: [...data.slice(0, index), ...data.slice(index + 1)]
        })
    }

    deleteDemande(demande: FrontDemandeAgrementForm) {
        new HttpService()
            .request("/front/demande/delete/" + demande.id, {
                method: "DELETE",
                credentials: "include"
            })
            .then((response: any) => {
                //console.log(response)
                if (response.success) {
                    //console.log("Demande supprimée")
                    this.removeDemande(demande)
                }
            })
    }

    getDemande(demande: FrontDemandeAgrementForm, index: number): ReactElement {
        return (
            <AccordionComponent id={"demande-" + demande.id} key={"front-demande-" + demande.id} title={this.getTitleDemande(demande)} index={index}>
                <RowComponent>
                    <ColumnComponent size={9}>
                        Etablissement(s) concerné(s) : <ul style={{ listStyleType: "square" }}>{this.getEtablissements(demande)}</ul>
                        <br />
                        {this.getStatut(demande)}
                    </ColumnComponent>
                    <ColumnComponent size={3}>
                        {this.getDemandeButton(demande)}
                        {this.getDeleteButton(demande)}
                    </ColumnComponent>
                    <BackOfficeModalActionComponent
                        id={"demande-delete-" + demande.id}
                        title={"Suppression de la demande "}
                        message={"Êtes vous sûr de vouloir supprimer la demande ?"}
                        doAction={() => this.deleteDemande(demande)}
                    />
                </RowComponent>
            </AccordionComponent>
        )
    }

    getEtablissements(demande: FrontDemandeAgrementForm): ReactElement[] {
        const list: ReactElement[] = []
        demande.etablissements?.forEach((etablissement, index) => {
            list.push(<li key={"etablissement-" + etablissement.id}>{etablissement.raisonSociale}</li>)
        })
        return list
    }

    getDemandeButton(demande: FrontDemandeAgrementForm): ReactElement {
        switch (demande.etat) {
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR: {
                return (
                    <Button iconLeft={Constant.ICON.EDIT_FILL} medium style={{ width: "100%" }} onClick={() => this.editDemande(demande.id!)}>
                        Compléter
                    </Button>
                )
            }
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__EN_COURS_DE_DEPOT: {
                return (
                    <Button iconLeft={Constant.ICON.EDIT_FILL} medium style={{ width: "100%" }} onClick={() => this.editDemande(demande.id!)}>
                        Modifier
                    </Button>
                )
            }
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__A_TRAITER:
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR:
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA:
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA:
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__SOUMISE_A_INSTRUCTION: {
                return (
                    <Button iconLeft={Constant.ICON.EYE_LINE} medium style={{ width: "100%" }} onClick={() => this.showDemande(demande, this.props.accountData)}>
                        Consulter
                    </Button>
                )
            }
        }
        return <span>&nbsp;</span>
    }

    getDemandes() {
        if (this.state && this.state.demandes) {
            const list: ReactElement[] = []

            //console.log(this.state.demandes)
            this.state.demandes
                .sort((a, b) => b.id! - a.id!)
                .forEach((demande: FrontDemandeAgrementForm, index) => {
                    //console.log(demande.etat)
                    if (demande.etat === Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR) {
                        list.push(this.getDemande(demande, index))
                        list.push(<VerticalSpacing key={"vp-" + index} height={"35px"} />)
                    }
                })
            //TODO Empecher les demandes supprimées de remonter
            this.state.demandes.forEach((demande: FrontDemandeAgrementForm, index) => {
                //console.log(demande.etat)
                if (demande.etat !== Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR && demande.etat !== Constant.STATUS.DEMANDE.DEMANDE__FRONT__SUPPRIMEE) {
                    list.push(this.getDemande(demande, index))
                    list.push(<VerticalSpacing key={"vp-" + index} height={"35px"} />)
                }
            })
            return <Fragment>{list}</Fragment>
        } else {
            return "Vous n'avez aucune demande en cours"
        }
    }

    getStatusLegende() {
        if (this.state && this.state.demandes && this.state.demandes.length > 0) {
            return (
                <Fragment>
                    Légende
                    <br />
                    <ul style={{ listStyleType: "square" }}>
                        <li>
                            <BadgeChooseColorsComponent color={"#FFFFFF"} small backgroundColor={"red"} label={"Refusée / supprimée"} />
                        </li>
                        <li>
                            <BadgeChooseColorsComponent color={"#FFFFFF"} small backgroundColor={"purple"} label={"En attente de votre action"} />
                        </li>
                        <li>
                            <BadgeChooseColorsComponent small color={"#FFFFFF"} backgroundColor={"orange"} label={"En cours d'instruction"} />
                        </li>
                        <li>
                            <BadgeChooseColorsComponent color={"#FFFFFF"} small backgroundColor={"green"} label={"Agréée"} />
                        </li>
                    </ul>
                    <VerticalSpacing height="20px" />
                </Fragment>
            )
        }
    }

    getNewDemandeRow() {
        if (this.state) {
            let showAddButton = true
            let allEsmsCategorieInvalid = true
            let countInProgress = 0

            this.countEtablissementAvailable = 0
            this.countEtablissementFinanceNotDefined = 0

            this.state.demandes?.forEach((demande) => {
                if (demande.etat == Constant.STATUS.DEMANDE.DEMANDE__FRONT__EN_COURS_DE_DEPOT) {
                    if (countInProgress++ > FrontAccountDemandesComponent.MAX_DEMANDE_IN_PROGRESS) {
                        showAddButton = false
                    }
                }
            })

            this.props.etablissements?.forEach((etablissement) => {
                if (etablissement.categorieValide) {
                    allEsmsCategorieInvalid = false

                    if (etablissement.etat === "ACTUEL") {
                        if (etablissement.cpomValue != null) {
                            this.countEtablissementAvailable++
                        } else {
                            this.countEtablissementFinanceNotDefined++
                        }
                    }
                }
            })

            //console.log("AZER " + allEsmsCategorieInvalid + " " + showAddButton + " ")
            //console.log("allEsmsCategorieInvalid : "+allEsmsCategorieInvalid)
            //console.log("countEtablissementAvailable : "+this.countEtablissementAvailable)
            //console.log("countEtablissementFinanceNotDefined : "+this.countEtablissementFinanceNotDefined)

            if (allEsmsCategorieInvalid || (this.countEtablissementAvailable == 0 && this.countEtablissementFinanceNotDefined == 0)) {
                return (
                    <RowComponent key="row-no-eligible-1">
                        <ColumnComponent size={2}></ColumnComponent>
                        <ColumnComponent size={8}>
                            <AlertWarningComponent title={"Vos établissements ne sont pas éligibles à une demande d'agrément."} />
                        </ColumnComponent>
                        <ColumnComponent size={2}></ColumnComponent>
                    </RowComponent>
                )
            } else if (this.countEtablissementFinanceNotDefined > 0 && this.countEtablissementAvailable == 0) {
                return (
                    <RowComponent key="row-no-eligible-2">
                        <ColumnComponent size={2}></ColumnComponent>
                        <ColumnComponent size={8}>
                            <AlertWarningComponent title={"Veuillez renseigner les informations financières sur au moins un de vos établissements éligibles afin de pouvoir faire une demande."} />
                        </ColumnComponent>
                        <ColumnComponent size={2}></ColumnComponent>
                    </RowComponent>
                )
            } else if (showAddButton) {
                return (
                    <RowComponent key="row-eligible-1">
                        <ColumnComponent size={9}>{this.getInformationCompletion(this.countEtablissementFinanceNotDefined)}</ColumnComponent>
                        <ColumnComponent size={3}>{this.getButtonNewDemande(this.countEtablissementAvailable)}</ColumnComponent>
                    </RowComponent>
                )
            }
        }
    }

    getInformationCompletion(countEtablissementFinanceNotDefined: number) {
        if (countEtablissementFinanceNotDefined > 0) {
            if (countEtablissementFinanceNotDefined == 1) {
                return (
                    <RowComponent>
                        <ColumnComponent size={2} />
                        <ColumnComponent size={8}>
                            <AlertWarningComponent description={"Il vous reste un établissement où les informations financières sont manquantes."} />
                        </ColumnComponent>
                        <ColumnComponent size={2} />
                    </RowComponent>
                )
            } else {
                return (
                    <RowComponent>
                        <ColumnComponent size={2} />
                        <ColumnComponent size={8}>
                            <AlertWarningComponent description={"Il vous reste " + countEtablissementFinanceNotDefined + " établissements où les informations financières sont manquantes."} />
                        </ColumnComponent>
                        <ColumnComponent size={2} />
                    </RowComponent>
                )
            }
        } else {
            return <div>&nbsp;</div>
        }
    }

    render() {
        console.info("render " + this.countEtablissementAvailable)
        return (
            <Fragment>
                {this.getNewDemandeRow()}
                <VerticalSpacing height="75px" />
                <RowComponent>
                    <ColumnComponent size={8}>{this.getDemandes()}</ColumnComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={3}>{this.getStatusLegende()}</ColumnComponent>
                </RowComponent>
            </Fragment>
        )
    }
}
