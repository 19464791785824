class Color {
    static BLUE_GREY_COLOR = "#5D7394"

    static LIGHT_BLUE = "#D9EAF4"
    static VERY_LIGHT_BLUE = "#F0F4F7"

    static RED_MARIANNE = "#000091"
    static LIGHT_RED_MARIANNE = "#ececfe"
}

export default Color
