import ContactFinanceurInformation from "../../model/ContactFinanceurInformation"

import FieldsetComponent from "../../../generic/component/FieldsetComponent"
import Button from "../../../generic/component/button/Button"
import TextInputComponent from "../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"

import BackOfficeLoginForm from "../../back/login/BackOfficeLoginForm"
import { Constant } from "../../constant/Constant"
import EspaceFinanceurService from "../../service/EspaceFinanceurService"
import HttpEspaceFinanceurService from "../../service/http/HttpEspaceFinanceurService"
import Response from "../../service/http/response/Response"
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import VerticalSpacing from "../../shared/component/space/VerticalSpacing"
import EspaceFinanceurPage from "../EspaceFinanceurPage"

interface EspaceFinanceurLoginPageState {
    errors?: Map<string, string> | undefined
}
export default class EspaceFinanceurLoginPage extends EspaceFinanceurPage<any, EspaceFinanceurLoginPageState> {
    login() {
        const form = new BackOfficeLoginForm()
        const loginInput = document.querySelector("#financeur-login-identifiant") as HTMLInputElement
        const passwordInput = document.querySelector("#financeur-login-password") as HTMLInputElement
        form.login = loginInput.value
        form.password = passwordInput.value
        form.type = "financeur"
        new HttpEspaceFinanceurService().login(form).then((response) => {
            const cast = new Response<ContactFinanceurInformation>(response)
            if (cast.success && !cast.hasError()) {
                EspaceFinanceurLoginPage.applicationService.setFinanceurConnected()
                const map = new Map<string, any>()
                map.set(Constant.KEY.FINANCEUR.CONTACT_FINANCEUR, cast.content)
                EspaceFinanceurService.getInstance().switchPage(Constant.DOM.ESPACE_FINANCEUR.HOME_PAGE, map)
            } else {
                this.setState({ errors: cast.getErrorMap() })
            }
        })
    }

    render() {
        return (
            <PrimaryContentBoxComponent title={"Accès autorité de tarification"}>
                <VerticalSpacing height="150px" />
                <RowComponent>
                    <ColumnComponent size={4} />
                    <ColumnComponent size={4}>
                        <FieldsetComponent
                            legend={"Authentification"}
                            content={
                                <div>
                                    <TextInputComponent
                                        label={"Identifiant"}
                                        id="financeur-login-identifiant"
                                        error={this.state?.errors?.has("login")}
                                        errorMessage={this.state?.errors?.get("login")}
                                    />
                                    <TextInputComponent
                                        label={"Mot de passe"}
                                        id="financeur-login-password"
                                        password
                                        error={this.state?.errors?.has("password")}
                                        errorMessage={this.state?.errors?.get("password")}
                                    />
                                    <div style={{ width: "100%", textAlign: "center" }}>
                                        <Button onClick={() => this.login()}>Valider</Button>
                                    </div>
                                </div>
                            }
                        />
                    </ColumnComponent>
                    <ColumnComponent size={4} />
                </RowComponent>
                <VerticalSpacing height="150px" />
            </PrimaryContentBoxComponent>
        )
    }
}
