export default class AvisFinanceur {
    id: number | null = null
    commentaire: string | null = null
    dateCreation: string | null = null
    dateModification: string | null = null
    dateAvis: string | null = null
    dateRelance: string | null = null
    demandeId: number | null = null
    organismeFinanceurId: number | null = null
    contactFinanceurId: number | null = null
    result: boolean | null = null
}
