import React, { ReactElement } from "react"

import DnumComponent, { DnumProps } from "./DnumComponent"

interface FieldsetComponentProps extends DnumProps {
    legend: string | ReactElement | null
    content: string | ReactElement
    centerContent?: any
}
export default class FieldsetComponent extends DnumComponent<FieldsetComponentProps, any> {
    constructor(props: FieldsetComponentProps) {
        super(props)
    }

    render() {
        const styleFieldset = {
            paddingLeft: "30px",
            borderRadius: "10px",
            padding: "25px"
        }

        const divFieldset = {
            width: "100%",
            display: "flex",
            justifyContent: this.props.centerContent ? "center" : "left"
        }

        if (length == null) {
            return (
                <fieldset style={styleFieldset}>
                    <div style={divFieldset}>{this.props.content}</div>
                </fieldset>
            )
        } else {
            return (
                <fieldset style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>{this.props.legend}</legend>
                    <div style={divFieldset}>{this.props.content}</div>
                </fieldset>
            )
        }
    }
}
