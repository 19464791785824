import React from "react"

import FinessEtablissement from "../../../../model/FinessEtablissement"

import TextInputComponent from "../../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"

import SecondaryContentBoxComponent from "../../../../shared/component/box/SecondaryContentBoxComponent"
import FrontComponent from "../../../FrontComponent"
import FrontDemandeAgrementFormStepProps from "../FrontDemandeAgrementFormStepProps"

interface FrontDemandeAgrementFormStep0BComponentState {
    etablissements: FinessEtablissement[] | undefined
}

export default class FrontDemandeAgrementFormStep0BComponent extends FrontComponent<FrontDemandeAgrementFormStepProps, FrontDemandeAgrementFormStep0BComponentState> {
    constructor(props: FrontDemandeAgrementFormStepProps) {
        super(props)
    }

    componentDidUpdate(prevProps: Readonly<FrontDemandeAgrementFormStepProps>, prevState: Readonly<FrontDemandeAgrementFormStep0BComponentState>, snapshot?: any) {
        this.checkEtpConcernes()
    }

    getSommeMasseSalariale(): number | undefined {
        return this.props.demandeInProgress?.etablissements?.reduce((accumulator, obj) => {
            return accumulator + obj.masseSalarialeChargee!
        }, 0)
    }

    getSommeEtp(): number | undefined {
        return this.props.demandeInProgress?.etablissements?.reduce((accumulator, obj) => {
            return Math.round((accumulator + obj.etp!) * 100) / 100
        }, 0)
    }

    checkEtpConcernes() {
        const span = document.querySelector("#etp-warning") as HTMLSpanElement
        const etpConcernes = document.querySelector("#etp") as HTMLInputElement
        const etpMax = this.getSommeEtp() as number

        try {
            if (!isNaN(Number(etpConcernes.value))) {
                const etp = Number(etpConcernes.value)

                if (etp > etpMax) {
                    span.style.display = "block"
                    return
                } else {
                    span.style.display = "none"
                    return
                }
            }
        } catch (e) {
            span.style.display = "none"
            return
        }
    }

    render() {
        return (
            <>
                <SecondaryContentBoxComponent title="Effectifs et salaires">
                    <RowComponent>
                        <ColumnComponent size={12}>
                            <TextInputComponent
                                label="Courriel de contact"
                                id="contact-email"
                                value={this.props.demandeInProgress?.emailContact != null ? this.props.demandeInProgress?.emailContact : this.props.informations?.contact?.email?.toString()}
                                errorMessage={this.props.errors?.get("contact-email")}
                                error={this.props.errors?.has("contact-email")}
                            />
                            <TextInputComponent label="Masse salariale chargée des ESMS concernés" id="masse-salariale-chargee" readonly value={this.getSommeMasseSalariale()?.toString()} />
                            <TextInputComponent label="Nombre d'ETP au total des ESMS concernés" value={this.getSommeEtp()?.toString()} readonly id="nombre-d-etp-au-total" />
                            <TextInputComponent
                                label="ETP concernés par la mesure"
                                id="etp"
                                onChangeHandler={(event) => this.checkEtpConcernes()}
                                errorMessage={this.props.errors?.get("etp")}
                                error={this.props.errors?.has("etp")}
                                value={this.props.demandeInProgress?.etpConcernes ?? ""}
                                placeholder="Nombre d'ETP"
                                number
                            />
                            <span id="etp-warning" style={{ color: "#d64d00", display: "none" }}>
                                <small>
                                    Attention le nombre d'ETP concernés est supérieur au nombre d'ETP total.
                                    <br />
                                    Vérifier vos informations financières sur les établissements concernés (Mon compte &sup; Etablissements).
                                </small>
                            </span>
                        </ColumnComponent>
                    </RowComponent>
                </SecondaryContentBoxComponent>
            </>
        )
    }
}
