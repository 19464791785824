import { Fragment } from "react"

import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import VerticalSpacing from "../../shared/component/space/VerticalSpacing"
import EspaceFinanceurPage from "../EspaceFinanceurPage"

export default class EspaceFinanceurContactPage extends EspaceFinanceurPage<any, any> {
    render() {
        return (
            <PrimaryContentBoxComponent title={"Contact"}>
                <VerticalSpacing height="25px" />
                <div style={{ margin: "15px" }}>
                    <br />
                    <div>
                        Vous pouvez contacter le bureau de l'emploi et de la politique salariale de la <b>Direction générale de la cohésion sociale</b> chargé de l'instruction de votre demande
                        d'agrément en lui adressant un message électronique à l'adresse suivante :{" "}
                        <a target={"_blank"} href={"mailto:DGCS-ACCORDS@social.gouv.fr"}>
                            DGCS-ACCORDS@social.gouv.fr
                        </a>
                        .
                    </div>
                    <br />
                    <br />
                </div>
            </PrimaryContentBoxComponent>
        )
    }
}
