import HttpService from "./HttpService";

export default class HttpBackMaintenanceService extends HttpService {
    MAINTENANCE_URI = "/maintenance"

    getEmailFromFiness(finessEJ: string) {
        return this.request<string>(this.MAINTENANCE_URI + "/account/" + finessEJ, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
        })
    }

    refreshFinessData(finessEJ : string){
        return this.request<string>(this.MAINTENANCE_URI + "/finess_ej/" + finessEJ, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
        })
    }

    updatePassword(email : string){
        return this.request<string>(this.MAINTENANCE_URI + "/update_password?email=" + email, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
        })
    }

    updateEmail(email : string, newEmail : string){
        return this.request<string>(this.MAINTENANCE_URI + "/update_email?email=" + email + "&newEmail=" + newEmail, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
        })
    }

    searchEmail(email : string){
        return this.request<string>(this.MAINTENANCE_URI + "/search_email?email=" + email, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
        })
    }
}
