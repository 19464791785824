import React from "react"

import { PageProps } from "../../../generic/component/grid/page/PageComponent"
import HeaderComponent from "../../../generic/component/header/HeaderComponent"

import Page404 from "../../../../../../Page404"
import { Constant } from "../../constant/Constant"
import RoleBackOffice from "../../constant/role/RoleBackOffice"
import BackOfficeService from "../../service/BackOfficeService"
import IPageCoordinator from "../../service/contract/IPageCoordinator"
import BackOfficePage from "../BackOfficePage"
import BackOfficeManageCnaPage from "../cna/BackOfficeManageCnaPage"
import FinanceurSuiviAvisPage from "../financeur/FinanceurSuiviAvisPage"
import BackOfficeAdminHomePage from "../home/BackOfficeAdminHomePage"
import BackOfficeInstructionListDemandeAgrementPage from "../instruction/list/BackOfficeInstructionListDemandeAgrementPage"
import BackOfficeLoginPage from "../login/BackOfficeLoginPage"
import BackOfficeMenuComponent from "../menu/BackOfficeMenuComponent"
import BackOfficeContactFinanceurPage from "../referentiel/contactfinanceur/BackOfficeContactFinanceurPage"
import BackOfficeEsmsPage from "../referentiel/esms/BackOfficeEsmsPage"
import BackOfficeHtmlTemplatePage from "../referentiel/htmltemplate/BackOfficeHtmlTemplatePage"
import BackOfficeIdentifiantConventionCollectivePage from "../referentiel/idcc/BackOfficeIdentifiantConventionCollectivePage"
import BackOfficeMembreCnaPage from "../referentiel/membrecna/BackOfficeMembreCnaPage"
import BackOfficeMotifRefusPage from "../referentiel/motifrefus/BackOfficeMotifRefusPage"
import BackOfficeOrganismeFinanceurPage from "../referentiel/organismefinanceur/BackOfficeOrganismeFinanceurPage"
import BackOfficePieceExigiblePage from "../referentiel/pieceexigible/BackOfficePieceExigiblePage"
import BackOfficeSignaturePage from "../referentiel/signature/BackOfficeSignaturePage"
import BackOfficeTypeAccordPage from "../referentiel/typeaccord/BackOfficeTypeAccordPage"
import BackOfficeTypeFinanceurPage from "../referentiel/typefinanceur/BackOfficeTypeFinanceurPage"
import BackOfficeTypologieAccordPage from "../referentiel/typologieaccord/BackOfficeTypologieAccordPage"
import BackOfficeUtilisateurAssociationPage from "../utilisateur/BackOfficeUtilisateurAssociationPage"
import BackOfficeUtilisateurFinanceurPage from "../utilisateur/BackOfficeUtilisateurFinanceurPage"
import BackOfficeUtilisateurMembreCnaPage from "../utilisateur/BackOfficeUtilisateurMembreCnaPage"
import BackOfficeInstructionExtraction from "../instruction/BackOfficeInstructionExtraction"
import BackOfficeTestMaintenancePage from "../maintenance/BackOfficeMaintenancePage"

interface BackOfficeLoginState extends PageProps {
    pageId?: string
    data?: Map<string, any>
    connected: boolean
}

export default class BackOfficeMainPage extends BackOfficePage<PageProps, BackOfficeLoginState> implements IPageCoordinator {
    constructor(props: any) {
        super(props)
        BackOfficeService.getInstance().setCoordinatorPage(this)
        this.state = {
            pageId: this.isUserConnected() ? Constant.DOM.BACK_OFFICE.ADMIN_HOME_PAGE : Constant.DOM.BACK_OFFICE.LOGIN_PAGE,
            data: new Map<string, any>(),
            connected: false
        }
    }

    getPageSwitcher() {
        return (pageId: any, map?: Map<string, any>) => {
            this.setState({ pageId: pageId, data: map })
        }
    }

    isUserConnected(): boolean {
        return BackOfficeService.getInstance().getBackAccount != null
    }

    getRole(): string | null {
        return BackOfficeService.getInstance().getRole()
    }

    getHomePageByRole() {
        const role = this.getRole()
        if (role != null && role === RoleBackOffice.MEMBRE_CNA) {
            return <BackOfficeManageCnaPage />
        } else {
            return <BackOfficeAdminHomePage />
        }
    }

    getContent() {
        if (this.state) {
            switch (this.state.pageId) {
                // login
                case Constant.DOM.BACK_OFFICE.LOGIN_PAGE:
                    return <BackOfficeLoginPage />

                // default admin home page
                case Constant.DOM.BACK_OFFICE.ADMIN_HOME_PAGE:
                    return this.getHomePageByRole()

                // gestion des demandes d'agrément
                case Constant.DOM.BACK_OFFICE.AGREMENT__EXTRACTION:
                    return <BackOfficeInstructionExtraction />
                case Constant.DOM.BACK_OFFICE.AGREMENT__TOUTES_LES_DEMANDES:
                    return <BackOfficeInstructionListDemandeAgrementPage key="demande-all" />
                case Constant.DOM.BACK_OFFICE.AGREMENT__DEMANDES_A_TRAITER:
                    return <BackOfficeInstructionListDemandeAgrementPage key="demande-a-traiter" etat={Constant.STATUS.DEMANDE.DEMANDE__BACK__A_TRAITER} />
                case Constant.DOM.BACK_OFFICE.AGREMENT__EN_ATTENTE_AVIS_FINANCEUR:
                    return <BackOfficeInstructionListDemandeAgrementPage key="demande-en-attente-avis-financeur" etat={Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR} />
                case Constant.DOM.BACK_OFFICE.AGREMENT__DEMANDES_EN_ATTENTE_CNA:
                    return <BackOfficeInstructionListDemandeAgrementPage key="demande-en-attente-cna" etat={Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA} />
                case Constant.DOM.BACK_OFFICE.AGREMENT__DEMANDES_PROCHAINE_CNA:
                    return <BackOfficeInstructionListDemandeAgrementPage key="demande-prochaine-cna" etat={Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA} />

                // instruction d'une demande d'agrément
                //case Constant.DOM.BACK_OFFICE.AGREMENT__SHOW_DEMANDE: return <BackOfficeInstructionPage typologies={this.state} demandeId={this.state.data?.get(Constant.KEY.BACK.DEMANDE_ID)} goBackButton={true} />

                // gestion des référentiels
                case Constant.DOM.BACK_OFFICE.REFERENTIEL__TEMPLATE_COURRIEL_DOCUMENT:
                    return <BackOfficeHtmlTemplatePage />
                case Constant.DOM.BACK_OFFICE.REFERENTIEL__TYPE_ACCORD:
                    return <BackOfficeTypeAccordPage />
                case Constant.DOM.BACK_OFFICE.REFERENTIEL__PIECES_EXIGIBLES:
                    return <BackOfficePieceExigiblePage />
                case Constant.DOM.BACK_OFFICE.REFERENTIEL__TYPOLOGIE_ACCORD:
                    return <BackOfficeTypologieAccordPage />
                case Constant.DOM.BACK_OFFICE.REFERENTIEL__MOTIF_REFUS:
                    return <BackOfficeMotifRefusPage />
                case Constant.DOM.BACK_OFFICE.REFERENTIEL__TYPE_FINANCEUR:
                    return <BackOfficeTypeFinanceurPage />
                case Constant.DOM.BACK_OFFICE.REFERENTIEL__ORGANISME_FINANCEUR:
                    return <BackOfficeOrganismeFinanceurPage />
                case Constant.DOM.BACK_OFFICE.REFERENTIEL__CONTACT_FINANCEUR:
                    return <BackOfficeContactFinanceurPage />
                case Constant.DOM.BACK_OFFICE.REFERENTIEL__ESMS:
                    return <BackOfficeEsmsPage />
                case Constant.DOM.BACK_OFFICE.REFERENTIEL__IDCC:
                    return <BackOfficeIdentifiantConventionCollectivePage />
                case Constant.DOM.BACK_OFFICE.REFERENTIEL__SIGNATURE:
                    return <BackOfficeSignaturePage />

                // CNA
                case Constant.DOM.BACK_OFFICE.CNA__MEMBRE:
                    return <BackOfficeMembreCnaPage />
                case Constant.DOM.BACK_OFFICE.CNA__LIST:
                    return <BackOfficeManageCnaPage />

                // financeur
                case Constant.DOM.BACK_OFFICE.FINANCEUR__SUIVI_AVIS:
                    return <FinanceurSuiviAvisPage />

                // compte utilisateur
                case Constant.DOM.BACK_OFFICE.UTILISATEUR__LIST_MEMBRE_CNA:
                    return <BackOfficeUtilisateurMembreCnaPage />
                case Constant.DOM.BACK_OFFICE.UTILISATEUR__LIST_FINANCEUR:
                    return <BackOfficeUtilisateurFinanceurPage />
                case Constant.DOM.BACK_OFFICE.UTILISATEUR__LIST_ASSOCIATION:
                    return <BackOfficeUtilisateurAssociationPage />

                // test page maintenance
                case Constant.DOM.BACK_OFFICE.MAINTENANCE_MENU:
                    return <BackOfficeTestMaintenancePage/>
            }
        }

        return <Page404 />
    }

    getToolLinks(): any[] {
        const toolLinks = []
        if (this.isUserConnected()) {
            toolLinks.push(
                <a href="#logout" className="fr-link fr-fi-logout-box-r-fill" rel="noopener" title="Déconnexion" id="logoutButton" onClick={() => BackOfficeService.getInstance().logout()}>
                    Déconnexion
                </a>
            )
        }
        return toolLinks
    }

    render() {
        return (
            <BackOfficePage fluid style={{ height: "100vh", padding: "0" }}>
                <HeaderComponent marianneTextFirstPart={"République"} marianneTextSecondPart={"française"} siteName={<b>ACCOLADE</b>} toolLinks={this.getToolLinks()}>
                    <BackOfficeMenuComponent role={this.getRole()} />
                </HeaderComponent>

                {this.getContent()}
            </BackOfficePage>
        )
    }
}
