import Captcha from "./lib/reactjs-captcha"
import React from "react"

import { DnumProps } from "../../../../../generic/component/DnumComponent"

import FrontComponent from "../../../FrontComponent"

interface FrontCapchaComponentProps extends DnumProps {
    setCaptchaMethod: (captcha: Captcha) => void
}

export default class FrontCaptchaComponent extends FrontComponent<FrontCapchaComponentProps, any> {
    captcha: Captcha | null = null

    constructor(props: FrontCapchaComponentProps) {
        super(props)
    }

    componentDidMount() {
        // share the captcha object with the parent
        this.props.setCaptchaMethod(this.captcha!)
    }

    render() {
        return (
            <section id="main-content">
                <div id="form-messages"></div>

                {/* captcha challenge: placeholder */}
                <Captcha
                    captchaStyleName="alphabetique6_7CaptchaFR"
                    ref={(captcha) => {
                        this.captcha = captcha
                    }}
                />
                {/* <Captcha captchaStyleName="captchaFR" ref={(captcha) => {this.captcha = captcha}} /> */}

                {/**
                    <label>
                        <span>Entrez les caractères de l'image :</span>
                        <input type="text" id="captchaFormulaireExtInput"/>
                    </label>
                    */}
            </section>
        )
    }
}
