import React, { Fragment, ReactElement } from "react"

import DemandeSignature from "../../model/DemandeSignature"

import FieldsetComponent from "../../../generic/component/FieldsetComponent"
import AlertSuccessComponent from "../../../generic/component/alert/AlertSuccessComponent"
import AlertWarningComponent from "../../../generic/component/alert/AlertWarningComponent"
import Button from "../../../generic/component/button/Button"
import DateInputComponent from "../../../generic/component/form/input/DateInputComponent"
import TextInputComponent from "../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"
import RadioButtonData from "../../../generic/component/radio/RadioButtonData"
import RadioButtonGroupComponent from "../../../generic/component/radio/RadioButtonGroupComponent"
import TextAreaComponent from "../../../generic/component/text/TextAreaComponent"

import AccoladeComponent from "../../AccoladeComponent"
import HttpSignatureService from "../../service/http/HttpSignatureService"
import UiService from "../../service/ui/UiService"
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import VerticalSpacing from "../../shared/component/space/VerticalSpacing"

interface SignatureValidationComponentState {
    demandeSignature: DemandeSignature | undefined
    error: string | undefined
}

export default class FrontSignatureValidationComponent extends AccoladeComponent<any, SignatureValidationComponentState> {
    constructor(props: any) {
        super(props)
    }

    getContent(): ReactElement {
        const radios: RadioButtonData[] = []
        radios.push(new RadioButtonData("radio-agreee", "Agréée", false, "name-radio-decision-demande", "agreee"))
        radios.push(new RadioButtonData("radio-refusee", "Refusée", false, "name-radio-decision-demande", "refusee"))

        return (
            <Fragment>
                <ContainerComponent>
                    <VerticalSpacing height={"20px"} />
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={4}>
                            <TextInputComponent label={"Identifiant numérique"} id={"signature-demande-id"} />
                        </ColumnComponent>
                        <ColumnComponent size={2} />
                        <ColumnComponent size={4}>
                            <DateInputComponent label={"Date de la décision"} id={"date-decision-demande"} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <VerticalSpacing height={"25px"} />
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <RadioButtonGroupComponent label={"Décision"} data={radios} id={"radio-decision-demande"} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextAreaComponent label={"Signature"} id={"signature-demande"} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <VerticalSpacing height={"25px"} />
                    <RowComponent>
                        <ColumnComponent size={5} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.validSignature()}>Valider</Button>
                        </ColumnComponent>
                        <ColumnComponent size={5} />
                    </RowComponent>
                    <VerticalSpacing height={"50px"} />
                    {this.getResult()}
                </ContainerComponent>
            </Fragment>
        )
    }

    validSignature() {
        const demandeId = document.querySelector("#signature-demande-id") as HTMLInputElement
        const dateDecision = document.querySelector("#date-decision-demande") as HTMLInputElement
        const decision = UiService.getRadioButtonsValue("name-radio-decision-demande")
        const signature = document.querySelector("#signature-demande") as HTMLInputElement

        //console.log("check "+demandeId.value+" "+dateDecision.value+" ["+decision+"] "+signature.value)

        if (demandeId.value != null && dateDecision.value != null && decision != null && signature.value != null) {
            new HttpSignatureService().validSignature(demandeId.value, dateDecision.value, decision, signature.value).then((response) => {
                if (response && response.success) {
                    this.setState({
                        demandeSignature: response.content,
                        error: undefined
                    })
                } else {
                    this.setState({
                        demandeSignature: undefined,
                        error: "La signature n'est pas valide"
                    })
                }
            })
        }
    }

    getResult() {
        if (this.state) {
            if (this.state.demandeSignature) {
                return (
                    <RowComponent>
                        <ColumnComponent size={3} />
                        <ColumnComponent size={6}>
                            <AlertSuccessComponent title={"La signature est valide"} />
                        </ColumnComponent>
                        <ColumnComponent size={3} />
                    </RowComponent>
                )
            } else {
                return (
                    <RowComponent>
                        <ColumnComponent size={3} />
                        <ColumnComponent size={6}>
                            <AlertWarningComponent title={"La signature n'est pas valide"} />
                        </ColumnComponent>
                        <ColumnComponent size={3} />
                    </RowComponent>
                )
            }
        }
    }

    render() {
        return (
            <RowComponent>
                <ColumnComponent size={1} />
                <ColumnComponent size={10}>
                    <FieldsetComponent legend={"Informations sur la demande concernée"} content={this.getContent()} />
                </ColumnComponent>
                <ColumnComponent size={1} />
            </RowComponent>
        )
    }
}
