import React, { ChangeEvent, ReactElement } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"
import { DnumErrorHandlerProps } from "../DnumErrorHandlerProps"
import ErrorMessageComponent from "../message/ErrorMessageComponent"
import RadioButtonComponent from "./RadioButtonComponent"
import RadioButtonData from "./RadioButtonData"
import "./RadioButtonGroupComponent.css"

interface RadioButtonGroupProps extends DnumProps, DnumErrorHandlerProps {
    label?: string
    data: RadioButtonData[]
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    vertical?: any
}

interface RadioButtonGroupsState {
    selectedValue: string
}

export default class RadioButtonGroupComponent extends DnumComponent<RadioButtonGroupProps, RadioButtonGroupsState> {
    constructor(props: RadioButtonGroupProps) {
        super(props)

        this.props.data.forEach((data) => {
            if (data.checked) {
                this.state = {
                    selectedValue: data.value
                }
            }
        })
    }

    getRadioButtons() {
        const radios: ReactElement[] = []
        this.props.data.map((item: RadioButtonData) => {
            if (this.state && this.state.selectedValue) {
                if (item.value == this.state.selectedValue) {
                    radios.push(
                        <RadioButtonComponent key={`rb-key-${item.id}`} label={item.label} id={item.id} name={item.name} value={item.value} checked={true} onChange={(e) => this.handleChange(e)} />
                    )
                } else {
                    radios.push(
                        <RadioButtonComponent key={`rb-key-${item.id}`} label={item.label} id={item.id} name={item.name} value={item.value} checked={false} onChange={(e) => this.handleChange(e)} />
                    )
                }
            } else if (item.checked) {
                radios.push(
                    <RadioButtonComponent key={`rb-key-${item.id}`} label={item.label} id={item.id} name={item.name} value={item.value} checked={item.checked} onChange={(e) => this.handleChange(e)} />
                )
            } else {
                radios.push(<RadioButtonComponent key={`rb-key-${item.id}`} label={item.label} id={item.id} name={item.name} value={item.value} onChange={(e) => this.handleChange(e)} />)
            }
            if (this.props.vertical) {
                radios.push(<br key={`br-${item.id}`} />)
            }
        })
        return radios
    }

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        if (this.props.onChange) {
            this.props.onChange(event)
        }

        this.setState({ selectedValue: event.target.value })
    }

    getErrorMessage(): ReactElement | undefined {
        if (this.props.errorMessage != null) {
            return <ErrorMessageComponent message={this.props.errorMessage} />
        }
    }

    render() {
        let propsError = {}
        let fieldsetClass = "fr-fieldset"

        if (this.props.error) {
            fieldsetClass += " fr-fieldset--error"
            propsError = { "aria-labelledby": "radio-error-legend radio-error-desc-error" }
        }
        if (!this.props.vertical) {
            fieldsetClass += " fr-fieldset--inline"
        }

        return (
            <div className="fr-form-group" style={this.props.style}>
                <fieldset className={fieldsetClass} {...propsError}>
                    <legend className="fr-fieldset__legend fr-text--regular" id="radio-legend">
                        {this.props.label}
                    </legend>
                    {this.getRadioButtons()}
                    {this.getErrorMessage()}
                </fieldset>
            </div>
        )
    }
}
