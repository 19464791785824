import React, { ChangeEvent } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface CheckboxProps extends DnumProps {
    label: string
    id: string
    checked: boolean
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    formId?: string
    disabled?: boolean
}

interface CheckboxState {
    checked: boolean
}

export default class CheckboxComponent extends DnumComponent<CheckboxProps, CheckboxState> {
    constructor(props: CheckboxProps) {
        super(props)
        this.state = { checked: this.props.checked }
    }

    componentDidUpdate(prevProps: Readonly<CheckboxProps>) {
        if (prevProps.checked !== this.props.checked) {
            this.setState({ checked: this.props.checked })
        }
    }

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        if (this.props.onChange) {
            this.props.onChange(event)
        }
        this.setState({ checked: event.target.checked })
    }

    // présent sur les 2 : defaultChecked={this.state?.checked}

    getInput(id: string, isChecked: boolean) {
        let disabled = {}
        if (this.props.disabled) {
            disabled = { disabled: this.props.disabled }
        }
        return <input type="checkbox" {...disabled} id={id} form={this.props.formId} checked={isChecked} name={this.props.id} onChange={(e) => this.handleChange(e)} />
    }

    render() {
        const id = this.generateId(`cb-${this.props.id}`)

        return (
            <div className="fr-checkbox-group">
                {this.getInput(id, this.state.checked)}
                <label className="fr-label" htmlFor={id}>
                    {this.props.label}
                </label>
            </div>
        )
    }
}
