import React, { CSSProperties } from "react"

import DnumComponent from "../../DnumComponent"

export interface PageProps {
    fluid?: any
    style?: CSSProperties
}

export default class PageComponent extends DnumComponent<PageProps, any> {
    private className = "fr-container"

    constructor(props: PageProps) {
        super(props)
    }

    getStyle() {
        return this.props.style ? this.props.style : {}
    }

    render() {
        if (this.props.fluid) {
            this.className += "fr-container--fluid"
        }

        return (
            <div className={this.className} style={this.getStyle()}>
                {this.props.children}
            </div>
        )
    }
}
