import Color from "./Color"
import Configuration from "./Configuration"
import Icon from "./Icon"
import Dom from "./dom/Dom"
import Key from "./key/Key"
import Status from "./status/Status"
import Text from "./text/Text"

export class Constant {
    public static readonly COLOR = Color

    public static readonly DOM = Dom

    public static readonly ICON = Icon

    public static readonly TEXT = Text

    public static readonly KEY = Key

    public static readonly CONFIGURATION = Configuration

    public static readonly STATUS = Status
}
