import React from "react"

import DnumComponent from "../DnumComponent"

interface LinkProps {
    icon?: null
    target?: string
    anchor?: string
    href?: string
}

export default class LinkComponent extends DnumComponent<any, any> {
    //private className = "fr-link"

    constructor(props: LinkProps) {
        super(props)
    }

    render() {
        let className = "fr-link"
        if (this.props.icon) {
            className += " " + this.props.icon + " fr-link--icon-left"
        }

        let target = "_self"
        if (this.props.target) {
            target = this.props.target
        }

        if (this.props.anchor) {
            return (
                <a className={className} href={this.props.anchor} target={target} onClick={() => this.props.onClick.apply()}>
                    {this.props.label}
                </a>
            )
        } else if (this.props.href) {
            return (
                <a className={className} href={this.props.href} target={target} onClick={() => this.props.onClick.apply()}>
                    {this.props.label}
                </a>
            )
        } else {
            return (
                <button className={className} onClick={() => this.props.onClick.apply()}>
                    {this.props.label}
                </button>
            )
        }
    }
}
