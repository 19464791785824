import React, { KeyboardEventHandler } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface AutoCompleteAddressTextInputProps extends DnumProps {
    label: string
    id: string
    value?: string
    placeholder?: string
    onKeyDownHandler?: KeyboardEventHandler
}

export default class AutoCompleteAddressTextInputComponent extends DnumComponent<AutoCompleteAddressTextInputProps, any> {
    private className = "fr-input-wrap"

    constructor(props: AutoCompleteAddressTextInputProps) {
        super(props)
    }

    getTexts() {
        return (
            <label className="fr-label" htmlFor="text-input-icon">
                {this.props.label}
            </label>
        )
    }

    onKeyDown(eventHandler: React.KeyboardEvent) {
        if (this.props.onKeyDownHandler) {
            this.props.onKeyDownHandler(eventHandler)
        }
    }

    getInput() {
        return (
            <div className={this.className}>
                <input
                    placeholder={this.props.placeholder}
                    className="fr-input"
                    type="text"
                    id={this.props.id}
                    defaultValue={this.props.value}
                    name="text-input-icon"
                    onKeyDown={this.onKeyDown.bind(this)}
                />
            </div>
        )
    }

    render() {
        return (
            <div className="fr-input-group" style={{ marginBottom: "0px" }}>
                {this.getTexts()}
                {this.getInput()}
            </div>
        )
    }
}
