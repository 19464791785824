import { Constant } from "../constant/Constant"
import FrontLegalNoticePage from "../front/legalnotice/FrontLegalNoticePage"
import EspaceFinanceurApplicationService from "../service/application/EspaceFinanceurApplicationService"
import EspaceFinanceurAccountPage from "./account/EspaceFinanceurAccountPage"
import EspaceFinanceurContactPage from "./contact/EspaceFinanceurContactPage"
import EspaceFinanceurHomePage from "./home/EspaceFinanceurHomePage"
import EspaceFinanceurLoginPage from "./login/EspaceFinanceurLoginPage"

export default class EspaceFinanceurRouter {
    applicationService: EspaceFinanceurApplicationService

    constructor(applicationService: EspaceFinanceurApplicationService) {
        this.applicationService = applicationService
    }

    search(pageId: string, pageData: Map<string, any> | undefined) {
        if (this.applicationService.isFinanceurConnected()) {
            console.info("A")
            switch (pageId) {
                case Constant.DOM.ESPACE_FINANCEUR.LOGIN_PAGE:
                    return <EspaceFinanceurLoginPage />
                case Constant.DOM.ESPACE_FINANCEUR.HOME_PAGE:
                    return <EspaceFinanceurHomePage contactFinanceur={pageData?.get(Constant.KEY.FINANCEUR.CONTACT_FINANCEUR)} />
                case Constant.DOM.ESPACE_FINANCEUR.MY_ACCOUNT:
                    return <EspaceFinanceurAccountPage />
                case Constant.DOM.ESPACE_FINANCEUR.CONTACT:
                    return <EspaceFinanceurContactPage />
                case Constant.DOM.FRONT.LEGAL_NOTICE_PAGE:
                    return <FrontLegalNoticePage />
            }
        } else {
            console.info("B")
            switch (pageId) {
                case Constant.DOM.ESPACE_FINANCEUR.CONTACT:
                    return <EspaceFinanceurContactPage />
                case Constant.DOM.FRONT.LEGAL_NOTICE_PAGE:
                    return <FrontLegalNoticePage />
                default:
                    return <EspaceFinanceurLoginPage />
            }
        }

        console.info("C")
        return <EspaceFinanceurLoginPage />
    }
}
