import React, { ReactElement } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface HighlightProps extends DnumProps {
    text?: string
    html?: ReactElement
}

export default class Highlight extends DnumComponent<HighlightProps, any> {
    constructor(props: HighlightProps) {
        super(props)
    }

    render() {
        if (this.props.html) {
            return (
                <div className="fr-highlight">
                    <p className="fr-text--sm">{this.props.html}</p>
                </div>
            )
        } else if (this.props.text) {
            return (
                <div className="fr-highlight">
                    <p className="fr-text--sm">{this.props.text}</p>
                </div>
            )
        } else {
            return <div className="fr-highlight">{this.props.children}</div>
        }
    }
}
