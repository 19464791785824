import React from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface QuadrilateralStatusProps extends DnumProps {
    width: string
    height: string
    color: string
}
export default class QuadrilateralStatus extends DnumComponent<QuadrilateralStatusProps, any> {
    constructor(props: QuadrilateralStatusProps) {
        super(props)
    }

    render() {
        const radius = "5px"
        return <div style={{ width: this.props.width, height: this.props.height, borderRadius: radius, background: this.props.color }}></div>
    }
}
