import HttpReferentielService from "./HttpReferentielService"
import Response from "./response/Response"

export default class HttpHtmlTemplateService extends HttpReferentielService {
    constructor() {
        super()
        this.uri = this.HTML_TEMPLATE_URI
    }

    public getTypes() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.request<Response<string[]>>(this.uri! + "/types", { method: "GET" })
    }

    public getCodes() {
        return this.request<Response<string[]>>(this.uri! + "/codes", { method: "GET" })
    }
}
