import { Fragment, ReactElement } from "react"

import FinancementEtablissement from "../../../../model/FinancementEtablissement"
import FinessEtablissement from "../../../../model/FinessEtablissement"
import OrganismeFinanceur from "../../../../model/OrganismeFinanceur"

import { DnumProps } from "../../../../../generic/component/DnumComponent"
import AccordionComponent from "../../../../../generic/component/accordion/AccordionComponent"
import AlertSuccessComponent from "../../../../../generic/component/alert/AlertSuccessComponent"
import AlertWarningComponent from "../../../../../generic/component/alert/AlertWarningComponent"
import Button from "../../../../../generic/component/button/Button"
import TextInputComponent from "../../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"
import ErrorMessageComponent from "../../../../../generic/component/message/ErrorMessageComponent"
import RadioButtonData from "../../../../../generic/component/radio/RadioButtonData"
import RadioButtonGroupComponent from "../../../../../generic/component/radio/RadioButtonGroupComponent"

import HttpAccountService from "../../../../service/http/HttpAccountService"
import ErrorResponse from "../../../../service/http/response/ErrorResponse"
import Response from "../../../../service/http/response/Response"
import UiService from "../../../../service/ui/UiService"
import VerticalSpacing from "../../../../shared/component/space/VerticalSpacing"
import ErrorService from "../../../../shared/service/ErrorService"
import FrontComponent from "../../../FrontComponent"

interface FrontAccountEtablissementComponentProps extends DnumProps {
    index: number
    etablissement: FinessEtablissement
    refreshFunction: () => void
}

interface FrontAccountEtablissementComponentState {
    financementDefined: boolean
    errorMessage?: string
    successMessage?: string | null
    errors?: ErrorResponse[] | undefined
    financeurs?: OrganismeFinanceur[]
}

export default class FrontAccountEtablissementComponent extends FrontComponent<FrontAccountEtablissementComponentProps, FrontAccountEtablissementComponentState> {
    constructor(props: FrontAccountEtablissementComponentProps) {
        super(props)
        this.state = {
            financementDefined: this.props.etablissement.cpomValue != null,
            errors: undefined,
            errorMessage: undefined
        }
    }

    componentDidMount() {
        new HttpAccountService().getEtablissementOrganismesFinanceur(this.props.etablissement.id!).then((response) => {
            this.setState({
                financeurs: response.content
            })
        })
    }

    isFinancementDefined() {
        const condition1 = this.state && this.state.financementDefined != null && this.state.financementDefined
        const condition2 = this.props.etablissement.cpomValue != null
        return condition1 || condition2
    }

    getEtablissementTitle(etablissement: FinessEtablissement, index: number): ReactElement {
        const financementDefined = this.isFinancementDefined()
        if (financementDefined && etablissement.categorieValide) {
            // financement ok && categorie ok
            return (
                <div>
                    {index + 1})&nbsp;&nbsp;{etablissement.raisonSociale!}
                </div>
            )
        } else if (financementDefined && !etablissement.categorieValide) {
            // financement ok && categorie ko
            return (
                <div>
                    {index + 1})&nbsp;&nbsp;{etablissement.raisonSociale!} <span style={{ color: "red", fontSize: "smaller" }}>&rarr; Non soumis à la procédure d'agrément</span>
                </div>
            )
        } else if (!financementDefined && etablissement.categorieValide) {
            // financement ko && categorie ok
            return (
                <div>
                    {index + 1})&nbsp;&nbsp;{etablissement.raisonSociale!} <span style={{ color: "red", fontSize: "smaller" }}>&rarr; Informations requises !</span>
                </div>
            )
        } else if (!financementDefined && !etablissement.categorieValide) {
            // financement ko && categorie ko
            return (
                <div>
                    {index + 1})&nbsp;&nbsp;{etablissement.raisonSociale!} <span style={{ color: "red", fontSize: "smaller" }}>&rarr; Non soumis à la procédure d'agrément</span>
                </div>
            )
        } else {
            return (
                <div>
                    {index + 1})&nbsp;&nbsp;{etablissement.raisonSociale!}
                </div>
            )
        }
    }

    getFinanceurs() {
        if (this.state && this.state.financeurs && this.state.financeurs?.length > 1) {
            const financeurs: ReactElement[] = []
            this.state.financeurs?.forEach((organisme) => {
                financeurs.push(<li key={organisme.type.id + "-" + organisme.id}>{organisme.nom}</li>)
            })

            return (
                <Fragment>
                    Financeurs :<br />
                    <ul style={{ listStyleType: "square" }}>{financeurs}</ul>
                </Fragment>
            )
        } else if (this.state && this.state.financeurs && this.state.financeurs.length > 0) {
            return <Fragment>Financeur : {this.state.financeurs[0].nom}</Fragment>
        }
    }

    getSuccessMessage() {
        if (this.state && this.state.successMessage && this.state.financementDefined) {
            return (
                <Fragment>
                    <VerticalSpacing height="15px" />
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={8}>
                            <AlertSuccessComponent title={this.state.successMessage} />
                        </ColumnComponent>
                        <ColumnComponent size={3} />
                    </RowComponent>
                    <VerticalSpacing height="20px" />
                </Fragment>
            )
        }
    }

    getErrorMessage() {
        if (this.state && this.state.errorMessage && !this.state.financementDefined) {
            return (
                <Fragment>
                    <VerticalSpacing height="15px" />
                    <ErrorMessageComponent message={this.state.errorMessage!} />
                    <VerticalSpacing height="20px" />
                </Fragment>
            )
        }
    }

    getAlert() {
        if (!this.isFinancementDefined()) {
            return (
                <Fragment>
                    <AlertWarningComponent small description={"Pour déposer une demande d'agrément pour cet établissement, il est nécessaire de renseigner les données financières ci-dessous."} />
                    <VerticalSpacing height="20px" />
                </Fragment>
            )
        }
    }

    updateEtablissementFinancement() {
        const etp = document.querySelector("#etp-" + this.props.etablissement.id) as HTMLInputElement

        const masseSalarialeBrute = document.querySelector("#masse-salariale-brute-" + this.props.etablissement.id) as HTMLInputElement
        const masseSalarialeChargee = document.querySelector("#masse-salariale-chargee-" + this.props.etablissement.id) as HTMLInputElement

        const enableEtpControl = true
        const enableMasseSalarialeBrute = false
        const enableMasseSalarialeChargee = false

        const financement = new FinancementEtablissement()
        financement.cpom = UiService.getRadioButtonsValue("cpom-etablissement-" + this.props.etablissement.id)!

        if (enableEtpControl && isNaN(Number.parseFloat(etp.value))) {
            this.setState({ errorMessage: "La valeur saisie pour le nombre d'ETP est incorrecte", successMessage: null })
            return
        } else {
            financement.etp = Number.parseFloat(etp.value)
        }
        if (enableMasseSalarialeBrute && isNaN(Number.parseFloat(masseSalarialeBrute.value))) {
            this.setState({ errorMessage: "La valeur saisie pour la masse salariale brute est incorrecte", successMessage: null })
            return
        } else {
            financement.masseSalarialeChargee = Number.parseFloat(masseSalarialeChargee.value)
        }

        if (enableMasseSalarialeChargee && isNaN(Number.parseFloat(masseSalarialeChargee.value))) {
            this.setState({ errorMessage: "La valeur  saisie pour la masse salariale chargée est incorrecte", successMessage: null })
            return
        } else {
            financement.masseSalarialeBrute = Number.parseFloat(masseSalarialeBrute.value)
        }

        new HttpAccountService().updateEtablissementFinancement(this.props.etablissement.numeroFinessEtablissement!, financement).then((frontResponse) => {
            const cast = new Response(frontResponse)

            if (cast.errorMessage) {
                this.setState({
                    errorMessage: cast.errorMessage,
                    financementDefined: false
                })
            } else if (cast.hasError()) {
                this.setState({
                    errors: cast.errors,
                    financementDefined: false
                })
            } else {
                this.setState({
                    errorMessage: undefined,
                    errors: undefined,
                    successMessage: "Les données sont enregistrées",
                    financementDefined: true
                })
            }

            this.props.refreshFunction.call(null)
        })
    }

    getCpomData(cpomValue: string | null) {
        const noChecked = "NON" === cpomValue
        const yesChecked = "SOUS_CPOM_ARS" === cpomValue || "SOUS_CPOM_ARS_CD" === cpomValue || "SOUS_CPOM_CD" === cpomValue

        const radios: RadioButtonData[] = []
        radios.push(new RadioButtonData("NON", "Non", noChecked, "cpom-etablissement-" + this.props.etablissement.id, "false"))
        radios.push(new RadioButtonData("OUI", "Oui", yesChecked, "cpom-etablissement-" + this.props.etablissement.id, "true"))
        return radios
    }

    getFinanceView() {
        const errorService = new ErrorService()

        if (this.props.etablissement.categorieValide) {
            return (
                <Fragment>
                    <br />
                    {this.getAlert()}
                    {this.getSuccessMessage()}
                    {this.getErrorMessage()}
                    <RowComponent style={{ marginTop: "30px", marginBottom: "30px" }}>
                        <ColumnComponent size={1}></ColumnComponent>
                        <ColumnComponent size={8}>
                            <u>Informations financières</u>
                            <br />
                            <br />
                            <br />
                            <RadioButtonGroupComponent
                                label="Sous CPOM ?"
                                data={this.getCpomData(this.props.etablissement.cpomValue)}
                                id="cpom-etablissement"
                                errorMessage={errorService.getErrorMessage("cpom", this.state?.errors)}
                                error={errorService.isErrorKeyPresent("cpom", this.state?.errors)}
                            />
                            <br />
                            <TextInputComponent
                                label={"ETP"}
                                number
                                value={this.props.etablissement.etp?.toString()}
                                error={errorService.isErrorKeyPresent("etp", this.state?.errors)}
                                errorMessage={errorService.getErrorMessage("etp", this.state?.errors)}
                                id={"etp-" + this.props.etablissement.id}
                            />
                            <TextInputComponent
                                label={"Masse salariale brute (en milliers d'euros)"}
                                number
                                value={this.props.etablissement.masseSalarialeBrute?.toString()}
                                error={errorService.isErrorKeyPresent("masse-salariale-brute", this.state?.errors)}
                                errorMessage={errorService.getErrorMessage("masse-salariale-brute", this.state?.errors)}
                                id={"masse-salariale-brute-" + this.props.etablissement.id}
                            />
                            <TextInputComponent
                                label={"Masse salariale chargée (en milliers d'euros)"}
                                number
                                value={this.props.etablissement.masseSalarialeChargee?.toString()}
                                error={errorService.isErrorKeyPresent("masse-salariale-chargee", this.state?.errors)}
                                errorMessage={errorService.getErrorMessage("masse-salariale-chargee", this.state?.errors)}
                                id={"masse-salariale-chargee-" + this.props.etablissement.id}
                            />

                            <div style={{ width: "100%", textAlign: "center" }}>
                                <Button onClick={() => this.updateEtablissementFinancement()}>Mettre à jour</Button>
                            </div>
                        </ColumnComponent>
                        <ColumnComponent size={3}></ColumnComponent>
                    </RowComponent>
                </Fragment>
            )
        } else {
            return <ErrorMessageComponent message={"Compte tenu de sa catégorisation cet établissement n'est pas soumis à la procédure d'agrément."} />
        }
    }

    render() {
        return (
            <AccordionComponent
                title={this.getEtablissementTitle(this.props.etablissement, this.props.index)}
                id="group-etablissement"
                index={this.props.index}
                key={"etablissement-" + this.props.index}>
                Numéro FINESS : {this.props.etablissement.numeroFinessEtablissement}
                <br />
                Numéro SIRET : {this.props.etablissement.siret != null ? this.props.etablissement.siret : "Information manquante"}
                <br />
                Catégorie : {this.props.etablissement.categorieLibelle} ({this.props.etablissement.categorieCode})<br />
                Mode de fixation des tarifs : {this.props.etablissement.modeFixationTarifLibelle} ({this.props.etablissement.modeFixationTarifCode})<br />
                {this.getFinanceurs()}
                <br />
                {this.getFinanceView()}
                <br />
            </AccordionComponent>
        )
    }
}
