import Cna from "../../model/Cna"

import HttpService from "./HttpService"
import Response from "./response/Response"

export default class HttpBackCnaService extends HttpService {
    protected uri: string | null = null

    constructor() {
        super()
        this.uri = "/back/cna"
    }

    public create(date: string) {
        const cna = new Cna()
        cna.datePrevue = date
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.request<Response<any>>(this.uri! + "/create", {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(cna)
        })
    }

    public update(date: string, cnaId: number) {
        const cna = new Cna()
        cna.datePrevue = date
        cna.id = cnaId
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.request<Response<any>>(this.uri! + "/update", {
            headers: { "Content-Type": "application/json" },
            method: "PUT",
            body: JSON.stringify(cna)
        })
    }

    public delete(cnaId: number) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.request<Response<any>>(this.uri! + "/delete?id=" + cnaId, {
            headers: { "Content-Type": "application/json" },
            method: "DELETE"
        })
    }

    public list() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.request<Response<Cna[]>>(this.uri! + "/list", {
            headers: { "Content-Type": "application/json" },
            method: "GET"
        })
    }

    public saveAvisCna(demandeId: number, cnaId: number, avisCna: string) {
        return this.request<Response<any>>(this.uri! + "/save-avis-cna?demandeId=" + demandeId + "&cnaId=" + cnaId + "&avisCna=" + avisCna, {
            headers: { "Content-Type": "application/json" },
            method: "POST"
        })
    }

    public saveAvisMinistere(demandeId: number, cnaId: number, avisMinistere: string) {
        return this.request<Response<any>>(this.uri! + "/save-avis-ministere?demandeId=" + demandeId + "&cnaId=" + cnaId + "&avisMinistere=" + avisMinistere, {
            headers: { "Content-Type": "application/json" },
            method: "POST"
        })
    }

    public validAvisCna(cnaId: number) {
        return this.request<Response<any>>(this.uri! + "/valid?id=" + cnaId, {
            headers: { "Content-Type": "application/json" },
            method: "POST"
        })
    }

    public saveRaisonRefus(demandeId: number, cnaId: number, raisonRefus: string) {
        return this.request<Response<any>>(this.uri! + "/save-raison-refus?demandeId=" + demandeId + "&cnaId=" + cnaId, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(raisonRefus)
        })
    }

    public saveCommentaireAcceptation(demandeId: number, cnaId: number, commentaireAcceptation: string) {
        return this.request<Response<any>>(this.uri! + "/save-commentaire-acceptation?demandeId=" + demandeId + "&cnaId=" + cnaId, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(commentaireAcceptation)
        })
    }

    public changeDateEffective(cnaId: number, date: string) {
        return this.request<Response<any>>(this.uri! + "/change-date-effective?cnaId=" + cnaId + "&dateEffective=" + date, {
            headers: { "Content-Type": "application/json" },
            method: "POST"
        })
    }
}
