import React, { ChangeEvent } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"
import { DnumErrorHandlerProps } from "../DnumErrorHandlerProps"
import BadgeErrorComponent from "../badge/BadgeErrorComponent"
import BadgeInfoComponent from "../badge/BadgeInfoComponent"
import BadgeWarningComponent from "../badge/BadgeWarningComponent"
import SimpleFigureComponent from "../figure/SimpleFigureComponent"
import inProgress from "./in_progress.gif"

interface FileUploadProps extends DnumProps, DnumErrorHandlerProps {
    label: string | null
    informations?: string
    inputFileIndex?: string
    onChangeHandler?: (event: ChangeEvent) => void
    acceptString?: string
    sendFileDirectly?: (input: HTMLInputElement) => void
    maxSizeInMo?: number
}

interface FileUploadState {
    level: "info" | "warning" | "error"
    error: string
}

export default class FileUploadComponent extends DnumComponent<FileUploadProps, FileUploadState> {
    constructor(props: FileUploadProps) {
        super(props)
    }

    getInformations() {
        if (this.props.informations) {
            return <span className="fr-hint-text">{this.props.informations}</span>
        }
    }

    getErrorMessage() {
        if (this.props.error) {
            return (
                <p id={this.getErrorKey()} className="fr-error-text">
                    {this.props.errorMessage}
                </p>
            )
        } else if (this.state && this.state.error) {
            return (
                <p id={this.getErrorKey()} className="fr-error-text">
                    {this.state.error}
                </p>
            )
        }
    }

    getErrorKey() {
        let inputFileIndex = this.props.inputFileIndex
        if (inputFileIndex == null) {
            inputFileIndex = "-base-index"
        }
        return "file-upload-with-error-desc-error" + inputFileIndex
    }

    onChangeWrapper(event: ChangeEvent) {
        const input: HTMLInputElement = document.querySelector("#file-upload-" + this.props.id) as HTMLInputElement

        if (this.props.onChangeHandler != null) {
            this.props.onChangeHandler(event)
        }

        if (input.files!.length == 0) {
            this.setState({ level: "warning" })
        } else if (input.files!.length > 0) {
            if (input.files![0].size > (this.props.maxSizeInMo ?? 4) * 1000 * 1024) {
                this.setState({
                    level: "error",
                    error: "Le fichier est supérieur à la taille maximum autorisée ("+(this.props.maxSizeInMo ?? 4)+" Mo)"
                })
            } else {
                if (this.props.sendFileDirectly) {
                    this.props.sendFileDirectly(input)
                }
            }
        }
    }

    getInput() {
        let errorProperties = {}
        if (this.props.error) {
            errorProperties = { "aria-describedby": this.getErrorKey() }
        }

        let accept = {}
        if (this.props.acceptString) {
            accept = { accept: this.props.acceptString }
        }

        return <input className="fr-upload" type="file" {...errorProperties} {...accept} onChange={(event) => this.onChangeWrapper(event)} id={"file-upload-" + this.props.id} name="file-upload" />
    }

    getBadge() {
        if (this.props.label) {
            if (this.state) {
                if (this.state.level === "info") {
                    return <BadgeInfoComponent label={this.props.label} noIcon={true} />
                } else if (this.state.level === "error") {
                    return <BadgeErrorComponent label={this.props.label} noIcon={true} />
                } else if (this.state.level === "warning") {
                    return <BadgeWarningComponent label={this.props.label} noIcon={true} />
                } else {
                    return this.state.level
                }
            }
            return <BadgeWarningComponent label={this.props.label} noIcon={true} />
        }
    }

    render() {
        let className = "fr-upload-group"
        if (this.props.error) {
            className += " fr-input-group--error"
        }
        return (
            <div className={className}>
                <label className="fr-label" htmlFor="file-upload">
                    {this.getBadge()}
                    {this.getInformations()}
                </label>
                <SimpleFigureComponent styleFigure={{ display: "none" }} id={this.props.id} image={inProgress} style={{ width: "100px" }} />
                {this.getInput()}
                {this.getErrorMessage()}
            </div>
        )
    }
}
