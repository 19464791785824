import React, { Fragment, ReactElement } from "react"

import { decode } from "html-entities"

import AccountData from "../../../model/AccountData"
import Cout from "../../../model/Cout"
import File from "../../../model/File"
import FileType from "../../../model/FileType"
import FinessEtablissement from "../../../model/FinessEtablissement"
import OrganismeFinanceur from "../../../model/OrganismeFinanceur"

import { DnumProps } from "../../../../generic/component/DnumComponent"
import BadgeGreenMintComponent from "../../../../generic/component/badge/BadgeGreenMintComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import HttpFrontFileService from "../../../service/http/HttpFrontFileService"
import UiService from "../../../service/ui/UiService"
import SecondaryContentBoxComponent from "../../../shared/component/box/SecondaryContentBoxComponent"
import VerticalSpacing from "../../../shared/component/space/VerticalSpacing"
import FrontComponent from "../../FrontComponent"
import FrontDemandeAgrementForm from "../FrontDemandeAgrementForm"

/**
 * Si les fichiers ne sont pas passés en props, on les charge
 */
interface FrontConsultationDemandeAgrementComponentProps extends DnumProps {
    demandeAgrement: FrontDemandeAgrementForm | undefined
    files?: File[] | undefined
    informations: AccountData | undefined
}

interface FrontConsultationDemandeAgrementComponentState {
    files?: File[] | undefined
}

export default class FrontConsultationDemandeAgrementComponent extends FrontComponent<FrontConsultationDemandeAgrementComponentProps, FrontConsultationDemandeAgrementComponentState> {
    constructor(props: FrontConsultationDemandeAgrementComponentProps) {
        super(props)
        this.state = {
            files: this.props.files
        }
    }

    componentDidUpdate(prevProps: Readonly<FrontConsultationDemandeAgrementComponentProps>, prevState: Readonly<FrontConsultationDemandeAgrementComponentState>, snapshot?: any) {
        if (this.props.files == null && this.state.files == null) {
            this.loadData()
        }
    }

    componentDidMount() {
        if (this.props.files == null) {
            this.loadData()
        }
    }

    loadData() {
        new HttpFrontFileService().getDemandesFiles(this.props.demandeAgrement!.id!).then((response) => {
            if (response.content != null && response.content.length > 0) {
                this.setState({ files: response.content })
            }
        })
    }

    getTitleComponent(title: string) {
        return <BadgeGreenMintComponent key={title} label={title} />
    }

    getTitleData() {
        return this.getTitleComponent("1 - Données de l'accord")
    }

    getTitleCouts() {
        return this.getTitleComponent("2 - Coût de la mesure")
    }

    getTitlePiecesJointes() {
        return this.getTitleComponent("3 - Pièces jointes")
    }

    getDueDateSignature() {
        if (this.props.demandeAgrement?.dateSignature != null) {
            return <div>DUE signée le : {UiService.formatServerDateToHumanDate(this.props.demandeAgrement?.dateSignature)}</div>
        }
    }

    getAccordDateSignature() {
        if (this.props.demandeAgrement?.dateSignature != null) {
            return <div>Accord signé le : {UiService.formatServerDateToHumanDate(this.props.demandeAgrement?.dateSignature)}</div>
        }
    }

    getNumeroDossier() {
        if (this.props.demandeAgrement?.numeroDossier != null) {
            return <div>Numéro de dossier : {decode(this.props.demandeAgrement?.numeroDossier)}</div>
        }
    }

    getAccordDateDepot() {
        if (this.props.demandeAgrement?.dateDepot != null) {
            return <div>Date de dépôt : {UiService.formatServerDateToHumanDate(this.props.demandeAgrement?.dateDepot)}</div>
        }
    }

    getEtablissementsConcernes() {
        const concernes: ReactElement[] = []

        this.props.demandeAgrement?.etablissements?.forEach((etablissement: FinessEtablissement) => {
            concernes.push(
                <li key={"concerne-" + etablissement.id}>
                    <span style={{ paddingTop: "15px" }}>{etablissement.raisonSociale}</span>
                </li>
            )
        })

        return concernes
    }

    getTitleEtablissementsConcernes() {
        const count: number = this.props.demandeAgrement?.etablissements?.length ?? 0
        if (count > 1) {
            return <span>Les {count} établissements concernés</span>
        } else if (count == 1) {
            return <span>Un établissement concerné</span>
        }
    }

    getConventionCollective() {
        return "Convention collective appliquée : " + this.props.informations?.idcc?.label
    }

    getEffectifsEtSalaires() {
        return (
            <>
                Courriel de contact : {this.props.demandeAgrement?.emailContact}
                <br />
                Masse salariale chargée des ESMS concernés :{" "}
                {this.props.demandeAgrement?.etablissements?.reduce((accumulator, obj) => {
                    return accumulator + obj.masseSalarialeChargee!
                }, 0)}
                <br />
                Nombre d'ETP au total :{" "}
                {this.props.demandeAgrement?.etablissements?.reduce((accumulator, obj) => {
                    return accumulator + obj.etp!
                }, 0)}
                <br />
                ETP concernés par la mesure : {this.props.demandeAgrement?.etpConcernes}
            </>
        )
    }

    getThematique() {
        return this.props.demandeAgrement?.typologieAccord?.libelle
    }

    hasDataForAnneeN(annee: 0 | 1 | 2 | 3): boolean {
        let flag = false
        this.props.demandeAgrement?.financement?.forEach((financement) => {
            if (financement.a == annee) {
                if ((financement.picf ?? 0) > 0 || (financement.picv ?? 0) > 0 || (financement.ds ?? 0) > 0 || (financement.mn ?? 0) > 0 || (financement.rp ?? 0) > 0) {
                    flag = true
                }
            }
        })
        return flag
    }

    getAnneeN(annee: 0 | 1 | 2 | 3): ReactElement {
        const ligneFinancement: ReactElement[] = []
        const financeurs = new Map<number, OrganismeFinanceur>()
        this.props.demandeAgrement?.etablissements?.forEach((etablissement) => {
            etablissement.financeurs?.forEach((financeur) => financeurs.set(financeur.id!, financeur))
        })

        // Financeurs
        this.props.demandeAgrement?.financement?.forEach((financement, index) => {
            if (financement.id != null && financement.a == annee) {
                ligneFinancement.push(
                    <div key={"annee-" + annee + "-1-" + index}>
                        {financeurs.get(financement.id)?.nom} : {this.getCleanSomme(financement)} €
                    </div>
                )
            }
        })
        // CAF
        this.props.demandeAgrement?.financement?.forEach((financement, index) => {
            if ((financement.picf ?? 0) > 0 && financement.a == annee) {
                ligneFinancement.push(<div key={"annee-" + annee + "-2-" + index}>CAF : {financement.picf} €</div>)
            }
        })
        // CNAV
        this.props.demandeAgrement?.financement?.forEach((financement, index) => {
            if ((financement.picf ?? 0) > 0 && financement.a == annee) {
                ligneFinancement.push(<div key={"annee-" + annee + "-3-" + index}>CNAV : {financement.picv} €</div>)
            }
        })
        return <Fragment key={"annee-" + annee}>{ligneFinancement}</Fragment>
    }

    getCleanSomme(cout: Cout): number {
        const a = cout.rp ?? 0
        const b = cout.ds ?? 0
        const c = cout.mn ?? 0
        return a + b + c
    }

    getPiecesJointes(styleFieldset: object) {
        let localFiles = this.props.files
        if ((localFiles == null || localFiles.length == 0) && this.state && this.state.files && this.state.files.length > 0) {
            localFiles = this.state.files
        }
        const elements: ReactElement[] = []
        if (localFiles?.length ?? 0 > 0) {
            let indexPJ = 1
            let documentAccord = true
            localFiles?.forEach((file, index) => {
                if (documentAccord && file && file.type == FileType.ACCORD_DUE) {
                    documentAccord = false
                    if (this.props.demandeAgrement?.typeDemande == "DUE") {
                        elements.push(
                            <fieldset key={"fs-pieces-jointes-due"} style={styleFieldset}>
                                <legend style={{ fontWeight: "bold" }}>DUE</legend>
                                {FrontConsultationDemandeAgrementComponent.applicationService.buildLink(file)}
                            </fieldset>
                        )
                        elements.push(<br key={"index-separator-file-"+index}/>)
                        //elements.push(<br />)
                    } else {
                        elements.push(
                            <fieldset style={styleFieldset} key={"fs-pieces-jointes-accord"}>
                                <legend style={{ fontWeight: "bold" }}>Accord</legend>
                                {FrontConsultationDemandeAgrementComponent.applicationService.buildLink(file)}
                            </fieldset>
                        )
                        elements.push(<br key={"index-separator-file-"+index}/>)
                        //elements.push(<br />)
                    }
                }
            })
            localFiles?.forEach((file, index) => {
                if (file && file.type?.indexOf("PIECE") != -1) {
                    elements.push(
                        <fieldset key={"fs-pieces-jointes-" + file.type} style={styleFieldset}>
                            <legend style={{ fontWeight: "bold" }}>Pièce complémentaire {indexPJ++}</legend>
                            {FrontConsultationDemandeAgrementComponent.applicationService.buildLink(file)}
                        </fieldset>
                    )
                    elements.push(<br key={"index-separator-file-"+(10 + index)}/>)
                }
            })
        }
        return elements
    }

    getAnneeOptionnelle(anneeOptionnelle: 2 | 3, styleFieldset: object): ReactElement {
        if (this.hasDataForAnneeN(anneeOptionnelle)) {
            return (
                <>
                    <VerticalSpacing key={"separator-annee-" + anneeOptionnelle} height="40px" />
                    <fieldset key={"fs-annee-n-" + anneeOptionnelle} style={styleFieldset}>
                        <legend style={{ fontWeight: "bold" }}>Année N+{anneeOptionnelle}</legend>
                        {this.getAnneeN(anneeOptionnelle)}
                    </fieldset>
                </>
            )
        }
        return <></>
    }

    getDueRender() {
        const styleFieldset = {
            paddingLeft: "30px",
            borderRadius: "10px",
            padding: "25px"
        }

        return (
            <Fragment key="due-details">
                <br key={'br1'} />
                {this.getTitleData()}
                <br key={'br2'} />
                <br key={'br3'} />
                <fieldset key={"fs-general"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>&nbsp;Générales&nbsp;</legend>
                    {this.getDueDateSignature()}
                    <br key={'br4'} />
                    {this.getConventionCollective()}
                    <br key={'br5'} />
                </fieldset>
                <VerticalSpacing key={"separator-1"} height="40px" />
                <fieldset key={"fs-etablissements"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>{this.getTitleEtablissementsConcernes()}</legend>
                    <RowComponent>
                        <div style={{ paddingLeft: "12px" }}>
                            {this.getEtablissementsConcernes()}
                            <br key={'br6'} />
                        </div>
                    </RowComponent>
                </fieldset>
                <VerticalSpacing key={"separator-2"} height="40px" />
                <fieldset key={"fs-effectifs-et-salaires"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>Effectifs et salaires</legend>
                    {this.getEffectifsEtSalaires()}
                    <br key={'br7'} />
                </fieldset>
                <VerticalSpacing key={"separator-3"} height="40px" />
                <fieldset key={"fs-thematique"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>Thématique de l'accord</legend>
                    {this.getThematique()}
                </fieldset>
                <VerticalSpacing key={"separator-4"} height="80px" />
                {this.getTitleCouts()}
                <br key={'br8'} />
                <br key={'br9'} />
                {this.getCouts(styleFieldset)}
                <VerticalSpacing key={"separator-6"} height="40px" />
                {this.getInformationsComplementaires(styleFieldset)}
                <VerticalSpacing key={"separator-7"} height="80px" />
                {this.getTitlePiecesJointes()}
                <br key={'br10'} />
                <br key={'br11'} />
                {this.getPiecesJointes(styleFieldset)}
                <br key={'br12'} />
            </Fragment>
        )
    }

    getCouts(styleFieldset: object) {
        if (this.props.demandeAgrement?.sansImpactFinancier) {
            return <span>Sans impact financier</span>
        } else {
            return (
                <Fragment>
                    <fieldset key={"fs-annee-n"} style={styleFieldset}>
                        <legend style={{ fontWeight: "bold" }}>Année N</legend>
                        {this.getAnneeN(0)}
                    </fieldset>
                    <VerticalSpacing key={"separator-5"} height="40px" />
                    <fieldset key={"fs-annee-n-1"} style={styleFieldset}>
                        <legend style={{ fontWeight: "bold" }}>Année N+1</legend>
                        {this.getAnneeN(1)}
                    </fieldset>
                    {this.getAnneeOptionnelle(2, styleFieldset)}
                    {this.getAnneeOptionnelle(3, styleFieldset)}
                </Fragment>
            )
        }
    }

    getAccordRender() {
        const styleFieldset = {
            paddingLeft: "30px",
            borderRadius: "10px",
            padding: "25px"
        }

        return (
            <Fragment key="accord-details">
                <br key={'br1'} />
                {this.getTitleData()}
                <br key={'br2'}/>
                <br key={'br3'}/>
                <fieldset key={"fs-general"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>&nbsp;Générales&nbsp;</legend>
                    {this.getNumeroDossier()}
                    <br key={'br4'} />
                    {this.getAccordDateSignature()}
                    <br key={'br5'} />
                    {this.getAccordDateDepot()}
                    <br key={'br6'} />
                    {this.getConventionCollective()}
                    <br key={'br7'} />
                </fieldset>
                <VerticalSpacing key={"separator-1"} height="40px" />
                <fieldset key={"fs-etablissements"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>{this.getTitleEtablissementsConcernes()}</legend>
                    <div style={{ paddingLeft: "12px" }}>
                        {this.getEtablissementsConcernes()}
                        <br key={'br8'} />
                    </div>
                </fieldset>
                <VerticalSpacing key={"separator-2"} height="40px" />
                <fieldset key={"fs-effectifs-et-salaires"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>Effectifs et salaires</legend>
                    {this.getEffectifsEtSalaires()}
                </fieldset>
                <VerticalSpacing key={"separator-3"} height="40px" />
                <fieldset key={"fs-thematique"} style={styleFieldset}>
                    <legend style={{ fontWeight: "bold" }}>Thématique de l'accord</legend>
                    {this.getThematique()}
                </fieldset>
                <VerticalSpacing key={"separator-4"} height="80px" />
                {this.getTitleCouts()}
                <br key={'br9'} />
                <br key={'br10'} />
                {this.getCouts(styleFieldset)}
                <VerticalSpacing key={"separator-6"} height="40px" />
                {this.getInformationsComplementaires(styleFieldset)}
                <VerticalSpacing key={"separator-7"} height="80px" />
                {this.getTitlePiecesJointes()}
                <br key={'br11'} />
                <br key={'br12'} />
                {this.getPiecesJointes(styleFieldset)}
                <br key={'br13'} />
            </Fragment>
        )
    }

    getInformationsComplementaires(styleFieldset: object) {
        if (this.props.demandeAgrement?.explication != null && this.props.demandeAgrement.explication !== "") {
            return (
                <Fragment>
                    <VerticalSpacing key={"separator-info-complement"} height="40px" />
                    <fieldset key={"fs-info-complement"} style={styleFieldset}>
                        <legend style={{ fontWeight: "bold" }}>Explications complémentaires</legend>
                        {decode(this.props.demandeAgrement.explication)}
                    </fieldset>
                </Fragment>
            )
        }
    }

    getTitle(): string {
        if (this.props.demandeAgrement?.typeDemande === "DUE") {
            return "Demande d'agrément sur DUE"
        } else {
            return "Demande d'agrément sur l'accord " + this.props.demandeAgrement?.numeroDossier
        }
    }

    render() {
        if (this.state) {
            return (
                <SecondaryContentBoxComponent title={decode(this.getTitle())}>
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.props.demandeAgrement?.typeDemande === "ACCORD" ? this.getAccordRender() : this.getDueRender()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                </SecondaryContentBoxComponent>
            )
        } else {
            return <>{null}</>
        }
    }
}
