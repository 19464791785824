export default class Cna {
    id: number | null = null

    datePrevue: string | null = null
    dateEffective: string | null = null
    dateValidation: string | null = null

    userCreation: string | null = null
    userModification: string | null = null
    userValidation: string | null = null
}
