import { Component, ReactElement } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface TableContainerProps extends DnumProps {
    bordered?: any
    noscroll?: any
    fixedlayout?: any
    caption?: string
    captionBottom?: any
    headers?: Component[] | ReactElement[]
    cells?: Component[] | ReactElement[]
}
export default class TableContainerComponent extends DnumComponent<TableContainerProps, any> {
    private className = "fr-table"

    constructor(props: TableContainerProps) {
        super(props)
        if (this.props.bordered) {
            this.className += " fr-table--bordered"
        } else if (this.props.noscroll) {
            this.className += " fr-table--no-scroll"
        } else if (this.props.fixedlayout) {
            this.className += " fr-table--layout-fixed"
        } else if (this.props.captionBottom) {
            this.className += " fr-table--caption-bottom"
        }
    }

    getHeaders() {
        if (this.props.headers) {
            return <thead>{this.props.headers}</thead>
        }
    }

    getBody() {
        if (this.props.cells) {
            return <tbody>{this.props.cells}</tbody>
        }
    }

    getCaption() {
        if (this.props.caption) {
            return <caption>{this.props.caption}</caption>
        }
    }

    render() {
        return (
            <div className={this.className}>
                <table>
                    {this.getCaption()}
                    {this.getHeaders()}
                    {this.getBody()}
                </table>
            </div>
        )
    }
}
