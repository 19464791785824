import { ReactElement } from "react"

import MembreCna from "../../../model/MembreCna"

import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import TextInputDataListItem from "../../../../generic/component/form/input/TextInputDataListItem"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielFormComponent from "../BackOfficeAbstractReferentielFormComponent"

export default class BackOfficeMembreCnaFormComponent extends BackOfficeAbstractReferentielFormComponent<MembreCna, null> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useMembreCnaService()
    }

    formToObject(id: number): MembreCna {
        const membreCna = new MembreCna(this.props.objectId)
        membreCna.organisme = (document.querySelector("#membre-cna-organisme") as HTMLInputElement).value
        membreCna.email = (document.querySelector("#membre-cna-email") as HTMLInputElement).value
        membreCna.nom = (document.querySelector("#membre-cna-nom") as HTMLInputElement).value
        membreCna.prenom = (document.querySelector("#membre-cna-prenom") as HTMLInputElement).value
        membreCna.active = (document.querySelector("input[name='membre-cna-actif']") as HTMLInputElement).checked
        return membreCna
    }

    componentDidMount() {
        super.componentDidMount()
        this.getHttpReferentielService()
            .getAll<MembreCna>()
            .then((response) => {
                const allOrganisme: string[] = []
                response.content?.forEach((item: MembreCna) => {
                    allOrganisme.push(item.organisme!)
                })
                const dataList = new Set<string>()
                allOrganisme.forEach((value: string) => {
                    if (!dataList.has(value)) {
                        dataList.add(value)
                    }
                })
                this.setState({ dataList: dataList })
            })
    }

    getActiveCheckbox(): ReactElement {
        const condition = this.state && this.state.item && this.state.item.active
        return this.getCheckbox("membre-cna-actif", "Actif", condition!)
    }

    getContent(): ReactElement | undefined {
        const create = this.props.objectId == null
        const edit = this.props.objectId !== null && this.state && this.state.item !== null
        let dataListProps = {}
        const dataList: TextInputDataListItem[] | undefined = this.getDataList()
        if (dataList != null && dataList.length > 0) {
            dataListProps = { dataList: dataList }
        }
        if (create || edit) {
            return (
                <>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Organisme"
                                id="membre-cna-organisme"
                                {...dataListProps}
                                value={this.getProperty("organisme")}
                                error={this.state?.errors?.has("organisme")}
                                errorMessage={this.state?.errors?.get("organisme")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent label="Nom" id="membre-cna-nom" value={this.getProperty("nom")} error={this.state?.errors?.has("nom")} errorMessage={this.state?.errors?.get("nom")} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Prénom"
                                id="membre-cna-prenom"
                                value={this.getProperty("prenom")}
                                error={this.state?.errors?.has("prenom")}
                                errorMessage={this.state?.errors?.get("prenom")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Email"
                                id="membre-cna-email"
                                value={this.getProperty("email")}
                                error={this.state?.errors?.has("email")}
                                errorMessage={this.state?.errors?.get("email")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getActiveCheckbox()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={4} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.save()}>Enregistrer</Button>
                        </ColumnComponent>
                        <ColumnComponent middle size={2}>
                            <Button secondary onClick={() => this.props.showListHandler()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={4} />
                    </RowComponent>
                </>
            )
        }
    }
}
