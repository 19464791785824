import { ChangeEvent, ReactElement } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"
import { DnumErrorHandlerProps } from "../DnumErrorHandlerProps"
import SelectOptionData from "./SelectOptionData"

interface SelectProps extends DnumProps, DnumErrorHandlerProps {
    label: string
    question?: string
    data: SelectOptionData[]
    successMessage?: string
    value?: string
    disabled?: boolean
    onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
}

interface SelectState {
    value?: string
}

export default class SelectComponent extends DnumComponent<SelectProps, SelectState> {
    static QUESTION_VALUE = "selectComponentQuestion"

    constructor(props: SelectProps) {
        super(props)
        this.state = { value: this.props.value }
    }

    componentDidMount() {
        this.setState({ value: this.props.value })
    }

    componentDidUpdate(prevProps: Readonly<SelectProps>, prevState: Readonly<SelectState>, snapshot?: any) {
        if (!(this.props.value === prevProps.value)) {
            this.setState({ value: this.props.value })
        }
    }

    getQuestion() {
        if (this.props.question) {
            return (
                <option key={SelectComponent.QUESTION_VALUE} value={SelectComponent.QUESTION_VALUE} disabled hidden>
                    {this.props.question}
                </option>
            )
        }
    }

    getOptions() {
        const options: ReactElement[] = []
        this.props.data.forEach((item, index) => {
            // TODO remove "selected" attribute, en passant value au component on n a plus besoin de cet attribut
            if (item.selected) {
                options.push(
                    <option key={"option-" + index} value={item.id}>
                        {item.label}
                    </option>
                )
            } else {
                options.push(
                    <option key={"option-" + index} value={item.id}>
                        {item.label}
                    </option>
                )
            }
        })
        return options
    }

    onChange(event: ChangeEvent<HTMLSelectElement>) {
        this.setState({ value: event.target.value })
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    private getErrorTemplate() {
        let disable = { disabled: false }
        if (this.props.disabled) {
            disable = { disabled: true }
        }
        const style = this.props.style ? this.props.style : {}
        return (
            <div className="fr-select-group fr-select-group--error">
                <label className="fr-label" htmlFor={this.props.id}>
                    {this.props.label}
                </label>
                <select
                    className="fr-select fr-select--error"
                    aria-describedby="select-error-desc-error"
                    onChange={(event) => this.onChange(event)}
                    {...disable}
                    style={style}
                    id={this.props.id}
                    name="select-error"
                    defaultValue={this.state.value}>
                    {this.getQuestion()}
                    {this.getOptions()}
                </select>
                <p id="select-error-desc-error" className="fr-error-text">
                    {this.props.errorMessage}
                </p>
            </div>
        )
    }

    private getSuccessTemplate() {
        let disable = { disabled: false }
        if (this.props.disabled) {
            disable = { disabled: true }
        }
        const style = this.props.style ? this.props.style : {}

        return (
            <div className="fr-select-group fr-select-group--valid">
                <label className="fr-label" htmlFor={this.props.id}>
                    {this.props.label}
                </label>
                <select
                    className="fr-select fr-select--valid"
                    aria-describedby="select-valid-desc-valid"
                    onChange={(event) => this.onChange(event)}
                    {...disable}
                    style={style}
                    id={this.props.id}
                    name="select-valid"
                    value={this.state.value}>
                    {this.getQuestion()}
                    {this.getOptions()}
                </select>
                <p id="select-valid-desc-valid" className="fr-valid-text">
                    {this.props.successMessage}
                </p>
            </div>
        )
    }

    private getTemplate() {
        let disable = { disabled: false }
        if (this.props.disabled) {
            disable = { disabled: true }
        }
        const style = this.props.style ? this.props.style : {}
        return (
            <div className="fr-select-group">
                <label className="fr-label" htmlFor="select">
                    {this.props.label}
                </label>
                <select
                    className="fr-select"
                    {...disable}
                    style={style}
                    onChange={(event) => this.onChange(event)}
                    id={this.props.id}
                    name="select"
                    value={this.state ? this.state.value : this.props.value}>
                    {this.getQuestion()}
                    {this.getOptions()}
                </select>
            </div>
        )
    }

    render() {
        if (this.props.successMessage) {
            return this.getSuccessTemplate()
        } else if (this.props.errorMessage) {
            return this.getErrorTemplate()
        } else {
            return this.getTemplate()
        }
    }
}
