import HttpService from "./HttpService"
import Response from "./response/Response"

export default class HttpFrontReferentielService extends HttpService {
    TYPOLOGIE_ACCORD_URI = "/front/typologie_accord"

    getAll<TypologieAccord>() {
        return this.request<Response<TypologieAccord[]>>(this.TYPOLOGIE_ACCORD_URI, { method: "GET" })
    }
}
