import AccoladeComponent from "../../accolade/AccoladeComponent"

import EspaceFinanceurApplicationService from "../service/application/EspaceFinanceurApplicationService"

export default class EspaceFinanceurComponent<P, S> extends AccoladeComponent<P, S> {

    protected static applicationService = new EspaceFinanceurApplicationService()

    cleanString(value: string | null | undefined): string {
        if (value != null) {
            return value
        }
        return ""
    }
}
