import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DnumComponent from "../DnumComponent";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";

export default class LoadingComponent extends DnumComponent<any, any> {
    render() {
        return(
            <FontAwesomeIcon icon={faRedoAlt} spin size="lg"/>
        )       
    }
}