import React, { CSSProperties, MouseEventHandler } from "react"

import DnumComponent from "../DnumComponent"

interface ButtonProps {
    onClick?: MouseEventHandler
    style?: CSSProperties
    width?: string
    secondary?: any
    group?: any
    small?: any
    medium?: any
    large?: any
    iconLeft?: string
    iconRight?: string
    iconOnly?: string
    modal?: any
    disabled?: any
}

export default class Button extends DnumComponent<ButtonProps, any> {
    private width: string
    private onClick?: MouseEventHandler
    protected className = "fr-btn"

    constructor(props: ButtonProps) {
        super(props)

        this.width = "auto"
        if (props.width) {
            this.width = props.width
        }

        if (props.onClick) {
            this.onClick = props.onClick
        }

        if (this.props.small) {
            this.className += " fr-btn--sm"
        } else if (this.props.medium) {
            this.className += " fr-btn--md"
        } else if (this.props.large) {
            this.className += " fr-btn--lg"
        }

        if (this.props.iconLeft) {
            this.className += " " + this.props.iconLeft + " fr-btn--icon-left"
        } else if (this.props.iconRight) {
            this.className += " " + this.props.iconRight + " fr-btn--icon-right"
        } else if (this.props.iconOnly) {
            this.className += " " + this.props.iconOnly
        }

        if (props.secondary) {
            this.className += " fr-btn--secondary"
        }
    }

    getButton() {
        let disabledProp = {}
        if (this.props.disabled) {
            disabledProp = { disabled: true }
        }

        if (this.props.style) {
            // this.props.style.width = this.width
            this.props.style.textAlign = "center"
            this.props.style.display = "block"
        }

        let modalProps = {}
        if (this.props.modal) {
            modalProps = {
                "data-fr-opened": false,
                "aria-controls": this.props.modal,
                "data-fr-js-modal": true
            }
        }

        if (this.props.children) {
            return (
                <button className={this.className} {...modalProps} {...disabledProp} style={this.props.style} title={this.props.children as string} onClick={this.onClick}>
                    {this.props.children}
                </button>
            )
        } else {
            return (
                <button className={this.className} {...modalProps} {...disabledProp} style={this.props.style} onClick={this.onClick}>
                    {this.props.children}
                </button>
            )
        }
    }

    render() {
        if (this.props.group) {
            return <li>{this.getButton()}</li>
        } else {
            return this.getButton()
        }
    }
}
