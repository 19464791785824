import React from "react"

import ColumnComponent from "../grid/column/ColumnComponent"
import RowComponent from "../grid/row/RowComponent"
import AbstractHeadBandComponent from "./AbstractHeadBandComponent"

export default class HeadBand6Component extends AbstractHeadBandComponent {
    render() {
        if (this.props.rightComponent) {
            return (
                <div>
                    <RowComponent>
                        <ColumnComponent size={8}>
                            <h6 style={this.getStyle()} className={this.getClassName()}>
                                {this.props.label}
                            </h6>
                        </ColumnComponent>
                        <ColumnComponent size={4}>{this.props.rightComponent}</ColumnComponent>
                    </RowComponent>
                </div>
            )
        } else {
            return (
                <h6 style={this.getStyle()} className={this.getClassName()}>
                    {this.props.label}
                </h6>
            )
        }
    }
}
