import HttpAdresseService from "../../service/HttpAdresseService"
import DnumComponent, { DnumProps } from "../DnumComponent"
import "./AutoCompleteAddress.css"
import AutoCompleteAddressTextInputComponent from "./AutoCompleteAddressTextInputComponent"

interface AutoCompleteAddressProps extends DnumProps {
    label: string
    id: string
    onSelectItemHandler: any
}

export default class AutoCompleteAddress extends DnumComponent<AutoCompleteAddressProps, any> {
    constructor(props: AutoCompleteAddressProps) {
        super(props)
        this.state = {
            results: [],
            displayResults: "none"
        }
    }

    onKeyDown() {
        const element: HTMLInputElement = document.getElementById(this.props.id) as HTMLInputElement

        if (element.value.length < 4) {
            this.setState({
                results: [],
                displayResults: "none"
            })
        } else {
            new HttpAdresseService().search(element.value).then((json) => {
                this.setState({
                    results: json.features,
                    displayResults: "block"
                })
            })
        }
    }

    getResults(): any[] {
        const items: any[] = []
        if (this.state && this.state.results && this.state.results.length > 0) {
            let index = 0
            this.state.results.forEach((item: any) => {
                const search = () => this.onAddressSelected(item.properties)
                items.push(
                    <li onClick={search} key={"key" + index++}>
                        {item.properties.label}
                    </li>
                )
            })
        }
        return items
    }

    onAddressSelected(address: JSON | any) {
        this.props.onSelectItemHandler(address)
        this.setState({
            results: [],
            displayResults: "none"
        })
    }

    render() {
        return (
            <>
                <AutoCompleteAddressTextInputComponent label={this.props.label} id={this.props.id} onKeyDownHandler={() => this.onKeyDown()} />
                <ul className="suggestions" style={{ overflow: "overlay", display: this.state.displayResults, marginTop: "0px" }}>
                    {this.getResults()}
                </ul>
            </>
        )
    }
}
