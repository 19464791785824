import React, { Fragment } from "react"

import { decode } from "html-entities"

import Adresse from "../../../../model/Adresse"

import { DnumProps } from "../../../../../generic/component/DnumComponent"
import AutoCompleteAddress from "../../../../../generic/component/address/AutoCompleteAddress"
import Button from "../../../../../generic/component/button/Button"
import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"

import HttpAccountService from "../../../../service/http/HttpAssociationAccountService"
import ErrorResponse from "../../../../service/http/response/ErrorResponse"
import Response from "../../../../service/http/response/Response"
import FrontComponent from "../../../FrontComponent"

interface FrontAccountAdresseComponentProps extends DnumProps {
    adresse: Adresse
}

interface FrontAccountAdresseComponentState {
    mode: "view" | "edit"
    saved: boolean
    errors: ErrorResponse[] | null
    housenumber: string
    street: string
    postcode: string
    city: string
}

export default class FrontAccountAdresseComponent extends FrontComponent<FrontAccountAdresseComponentProps, FrontAccountAdresseComponentState> {
    constructor(props: FrontAccountAdresseComponentProps) {
        super(props)
    }

    clickEdit() {
        this.setState({ mode: "edit" })
    }

    clickCancel() {
        this.setState({ mode: "view" })
    }

    getAdresse() {
        if (this.state && this.state.city != null) {
            return (
                <Fragment>
                    Voie : {(this.state.housenumber != null ? decode(this.state.housenumber) + " " : "") + decode(this.state.street)}
                    <br />
                    <br />
                    Commune : {decode(this.state.postcode) + " " + decode(this.state.city)}
                    <br />
                </Fragment>
            )
        } else if (this.props.adresse?.numero != null) {
            return (
                <Fragment>
                    Voie : {decode(this.props.adresse?.numero) + " " + decode(this.props.adresse?.rue)}
                    <br />
                    <br />
                    Commune : {decode(this.props.adresse?.codePostal) + " " + decode(this.props.adresse?.commune)}
                    <br />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    Voie : {decode(this.props.adresse?.rue)}
                    <br />
                    <br />
                    Commune : {decode(this.props.adresse?.codePostal) + " " + decode(this.props.adresse?.commune)}
                    <br />
                </Fragment>
            )
        }
    }

    onAdresseSelected(item: any) {
        const inputElement: HTMLInputElement = document.querySelector("#new-adresse") as HTMLInputElement
        inputElement.value = item.label

        new HttpAccountService().saveAdresse(item.housenumber, item.housenumber != null ? item.street : item.name, item.postcode, item.city).then((response) => {
            const cast = new Response(response)
            if (cast.errors && cast.errors.length > 0) {
                this.setState({ errors: cast.errors })
            } else {
                this.setState({
                    saved: true,
                    mode: "view",
                    housenumber: item.housenumber,
                    street: item.housenumber != null ? item.street : item.name,
                    postcode: item.postcode,
                    city: item.city
                })
            }
        })
    }

    render() {
        if (this.state?.mode === "edit") {
            return (
                <Fragment key={"fragment-edit-adresse"}>
                    <RowComponent id={"row-edit-adresse"}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={7}>
                            <AutoCompleteAddress label={"Définissez votre adresse"} id={"new-adresse"} onSelectItemHandler={(item: any) => this.onAdresseSelected(item)} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={2}>
                            <Button key="click-cancel-address" style={{ width: "120px" }} onClick={() => this.clickCancel()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                </Fragment>
            )
        } else {
            return (
                <Fragment key={"fragment-view-adresse"}>
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={7}>{this.getAdresse()}</ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={2}>
                            <Button key="click-edit-address" style={{ width: "120px" }} onClick={() => this.clickEdit()}>
                                Editer
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                </Fragment>
            )
        }
    }
}
