import React from "react"

import { Constant } from "../../../constant/Constant"
import BackOfficeAbstractReferentielPage from "../BackOfficeAbstractReferentielPage"
import BackOfficeMembreCnaFormComponent from "./BackOfficeMembreCnaFormComponent"
import BackOfficeMembreCnaListComponent from "./BackOfficeMembreCnaListComponent"

export default class BackOfficeMembreCnaPage extends BackOfficeAbstractReferentielPage {
    getObjectTitle(): string {
        return "Membres de la CNA"
    }

    getFormComponent(itemId: number | null, showListMethod: () => void): React.ReactElement {
        return <BackOfficeMembreCnaFormComponent objectId={itemId} showListHandler={showListMethod} />
    }

    getListComponent(forceRefresh: string, addButtonHandler: () => void, editButtonHandler: (id: number | null) => void, deleteButtonHandler: (id: number | null) => void): React.ReactElement {
        return <BackOfficeMembreCnaListComponent forceRefresh={forceRefresh} addButtonHandler={addButtonHandler} editButtonHandler={editButtonHandler} deleteButtonHandler={deleteButtonHandler} />
    }

    getId(): string {
        return Constant.DOM.BACK_OFFICE.CNA__MEMBRE
    }
}
