import ErrorResponse from "./ErrorResponse"

export default class Response<TYPE> {
    success: boolean | undefined
    content: TYPE | undefined
    errors: ErrorResponse[] | undefined
    errorMessage: string | undefined
    successMessage: string | undefined
    itemCount: number | undefined

    constructor(json: any) {
        this.success = json.success
        this.content = json.content
        this.errors = json.errors
        this.errorMessage = json.errorMessage
        this.successMessage = json.successMessage
        this.itemCount = json.itemCount
    }

    public getErrorMap(): Map<string, string> {
        const map = new Map()
        this.errors?.forEach((item) => {
            map.set(item.errorKey, item.errorMessage)
        })
        return map
    }

    public hasError(): boolean {
        return this.errors != undefined && this.errors.length > 0
    }
}
