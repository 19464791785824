import React from "react"

import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import { PageProps } from "../../../generic/component/grid/page/PageComponent"

import { Constant } from "../../constant/Constant"
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import BackOfficePage from "../BackOfficePage"

export default class FinanceurSuiviAvisPage extends BackOfficePage<PageProps, any> {
    render(): JSX.Element {
        return (
            <ContainerComponent id={Constant.DOM.BACK_OFFICE.FINANCEUR__SUIVI_AVIS}>
                <PrimaryContentBoxComponent title={"Suivi des avis d'autorités de tarification"}>{this.props.children}</PrimaryContentBoxComponent>
            </ContainerComponent>
        )
    }
}
