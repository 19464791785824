import { Component } from "react"

export default class UtilService {
    static instance: UtilService | null = null

    public static getInstance() {
        if (this.instance == null) {
            this.instance = new UtilService()
        }
        return this.instance
    }

    public generateId(prefix?: string) {
        const regex = new RegExp("[.]")
        let id = Math.random().toString(36).toString()
        if (prefix) id = prefix + id

        return id.replace(regex, "")
    }

    public arrayToSet<TYPE>(array: TYPE[]) {
        const set = new Set<TYPE>()
        array.forEach((item: TYPE) => set.add(item))
        return set
    }

    public setToArray<TYPE>(set: Set<TYPE>) {
        const array: TYPE[] = []
        set.forEach((item: TYPE) => array.push(item))
        return array
    }
}
