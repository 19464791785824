import React, { ReactElement } from "react"

import OrganismeFinanceur from "../../../model/OrganismeFinanceur"
import TypeFinanceur from "../../../model/TypeFinanceur"

import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"
import SelectComponent from "../../../../generic/component/select/SelectComponent"
import SelectOptionData from "../../../../generic/component/select/SelectOptionData"

import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielFormComponent from "../BackOfficeAbstractReferentielFormComponent"
import BackOfficeOrganismeFinanceurExtraDataState from "./BackOfficeOrganismeFinanceurExtraDataState"

export default class BackOfficeOrganismeFinanceurFormComponent extends BackOfficeAbstractReferentielFormComponent<OrganismeFinanceur, BackOfficeOrganismeFinanceurExtraDataState> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useOrganismeFinanceurService()
    }

    onComponentDidMount(object: OrganismeFinanceur | null) {
        new HttpReferentielService()
            .useTypeFinanceurService()
            .getAll<TypeFinanceur>()
            .then((response) => {
                // update state
                this.updatePartialState(response.content!)
            })
    }

    updatePartialState(typeFinanceurList: TypeFinanceur[]) {
        const newState: { [k: string]: any } = { extra: {} }

        if (typeFinanceurList != null) {
            newState.extra.typeFinanceurList = typeFinanceurList
        }

        this.setState(Object.assign(this.state != null ? this.state : {}, newState))
    }

    formToObject(id: number): OrganismeFinanceur {
        const organismeFinanceur = new OrganismeFinanceur(this.props.objectId)
        organismeFinanceur.type.id = Number.parseInt((document.querySelector("#type-financeur-select") as HTMLSelectElement).value)
        organismeFinanceur.nom = (document.querySelector("#organisme-financeur-nom") as HTMLInputElement).value
        return organismeFinanceur
    }

    getTypes() {
        const options: SelectOptionData[] = []
        if (this.state && this.state.extra && this.state.extra.typeFinanceurList) {
            this.state.extra.typeFinanceurList.forEach((type: TypeFinanceur) => {
                options.push(new SelectOptionData(type.id!.toString(), type.nom!, type.id == this.state.item?.type.id))
            })
        }
        return options
    }

    getContent(): ReactElement | undefined {
        const create = this.props.objectId == null
        const edit = this.props.objectId !== null && this.state && this.state.item !== null
        if (create || edit) {
            return (
                <>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <SelectComponent
                                label="Type de financeur"
                                value={this.state?.item?.type.id!.toString() ?? ""}
                                disabled={edit && this.state && this.state.item && this.state.item.system!}
                                data={this.getTypes()}
                                id="type-financeur-select"
                                errorMessage={this.state?.errors?.get("type")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Nom"
                                id="organisme-financeur-nom"
                                readonly={edit && this.state && this.state.item?.system}
                                value={this.getProperty("nom")}
                                error={this.state?.errors?.has("nom")}
                                errorMessage={this.state?.errors?.get("nom")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={4} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.save()}>Enregistrer</Button>
                        </ColumnComponent>
                        <ColumnComponent middle size={2}>
                            <Button secondary onClick={() => this.props.showListHandler()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={4} />
                    </RowComponent>
                </>
            )
        }
    }
}
