import React, { ReactElement } from "react"

import IdentifiantConventionCollective from "../../../model/IdentifiantConventionCollective"

import Button from "../../../../generic/component/button/Button"

import { Constant } from "../../../constant/Constant"
import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielListComponent from "../BackOfficeAbstractReferentielListComponent"
import BackOfficeModalDeleteComponent from "../BackOfficeModalDeleteComponent"

export default class BackOfficeIdentifiantConventionCollectiveListComponent extends BackOfficeAbstractReferentielListComponent<IdentifiantConventionCollective> {
    getLine(item: IdentifiantConventionCollective, index: number): React.ReactElement {
        return (
            <tr key={"row-idcc-" + item.id}>
                <td>{this.getStatutBar(item.active!)}</td>
                <td>{item.code}</td>
                <td>{item.label}</td>
                <td>
                    <Button iconOnly={Constant.ICON.EDIT_LINE} onClick={() => this.props.editButtonHandler(item.id)}>
                        Edit
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button secondary iconOnly={Constant.ICON.DELETE_LINE} modal={"idcc-" + item.id}>
                        Supprimer
                    </Button>
                    <BackOfficeModalDeleteComponent
                        id={"idcc-" + item.id}
                        title={"Suppression de l'IDCC de code " + item.code}
                        message={"Vous confirmez la suppression de l'IDCC ?"}
                        deleteButtonHandler={() => {
                            this.delete(item.id!)
                        }}
                    />
                </td>
            </tr>
        )
    }

    getThead(): React.ReactElement {
        return (
            <thead style={{ width: "100%" }}>
                <tr>
                    <th scope="col" style={{ width: "5%" }}></th>
                    <th scope="col" style={{ width: "15%" }}>
                        Code
                    </th>
                    <th scope="col" style={{ width: "60%" }}>
                        Libellé
                    </th>
                    <th scope="col" style={{ width: "20%" }}>
                        Actions
                    </th>
                </tr>
            </thead>
        )
    }

    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useIdentifiantConventionCollectiveService()
    }

    getAddLabel(): string {
        return "Ajouter un IDCC"
    }
}
