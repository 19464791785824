import { Fragment } from "react"

import File from "../../../../model/File"
import FileType from "../../../../model/FileType"

import BadgeSuccessComponent from "../../../../../generic/component/badge/BadgeSuccessComponent"
import Button from "../../../../../generic/component/button/Button"
import FileUploadComponent from "../../../../../generic/component/fileupload/FileUploadComponent"
import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"

import { Constant } from "../../../../constant/Constant"
import SecondaryContentBoxComponent from "../../../../shared/component/box/SecondaryContentBoxComponent"
import VerticalSpacing from "../../../../shared/component/space/VerticalSpacing"
import FrontComponent from "../../../FrontComponent"
import FrontDemandeAgrementFormStepProps from "../FrontDemandeAgrementFormStepProps"

interface FrontDemandeAgrementFormStep2AComponentProps extends FrontDemandeAgrementFormStepProps {
    fileAccordDue: File | undefined
    deleteFileFunction: (file: File | undefined, fileType: string) => void
    typeDemande: string | null | undefined
    sendFileDirectly?: (input: HTMLInputElement) => void
}

export default class FrontDemandeAgrementFormStep2AComponent extends FrontComponent<FrontDemandeAgrementFormStep2AComponentProps, any> {
    constructor(props: FrontDemandeAgrementFormStep2AComponentProps) {
        super(props)
    }

    getUploadedFile() {
        if (this.props.fileAccordDue) {
            return (
                <Fragment>
                    <BadgeSuccessComponent label={this.getLabel()} noIcon={true} />
                    <br />
                    <br />
                    <RowComponent>
                        <ColumnComponent size={9}>{FrontDemandeAgrementFormStep2AComponent.applicationService.buildLink(this.props.fileAccordDue)}</ColumnComponent>
                        <ColumnComponent size={3}>
                            <Button small iconLeft={Constant.ICON.DELETE_LINE} onClick={() => this.props.deleteFileFunction(this.props.fileAccordDue, FileType.ACCORD_DUE)}>
                                Supprimer
                            </Button>
                        </ColumnComponent>
                    </RowComponent>
                </Fragment>
            )
        }
    }

    getLabel() {
        if (this.props.typeDemande === "DUE") {
            return "La document de votre DUE "
        } else if (this.props.typeDemande === "ACCORD") {
            return "Votre accord déposé "
        } else {
            return "Fichier de votre accord / DUE "
        }
    }

    sendFileDirectlyWrapper(id: HTMLInputElement) {
        if (this.props.sendFileDirectly) {
            this.props.sendFileDirectly(id)
        }
    }

    render() {
        if (this.props.fileAccordDue) {
            return (
                <SecondaryContentBoxComponent title="Le texte déposé">
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getUploadedFile()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                </SecondaryContentBoxComponent>
            )
        } else {
            return (
                <>
                    <SecondaryContentBoxComponent title="Le texte déposé">
                        <RowComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={10}>
                                <FileUploadComponent
                                    maxSizeInMo={12}
                                    label={this.getLabel()}
                                    sendFileDirectly={(input: HTMLInputElement) => this.sendFileDirectlyWrapper(input)}
                                    acceptString={".pdf,.jpg,.jpeg,.bmp"}
                                    informations="Fichier d'extension pdf, jpg, jpeg, bmp, < 12 Mo"
                                    error={this.props.errors?.has("file-accord-due")}
                                    errorMessage={this.props.errors?.get("file-accord-due")}
                                    id={FileType.ACCORD_DUE}
                                />
                            </ColumnComponent>
                            <ColumnComponent size={1} />
                        </RowComponent>
                    </SecondaryContentBoxComponent>
                    <VerticalSpacing height={"20px"} />
                </>
            )
        }
    }
}
