module.exports.spread = function (initArr, addArr) {
    var addArrKeys = Object.keys(addArr)
    for (var i = 0; i < addArrKeys.length; i++) {
        initArr[addArrKeys[i]] = addArr[addArrKeys[i]]
    }
}

module.exports.getScript = function (url, callback) {
    this.ajax(url, function (responseText) {
        var f = new Function(responseText)
        f()
        if (typeof callback === "function") {
            callback()
        }
    })
}

module.exports.addValidateEvent = function (captchaInstance) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    let userInput = captchaInstance.userInput
    if (userInput && this.useUserInputBlurValidation(userInput)) {
        userInput.onblur = function () {
            var captchaCode = userInput.value
            if (captchaCode.length !== 0) {
                self.validateUnsafe(captchaInstance, function (isHuman) {
                    var event = new CustomEvent("validatecaptcha", { detail: isHuman })
                    userInput.dispatchEvent(event)
                    if (!isHuman) {
                        captchaInstance.reloadImage()
                    }
                })
            }
        }
    }
}

module.exports.validateUnsafe = function (captchaInstance, callback) {
    var captchaCode = captchaInstance.userInput.value
    this.ajax(captchaInstance.validationUrl + "&i=" + captchaCode, function (isHuman) {
        isHuman = isHuman == "true"
        callback(isHuman)
    })
}

module.exports.useUserInputBlurValidation = function (userInput) {
    return userInput.getAttribute("data-correct-captcha") !== null
}

module.exports.getHtml = function (captchaStyleName, captchaEndpoint, callback) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this
    var url = captchaEndpoint + "?get=html&c=" + captchaStyleName

    this.ajax(url, function (captchaHtml) {
        captchaHtml = self.changeRelativeToAbsoluteUrls(captchaHtml, captchaEndpoint)
        callback(captchaHtml)
    })
}

// get captcha endpoint handler from configued captchaEndpoint value,
// the result can be "simple-captcha-endpoint.ashx", "botdetectcaptcha",
// or "simple-botdetect.php"
module.exports.getCaptchaEndpointHandler = function (captchaEndpoint) {
    var splited = captchaEndpoint.split("/")
    return splited[splited.length - 1]
}

// get backend base url from configued captchaEndpoint value
module.exports.getBackendBaseUrl = function (captchaEndpoint, captchaEndpointHandler) {
    var lastIndex = captchaEndpoint.lastIndexOf(captchaEndpointHandler)
    return captchaEndpoint.substring(0, lastIndex)
}

// change relative to absolute urls in captcha html markup
module.exports.changeRelativeToAbsoluteUrls = function (originCaptchaHtml, captchaEndpoint) {
    let find = "/api/simple-captcha-endpoint"
    let recherche = new RegExp(find, "g")
    originCaptchaHtml = originCaptchaHtml.replace(recherche, captchaEndpoint)
    find = "amp;"
    recherche = new RegExp(find, "g")
    originCaptchaHtml = originCaptchaHtml.replace(recherche, "")

    return originCaptchaHtml
}

module.exports.ajax = function (url, callback) {
    function xhr() {
        var x = null
        try {
            x = new XMLHttpRequest()
        } catch (e) {
            console.error(e)
        }
        return x
    }

    var x = xhr()
    if (x) {
        if (x && 0 === x.readyState) {
            x.onreadystatechange = function () {
                if (4 === x.readyState && x.status === 200) {
                    if (typeof callback === "function") {
                        callback(x.response)
                    }
                }
            }
            x.open("GET", url, true)
            x.send()
        }
    }
}

module.exports.addCustomEventPolyfill = function () {
    if (typeof window.CustomEvent !== "function") {
        window.CustomEvent = function (event, params) {
            params = params || { bubbles: false, cancelable: false, detail: undefined }
            var evt = document.createEvent("CustomEvent")
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
            return evt
        }
        window.CustomEvent.prototype = window.Event.prototype
    }
}
