import HttpReferentielService from "./HttpReferentielService"
import Response from "./response/Response"

export default class HttpContactFinanceurService extends HttpReferentielService {
    constructor() {
        super()
        this.uri = this.CONTACT_FINANCEUR_URI
    }

    public changePassword(contactFinanceurId: number) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.request<Response<string[]>>(this.uri! + "/change-password?id=" + contactFinanceurId, { method: "PUT" })
    }
}
