import React from "react"

import { Constant } from "../../../constant/Constant"
import BackOfficeAbstractReferentielPage from "../BackOfficeAbstractReferentielPage"
import BackOfficePieceExigibleFormComponent from "./BackOfficePieceExigibleFormComponent"
import BackOfficePieceExigibleListComponent from "./BackOfficePieceExigibleListComponent"

export default class BackOfficePieceExigiblePage extends BackOfficeAbstractReferentielPage {
    getObjectTitle(): string {
        return "Pièces exigibles"
    }

    getFormComponent(itemId: number | null, showListMethod: () => void): React.ReactElement {
        return <BackOfficePieceExigibleFormComponent objectId={itemId} showListHandler={showListMethod} />
    }

    getListComponent(forceRefresh: string, addButtonHandler: () => void, editButtonHandler: (id: number | null) => void, deleteButtonHandler: (id: number | null) => void): React.ReactElement {
        return <BackOfficePieceExigibleListComponent forceRefresh={forceRefresh} addButtonHandler={addButtonHandler} editButtonHandler={editButtonHandler} deleteButtonHandler={deleteButtonHandler} />
    }

    getId(): string {
        return Constant.DOM.BACK_OFFICE.REFERENTIEL__PIECES_EXIGIBLES
    }
}
