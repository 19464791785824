import { ChangeEvent, Fragment, ReactElement } from "react"
import ReactTooltip from "react-tooltip"

import File from "../../../../model/File"

import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"

import { Constant } from "../../../../constant/Constant"
import SecondaryContentBoxComponent from "../../../../shared/component/box/SecondaryContentBoxComponent"
import FileUploaderComponent from "../../../../shared/component/fileupload/FileUploaderComponent"
import FrontComponent from "../../../FrontComponent"
import FrontDemandeAgrementForm from "../../FrontDemandeAgrementForm"
import FrontDemandeAgrementFormStepProps from "../FrontDemandeAgrementFormStepProps"

interface FrontDemandeAgrementFormStep2BProps extends FrontDemandeAgrementFormStepProps {
    pieceComplementaire1: File | undefined
    pieceComplementaire2: File | undefined
    pieceComplementaire3: File | undefined
    pieceComplementaire4: File | undefined
    pieceComplementaire5: File | undefined
    deleteFileFunction: (file: File | undefined, fileType: string) => void
    sendFileDirectly?: (input: HTMLInputElement) => void
}

interface FrontDemandeAgrementFormStep2BState {
    forceRefresh: number
}

export default class FrontDemandeAgrementFormStep2BComponent extends FrontComponent<FrontDemandeAgrementFormStep2BProps, FrontDemandeAgrementFormStep2BState> {
    constructor(props: FrontDemandeAgrementFormStep2BProps) {
        super(props)
    }

    componentDidMount() {
        this.setState({
            forceRefresh: 0
        })
    }

    onChangeEvent(event: ChangeEvent) {
        this.setState({
            forceRefresh: this.state.forceRefresh + 1
        })
    }

    sendFileDirectlyWrapped(input: HTMLInputElement) {
        if (this.props.sendFileDirectly) {
            this.props.sendFileDirectly(input)
        }
    }

    getFileUploader(title: string, file: File | undefined, fileType: string) {
        return (
            <FileUploaderComponent<FrontDemandeAgrementForm>
                labelDocument={title}
                labelAdd={"Ajouter un document"}
                acceptString={".pdf,.jpg,.jpeg,.bmp"}
                informations="Fichier d'extension pdf, jpg, jpeg, bmp, < 12 Mo"
                file={file}
                sendFileDirectly={(input) => this.sendFileDirectlyWrapped(input)}
                deleteFileFunction={() => this.props.deleteFileFunction(file, fileType)}
                onChangeHandler={(event) => this.onChangeEvent(event)}
                errors={this.props.errors}
                current={this.props.demandeInProgress}
                id={fileType}
            />
        )
    }

    getPieceJointeDefined(ids: number[]): ReactElement | undefined {
        if ((this.props.pieceComplementaire1 != undefined || this.isPieceJointeDefined(1)) && !ids.includes(1)) {
            ids.push(1)
            return this.getPieceJointe1()
        } else if ((this.props.pieceComplementaire2 != undefined || this.isPieceJointeDefined(2)) && !ids.includes(2)) {
            ids.push(2)
            return this.getPieceJointe2()
        } else if ((this.props.pieceComplementaire3 != undefined || this.isPieceJointeDefined(3)) && !ids.includes(3)) {
            ids.push(3)
            return this.getPieceJointe3()
        } else if ((this.props.pieceComplementaire4 != undefined || this.isPieceJointeDefined(4)) && !ids.includes(4)) {
            ids.push(4)
            return this.getPieceJointe4()
        } else if ((this.props.pieceComplementaire5 != undefined || this.isPieceJointeDefined(5)) && !ids.includes(5)) {
            ids.push(5)
            return this.getPieceJointe5()
        }
    }

    getFirstPieceJointeUndefined(): ReactElement | undefined {
        if (this.props.pieceComplementaire1 == undefined && !this.isPieceJointeDefined(1)) {
            return this.getPieceJointe1()
        } else if (this.props.pieceComplementaire2 == undefined && !this.isPieceJointeDefined(2)) {
            return this.getPieceJointe2()
        } else if (this.props.pieceComplementaire3 == undefined && !this.isPieceJointeDefined(3)) {
            return this.getPieceJointe3()
        } else if (this.props.pieceComplementaire4 == undefined && !this.isPieceJointeDefined(4)) {
            return this.getPieceJointe4()
        } else if (this.props.pieceComplementaire5 == undefined && !this.isPieceJointeDefined(5)) {
            return this.getPieceJointe5()
        }
    }

    isPieceJointeDefined(index: number): boolean {
        const input: HTMLInputElement = document.querySelector("#file-upload-PIECE_COMPLEMENTAIRE_" + index) as HTMLInputElement
        return input != null && input.value != null && !(input.value === "")
    }

    static count = 0

    getPiecesJointes(): ReactElement[] {
        const piecesJointes: Map<number, ReactElement> = new Map<number, React.ReactElement>()

        FrontDemandeAgrementFormStep2BComponent.count = 0

        const ids: number[] = [5]

        for (let i = 0; i < 5; i++) {
            const element = this.getPieceJointeDefined(ids)
            if (element != undefined) {
                piecesJointes.set(FrontDemandeAgrementFormStep2BComponent.count++, element)
            }
        }

        if (piecesJointes.size < 5) {
            piecesJointes.set(FrontDemandeAgrementFormStep2BComponent.count, this.getFirstPieceJointeUndefined()!)
        }

        const elements: ReactElement[] = []

        piecesJointes.forEach((value: ReactElement, key) => {
            elements.push(value)
        })

        return elements
    }

    getPieceJointe1(): ReactElement {
        return (
            <Fragment key={"piece-complementaire-1"}>
                {this.getFileUploader("Pièce complémentaire " + (FrontDemandeAgrementFormStep2BComponent.count + 1), this.props.pieceComplementaire1, "PIECE_COMPLEMENTAIRE_1")}
                <hr />
            </Fragment>
        )
    }

    getPieceJointe2(): ReactElement {
        return (
            <Fragment key={"piece-complementaire-2"}>
                {this.getFileUploader("Pièce complémentaire " + (FrontDemandeAgrementFormStep2BComponent.count + 1), this.props.pieceComplementaire2, "PIECE_COMPLEMENTAIRE_2")}
                <hr />
            </Fragment>
        )
    }

    getPieceJointe3(): ReactElement {
        return (
            <Fragment key={"piece-complementaire-3"}>
                {this.getFileUploader("Pièce complémentaire " + (FrontDemandeAgrementFormStep2BComponent.count + 1), this.props.pieceComplementaire3, "PIECE_COMPLEMENTAIRE_3")}
                <hr />
            </Fragment>
        )
    }

    getPieceJointe4(): ReactElement {
        return (
            <Fragment key={"piece-complementaire-4"}>
                {this.getFileUploader("Pièce complémentaire " + (FrontDemandeAgrementFormStep2BComponent.count + 1), this.props.pieceComplementaire4, "PIECE_COMPLEMENTAIRE_4")}
                <hr />
            </Fragment>
        )
    }

    getPieceJointe5(): ReactElement {
        return (
            <Fragment key={"piece-complementaire-5"}>
                {this.getFileUploader("Pièce complémentaire " + (FrontDemandeAgrementFormStep2BComponent.count + 1), this.props.pieceComplementaire5, "PIECE_COMPLEMENTAIRE_5")}
                <hr />
            </Fragment>
        )
    }

    render() {
        const title = (
            <div>
                Autres pièces jointes <span data-html={true} data-tip={FileUploaderComponent.TOOLTIP} className={Constant.ICON.INFO_LINE} aria-hidden={true}></span>
            </div>
        )
        return (
            <SecondaryContentBoxComponent title={title}>
                <ReactTooltip />
                <RowComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={10}>{this.getPiecesJointes()}</ColumnComponent>
                    <ColumnComponent size={1} />
                </RowComponent>
            </SecondaryContentBoxComponent>
        )
    }
}
