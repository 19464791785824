import React from "react"

import MembreCna from "../../../model/MembreCna"

import Button from "../../../../generic/component/button/Button"

import { Constant } from "../../../constant/Constant"
import HttpMembreCnaService from "../../../service/http/HttpMembreCnaService"
import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielListComponent from "../BackOfficeAbstractReferentielListComponent"
import BackOfficeModalActionComponent from "../BackOfficeModalActionComponent"
import BackOfficeModalDeleteComponent from "../BackOfficeModalDeleteComponent"

export default class BackOfficeMembreCnaListComponent extends BackOfficeAbstractReferentielListComponent<MembreCna> {
    protected getUsePager(): boolean {
        return true
    }

    getLine(item: MembreCna, index: number): React.ReactElement {
        return (
            <tr key={"row-membre-cna-" + item.id}>
                <td>{this.getStatutBar(item.active!)}</td>
                <td>{item.organisme}</td>
                <td>{item.nom}</td>
                <td>{item.prenom}</td>
                <td>
                    <a href={"mailto:" + item.email}>{item.email}</a>
                </td>
                <td>
                    <Button iconOnly={Constant.ICON.EDIT_LINE} onClick={() => this.props.editButtonHandler(item.id)}>
                        Edit
                    </Button>
                    &nbsp;&nbsp;
                    <Button secondary iconOnly={Constant.ICON.REFRESH_LINE} modal={"action-membre-cna-" + item.id}>
                        Nouveau mot de passe
                    </Button>
                    &nbsp;&nbsp;
                    <Button iconOnly={Constant.ICON.DELETE_LINE} modal={"delete-membre-cna-" + item.id}>
                        Supprimer
                    </Button>
                    <BackOfficeModalDeleteComponent
                        id={"delete-membre-cna-" + item.id}
                        title={"Suppression de ce membre : " + item.organisme + " - " + item.nom + " - " + item.prenom}
                        message={"Vous confirmez la suppression de ce membre de la CNA ?"}
                        deleteButtonHandler={() => {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            this.delete(item.id!)
                        }}
                    />
                    <BackOfficeModalActionComponent
                        id={"action-membre-cna-" + item.id}
                        title={"Générer un nouveau mot de passe"}
                        message={"Si vous confirmer un nouveau mot de passe va être générer et un mail informatif va être envoyé au membre"}
                        doAction={() => this.sendNewPassword(item.id!)}
                    />
                </td>
            </tr>
        )
    }

    sendNewPassword(membreCnaId: number) {
        new HttpMembreCnaService().changePassword(membreCnaId).then((response) => {
            if (response.success) {
                this.setState({
                    successMessage: response.successMessage,
                    errorMessage: response.errorMessage
                })
            } else {
                this.setState({
                    successMessage: undefined,
                    errorMessage: response.errorMessage
                })
            }
        })
    }

    getHttpReferentielService(): HttpReferentielService {
        return new HttpMembreCnaService()
    }

    getAddLabel(): string {
        return "Ajouter un membre"
    }

    getThead(): React.ReactElement {
        return (
            <thead>
                <tr>
                    <th scope="col" style={{ width: "5%" }}></th>
                    <th scope="col" style={{ width: "15%" }}>
                        Nom de l'organisme
                    </th>
                    <th scope="col" style={{ width: "17%" }}>
                        Nom
                    </th>
                    <th scope="col" style={{ width: "17%" }}>
                        Prénom
                    </th>
                    <th scope="col" style={{ width: "26%" }}>
                        Courriel
                    </th>
                    <th scope="col" style={{ width: "20%" }}>
                        Actions
                    </th>
                </tr>
            </thead>
        )
    }
}
