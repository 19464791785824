import React, { Fragment, ReactElement } from "react"

import DemandeDetails from "../../../model/DemandeDetails"
import EtapeInstruction from "../../../model/EtapeInstruction"
import FullAvisFinanceur from "../../../model/FullAvisFinanceur"
import Instruction from "../../../model/Instruction"
import TypologieAccord from "../../../model/TypologieAccord"

import { DnumProps } from "../../../../generic/component/DnumComponent"
import AlertErrorComponent from "../../../../generic/component/alert/AlertErrorComponent"
import AlertInfoComponent from "../../../../generic/component/alert/AlertInfoComponent"
import AlertSuccessComponent from "../../../../generic/component/alert/AlertSuccessComponent"
import AlertWarningComponent from "../../../../generic/component/alert/AlertWarningComponent"
import BadgeSuccessComponent from "../../../../generic/component/badge/BadgeSuccessComponent"
import BadgeWarningComponent from "../../../../generic/component/badge/BadgeWarningComponent"
import Button from "../../../../generic/component/button/Button"
import ButtonPdf from "../../../../generic/component/button/pdf/ButtonPdf"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import ContainerComponent from "../../../../generic/component/grid/container/ContainerComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"
import ErrorMessageComponent from "../../../../generic/component/message/ErrorMessageComponent"
import TextAreaComponent from "../../../../generic/component/text/TextAreaComponent"

import { Constant } from "../../../constant/Constant"
import RoleBackOffice from "../../../constant/role/RoleBackOffice"
import BackOfficeService from "../../../service/BackOfficeService"
import HttpBackDemande from "../../../service/http/HttpBackDemande"
import HttpBackInstruction from "../../../service/http/HttpBackInstruction"
import UiService from "../../../service/ui/UiService"
import PrimaryContentBoxComponent from "../../../shared/component/box/PrimaryContentBoxComponent"
import SecondaryContentBoxComponent from "../../../shared/component/box/SecondaryContentBoxComponent"
import VerticalSpacing from "../../../shared/component/space/VerticalSpacing"
import BackOfficePage from "../../BackOfficePage"
import BackOfficeDemandeAgrementForm from "../../common/BackOfficeDemandeAgrementForm"
import BackOfficeInstructionComponent from "./BackOfficeInstructionComponent"
import imageEngrenage from "./engrenage.png"
import imageUser from "./user.png"

interface BackInstructionPageProps extends DnumProps {
    demandeId: number
    title?: string | ReactElement
    showActionOrStatusRow?: boolean
    goBackButton: boolean
    parentMethod?: () => void
    typologies: TypologieAccord[] | undefined
}

interface BackInstructionPageState {
    demande: BackOfficeDemandeAgrementForm | undefined
    instruction: Instruction | undefined
    showConfirmNonComplete: boolean
    showConfirmComplete: boolean
    showConfirmDelete: boolean
    showConfirmProchaineCNA: boolean
    showConfirmEnAttenteCNA: boolean
    showConfirmDeleteCNA: boolean
    errorMessage: string | undefined
    avisList: FullAvisFinanceur<null>[] | null
    etapes: EtapeInstruction[] | null
    showHistoric: boolean
}

export default class BackOfficeInstructionPage extends BackOfficePage<BackInstructionPageProps, BackInstructionPageState> {
    constructor(props: BackInstructionPageProps) {
        super(props)
        this.state = {
            demande: undefined,
            instruction: undefined,
            showConfirmNonComplete: false,
            showConfirmComplete: false,
            showConfirmDelete: false,
            showConfirmProchaineCNA: false,
            showConfirmEnAttenteCNA: false,
            showConfirmDeleteCNA: false,
            errorMessage: undefined,
            avisList: null,
            etapes: null,
            showHistoric: false
        }
    }

    componentDidMount() {
        new HttpBackDemande().getDemande(this.props.demandeId!).then((response) => {
            this.setState({ demande: response?.content })
            this.loadDetails()
        })
    }

    loadDetails() {
        new HttpBackInstruction().getDetailsByDemande(this.props.demandeId!).then((response) => {
            if (response!.success && response!.content != null) {
                const details = response?.content as DemandeDetails
                if (details) {
                    this.setState({
                        avisList: details?.avis,
                        etapes: details?.etapes
                    })
                }
            }
        })
    }

    getInstructionComponent() {
        if (this.state && this.state.demande) {
            return (
                <BackOfficeInstructionComponent
                    demande={this.state.demande}
                    typologies={this.props.typologies}
                    key={"demande-" + this.props.demandeId + "-component"}
                    avisList={this.state?.avisList}
                />
            )
        }
    }

    getConfirmDemandeNonComplete() {
        if (this.state && this.state.showConfirmNonComplete) {
            return (
                <Fragment>
                    <ContainerComponent>
                        <RowComponent>
                            <ColumnComponent size={2} />
                            <ColumnComponent size={8}>
                                <VerticalSpacing height={"20px"} />
                                <TextAreaComponent id="detail-information-exigible" label="Message à destination du demandeur" />
                                <br />
                                <AlertWarningComponent description={"Je confirme la non complétude."} />
                            </ColumnComponent>
                            <ColumnComponent size={2} />
                        </RowComponent>
                        <RowComponent style={{ marginTop: "45px" }}>
                            <ColumnComponent size={4} />
                            <ColumnComponent size={2}>
                                <Button onClick={() => this.confirmNonCompletude()}>Valider</Button>
                            </ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={2}>
                                <Button secondary onClick={() => this.cancel()}>
                                    Annuler
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={3} />
                        </RowComponent>
                    </ContainerComponent>
                </Fragment>
            )
        }
    }

    getConfirmDemandeToDelete() {
        if (this.state && this.state.showConfirmDelete) {
            return (
                <Fragment>
                    <ContainerComponent>
                        <RowComponent>
                            <ColumnComponent size={3} />
                            <ColumnComponent size={6}>
                                <VerticalSpacing height={"20px"} />
                                <AlertErrorComponent description={"Je confirme la suppression de ce dossier."} />
                            </ColumnComponent>
                            <ColumnComponent size={3} />
                        </RowComponent>
                        <RowComponent style={{ marginTop: "40px" }}>
                            <ColumnComponent size={4} />
                            <ColumnComponent size={2} style={{ textAlign: "center" }}>
                                <Button onClick={() => this.confirmDelete()}>Valider</Button>
                            </ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={2}>
                                <Button secondary onClick={() => this.cancel()}>
                                    Annuler
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={3} />
                        </RowComponent>
                    </ContainerComponent>
                </Fragment>
            )
        }
    }

    getConfirmDemandeToDeleteCNA() {
        if (this.state && this.state.showConfirmDeleteCNA) {
            return (
                <Fragment>
                    <ContainerComponent>
                        <RowComponent>
                            <ColumnComponent size={3} />
                            <ColumnComponent size={6}>
                                <VerticalSpacing height={"20px"} />
                                <AlertErrorComponent description={"Je confirme la suppression de cette demande de la CNA actuelle."} />
                            </ColumnComponent>
                            <ColumnComponent size={3} />
                        </RowComponent>
                        <RowComponent style={{ marginTop: "40px" }}>
                            <ColumnComponent size={4} />
                            <ColumnComponent size={2} style={{ textAlign: "center" }}>
                                <Button onClick={() => this.deleteCnaId()}>Valider</Button>
                            </ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={2}>
                                <Button secondary onClick={() => this.cancel()}>
                                    Annuler
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={3} />
                        </RowComponent>
                    </ContainerComponent>
                </Fragment>
            )
        }
    }

    getErrorRow() {
        if (this.state && this.state.errorMessage) {
            return (
                <RowComponent>
                    <ColumnComponent size={3} />
                    <ColumnComponent size={6}>
                        <ErrorMessageComponent message={this.state.errorMessage} />
                    </ColumnComponent>
                    <ColumnComponent size={3} />
                </RowComponent>
            )
        }
    }

    getConfirmDemandeComplete() {
        if (this.state && this.state.showConfirmComplete) {
            return (
                <Fragment>
                    <ContainerComponent>
                        {this.getErrorRow()}
                        <RowComponent>
                            <ColumnComponent size={3} />
                            <ColumnComponent size={6}>
                                <VerticalSpacing height={"20px"} />
                                <AlertInfoComponent description={"Je confirme la complétude du dossier."} />
                                <VerticalSpacing height={"20px"} />
                            </ColumnComponent>
                            <ColumnComponent size={3} />
                        </RowComponent>
                        <RowComponent style={{ marginTop: "20px" }}>
                            <ColumnComponent size={4} />
                            <ColumnComponent size={2} style={{ textAlign: "center" }}>
                                <Button onClick={() => this.confirmCompletude()}>Valider</Button>
                            </ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={2} style={{ textAlign: "center" }}>
                                <Button secondary onClick={() => this.cancel()}>
                                    Annuler
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={2} />
                        </RowComponent>
                    </ContainerComponent>
                </Fragment>
            )
        }
    }

    getConfirmProchaineCNA() {
        if (this.state && this.state.showConfirmProchaineCNA) {
            return (
                <Fragment>
                    <ContainerComponent>
                        <RowComponent>
                            <ColumnComponent size={3} />
                            <ColumnComponent size={6}>
                                <VerticalSpacing height={"20px"} />
                                <AlertSuccessComponent description={"Cette demande sera dans la prochaine CNA."} />
                                <VerticalSpacing height={"20px"} />
                            </ColumnComponent>
                            <ColumnComponent size={3} />
                        </RowComponent>
                        <RowComponent style={{ marginTop: "20px" }}>
                            <ColumnComponent size={4} />
                            <ColumnComponent size={2} style={{ textAlign: "center" }}>
                                <Button onClick={() => this.confirmProchaineCNA()}>Valider</Button>
                            </ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={2} style={{ textAlign: "center" }}>
                                <Button secondary onClick={() => this.cancel()}>
                                    Annuler
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={2} />
                        </RowComponent>
                    </ContainerComponent>
                </Fragment>
            )
        }
    }

    getConfirmEnAttenteCNA() {
        if (this.state && this.state.showConfirmEnAttenteCNA) {
            return (
                <Fragment>
                    <ContainerComponent>
                        <RowComponent>
                            <ColumnComponent size={3} />
                            <ColumnComponent size={6}>
                                <VerticalSpacing height={"20px"} />
                                <AlertSuccessComponent description={'Cette demande retourne au statut "En attente CNA".'} />
                                <VerticalSpacing height={"20px"} />
                            </ColumnComponent>
                            <ColumnComponent size={3} />
                        </RowComponent>
                        <VerticalSpacing height={"40px"} />
                        <RowComponent style={{ marginTop: "20px" }}>
                            <ColumnComponent size={4} />
                            <ColumnComponent size={2} style={{ textAlign: "center" }}>
                                <Button onClick={() => this.confirmEnAttenteCNA()}>Valider</Button>
                            </ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={2} style={{ textAlign: "center" }}>
                                <Button secondary onClick={() => this.cancel()}>
                                    Annuler
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={2} />
                        </RowComponent>
                    </ContainerComponent>
                </Fragment>
            )
        }
    }

    goBack() {
        switch (this.state.demande?.etat) {
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__A_TRAITER: {
                BackOfficeService.getInstance().switchPage(Constant.DOM.BACK_OFFICE.AGREMENT__DEMANDES_A_TRAITER)
                return
            }
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA: {
                BackOfficeService.getInstance().switchPage(Constant.DOM.BACK_OFFICE.AGREMENT__DEMANDES_EN_ATTENTE_CNA)
                return
            }
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR: {
                BackOfficeService.getInstance().switchPage(Constant.DOM.BACK_OFFICE.AGREMENT__EN_ATTENTE_AVIS_FINANCEUR)
                return
            }
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA: {
                BackOfficeService.getInstance().switchPage(Constant.DOM.BACK_OFFICE.AGREMENT__DEMANDES_PROCHAINE_CNA)
                return
            }
        }
        BackOfficeService.getInstance().switchPage(Constant.DOM.BACK_OFFICE.AGREMENT__TOUTES_LES_DEMANDES)
    }

    changeDemandeEtat(etat: string, commentaire?: string) {
        new HttpBackInstruction().updateDemande(this.state.demande!.id!, etat, commentaire).then((response) => {
            if (response && response.errors && response.errors.length > 0) {
                this.setState({ errorMessage: response!.errors[0].errorMessage! })
            } else {
                const button = document.querySelector("#instruction-demande-" + this.state.demande!.id!) as HTMLButtonElement
                if (this.props.parentMethod) {
                    this.props.parentMethod()
                }
                button.click()
            }
        })
    }

    deleteCnaId() {
        //TODO Handle the refresh
        new HttpBackDemande().deleteCnaId(this.state.demande!.id!).then((response) => {
            if (response && response.errors && response.errors.length > 0) {
                this.setState({ errorMessage: response!.errors[0].errorMessage! })
            } else {
                const button = document.querySelector("#cna-demande-" + this.state.demande!.id!) as HTMLButtonElement
                if (this.props.parentMethod) {
                    this.props.parentMethod()
                }
                button.click()
            }
        })
    }

    confirmNonCompletude() {
        const textArea = document.querySelector("#detail-information-exigible") as HTMLTextAreaElement
        this.changeDemandeEtat(Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR, textArea.value)
    }

    confirmCompletude() {
        if (this.state.demande?.financementTotal == 0 || this.state.demande?.sansImpactFinancier) {
            this.changeDemandeEtat(Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA)
        } else {
            this.changeDemandeEtat(Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR)
        }
    }

    confirmDelete() {
        this.changeDemandeEtat(Constant.STATUS.DEMANDE.DEMANDE__BACK__SUPPRIMER)
    }

    confirmEnAttenteCNA() {
        this.changeDemandeEtat(Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA)
    }

    confirmProchaineCNA() {
        this.changeDemandeEtat(Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA)
    }

    confirmDeleteCNA() {
        this.deleteCnaId()
    }

    setDemandeToDelete() {
        this.setState({
            showConfirmNonComplete: false,
            showConfirmDelete: true,
            showConfirmComplete: false,
            showConfirmProchaineCNA: false,
            showConfirmEnAttenteCNA: false,
            showConfirmDeleteCNA: false
        })
    }

    setDemandeIncomplete() {
        this.setState({
            showConfirmNonComplete: true,
            showConfirmDelete: false,
            showConfirmComplete: false,
            showConfirmProchaineCNA: false,
            showConfirmEnAttenteCNA: false,
            showConfirmDeleteCNA: false
        })
    }

    setProchaineCNA() {
        this.setState({
            showConfirmProchaineCNA: true,
            showConfirmNonComplete: false,
            showConfirmDelete: false,
            showConfirmComplete: false,
            showConfirmEnAttenteCNA: false,
            showConfirmDeleteCNA: false
        })
    }

    setDemandeComplete() {
        this.setState({
            showConfirmNonComplete: false,
            showConfirmDelete: false,
            showConfirmComplete: true,
            showConfirmProchaineCNA: false,
            showConfirmEnAttenteCNA: false,
            showConfirmDeleteCNA: false
        })
    }

    setDemandeEnAttenteCNA() {
        this.setState({
            showConfirmNonComplete: false,
            showConfirmDelete: false,
            showConfirmComplete: false,
            showConfirmProchaineCNA: false,
            showConfirmEnAttenteCNA: true,
            showConfirmDeleteCNA: false
        })
    }

    setDemandeToDeleteCNA() {
        this.setState({
            showConfirmNonComplete: false,
            showConfirmDelete: false,
            showConfirmComplete: false,
            showConfirmProchaineCNA: false,
            showConfirmEnAttenteCNA: false,
            showConfirmDeleteCNA: true
        })
    }

    cancel() {
        this.setState({
            showConfirmNonComplete: false,
            showConfirmDelete: false,
            showConfirmComplete: false,
            showConfirmProchaineCNA: false,
            showConfirmEnAttenteCNA: false,
            showConfirmDeleteCNA: false
        })
    }

    getInstructionRow() {
        if (this.state.demande?.etat == Constant.STATUS.DEMANDE.DEMANDE__BACK__A_TRAITER && this.state.demande?.cnaId == null) {
            return (
                <RowComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={2}>
                        <Button iconLeft={Constant.ICON.CHECK_LINE} onClick={() => this.setDemandeComplete()}>
                            Complet
                        </Button>
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={2}>
                        <Button iconLeft={Constant.ICON.WARNING_LINE} secondary onClick={() => this.setDemandeIncomplete()}>
                            Incomplet
                        </Button>
                    </ColumnComponent>
                    <ColumnComponent size={3} />
                    <ColumnComponent size={2}>
                        <Button iconLeft={Constant.ICON.DELETE_LINE} secondary onClick={() => this.setDemandeToDelete()}>
                            Supprimer
                        </Button>
                    </ColumnComponent>
                </RowComponent>
            )
        } else if (this.state.demande?.etat == Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA) {
            return (
                <RowComponent>
                    <ColumnComponent size={4} />
                    <ColumnComponent size={4}>
                        <Button iconRight={Constant.ICON.ARROW_RIGHT_S_LINE_DOUBLE} secondary onClick={() => this.setProchaineCNA()}>
                            Prochaine CNA
                        </Button>
                    </ColumnComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={2}></ColumnComponent>
                </RowComponent>
            )
        } else if (this.state.demande?.etat == Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR) {
            return (
                <Fragment>
                    <RowComponent>
                        <ColumnComponent size={3} />
                        <ColumnComponent size={6}>
                            Cette demande est en attente d'avis financeur(s).
                            <br />
                            <br />
                        </ColumnComponent>
                        <ColumnComponent size={3} />
                    </RowComponent>
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={3}>
                            <Button iconRight={Constant.ICON.ARROW_RIGHT_S_LINE_DOUBLE} secondary onClick={() => this.setDemandeEnAttenteCNA()}>
                                En attente CNA
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={2} />
                        <ColumnComponent size={2}>
                            <Button iconLeft={Constant.ICON.WARNING_LINE} secondary onClick={() => this.setDemandeIncomplete()}>
                                Incomplet
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={2}>
                            <Button iconLeft={Constant.ICON.DELETE_LINE} secondary onClick={() => this.setDemandeToDelete()}>
                                Supprimer
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                </Fragment>
            )
        } else if (this.state.demande?.etat == Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA && this.props.showActionOrStatusRow) {
            if (this.state.demande?.cnaId != null) {
                return (
                    <Fragment>
                        <RowComponent>
                            <ColumnComponent size={3} />
                            <ColumnComponent size={6}>Cette demande est intégrée au programme de la prochaine CNA.</ColumnComponent>
                            <ColumnComponent size={3} />
                        </RowComponent>
                        <RowComponent>
                            <ColumnComponent size={3}>
                                <Button iconRight={Constant.ICON.ARROW_LEFT_S_LINE_DOUBLE} secondary onClick={() => this.setDemandeEnAttenteCNA()}>
                                    En attente CNA
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={4} />
                            <ColumnComponent size={2}>
                                <Button iconLeft={Constant.ICON.WARNING_LINE} secondary onClick={() => this.setDemandeIncomplete()}>
                                    Incomplet
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={2}>
                                <Button iconLeft={Constant.ICON.DELETE_LINE} secondary onClick={() => this.setDemandeToDelete()}>
                                    Supprimer
                                </Button>
                            </ColumnComponent>
                        </RowComponent>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>
                        <RowComponent>
                            <ColumnComponent size={3} />
                            <ColumnComponent size={6}>
                                Cette demande est éligible à la prochaine CNA.
                                <br />
                                <br />
                                <br />
                            </ColumnComponent>
                            <ColumnComponent size={3} />
                        </RowComponent>
                        <RowComponent>
                            <ColumnComponent size={3}>
                                <Button iconRight={Constant.ICON.ARROW_LEFT_S_LINE_DOUBLE} secondary onClick={() => this.setDemandeEnAttenteCNA()}>
                                    En attente CNA
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={4} />
                            <ColumnComponent size={2}>
                                <Button iconLeft={Constant.ICON.WARNING_LINE} secondary onClick={() => this.setDemandeIncomplete()}>
                                    Incomplet
                                </Button>
                            </ColumnComponent>
                            <ColumnComponent size={1} />
                            <ColumnComponent size={2}>
                                <Button iconLeft={Constant.ICON.DELETE_LINE} secondary onClick={() => this.setDemandeToDelete()}>
                                    Supprimer
                                </Button>
                            </ColumnComponent>
                        </RowComponent>
                    </Fragment>
                )
            }
        } else if (this.state.demande?.etat == Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR) {
            return (
                <RowComponent>
                    <ColumnComponent size={3} />
                    <ColumnComponent size={6}>Cette demande est en attente d'un complément d'information de la part de l'employeur.</ColumnComponent>
                    <ColumnComponent size={3} />
                </RowComponent>
            )
        } else if (this.state.demande?.cnaId != null){
            return <Fragment>
                <RowComponent>
                    <ColumnComponent size={3} />
                    <ColumnComponent size={6}>Vous pouvez supprimer cette demande de cette CNA </ColumnComponent>
                    <ColumnComponent size={3} />
                </RowComponent>
                <RowComponent style={{margin : "20px"}}>
                    <ColumnComponent size={4}/>
                    <ColumnComponent size={4}>
                        <Button iconLeft={Constant.ICON.DELETE_LINE} onClick={() => this.setDemandeToDeleteCNA()}>
                            Supprimer
                        </Button>
                    </ColumnComponent>
                    <ColumnComponent size={4}/>
                </RowComponent>
            </Fragment>
        }
        else {
            return <Fragment></Fragment>
        }
    }

    getActionRow() {
        if (this.state) {
            if (this.state.showConfirmDelete) {
                return this.getConfirmDemandeToDelete()
            } else if (this.state.showConfirmNonComplete) {
                return this.getConfirmDemandeNonComplete()
            } else if (this.state.showConfirmComplete) {
                return this.getConfirmDemandeComplete()
            } else if (this.state.showConfirmProchaineCNA) {
                return this.getConfirmProchaineCNA()
            } else if (this.state.showConfirmEnAttenteCNA) {
                return this.getConfirmEnAttenteCNA()
            } else if (this.state.showConfirmDeleteCNA) {
                return this.getConfirmDemandeToDeleteCNA()
            }
        }
        return this.getInstructionRow()
    }

    getStatusRow() {
        if (this.state.demande?.etat == Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_AGREE) {
            return <BadgeSuccessComponent label={"Demande agréée"} />
        } else {
            return <BadgeWarningComponent label={"Demande refusée"} />
        }
    }

    getActionRowOrStatusRow() {
        if (this.props.showActionOrStatusRow == null || this.props.showActionOrStatusRow) {
            if (this.state.demande?.etat == Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_AGREE || this.state.demande?.etat == Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_REFUSEE) {
                return (
                    <SecondaryContentBoxComponent title="Demande traitée en CNA">
                        <VerticalSpacing height={"20px"} />
                        {this.getStatusRow()}
                        <VerticalSpacing height={"20px"} />
                    </SecondaryContentBoxComponent>
                )
            } else {
                return (
                    <SecondaryContentBoxComponent title="Orientation de la demande">
                        <VerticalSpacing height={"20px"} />
                        {this.getActionRow()}
                        <VerticalSpacing height={"20px"} />
                    </SecondaryContentBoxComponent>
                )
            }
        } else {
            return(
                <SecondaryContentBoxComponent title="Gestion de la demande">
                    <VerticalSpacing height={"20px"} />
                    {this.getActionRow()}
                    <VerticalSpacing height={"20px"} />
                </SecondaryContentBoxComponent>
            )
        }
    }

    getEtat(etat: string | null): ReactElement {
        switch (etat) {
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA:
                return <span>En attente CNA</span>
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA:
                return <span>Prochaine CNA</span>
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__SOUMISE_A_INSTRUCTION:
                return <span>Soumise à instruction</span>
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__SUPPRIMEE:
                return <span>Supprimée par le demandeur</span>
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR:
                return <span>En attente avis financeur</span>
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__A_TRAITER:
                return <span>A traiter</span>
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR:
                return <span>Retour employeur</span>
            case Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_AGREE:
                return <span>Décision agréée</span>
            case Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_REFUSEE:
                return <span>Décision refusée</span>
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__SUPPRIMER:
                return <span>Supprimée</span>
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__EN_COURS_DE_DEPOT:
                return <span>En cours de dépôt</span>
            case Constant.STATUS.DEMANDE.DEMANDE__SYSTEM__AGREEE_IMPLICITEMENT:
                return <span>Agréer SVA</span>
        }
        return <span>???</span>
    }

    getHistoriqueData() {
        if (this.state?.showHistoric) {
            const methodGetEtat = this.getEtat
            const infos: ReactElement[] = []
            this.state.etapes?.forEach(function (etape: EtapeInstruction) {
                infos.push(
                    <tr>
                        <td>{UiService.formatServerDateToShortHumanNumericDate(etape.eventDate)}</td>
                        <td>&rarr;</td>
                        <td>{methodGetEtat(etape.nextEtat)}</td>
                        <td>{etape.system ? <img src={imageEngrenage} alt="Système" width={"25px"} /> : <img src={imageUser} alt="Système" width={"20px"} />}</td>
                        <td>{etape.commentaire}</td>
                    </tr>
                )
            })
            return (
                <table style={{ marginTop: "40px", marginBottom: "40px" }}>
                    <thead>
                        <th colSpan={5}>Historiques des opérations</th>
                    </thead>
                    <tbody>{infos}</tbody>
                </table>
            )
        }
    }

    openInNewTab(url: string | URL | undefined) {
        window.open(url, "_blank", "noopener,noreferrer")
    }

    flipHistoric() {
        this.setState({ showHistoric: !this.state.showHistoric })
    }

    getHistoricButton() {
        if (BackOfficeService.getInstance().getBackAccount?.type != RoleBackOffice.MEMBRE_CNA) {
            return <span className={Constant.ICON.INFO_LINE} aria-hidden={true} onClick={() => this.flipHistoric()}></span>
        }
    }

    render(): JSX.Element {
        let rightComponent = (
            <RowComponent>
                <ColumnComponent size={6}></ColumnComponent>
                <ColumnComponent size={6}>
                    <ButtonPdf secondary small onClick={() => this.openInNewTab("/accolade/back/pdf/" + this.props.demandeId)} />
                </ColumnComponent>
            </RowComponent>
        )

        if (this.props.goBackButton) {
            rightComponent = (
                <RowComponent>
                    <ColumnComponent size={6}>
                        <ButtonPdf secondary small onClick={() => this.openInNewTab("/accolade/back/pdf/" + this.props.demandeId)} />
                    </ColumnComponent>
                    <ColumnComponent size={6}>
                        <Button style={{ marginTop: "8px" }} onClick={() => this.goBack()} small iconLeft={Constant.ICON.ARROW_LEFT_LINE}>
                            Retour
                        </Button>
                    </ColumnComponent>
                </RowComponent>
            )
        }

        return (
            <ContainerComponent id={Constant.DOM.BACK_OFFICE.AGREMENT__TOUTES_LES_DEMANDES}>
                <PrimaryContentBoxComponent title={this.props.title ? this.props.title : "Instruction de la demande"} rightComponent={rightComponent}>
                    <RowComponent>
                        <ColumnComponent size={2} />
                        <ColumnComponent size={8}>{this.getHistoriqueData()}</ColumnComponent>
                        <ColumnComponent size={1}></ColumnComponent>
                        <ColumnComponent>{this.getHistoricButton()}</ColumnComponent>
                    </RowComponent>
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getInstructionComponent()}</ColumnComponent>
                        <ColumnComponent size={1}></ColumnComponent>
                    </RowComponent>
                    <VerticalSpacing height="55px" />
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getActionRowOrStatusRow()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <VerticalSpacing height="50px" />
                </PrimaryContentBoxComponent>
            </ContainerComponent>
        )
    }
}
