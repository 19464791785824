import { ReactElement } from "react"

import File from "../../model/File"

import LocalStorageService from "../storage/LocalStorageService"

export default class EspaceFinanceurApplicationService {
    storage: LocalStorageService | null = null
    financeurConnected = "financeurConnected"
    static instance: EspaceFinanceurApplicationService | undefined = undefined

    static getInstance() {
        if (EspaceFinanceurApplicationService.instance == null) {
            EspaceFinanceurApplicationService.instance = new EspaceFinanceurApplicationService()
        }
        return EspaceFinanceurApplicationService.instance
    }

    constructor() {
        this.storage = new LocalStorageService()
    }

    isFinanceurConnected() {
        return this.storage?.getBooleanItem(this.financeurConnected) == true
    }

    setFinanceurConnected() {
        this.storage?.setBooleanItem(this.financeurConnected, true)
    }

    logout(from: string) {
        //console.log("From : "+from)
        this.storage?.setBooleanItem(this.financeurConnected, false)
    }

    getLink(file: File): string {
        return process.env.REACT_APP_CONTEXT_PATH + "/back/financeur/download/" + file.key
    }

    buildLink(file: File): ReactElement {
        return (
            <a href={this.getLink(file)} target="_blank">
                {file.originalName + "." + file.extension}
            </a>
        )
    }
}
