import BackOfficeComponent from "../../BackOfficeComponent";
import {DnumProps} from "../../../../generic/component/DnumComponent";
import HttpBackInstruction from "../../../service/http/HttpBackInstruction";

interface BackOfficeInstructionNumeroDossierDaccordProps extends DnumProps {
    idDossier: string
    typeDossier: string
}

interface BackOfficeInstructionNumeroDossierDaccordState {
    status: string
}

export default class BackOfficeInstructionNumeroDossierDaccord extends BackOfficeComponent<BackOfficeInstructionNumeroDossierDaccordProps, BackOfficeInstructionNumeroDossierDaccordState> {

    constructor(props: BackOfficeInstructionNumeroDossierDaccordProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.typeDossier==='ACCORD') {
            try {
                new HttpBackInstruction().getDaccordStatus(this.props.idDossier!)
                    .then((response) => {
                        console.log(response?.content)
                        this.setState({status: response?.content})
                    })
            } catch (exception) {
                console.error("erreur : "+exception)
                this.setState({status:"erreur"})
            }
        }
    }

    render() {
        return <div key={"status-daccord-demande-"+this.props.idDossier}>{this.state?.status ?? ""}</div>;
    }
}
