import File from "../../model/File"

import HttpService from "./HttpService"
import Response from "./response/Response"

export default class HttpBackFileService extends HttpService {
    FILE_URI = "/back/file"

    public getDemandesFiles(parentId: number) {
        return this.request<Response<File[]>>(this.FILE_URI + "/by_parent/demande/" + parentId, {
            method: "GET"
        })
    }
}
