import DemandeSignature from "../../model/DemandeSignature"

import HttpService from "./HttpService"
import Response from "./response/Response"

export default class HttpSignatureService extends HttpService {
    FILE_URI = "/signature/valid"

    public validSignature(id: string, dateDecision: string, decision: string, signature: string): Promise<Response<DemandeSignature>> {
        //console.log("params : "+id+" "+dateDecision+" "+decision+" "+signature)
        const infos = new SignatureInfos()
        infos.id = id.toString()
        infos.dateDecision = dateDecision
        infos.decision = decision
        infos.signature = signature

        //console.log(JSON.stringify(infos))

        return this.request<Response<DemandeSignature>>(this.FILE_URI, {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(infos)
        }).then((response) => new Response(response))
    }
}

class SignatureInfos {
    id: string | undefined
    dateDecision: string | undefined
    decision: string | undefined
    signature: string | undefined
}
