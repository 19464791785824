import AccountData from "../../model/AccountData"
import AvisFinanceur from "../../model/AvisFinanceur"
import ContactFinanceurInformation from "../../model/ContactFinanceurInformation"
import Esms from "../../model/Esms"
import File from "../../model/File"

import BackOfficeLoginForm from "../../back/login/BackOfficeLoginForm"
import HttpService from "./HttpService"
import FinanceurMainResponse from "./response/FinanceurMainResponse"
import Response from "./response/Response"
import PagerResponse from "../../shared/dto/PagerResponse";
import BackOfficeDemandeAgrementForm from "../../back/common/BackOfficeDemandeAgrementForm";
import DemandeDetails from "../../model/DemandeDetails";

export default class HttpEspaceFinanceurService extends HttpService {
    ESPACE_FINANCEUR_LOGIN_URI = "/back/financeur/login"
    ESPACE_FINANCEUR_STATUS = "/back/financeur/status"
    ESPACE_FINANCEUR_AVIS_URI = "/back/financeur/avis"
    ESPACE_FINANCEUR_DEMANDE_FILES_URI = "/back/financeur/demande/files/"
    ESPACE_FINANCEUR_DEMANDE_ACCOUNT_URI = "/back/financeur/demande/account/"
    ESPACE_FINANCEUR_DEMANDE_ACCEPT_URI = "/back/financeur/accepter"
    ESPACE_FINANCEUR_DEMANDE_REFUSE_URI = "/back/financeur/refuser"
    ESPACE_FINANCEUR_ESMS_URI = "/back/financeur/esms/list"
    ESPACE_FINANCEUR_DEMANDES_URI = "/back/financeur/demande/list"
    ESPACE_FINANCEUR_DEMANDE_URI = "/back/financeur/demande"


    login(form: BackOfficeLoginForm) {
        return this.request<Response<ContactFinanceurInformation>>(this.ESPACE_FINANCEUR_LOGIN_URI, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(form)
        })
    }

    getStatus() {
        return this.request<Response<boolean>>(this.ESPACE_FINANCEUR_STATUS, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        })
    }

    getAvis() {
        return this.request<Response<FinanceurMainResponse>>(this.ESPACE_FINANCEUR_AVIS_URI, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.espaceFinanceurSessionExpired(response))
    }

    getDemandesFiles(parentId: number) {
        return this.request<Response<File[]>>(this.ESPACE_FINANCEUR_DEMANDE_FILES_URI + "/" + parentId, {
            method: "GET"
        })
    }

    getDemandeAccount(finessEJ: string) {
        return this.request<Response<AccountData>>(this.ESPACE_FINANCEUR_DEMANDE_ACCOUNT_URI + finessEJ, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        })
    }

    getEsmsList() {
        return this.request<Response<Esms[]>>(this.ESPACE_FINANCEUR_ESMS_URI, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        })
    }

    sendAccept(avisId: number | null, contactFinanceurId: number | null, organismeId: number | null, demandeId: number | null, comment: string) {
        const avis = new AvisFinanceur()
        avis.id = avisId
        avis.organismeFinanceurId = organismeId
        avis.contactFinanceurId = contactFinanceurId
        avis.commentaire = comment
        avis.demandeId = demandeId
        return this.request(this.ESPACE_FINANCEUR_DEMANDE_ACCEPT_URI, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(avis)
        })
    }

    sendRefuse(avisId: number | null, contactFinanceurId: number | null, organismeId: number | null, demandeId: number | null, comment: string) {
        const avis = new AvisFinanceur()
        avis.id = avisId
        avis.organismeFinanceurId = organismeId
        avis.contactFinanceurId = contactFinanceurId
        avis.commentaire = comment
        avis.demandeId = demandeId
        return this.request(this.ESPACE_FINANCEUR_DEMANDE_REFUSE_URI, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(avis)
        })
    }

    getDemandes(
        cnaId: number | null,
        raisonSocialeFilter: string | null,
        type: string | null,
        idcc: string | null,
        idTypologie: number | null,
        codePostal: string | null,
        page: number,
        size: number
    ) {
        //console.log("TyPo ID : "+idTypologie)
        //console.log("sTatUTS : "+statuts)

        const params = new URLSearchParams()

        if (raisonSocialeFilter != null && !(raisonSocialeFilter.trim()==='')) {
            params.set("raisonSociale", raisonSocialeFilter)
        }
        if (type != null) {
            params.set("type", type)
        }
        if (idcc != null) {
            params.set("idcc", idcc.toString())
        }
        if (idTypologie != null) {
            params.set("idThematique", idTypologie.toString())
        }
        if (codePostal != null && !(codePostal.trim()==='')) {
            params.set("codePostal", codePostal)
        }
        if (page != null) {
            params.set("page", page.toString())
        }
        if (size != null) {
            params.set("size", size.toString())
        }

        return this.request<Response<PagerResponse<BackOfficeDemandeAgrementForm>>>(this.ESPACE_FINANCEUR_DEMANDES_URI + "?" + params.toString(), {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    getDemande(id: number) {
        return this.request<Response<BackOfficeDemandeAgrementForm>>(this.ESPACE_FINANCEUR_DEMANDE_URI + "/" + id, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    getDetailsByDemande(demandeId: number) {
        return this.request<Response<DemandeDetails>>(this.ESPACE_FINANCEUR_DEMANDE_URI + "/details?demandeId=" + demandeId.toString(), {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }
}
