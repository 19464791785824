import { Fragment } from "react"

import ColumnComponent from "./fr/gouv/dnum/generic/component/grid/column/ColumnComponent"
import RowComponent from "./fr/gouv/dnum/generic/component/grid/row/RowComponent"

import AccoladeComponent from "./fr/gouv/dnum/accolade/AccoladeComponent"
import VerticalSpacing from "./fr/gouv/dnum/accolade/shared/component/space/VerticalSpacing"

export default class Page404 extends AccoladeComponent<any, any> {
    render() {
        return (
            <Fragment>
                <VerticalSpacing height={"200px"} />
                <RowComponent>
                    <ColumnComponent size={4} />
                    <ColumnComponent size={4}>
                        <div>
                            <fieldset style={{ borderRadius: "10px" }}>
                                <legend>
                                    <b>Accolade</b> - <span style={{ color: "red" }}>Erreur 404</span>
                                </legend>
                                Application de gestion des agréments sur accord
                                <br />
                                Cette page n'existe pas.
                            </fieldset>
                        </div>
                    </ColumnComponent>
                    <ColumnComponent size={4} />
                </RowComponent>
            </Fragment>
        )
    }
}
