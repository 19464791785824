class Icon {
    static SUCCESS_FILL = "fr-icon-success-fill"
    static SUCCESS_LINE = "fr-icon-success-line"

    static ERROR_FILL = "fr-icon-error-fill"
    static ERROR_LINE = "fr-icon-error-line"

    static INFO_FILL = "fr-icon-info-fill"
    static INFO_LINE = "fr-icon-info-line"

    static WARNING_FILL = "fr-icon-warning-fill"
    static WARNING_LINE = "fr-icon-warning-line"

    static FLASHLIGHT_FILL = "fr-icon-flashlight-fill"
    static FLASHLIGHT_LINE = "fr-icon-flashlight-line"

    static MENU_FILL = "fr-fi-menu-fill"
    static MENU_2_FILL = "fr-fi-menu-2-fill"

    static ARROW_UP_LINE = "fr-fi-arrow-up-line"
    static ARROW_RIGHT_LINE = "fr-fi-arrow-right-line"
    static ARROW_DOWN_LINE = "fr-fi-arrow-down-line"
    static ARROW_LEFT_LINE = "fr-fi-arrow-left-line"

    static ARROW_UP_S_LINE = "fr-fi-arrow-up-s-line"
    static ARROW_RIGHT_S_LINE = "fr-fi-arrow-right-s-line"
    static ARROW_DOWN_S_LINE = "fr-fi-arrow-down-s-line"
    static ARROW_LEFT_S_LINE = "fr-fi-arrow-left-s-line"

    static ARROW_LEFT_S_FIRST_LINE = "fr-fi-arrow-left-s-first-line"
    static ARROW_LEFT_S_LINE_DOUBLE = "fr-fi-arrow-left-s-line-double"
    static ARROW_RIGHT_S_LAST_LINE = "fr-fi-arrow-right-s-last-line"
    static ARROW_RIGHT_S_LINE_DOUBLE = "fr-fi-arrow-right-s-line-double"
    static ARROW_RIGHT_UP_LINE = "fr-fi-arrow-right-up-line"

    static USER_FILL = "fr-fi-user-fill"
    static USER_LINE = "fr-fi-user-line"

    static ACCOUNT_FILL = "fr-fi-account-fill"
    static ACCOUNT_LINE = "fr-fi-account-line"

    static LOCK_FILL = "fr-fi-lock-fill"
    static LOCK_LINE = "fr-fi-lock-line"

    static LOGOUT_FILL = "fr-fi-logout-box-r-fill"
    static LOGOUT_LINE = "fr-fi-logout-box-r-line"

    static CLOSE_LINE = "fr-fi-close-line"
    static CHECK_LINE = "fr-fi-check-line"
    static INFORMATION_FILL = "fr-fi-information-fill"
    static INFORMATION_LINE = "fr-fi-information-line"
    static QUESTION_FILL = "fr-fi-question-fill"
    static QUESTION_LINE = "fr-fi-question-line"
    static ERROR_WARNING_FILL = "fr-fi-error-warning-fill"
    static ERROR_WARNING_LINE = "fr-fi-error-warning-line"

    static ADD_CIRCLE_FILL = "fr-fi-add-circle-fill"
    static ADD_CIRCLE_LINE = "fr-fi-add-circle-line"
    static SUBSTRACT_LINE = "fr-fi-subtract-line"
    static ADD_LINE = "fr-fi-add-line"

    static SEARCH_FILL = "fr-fi-search_fill"
    static SEARCH_LINE = "fr-fi-search-line"

    static EYE_FILL = "fr-fi-eye-fill"
    static EYE_LINE = "fr-fi-eye-line"
    static EYE_OFF_FILL = "fr-fi-eye-off-fill"
    static EYE_OFF_LINE = "fr-fi-eye-off-line"

    static PRINTER_FILL = "fr-fi-printer-fill"
    static PRINTER_LINE = "fr-fi-printer-line"
    static UPLOAD_2_FILL = "fr-fi-upload-2-fill"
    static UPLOAD_2_LINE = "fr-fi-upload-2-line"
    static DOWNLOAD_FILL = "fr-fi-file-download-fill"
    static DOWNLOAD_LINE = "fr-fi-file-download-line"
    // TODO check
    //static DOWNLOAD_LINE = "fr-fi-download-line"
    static EXTERNAL_LINK_LINE = "fr-fi-external-link-line"
    static EDIT_FILL = "fr-fi-edit-fill"
    static EDIT_LINE = "fr-fi-edit-line"
    static LINKS_FILL = "fr-fi-links-fill"

    static DELETE_LINE = "fr-fi-delete-line"
    static DELETE_FILL = "fr-fi-delete-fill"

    static SAVE_FILL = "fr-fi-save-fill"
    static SAVE_LINE = "fr-fi-save-line"

    static CALENDAR_FILL = "fr-fi-calendar-fill"
    static CALENDAR_LINE = "fr-fi-calendar-line"

    static FILE_FILL = "fr-fi-file-fill"
    static FILE_LINE = "fr-fi-file-line"

    static MAIL_FILL = "fr-fi-mail-fill"
    static MAIL_LINE = "fr-fi-mail-line"

    static REFRESH_FILL = "fr-fi-refresh-fill"
    static REFRESH_LINE = "fr-fi-refresh-line"

    static EQUALIZER_FILL = "fr-fi-equalizer-fill"
    static EQUALIZER_LINE = "fr-fi-equalizer-line"

    static FILTER_FILL = "fr-fi-filter-fill"
    static FILTER_LINE = "fr-fi-filter-line"

    static ATTACHMENT_FILL = "fr-fi-attachment-fill"
    static ATTACHMENT_LINE = "fr-fi-attachment-line"

    static PLAY_FILL = "fr-fi-play-fill"
    static PLAY_LINE = "fr-fi-play-line"
    static VOLUME_UP_FILL = "fr-fi-volume-up-fill"
    static VOLUME_UP_LINE = "fr-fi-volume-up-line"
    static VOLUME_MUTE_FILL = "fr-fi-volume-mute-fill"
    static VOLUME_MUTE_LINE = "fr-fi-volume-mute-line"
    static VOLUME_DOWN_FILL = "fr-fi-volume-down-fill"
    static VOLUME_DOWN_LINE = "fr-fi-volume-down-line"
    static PAUSE_CIRCLE_FILL = "fr-fi-pause-circle-fill"
    static PAUSE_CIRCLE_LINE = "fr-fi-pause-circle-line"

    static FILE_PDF_FILL = "fr-fi-file-pdf-fill"
    static FILE_PDF_LINE = "fr-fi-file-pdf-line"

    static CHAT_QUOTE_FILL = "fr-fi-chat-quote-fill"
    static CHAT_QUOTE_LINE = "fr-fi-chat-quote-line"

    static DAILYMOTION_FILL = "fr-fi-dailymotion-fill"
    static DAILYMOTION_LINE = "fr-fi-dailymotion-line"
    static FACEBOOK_CIRCLE_FILL = "fr-fi-facebook-circle-fill"
    static FACEBOOK_CIRCLE_LINE = "fr-fi-facebook-circle-line"
    static GITHUB_FILL = "fr-fi-github-fill"
    static GITHUB_LINE = "fr-fi-github-line"
    static INSTAGRAM_FILL = "fr-fi-instagram-fill"
    static INSTAGRAM_LINE = "fr-fi-instagram-line"
    static LINKEDIN_BOX_FILL = "fr-fi-linkedin-box-fill"
    static LINKEDIN_BOX_LINE = "fr-fi-linkedin-box-line"
    static NPMJS_FILL = "fr-fi-npmjs-fill"
    static NPMJS_LINE = "fr-fi-npmjs-line"
    static REMIXICON_FILL = "fr-fi-remixicon-fill"
    static REMIXICON_LINE = "fr-fi-remixicon-line"
    static SLACK_FILL = "fr-fi-slack-fill"
    static SLACK_LINE = "fr-fi-slack-line"
    static SNAPCHAT_FILL = "fr-fi-snapchat-fill"
    static SNAPCHAT_LINE = "fr-fi-snapchat-line"
    static TELEGRAM_FILL = "fr-fi-telegram-fill"
    static TELEGRAM_LINE = "fr-fi-telegram-line"
    static TWITTER_FILL = "fr-fi-twitter-fill"
    static TWITTER_LINE = "fr-fi-twitter-line"
    static TIKTOK_FILL = "fr-fi-tiktok-fill"
    static TIKTOK_LINE = "fr-fi-tiktok-line"
    static TWITCH_FILL = "fr-fi-twitch-fill"
    static TWITCH_LINE = "fr-fi-twitch-line"
    static VIMEO_FILL = "fr-fi-vimeo-fill"
    static VIMEO_LINE = "fr-fi-vimeo-line"
    static YOUTUBE_FILL = "fr-fi-youtube-fill"
    static YOUTUBE_LINE = "fr-fi-youtube-line"

    static THEME_FILL = "fr-fi-theme-fill"
}

export default Icon
