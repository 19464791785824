import DnumComponent, { DnumProps } from "../DnumComponent"

interface SuccessMessageProps extends DnumProps {
    message: string
}
export default class SuccessMessageComponent extends DnumComponent<SuccessMessageProps, any> {
    constructor(props: SuccessMessageProps) {
        super(props)
    }

    render() {
        return (
            <p id="text-input-valid-desc-valid" className="fr-valid-text">
                {this.props.message}
            </p>
        )
    }
}
