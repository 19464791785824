import { ReactElement } from "react"

import TypeFinanceur from "../../../model/TypeFinanceur"

import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielFormComponent from "../BackOfficeAbstractReferentielFormComponent"

export default class BackOfficeTypeFinanceurFormComponent extends BackOfficeAbstractReferentielFormComponent<TypeFinanceur, null> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useTypeFinanceurService()
    }

    formToObject(id: number): TypeFinanceur {
        const typeFinanceur = new TypeFinanceur(this.props.objectId)
        typeFinanceur.nom = (document.querySelector("#type-financeur-nom") as HTMLInputElement).value
        return typeFinanceur
    }

    getContent(): ReactElement | undefined {
        const create = this.props.objectId == null
        const edit = this.props.objectId !== null && this.state && this.state.item !== null
        if (create || edit) {
            return (
                <>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Nom"
                                id="type-financeur-nom"
                                readonly={edit && this.state.item?.system}
                                value={this.getProperty("nom")}
                                error={this.state?.errors?.has("nom")}
                                errorMessage={this.state?.errors?.get("nom")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={4} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.save()}>Enregistrer</Button>
                        </ColumnComponent>
                        <ColumnComponent middle size={2}>
                            <Button secondary onClick={() => this.props.showListHandler()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={4} />
                    </RowComponent>
                </>
            )
        }
    }
}
