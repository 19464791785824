import AvisCna from "./AvisCna"
import AvisMinistere from "./AvisMinistere"
import Cpom from "./Cpom"
import Demande from "./Demande"

export default class Status {
    public static readonly DEMANDE = Demande

    public static readonly CPOM = Cpom

    public static readonly AVIS_CNA = AvisCna

    public static readonly AVIS_MINISTERE = AvisMinistere
}
