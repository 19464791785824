export default class Demande {
    static DEMANDE__FRONT__EN_COURS_DE_DEPOT = "DEMANDE__FRONT__EN_COURS_DE_DEPOT" // brouillon
    static DEMANDE__FRONT__SOUMISE_A_INSTRUCTION = "DEMANDE__FRONT__SOUMISE_A_INSTRUCTION" // déposée
    static DEMANDE__FRONT__SUPPRIMEE = "DEMANDE__FRONT__SUPPRIMEE" // supprimée

    static DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR = "DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR" // en attente avis financeur
    static DEMANDE__BACK__RETOUR_EMPLOYEUR = "DEMANDE__BACK__RETOUR_EMPLOYEUR" // retour employeur
    static DEMANDE__BACK__SUPPRIMER = "DEMANDE__BACK__SUPPRIMER" // supprimer
    static DEMANDE__BACK__EN_ATTENTE_CNA = "DEMANDE__BACK__EN_ATTENTE_CNA" // en attente CNA
    static DEMANDE__BACK__PROCHAINE_CNA = "DEMANDE__BACK__PROCHAINE_CNA" // prochaine CNA
    static DEMANDE__BACK__A_TRAITER = "DEMANDE__BACK__A_TRAITER" // a traiter

    static DEMANDE__CNA__DECISION_AGREE = "DEMANDE__CNA__DECISION_AGREE"
    static DEMANDE__CNA__DECISION_REFUSEE = "DEMANDE__CNA__DECISION_REFUSEE"

    static DEMANDE__SYSTEM__AGREEE_IMPLICITEMENT = "DEMANDE__SYSTEM__AGREEE_IMPLICITEMENT"
}
