import { ReactElement } from "react"

import MotifRefus from "../../../model/MotifRefus"

import Button from "../../../../generic/component/button/Button"
import CheckboxComponent from "../../../../generic/component/checkbox/CheckboxComponent"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielFormComponent from "../BackOfficeAbstractReferentielFormComponent"

export default class BackOfficeMotifRefusFormComponent extends BackOfficeAbstractReferentielFormComponent<MotifRefus, null> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useMotifRefusService()
    }

    formToObject(id: number): MotifRefus {
        const motifRefus = new MotifRefus(this.props.objectId)
        motifRefus.code = (document.querySelector("#motif-refus-code") as HTMLInputElement).value
        motifRefus.libelle = (document.querySelector("#motif-refus-libelle") as HTMLInputElement).value
        motifRefus.active = (document.querySelector("input[name='motif-refus-actif']") as HTMLInputElement).checked
        return motifRefus
    }

    getActiveCheckbox(): ReactElement {
        const condition = this.state && this.state.item && this.state.item.active
        return this.getCheckbox("motif-refus-actif", "Actif", condition!)
    }

    getContent(): ReactElement | undefined {
        const create = this.props.objectId == null
        const edit = this.props.objectId !== null && this.state && this.state.item !== null
        if (create || edit) {
            return (
                <>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Code"
                                id="motif-refus-code"
                                value={this.getProperty("code")}
                                error={this.state?.errors?.has("code")}
                                errorMessage={this.state?.errors?.get("code")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Libellé"
                                id="motif-refus-libelle"
                                value={this.getProperty("libelle")}
                                error={this.state?.errors?.has("libelle")}
                                errorMessage={this.state?.errors?.get("libelle")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getActiveCheckbox()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={4} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.save()}>Enregistrer</Button>
                        </ColumnComponent>
                        <ColumnComponent middle size={2}>
                            <Button secondary onClick={() => this.props.showListHandler()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={4} />
                    </RowComponent>
                </>
            )
        }
    }
}
