import TypologieAccord from "../../model/TypologieAccord"

import { DnumProps } from "../../../generic/component/DnumComponent"

import BackOfficeComponent from "../BackOfficeComponent"
import BackOfficeInstructionPage from "../instruction/edit/BackOfficeInstructionPage"

interface BackOfficeModalShowDemandeComponentProps extends DnumProps {
    demandeId: number
    showActionOrStatusRow: boolean
    prefixId: string
    parentMethod?: () => void
    typologies: TypologieAccord[] | undefined
}

export default class BackOfficeModalShowDemandeComponent extends BackOfficeComponent<BackOfficeModalShowDemandeComponentProps, any> {
    render() {
        return (
            <dialog
                aria-labelledby={this.props.id + "-title"}
                id={this.props.prefixId + "-demande-" + this.props.demandeId}
                key={this.props.prefixId + "-demande-" + this.props.demandeId}
                className="fr-modal"
                role="dialog">
                <div className="fr-container fr-container--fluid fr-container-md">
                    <div className="fr-grid-row fr-grid-row--center">
                        <div className="fr-col-12 fr-col-md-12 fr-col-lg-12">
                            <div className="fr-modal__body">
                                <div className="fr-modal__header">&nbsp;</div>
                                <div className="fr-modal__content">
                                    <BackOfficeInstructionPage
                                        demandeId={this.props.demandeId}
                                        key={"modal-" + this.props.demandeId}
                                        goBackButton={false}
                                        typologies={this.props.typologies}
                                        parentMethod={this.props.parentMethod}
                                        showActionOrStatusRow={this.props.showActionOrStatusRow}
                                        title={"Détails de la demande " + this.props.demandeId}
                                    />
                                </div>
                                <div className="fr-modal__footer">
                                    <ul className="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
                                        <li>
                                            <button className="fr-btn fr-btn--close fr-fi-checkbox-line fr-btn--secondary" aria-controls={this.props.prefixId + "-demande-" + this.props.demandeId}>
                                                Fermer
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </dialog>
        )
    }
}
