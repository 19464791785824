import { ReactElement } from "react"

import File from "../../model/File"

import LocalStorageService from "../storage/LocalStorageService"

export default class BackApplicationService {
    storage: LocalStorageService | null = null

    gestionnaireConnected = "gestionnaireConnected"
    adminConnected = "adminConnected"
    membreCnaConnected = "membreCnaConnected"

    accountType = "accountType"

    isGestionnaireConnected() {
        return this.storage?.getBooleanItem(this.gestionnaireConnected) == true
    }

    isAdminConnected() {
        return this.storage?.getBooleanItem(this.adminConnected) == true
    }

    isMembreCnaConnected() {
        return this.storage?.getBooleanItem(this.membreCnaConnected) == true
    }

    getAccountType() {
        return this.storage?.getItem(this.accountType)
    }

    setAccountType(type: string) {
        this.storage?.setItem(this.accountType, type)
    }

    logout() {
        this.storage?.setBooleanItem(this.gestionnaireConnected, false)
        this.storage?.setBooleanItem(this.adminConnected, false)
        this.storage?.setBooleanItem(this.membreCnaConnected, false)
    }

    getLink(file: File): string {
        return process.env.REACT_APP_CONTEXT_PATH + "/back/file/download/" + file.key
    }

    buildLink(file: File): ReactElement {
        return (
            <a href={this.getLink(file)} target="_blank">
                {file.originalName + "." + file.extension}
            </a>
        )
    }
}
