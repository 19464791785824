import React, { ChangeEvent } from "react"
import ReactTooltip from "react-tooltip"

import { decode } from "html-entities"
import styled from "styled-components"

import Cout from "../../../../model/Cout"
import OrganismeFinanceur from "../../../../model/OrganismeFinanceur"

import CheckboxComponent from "../../../../../generic/component/checkbox/CheckboxComponent"
import TableCellComponent from "../../../../../generic/component/table/TableCellComponent"
import TableContainerComponent from "../../../../../generic/component/table/TableContainerComponent"
import TableHeaderComponent from "../../../../../generic/component/table/TableHeaderComponent"
import TextAreaComponent from "../../../../../generic/component/text/TextAreaComponent"

import { Constant } from "../../../../constant/Constant"
import SecondaryContentBoxComponent from "../../../../shared/component/box/SecondaryContentBoxComponent"
import VerticalSpacing from "../../../../shared/component/space/VerticalSpacing"
import FrontComponent from "../../../FrontComponent"
import FrontDemandeAgrementFormStepProps from "../FrontDemandeAgrementFormStepProps"

interface FrontDemandeAgrementFormStep1ComponentState {
    organismes: OrganismeFinanceur[] | undefined
    prices: Map<string, number | null> | undefined
    mounted: boolean
    forceRefresh: number
    noImpactChecked: boolean | undefined
}

interface FrontDemandeAgrementFormStep1ComponentProps extends FrontDemandeAgrementFormStepProps {
    dateUpdate: Date
}

export default class FrontDemandeAgrementFormStep1Component extends FrontComponent<FrontDemandeAgrementFormStep1ComponentProps, FrontDemandeAgrementFormStep1ComponentState> {
    static years = [0, 1, 2, 3]

    static RESOURCES_PROPRES_KEY = "resources-propres-key"
    static DOTATIONS_SUBVENTIONS_KEY = "dotations_subventions_key"
    static MESURES_NOUVELLES_KEY = "mesures-nouvelles-key"

    static POUR_INFO_CAF = "pour-info-caf"
    static POUR_INFO_CNAV = "pour-info-cnav"

    constructor(props: FrontDemandeAgrementFormStep1ComponentProps) {
        super(props)

        // annee => financeur => colonne
        this.state = {
            forceRefresh: 0,
            organismes: undefined,
            prices: new Map<string, number | null>(),
            mounted: false,
            noImpactChecked: false
        }
    }

    componentDidMount() {
        this.setState({ mounted: true, forceRefresh: this.state.forceRefresh + 1 })
    }

    componentDidUpdate(prevProps: Readonly<FrontDemandeAgrementFormStep1ComponentProps>, prevState: Readonly<FrontDemandeAgrementFormStep1ComponentState>, snapshot?: any) {
        if (this.state && this.state.mounted && this.props.dateUpdate.getTime() > prevProps.dateUpdate.getTime()) {
            const map = new Map<number, OrganismeFinanceur>()
            this.props.demandeInProgress?.etablissements?.forEach((etablissement) => {
                etablissement.financeurs?.forEach((financeur) => {
                    map.set(financeur.id!, financeur)
                })
            })

            this.setState({ organismes: Array.from(map.values()), forceRefresh: this.state.forceRefresh + 1 })

            if (this.props.dateUpdate.getTime() - prevProps.dateUpdate.getTime() < 1500) {
                this.updatePrice()
            }
        }
    }

    updatePrice() {
        if (this.props.demandeInProgress) {
            const columnRpTotalByYear = [0, 0, 0, 0]
            const columnDsTotalByYear = [0, 0, 0, 0]
            const columnMnTotalByYear = [0, 0, 0, 0]
            const columnPicfTotalByYear = [0, 0, 0, 0]
            const columnPicvTotalByYear = [0, 0, 0, 0]
            let columnTotal = 0

            this.props.demandeInProgress?.financement?.forEach((cout: Cout) => {
                let lineTotal = 0

                columnRpTotalByYear[cout.a!] += cout.rp ?? 0
                this.state.prices?.set(cout.a + "-" + cout.id + "-" + FrontDemandeAgrementFormStep1Component.RESOURCES_PROPRES_KEY, cout.rp ?? 0)

                columnDsTotalByYear[cout.a!] += cout.ds ?? 0
                this.state.prices?.set(cout.a + "-" + cout.id + "-" + FrontDemandeAgrementFormStep1Component.DOTATIONS_SUBVENTIONS_KEY, cout.ds ?? 0)

                columnMnTotalByYear[cout.a!] += cout.mn ?? 0
                this.state.prices?.set(cout.a + "-" + cout.id + "-" + FrontDemandeAgrementFormStep1Component.MESURES_NOUVELLES_KEY, cout.mn ?? 0)

                columnPicfTotalByYear[cout.a!] += cout.picf ?? 0
                this.state.prices?.set(cout.a + "-" + FrontDemandeAgrementFormStep1Component.POUR_INFO_CAF, cout.picf ?? 0)

                columnPicvTotalByYear[cout.a!] += cout.picv ?? 0
                this.state.prices?.set(cout.a + "-" + FrontDemandeAgrementFormStep1Component.POUR_INFO_CNAV, cout.picv ?? 0)

                lineTotal = this.cleanAddition3(cout.rp ?? 0, cout.ds ?? 0, cout.mn ?? 0)

                if (cout.id != null) {
                    this.state.prices?.set(cout.a + "-" + cout.id + "-total", lineTotal)
                }

                columnTotal += lineTotal
            })

            const totalByYear = [0, 0, 0, 0]

            FrontDemandeAgrementFormStep1Component.years.forEach((year: number) => {
                this.state.prices?.set("sous-total-annee-" + year + "-" + FrontDemandeAgrementFormStep1Component.RESOURCES_PROPRES_KEY, columnRpTotalByYear[year])
                this.state.prices?.set("sous-total-annee-" + year + "-" + FrontDemandeAgrementFormStep1Component.DOTATIONS_SUBVENTIONS_KEY, columnDsTotalByYear[year])
                this.state.prices?.set("sous-total-annee-" + year + "-" + FrontDemandeAgrementFormStep1Component.MESURES_NOUVELLES_KEY, columnMnTotalByYear[year])

                const total = this.cleanAddition3(columnRpTotalByYear[year], columnDsTotalByYear[year], columnMnTotalByYear[year])
                this.state.prices?.set("sous-total-annee-" + year + "-total", total)
                this.state.prices?.set("annee-" + year, total + columnPicvTotalByYear[year] + columnPicfTotalByYear[year])

                totalByYear[year] = total
            })

            // total des années par colonne
            const totalRp = this.cleanAddition2(this.cleanAddition2(columnRpTotalByYear[0], columnRpTotalByYear[1]), this.cleanAddition2(columnRpTotalByYear[2], columnRpTotalByYear[3]))
            this.state.prices?.set("total-global-" + FrontDemandeAgrementFormStep1Component.RESOURCES_PROPRES_KEY, totalRp)
            const totalDs = this.cleanAddition2(this.cleanAddition2(columnDsTotalByYear[0], columnDsTotalByYear[1]), this.cleanAddition2(columnDsTotalByYear[2], columnDsTotalByYear[3]))
            this.state.prices?.set("total-global-" + FrontDemandeAgrementFormStep1Component.DOTATIONS_SUBVENTIONS_KEY, totalDs)
            const totalMn = this.cleanAddition2(this.cleanAddition2(columnMnTotalByYear[0], columnMnTotalByYear[1]), this.cleanAddition2(columnMnTotalByYear[2], columnMnTotalByYear[3]))
            this.state.prices?.set("total-global-" + FrontDemandeAgrementFormStep1Component.MESURES_NOUVELLES_KEY, totalMn)

            const total = totalByYear.reduce((sum, current) => sum + current, 0)
            this.state.prices?.set("total-global-total", total)
        }

        // TODO pourquoi juste l'année 0
        this.state.organismes?.forEach((organisme) => {
            this.updateTotalLine(null, 0, organisme.id!, FrontDemandeAgrementFormStep1Component.RESOURCES_PROPRES_KEY)
            this.updateTotalLine(null, 0, organisme.id!, FrontDemandeAgrementFormStep1Component.DOTATIONS_SUBVENTIONS_KEY)
            this.updateTotalLine(null, 0, organisme.id!, FrontDemandeAgrementFormStep1Component.MESURES_NOUVELLES_KEY)
            this.updateTotalLine(null, 0, organisme.id!, "total")
        })
    }

    private cleanAddition2(a: number, b: number) {
        return Number.parseInt(a.toString()) + Number.parseInt(b.toString())
    }

    private cleanAddition3(a: number, b: number, c: number) {
        return Number.parseInt(a.toString()) + Number.parseInt(b.toString()) + Number.parseInt(c.toString())
    }

    getHeaders() {
        const headers: any[] = []

        const tooltipRessourcesPropres = "Financement sur ressources propres<br/>(hors gestion contrôlée par une autorité de tarification)"
        const tooltipDotationsSubventions = "Financement sur dotations / subventions annuelles allouées<br/>(moyens de reconduction)"
        const tooltipMesuresNouvelles = "Financement sur des mesures nouvelles<br/>(nouvelle demande de crédits)"

        headers.push(
            <tr key="header-line0">
                <TableCellComponent colspan={5}>
                    <div style={{ padding: "10px", fontSize: "16px" }}>
                        <div style={{ lineHeight: "250%" }}>
                            <span>Les années N et N+1 sont obligatoirement renseignées.</span>
                            <br />
                            <span style={{ fontWeight: "normal" }}>Les données renseignées correspondent à des dépenses nouvelles.</span>
                            <br />
                            <span style={{ fontWeight: "normal" }}>Les années N+2 et N+3 sont renseignées uniquement si des dépenses nouvelles interviennent en N+2 N+3.</span>
                            <br />
                        </div>
                        <ul>
                            <li style={{ marginBottom: "5px" }}>
                                Les resources propres{" "}
                                <span style={{ fontWeight: "normal" }}>
                                    correspondent aux ressources hors gestion contrôlée par un financeur public. En d'autres termes, les excédents et les reports de crédits n'en font pas partie.
                                </span>
                            </li>
                            <li style={{ marginBottom: "5px" }}>
                                Les dotations / subventions <span style={{ fontWeight: "normal" }}>correspondent aux financements des autorités de tarifications.</span>
                            </li>
                            <li style={{ marginBottom: "5px" }}>
                                Les mesures nouvelles <span style={{ fontWeight: "normal" }}>correspondent à une demande de financement supplémentaire aux financements publics.</span>
                            </li>
                        </ul>
                    </div>
                    <hr />
                </TableCellComponent>
            </tr>
        )
        headers.push(
            <tr key="header-line1">
                <TableHeaderComponent>&nbsp;</TableHeaderComponent>
                <TableHeaderComponent style={{ textAlign: "center" }}>
                    <div>
                        Ressources propres&nbsp;<span data-tip={tooltipRessourcesPropres} data-html={true} className={Constant.ICON.INFO_LINE} aria-hidden={true}></span>
                    </div>
                </TableHeaderComponent>
                <TableHeaderComponent style={{ textAlign: "center" }}>
                    <div>
                        Dotations / subventions&nbsp;<span data-tip={tooltipDotationsSubventions} data-html={true} className={Constant.ICON.INFO_LINE} aria-hidden={true}></span>
                    </div>
                </TableHeaderComponent>
                <TableHeaderComponent style={{ textAlign: "center" }}>
                    <div>
                        Mesures nouvelles&nbsp;<span data-tip={tooltipMesuresNouvelles} data-html={true} className={Constant.ICON.INFO_LINE} aria-hidden={true}></span>
                    </div>
                </TableHeaderComponent>
                <TableHeaderComponent>&nbsp;</TableHeaderComponent>
            </tr>
        )

        return headers
    }

    getInputText(annee: number, idFinanceur: number, columnKey: string) {
        const inputId = "cell-" + annee + "-" + idFinanceur + "-" + columnKey
        const value = this.getCellValue(annee, idFinanceur, columnKey)

        return (
            <input
                defaultValue={value}
                key={inputId + "-" + Math.random()}
                type={"number"}
                min={0}
                id={inputId}
                onChange={(event) => this.updateTotalLine(event, annee, idFinanceur, columnKey)}
                style={{ border: "1px black dotted", textAlign: "center", width: "100%" }}
                placeholder="En €"
            />
        )
    }

    getInputTextPourInfo(annee: number, columnKey: string) {
        const inputId = "cell-" + annee + "-" + columnKey
        const value = this.getCellValuePourInfo(annee, columnKey)

        return (
            <input
                defaultValue={value}
                key={inputId + "-" + Math.random()}
                type={"number"}
                id={inputId}
                min={0}
                onChange={(event) => this.updateTotalPourInfo(event, annee, columnKey)}
                style={{ border: "1px black dotted", textAlign: "center", width: "100%" }}
                placeholder="En €"
            />
        )
    }

    parseValue(value: string | null): number | null {
        try {
            return Number.parseInt(value!)
        } catch (exception) {
            return null
        }
    }

    updateTotalLine(event: ChangeEvent<HTMLInputElement> | null, annee: number, idFinanceur: number, columnKey: string) {
        if (event != null) {
            event.preventDefault()
        }

        // update the value in map
        const temp = document.querySelector("#cell-" + annee + "-" + idFinanceur + "-" + columnKey) as HTMLInputElement
        if (temp != null) {
            this.state.prices?.set(annee + "-" + idFinanceur + "-" + columnKey, this.parseValue(temp.value))
        }

        const resourcesPropres = document.querySelector("#cell-" + annee + "-" + idFinanceur + "-" + FrontDemandeAgrementFormStep1Component.RESOURCES_PROPRES_KEY) as HTMLInputElement
        const dotationsSubventions = document.querySelector("#cell-" + annee + "-" + idFinanceur + "-" + FrontDemandeAgrementFormStep1Component.DOTATIONS_SUBVENTIONS_KEY) as HTMLInputElement
        const mesuresNouvelles = document.querySelector("#cell-" + annee + "-" + idFinanceur + "-" + FrontDemandeAgrementFormStep1Component.MESURES_NOUVELLES_KEY) as HTMLInputElement
        const inputs = [resourcesPropres, dotationsSubventions, mesuresNouvelles]

        let total = 0
        inputs.forEach((input) => {
            if (input != null) {
                if (!isNaN(Number.parseInt(input.value))) {
                    total += Number.parseInt(input.value)
                }
            }
        })

        const totalInput = document.querySelector("#cell-" + annee + "-" + idFinanceur + "-total") as HTMLInputElement
        if (totalInput != null) {
            totalInput.value = total.toString()
        }

        this.onChangeUpdateTotalColonne(event, annee, columnKey)
        this.onChangeUpdateTotalColonne(event, annee, "total")
    }

    updateTotalPourInfo(event: ChangeEvent<HTMLInputElement> | null, annee: number, columnKey: string) {
        this.onChangeUpdateTotalAnneeAvecPourInfo(event, annee, columnKey)
    }

    onChangeUpdateTotalColonne(event: ChangeEvent<HTMLInputElement> | null, annee: number, columnKey: string) {
        if (event != null) {
            event.preventDefault()
        }

        // update the value in map

        let total = 0
        this.state.organismes?.forEach((organisme) => {
            const organismeInput = document.querySelector("#cell-" + annee + "-" + organisme.id + "-" + columnKey) as HTMLInputElement
            if (organismeInput != null && !isNaN(Number.parseInt(organismeInput.value))) {
                total += Number.parseInt(organismeInput.value)
            }
        })

        const totalAnneeInput = document.querySelector("#sous-total-annee-" + annee + "-" + columnKey) as HTMLInputElement
        totalAnneeInput.value = total.toString()
        this.state.prices?.set("sous-total-annee-" + annee + "-" + columnKey, total)

        this.onChangeUpdateTotalAnneeAvecPourInfo(event, annee, columnKey)

        this.onChangeUpdateTotalGlobal(event, columnKey)
    }

    onChangeUpdateTotalGlobal(event: ChangeEvent<HTMLInputElement> | null, columnKey: string) {
        if (event != null) {
            event.preventDefault()
        }

        let total = 0

        FrontDemandeAgrementFormStep1Component.years.forEach((year) => {
            const annee = document.querySelector("#sous-total-annee-" + year + "-" + columnKey) as HTMLInputElement
            if (!isNaN(Number.parseInt(annee.value))) {
                total += Number.parseInt(annee.value)
            }
        })

        const totalGlobal = document.querySelector("#total-global-" + columnKey) as HTMLInputElement
        totalGlobal.value = total.toString()
        this.state.prices?.set("total-global-" + columnKey, total)
    }

    onChangeUpdateTotalAnneeAvecPourInfo(event: ChangeEvent<HTMLInputElement> | null, annee: number, columnKey: string) {
        if (event != null) {
            event.preventDefault()
        }

        const sousTotalAnnee = document.querySelector("#sous-total-annee-" + annee + "-total") as HTMLInputElement
        const cnav = document.querySelector("#cell-" + annee + "-" + FrontDemandeAgrementFormStep1Component.POUR_INFO_CAF) as HTMLInputElement
        const caf = document.querySelector("#cell-" + annee + "-" + FrontDemandeAgrementFormStep1Component.POUR_INFO_CNAV) as HTMLInputElement
        const totalAnnee = document.querySelector("#annee-" + annee) as HTMLInputElement

        const a = sousTotalAnnee.value === "" ? "0" : sousTotalAnnee.value
        const b = cnav.value === "" ? "0" : cnav.value
        const c = caf.value === "" ? "0" : caf.value

        const addition = this.cleanAddition3(parseInt(a), parseInt(b), parseInt(c))

        totalAnnee.value = addition.toString()
    }

    getCellValue(annee: number, idFinanceur: number, columnKey: string): string {
        try {
            const v = this.state.prices?.get(annee + "-" + idFinanceur + "-" + columnKey)

            if (v == undefined) {
                return ""
            } else {
                return v.toString()
            }
        } catch (exception) {
            return ""
        }
    }

    getCellValuePourInfo(annee: number, columnKey: string): string {
        try {
            const v = this.state.prices?.get(annee + "-" + columnKey)
            if (v == undefined) {
                return ""
            } else {
                return v.toString()
            }
        } catch (exception) {
            return ""
        }
    }

    getYearTitle(prefix: string, year: number): string {
        if (year == 0) {
            return prefix + " N"
        } else {
            return prefix + " N+" + year
        }
    }

    getTableCells() {
        const lines: any[] = []

        FrontDemandeAgrementFormStep1Component.years.forEach((year, index) => {
            lines.push(
                <tr key={"header-line-" + year} style={{ backgroundColor: "darkgray" }}>
                    <TableHeaderComponent>{this.getYearTitle("Année", year)}</TableHeaderComponent>
                    <TableHeaderComponent colSpan={3}>&nbsp;</TableHeaderComponent>
                    <TableHeaderComponent>Total</TableHeaderComponent>
                </tr>
            )

            this.getLines(year).forEach((cell) => lines.push(cell))

            lines.push(
                <tr key={"separator-line-0-" + index} style={{ background: "white" }}>
                    <td colSpan={5}></td>
                </tr>
            )
        })

        if (this.state?.organismes?.length != undefined && this.state.organismes.length > 0) {
            lines.push(this.getTotalGlobalLine())
        } else {
            lines.push(this.getTotalGlobalLineWithOutOrganisme())
        }

        return lines
    }

    getTotalGlobalLine() {
        const StyledInputTotal = styled.input`
            border: 1px dotted black;
            width: 100%;
            text-align: center;
            readonly: true;
        `

        return (
            <tr key="total-global">
                <TableCellComponent style={{ textAlign: "center" }}>Total Global</TableCellComponent>
                <TableCellComponent style={{ textAlign: "center" }}>
                    <StyledInputTotal
                        placeholder="En €"
                        id={"total-global-" + FrontDemandeAgrementFormStep1Component.RESOURCES_PROPRES_KEY}
                        defaultValue={this.getTotalValue("total-global-" + FrontDemandeAgrementFormStep1Component.RESOURCES_PROPRES_KEY)}
                    />
                </TableCellComponent>
                <TableCellComponent style={{ textAlign: "center" }}>
                    <StyledInputTotal
                        placeholder="En €"
                        id={"total-global-" + FrontDemandeAgrementFormStep1Component.DOTATIONS_SUBVENTIONS_KEY}
                        defaultValue={this.getTotalValue("total-global-" + FrontDemandeAgrementFormStep1Component.DOTATIONS_SUBVENTIONS_KEY)}
                    />
                </TableCellComponent>
                <TableCellComponent style={{ textAlign: "center" }}>
                    <StyledInputTotal
                        placeholder="En €"
                        id={"total-global-" + FrontDemandeAgrementFormStep1Component.MESURES_NOUVELLES_KEY}
                        defaultValue={this.getTotalValue("total-global-" + FrontDemandeAgrementFormStep1Component.MESURES_NOUVELLES_KEY)}
                    />
                </TableCellComponent>
                <TableCellComponent style={{ textAlign: "center" }}>
                    <StyledInputTotal placeholder="En €" id={"total-global-total"} defaultValue={this.getTotalValue("total-global-total")} />
                </TableCellComponent>
            </tr>
        )
    }

    getTotalGlobalLineWithOutOrganisme() {
        const StyledInputTotal = styled.input`
            border: 1px dotted black;
            width: 100%;
            text-align: center;
            readonly: true;
        `

        return (
            <tr key="total-global">
                <TableCellComponent style={{ textAlign: "center" }}>Total Global</TableCellComponent>
                <TableCellComponent style={{ textAlign: "center" }}>
                    <StyledInputTotal placeholder="En €" id={"total-global-total"} defaultValue={this.getTotalValue("total-global-total")} />
                </TableCellComponent>
                <TableCellComponent style={{ textAlign: "center" }}></TableCellComponent>
                <TableCellComponent style={{ textAlign: "center" }}></TableCellComponent>
                <TableCellComponent style={{ textAlign: "center" }}></TableCellComponent>
            </tr>
        )
    }

    getLines(year: number) {
        const StyledInputTotal = styled.input`
            border: 1px dotted black;
            width: 100%;
            text-align: center;
            readonly: true;
        `

        const lines: any[] = []

        if (this.state) {
            this.state.organismes?.forEach((organisme, index) => {
                const style = { backgroundColor: Constant.COLOR.VERY_LIGHT_BLUE }
                if (index % 2 == 0) {
                    style.backgroundColor = Constant.COLOR.LIGHT_BLUE
                }

                lines.push(
                    <tr key={"line-" + year + "-" + index} style={style}>
                        <TableCellComponent style={{ textAlign: "center" }}>{organisme.nom}</TableCellComponent>
                        <TableCellComponent style={{ textAlign: "center" }}>{this.getInputText(year, organisme.id!, FrontDemandeAgrementFormStep1Component.RESOURCES_PROPRES_KEY)}</TableCellComponent>
                        <TableCellComponent style={{ textAlign: "center" }}>
                            {this.getInputText(year, organisme.id!, FrontDemandeAgrementFormStep1Component.DOTATIONS_SUBVENTIONS_KEY)}
                        </TableCellComponent>
                        <TableCellComponent style={{ textAlign: "center" }}>{this.getInputText(year, organisme.id!, FrontDemandeAgrementFormStep1Component.MESURES_NOUVELLES_KEY)}</TableCellComponent>
                        <TableCellComponent style={{ textAlign: "center" }}>
                            <StyledInputTotal
                                id={"cell-" + year + "-" + organisme.id + "-total"}
                                defaultValue={this.getTotalValue(year + "-" + organisme.id + "-total")}
                                placeholder="En €"
                                onChange={(event) => this.onChangeUpdateTotalColonne(event, year, "total")}
                            />
                        </TableCellComponent>
                    </tr>
                )
            })
        }

        let style = { display: "table-row" }
        if (this.state == undefined || this.state.organismes == undefined || this.state.organismes.length == 0) {
            style = { display: "none" }
        }

        lines.push(
            <tr key={"sous-total-" + year} style={style}>
                <TableCellComponent>Sous total {this.getYearTitle("année", year)}</TableCellComponent>
                <TableCellComponent style={{ textAlign: "center" }}>
                    <StyledInputTotal
                        id={"sous-total-annee-" + year + "-" + FrontDemandeAgrementFormStep1Component.RESOURCES_PROPRES_KEY}
                        readOnly={true}
                        placeholder="En €"
                        defaultValue={this.getTotalValue("sous-total-annee-" + year + "-" + FrontDemandeAgrementFormStep1Component.RESOURCES_PROPRES_KEY)}
                    />
                </TableCellComponent>
                <TableCellComponent style={{ textAlign: "center" }}>
                    <StyledInputTotal
                        id={"sous-total-annee-" + year + "-" + FrontDemandeAgrementFormStep1Component.DOTATIONS_SUBVENTIONS_KEY}
                        readOnly={true}
                        placeholder="En €"
                        defaultValue={this.getTotalValue("sous-total-annee-" + year + "-" + FrontDemandeAgrementFormStep1Component.DOTATIONS_SUBVENTIONS_KEY)}
                    />
                </TableCellComponent>
                <TableCellComponent style={{ textAlign: "center" }}>
                    <StyledInputTotal
                        id={"sous-total-annee-" + year + "-" + FrontDemandeAgrementFormStep1Component.MESURES_NOUVELLES_KEY}
                        readOnly={true}
                        placeholder="En €"
                        defaultValue={this.getTotalValue("sous-total-annee-" + year + "-" + FrontDemandeAgrementFormStep1Component.MESURES_NOUVELLES_KEY)}
                    />
                </TableCellComponent>
                <TableCellComponent style={{ textAlign: "center" }}>
                    <StyledInputTotal id={"sous-total-annee-" + year + "-total"} placeholder="En €" readOnly={true} defaultValue={this.getTotalValue("sous-total-annee-" + year + "-total")} />
                </TableCellComponent>
            </tr>
        )

        lines.push(
            <tr key={"separator-line-avant-pour-info-" + year} style={style}>
                <td colSpan={5}></td>
            </tr>
        )

        lines.push(
            <tr key={"cnav-" + year}>
                <TableCellComponent>Pour info CNAV</TableCellComponent>
                <TableCellComponent>{this.getInputTextPourInfo(year, FrontDemandeAgrementFormStep1Component.POUR_INFO_CNAV)}</TableCellComponent>
                <TableCellComponent colspan={3}></TableCellComponent>
            </tr>
        )

        lines.push(
            <tr key={"caf-" + year}>
                <TableCellComponent>Pour info CAF</TableCellComponent>
                <TableCellComponent>{this.getInputTextPourInfo(year, FrontDemandeAgrementFormStep1Component.POUR_INFO_CAF)}</TableCellComponent>
                <TableCellComponent colspan={3}></TableCellComponent>
            </tr>
        )

        lines.push(
            <tr key={"separator-line-apres-pour-info-" + year}>
                <td colSpan={5}></td>
            </tr>
        )

        lines.push(
            <tr key={"total-" + year}>
                <TableCellComponent>Coût total {this.getYearTitle("année", year)}</TableCellComponent>
                <TableCellComponent>
                    <StyledInputTotal id={"annee-" + year} placeholder="En €" defaultValue={this.getTotalValue("annee-" + year)} />
                </TableCellComponent>
                <TableCellComponent colspan={3}></TableCellComponent>
            </tr>
        )

        return lines
    }

    getTotalValue(id: string): string {
        if (this.state) {
            const price = this.state.prices?.get(id)
            if (price === 0) {
                return ""
            }
            if (price != null) {
                return Number.parseInt(price.toString()).toString()
            }
        }
        return ""
    }

    getExplicationValue(): string {
        if (this.props.demandeInProgress?.explication != null) {
            return this.props.demandeInProgress.explication
        }
        return ""
    }

    getCoutsError() {
        if (this.props.errors && this.props.errors.has("couts")) {
            return <span className="fr-error-text">{this.props.errors.get("couts")}</span>
        }
    }

    getCoutsForm() {
        if (this.state == null || this.state.noImpactChecked == undefined || !this.state.noImpactChecked) {
            return (
                <div style={{ display: "block" }}>
                    {this.getCoutsError()}
                    <TableContainerComponent headers={this.getHeaders()} cells={this.getTableCells()} noscroll />
                </div>
            )
        } else {
            return (
                <div style={{ display: "none" }}>
                    {this.getCoutsError()}
                    <TableContainerComponent headers={this.getHeaders()} cells={this.getTableCells()} noscroll />
                </div>
            )
        }
    }

    onImpactFinancierChange() {
        const checkbox = document.querySelector("[id^='cb-sans-impact-financier']") as HTMLInputElement
        this.setState({
            noImpactChecked: checkbox.checked
        })
    }

    render() {
        return (
            <>
                <SecondaryContentBoxComponent title="Coût des mesures proposées">
                    <CheckboxComponent
                        label="A cocher si votre accord n'induit aucun coût nouveau pour votre association. Ne pas cocher cette case si votre accord représente un coût mais qu'il est financé par des ressources propres ou dans le cadre des moyens qui vous ont été alloués par votre autorité de tarification : ces informations vous seront demandées par la suite."
                        id="sans-impact-financier"
                        onChange={() => this.onImpactFinancierChange()}
                        checked={this.state?.noImpactChecked ?? false}
                    />
                    {this.getCoutsForm()}
                </SecondaryContentBoxComponent>
                <VerticalSpacing height="20px" />
                <SecondaryContentBoxComponent title="Eléments complémentaires d'explication">
                    <TextAreaComponent id="explication" rows={10} text={decode(this.getExplicationValue())} />
                </SecondaryContentBoxComponent>
                <ReactTooltip />
            </>
        )
    }
}
