import React, { Fragment } from "react"

import DnumComponent, { DnumProps } from "../../../generic/component/DnumComponent"
import HorizontalMenuComponent from "../../../generic/component/menu/horizontal/HorizontalMenuComponent"
import HorizontalMenuItemData from "../../../generic/component/menu/horizontal/HorizontalMenuItemData"

import { Constant } from "../../constant/Constant"
import Role from "../../constant/role/Role"
import BackOfficeService from "../../service/BackOfficeService"

export default class BackOfficeMenuComponent extends DnumComponent<any, any> {
    getHorizontalAdminMenu(role: string | null): HorizontalMenuItemData[] {
        const agrement = new HorizontalMenuItemData("Gestion des demandes", "agrement-menu-item", [
            new HorizontalMenuItemData("Extraction des données", Constant.DOM.BACK_OFFICE.AGREMENT__EXTRACTION, null),
            new HorizontalMenuItemData("Toutes les demandes", Constant.DOM.BACK_OFFICE.AGREMENT__TOUTES_LES_DEMANDES, null),
            new HorizontalMenuItemData("- A traiter", Constant.DOM.BACK_OFFICE.AGREMENT__DEMANDES_A_TRAITER, null),
            new HorizontalMenuItemData("- En attente avis financeur", Constant.DOM.BACK_OFFICE.AGREMENT__EN_ATTENTE_AVIS_FINANCEUR, null),
            new HorizontalMenuItemData("- En attente CNA", Constant.DOM.BACK_OFFICE.AGREMENT__DEMANDES_EN_ATTENTE_CNA, null),
            new HorizontalMenuItemData("- Prochaine CNA", Constant.DOM.BACK_OFFICE.AGREMENT__DEMANDES_PROCHAINE_CNA, null)
        ])

        const referentiel = new HorizontalMenuItemData("Référentiels", "referentiel-menu-item", [
            /*
            new HorizontalMenuItemData("Types d'accord", Constant.DOM.BACK_OFFICE.REFERENTIEL__TYPE_ACCORD, null),
            new HorizontalMenuItemData("Motifs de refus", Constant.DOM.BACK_OFFICE.REFERENTIEL__MOTIF_REFUS, null),
            new HorizontalMenuItemData("Pièces exigibles", Constant.DOM.BACK_OFFICE.REFERENTIEL__PIECES_EXIGIBLES, null),
            new HorizontalMenuItemData("Typologie des accords", Constant.DOM.BACK_OFFICE.REFERENTIEL__TYPOLOGIE_ACCORD, null),
            */
            new HorizontalMenuItemData("Modèles de courriel / document", Constant.DOM.BACK_OFFICE.REFERENTIEL__TEMPLATE_COURRIEL_DOCUMENT, null),
            new HorizontalMenuItemData("ESMS", Constant.DOM.BACK_OFFICE.REFERENTIEL__ESMS, null),
            new HorizontalMenuItemData("IDCC", Constant.DOM.BACK_OFFICE.REFERENTIEL__IDCC, null),
            new HorizontalMenuItemData("Signature", Constant.DOM.BACK_OFFICE.REFERENTIEL__SIGNATURE, null)
        ])

        const cna = new HorizontalMenuItemData("CNA", "cna-menu-item", [
            new HorizontalMenuItemData("Liste des CNA", Constant.DOM.BACK_OFFICE.CNA__LIST, null),
            new HorizontalMenuItemData("Membres CNA", Constant.DOM.BACK_OFFICE.CNA__MEMBRE, null)
        ])

        const membreCna = new HorizontalMenuItemData("Liste des CNA", Constant.DOM.BACK_OFFICE.CNA__LIST, null)

        const financeur = new HorizontalMenuItemData("Autorités de tarification", "financeur-menu-item", [
            //new HorizontalMenuItemData("Suivi des avis", Constant.DOM.BACK_OFFICE.FINANCEUR__SUIVI_AVIS, null),
            new HorizontalMenuItemData("Types", Constant.DOM.BACK_OFFICE.REFERENTIEL__TYPE_FINANCEUR, null),
            new HorizontalMenuItemData("Organismes", Constant.DOM.BACK_OFFICE.REFERENTIEL__ORGANISME_FINANCEUR, null),
            new HorizontalMenuItemData("Contacts", Constant.DOM.BACK_OFFICE.REFERENTIEL__CONTACT_FINANCEUR, null)
        ])

        const utilisateur = new HorizontalMenuItemData("Comptes utilisateur", "compte-menu-item", [
            new HorizontalMenuItemData("Associations", Constant.DOM.BACK_OFFICE.UTILISATEUR__LIST_ASSOCIATION, null),
            new HorizontalMenuItemData("Financeurs", Constant.DOM.BACK_OFFICE.UTILISATEUR__LIST_FINANCEUR, null),
            new HorizontalMenuItemData("Membres CNA", Constant.DOM.BACK_OFFICE.UTILISATEUR__LIST_MEMBRE_CNA, null)
        ])

        const maintenance = new HorizontalMenuItemData("Maintenance", "maintenance-menu",null)
 
        if (role === Role.BACK.ADMIN) {
            return [agrement, cna, financeur, referentiel, utilisateur,maintenance]
        } else if (role === Role.BACK.GESTIONNAIRE) {
            return [agrement, cna]
        } else if (role == Role.BACK.MEMBRE_CNA) {
            return []
        } else {
            return []
        }
    }

    getHorizontalMenuByRole(role: string | null) {
        const array = this.getHorizontalAdminMenu(role)
        return (
            <div className="fr-container">
                <HorizontalMenuComponent onClickHandler={(id: string) => BackOfficeService.getInstance().switchPage(id)} data={array} currentId={Constant.DOM.BACK_OFFICE.ADMIN_HOME_PAGE} />
            </div>
        )
    }

    render() {
        const role = BackOfficeService.getInstance().getRole()
        return <Fragment>{this.getHorizontalMenuByRole(role)}</Fragment>
    }
}
