import React from "react"

import AccordionComponent from "../../../generic/component/accordion/AccordionComponent"
import AccordionGroupComponent from "../../../generic/component/accordion/group/AccordionGroupComponent"

import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import CustomHighlightComponent from "../../shared/component/highlight/CustomHighlightComponent"
import FrontPage from "../FrontPage"

export default class FrontLegalNoticePage extends FrontPage<any, any> {
    render() {
        return (
            <PrimaryContentBoxComponent title="Informations légales">
                <AccordionGroupComponent>
                    <AccordionComponent title="Mentions légales" id="group-legal" index={0}>
                        <CustomHighlightComponent>
                            <>
                                Publié le 2 novembre 2017, Direction Générale de la Cohésion Sociale
                                <br />
                                <br />
                                Les mentions légales obligatoires concernant le site{" "}
                                <a href="https://accords-agrements.social.gouv.fr" target="_blank" rel="noreferrer">
                                    accords-agrements.social.gouv.fr
                                </a>{" "}
                                des agréments des conventions et accords applicables aux salariés des établissements sociaux et médico-sociaux privés à but non lucratif concernés par la procédure
                                d'agrément conformément à l'article L314-16 du code de l'action sociale et des familles sont indiquées sur cette page.
                            </>
                        </CustomHighlightComponent>
                    </AccordionComponent>
                    <AccordionComponent title={"Editeur du site"} id="group-legal" index={1}>
                        <CustomHighlightComponent>
                            <>
                                Ministère des solidarités et de la santé, 14 avenue Duquesne, 75350 Paris 07 SP : <b>direction générale de la cohésion sociale</b>. Directeur de publication : Jean
                                Philippe VINQUANT, Directeur Général de la cohésion sociale
                            </>
                        </CustomHighlightComponent>
                    </AccordionComponent>
                    <AccordionComponent title={"Hébergeur du site"} id="group-legal" index={2}>
                        <CustomHighlightComponent>
                            <>
                                Ministère des solidarités et de la santé 14 avenue Duquesne, 75350 Paris 07 SP : <b>direction des systèmes d'information</b>.
                            </>
                        </CustomHighlightComponent>
                    </AccordionComponent>
                    <AccordionComponent title={"Protection de la vie privée"} id="group-legal" index={3}>
                        <CustomHighlightComponent>
                            <>
                                Le site web, a fait l'objet d'une déclaration à la Commission Nationale de l'Informatique et des Libertés (CNIL) sous le n° d'enregistrement 2104475.
                                <br />
                                <br />
                                Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, vous disposez d'un droit d'accès, de
                                rectification, d'opposition et d'effacement sur les données vous concernant que vous pouvez exercer en contactant l'éditeur.
                            </>
                        </CustomHighlightComponent>
                    </AccordionComponent>
                    <AccordionComponent title={"Droits de reproduction"} id="group-legal" index={4}>
                        <CustomHighlightComponent>
                            <>
                                Les contenus du site à l'exception des marques et logos et des contenus grevés de droits de propriété intellectuelle de tiers ou contenant des données personnelles,
                                sont des informations publiques librement et gratuitement réutilisables dans les conditions fixées par la loi n°78-753 du 17 juillet 1978. Si vous souhaitez réutiliser
                                des contenus présents sur ce site, merci de bien vouloir contacter l'éditeur en vous reportant à la rubrique contact dudit site. Après avoir obtenu l'accord auprès du
                                service gestionnaire de ce site, la reproduction (à but non lucratif) est autorisée à condition d'en mentionner la source ainsi que la date.
                            </>
                        </CustomHighlightComponent>
                    </AccordionComponent>
                    <AccordionComponent title={"Liens hypertextes"} id="group-legal" index={5}>
                        <CustomHighlightComponent>
                            <>
                                Des liens vers d'autres sites sont proposés. Leur présence ne saurait engager l'éditeur quant à leur contenu et ne visent qu'à permettre à l'internaute de trouver plus
                                facilement d'autres ressources d'information sur le sujet consulté.
                                <br />
                                <br />
                                La mise en place de lien vers le site web est conditionnée à aucun accord préalable.
                            </>
                        </CustomHighlightComponent>
                    </AccordionComponent>
                    <AccordionComponent title={"Politique de sécurité"} id="group-legal" index={6}>
                        <CustomHighlightComponent>
                            <>
                                Notre site vous garantit une connexion sécurisée. Vous pouvez vérifier cette sécurité par la présence du « https: » avant l'adresse et de l'image d'un cadenas. Un
                                double-clic sur le pictogramme affiche les données relatives au certificat de sécurité du site.
                            </>
                        </CustomHighlightComponent>
                    </AccordionComponent>
                </AccordionGroupComponent>
            </PrimaryContentBoxComponent>
        )
    }
}
