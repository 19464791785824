import { ReactElement } from "react"

import File from "../../model/File"

import LocalStorageService from "../storage/LocalStorageService"

export default class FrontApplicationService {
    storage: LocalStorageService | null = null

    userConnected = "userConnected"
    accountStatus = "accountStatus"

    constructor() {
        this.storage = new LocalStorageService()
    }

    setUserConnected() {
        this.storage?.setBooleanItem(this.userConnected, true)
    }

    isUserConnected() {
        return this.storage?.getBooleanItem(this.userConnected) == true
    }

    logout() {
        this.storage?.setBooleanItem(this.userConnected, false)
    }

    // status lié à l'étape du formulaire d'inscription
    setAccountStatus(status: "infoEF" | "infoET" | "readyPartial" | "ready") {
        this.storage?.setItem(this.accountStatus, status)
    }

    getAccountStatus() {
        return this.storage?.getItem(this.accountStatus)
    }

    getLink(file: File): string {
        return process.env.REACT_APP_CONTEXT_PATH + "/front/file/download/" + file.key
    }

    buildLink(file: File): ReactElement {
        return (
            <a href={this.getLink(file)} target="_blank">
                {file.originalName + "." + file.extension}
            </a>
        )
    }
}
