import React, { ReactElement } from "react"

import { DnumProps } from "../../../generic/component/DnumComponent"
import AlertSuccessComponent from "../../../generic/component/alert/AlertSuccessComponent"
import AlertWarningComponent from "../../../generic/component/alert/AlertWarningComponent"
import Button from "../../../generic/component/button/Button"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"
import PagerComponent from "../../../generic/component/pager/PagerComponent"
import DotStatus from "../../../generic/component/status/DotStatus"

import { Constant } from "../../constant/Constant"
import HttpReferentielService from "../../service/http/HttpReferentielService"
import VerticalSpacing from "../../shared/component/space/VerticalSpacing"
import Pager from "../../shared/dto/Pager"
import BackOfficeComponent from "../BackOfficeComponent"

interface ListReferentielLocalProps extends DnumProps {
    addButtonHandler: () => void
    editButtonHandler: (id: number | null) => void
    deleteButtonHandler: (id: number | null) => void
    forceRefresh: string
}

interface ListReferentielLocalState<OBJECT> {
    items?: OBJECT[] | null
    pager?: Pager | null
    itemCount?: number | null
    errorMessage?: string | null
    successMessage?: string | null
    filter?: Map<string, string> | null
    updateFilter?: number
}

export default abstract class BackOfficeAbstractReferentielListComponent<OBJECT> extends BackOfficeComponent<ListReferentielLocalProps, ListReferentielLocalState<OBJECT>> {
    constructor(props: ListReferentielLocalProps) {
        super(props)
    }

    componentDidMount() {
        this.getHttpReferentielService()
            .getAll<OBJECT>(this.getInitPager())
            .then((response) => {
                this.setState({
                    items: response.content,
                    pager: this.getInitPager(),
                    itemCount: response.itemCount,
                    filter: new Map<string, string>(),
                    updateFilter: 0
                })
            })
    }

    currentPage = -1
    previousPage = -1

    componentDidUpdate(prevProps: Readonly<ListReferentielLocalProps>, prevState: Readonly<ListReferentielLocalState<OBJECT>>, snapshot?: any) {
        //console.log("passepasse "+this.state?.filter)

        if (this.state) {
            if (this.state && this.state.pager) {
                this.previousPage = this.currentPage
                this.currentPage = this.state.pager.pageNumber!
            }

            // force refresh
            const condition1 = prevProps.forceRefresh !== this.props.forceRefresh
            // changement du pager
            const condition2 = this.state != null && this.state.pager != null && prevState != null && prevState.pager != null && this.previousPage != prevState.pager.pageNumber
            // forceFilterRefresh
            const condition3 = this.state?.updateFilter != prevState?.updateFilter

            if (condition1 || condition2 || condition3) {
                this.getHttpReferentielService()
                    .getAll<OBJECT>(this.state.pager, this.state?.filter ?? null)
                    .then((response) => {
                        this.setState({
                            items: response.content,
                            itemCount: response.itemCount
                        })
                    })
            }
        }
    }

    switchPager(newPageNumber: number) {
        const pager = this.state.pager!
        pager.setCurrentPage(newPageNumber)
        this.setState({
            pager: pager
        })
    }

    delete(id: number) {
        //this.props.deleteButtonHandler(id)
        this.getHttpReferentielService()
            .delete(id)
            .then((response) => {
                if (response.success) {
                    this.setState({
                        successMessage: response.successMessage,
                        errorMessage: response.errorMessage
                    })
                    // delete behaviour on parent
                    this.props.deleteButtonHandler(id)
                } else {
                    this.setState({
                        successMessage: undefined,
                        errorMessage: response.errorMessage
                    })
                }
            })
    }

    abstract getLine(item: OBJECT, index: number): ReactElement
    abstract getAddLabel(): string
    abstract getHttpReferentielService(): HttpReferentielService

    protected getUsePager(): boolean {
        return false
    }

    protected getItemByPage(): number {
        return 10
    }

    getInitPager(): Pager | null {
        if (this.getUsePager()) {
            return new Pager(this.getItemByPage())
        }
        return null
    }

    getThead(): React.ReactElement {
        return (
            <thead style={{ width: "100%" }}>
                <tr>
                    <th scope="col" style={{ width: "25%" }}>
                        Code
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                        Libellé
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                        Actif
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                        Actions
                    </th>
                </tr>
            </thead>
        )
    }

    getItems(): any[] | null {
        if (this.state != null && this.state.items) {
            const items: any[] = []
            this.state.items.forEach((item: OBJECT, index: number) => {
                items.push(this.getLine(item, index))
            })
            return items
        }
        return null
    }

    getPagerView() {
        //const condition = this.state!=null && this.state.pager!=null && this.state.items!=null

        if (this.state != null && this.state.pager != null && this.state.items != null) {
            return (
                <RowComponent center>
                    <ColumnComponent size={4} />
                    <ColumnComponent size={4} style={{ textAlign: "center" }}>
                        <PagerComponent
                            itemByPage={this.state.pager.itemByPage!}
                            itemCount={this.state.itemCount!}
                            currentPage={this.state.pager.pageNumber!}
                            choosePage={(pageNumber: number) => this.switchPager(pageNumber)}
                            href="#table-referentiel"
                        />
                    </ColumnComponent>
                    <ColumnComponent size={4} />
                </RowComponent>
            )
        } else {
            console.log("No pager")
        }
    }

    getDeleteMessage() {
        if (this.state) {
            if (this.state.errorMessage) {
                return <AlertWarningComponent description={this.state.errorMessage} />
            } else if (this.state.successMessage) {
                return <AlertSuccessComponent description={this.state.successMessage} />
            }
        }
    }

    getStatutBar(green: boolean): ReactElement {
        if (green) {
            return <DotStatus size={10} color={"green"} />
        } else {
            return <DotStatus size={10} color={"red"} />
        }
    }

    getFilter(): ReactElement {
        return <>{null}</>
    }

    openInNewTab = (url: string | URL | undefined) => {
        window.open(url, "_blank", "noopener,noreferrer")
    }

    getUrl() {
        switch (this.constructor.name) {
            case "BackOfficeMembreCnaListComponent":
                return "/back/csv/cnas"
            case "BackOfficeContactFinanceurListComponent":
                return "/back/csv/contacts"
            case "BackOfficeInstructionListDemandeAgrementPage":
                return "/back/csv/demandes"
            case "BackOfficeUtilisateurAssociationPage":
                return "/back/csv/associations"
            default:
                return "none"
        }
    }

    render() {
        return (
            <>
                <RowComponent>
                    <ColumnComponent size={3}></ColumnComponent>
                    <ColumnComponent size={5}>{this.getDeleteMessage()}</ColumnComponent>
                    <ColumnComponent size={1} />

                    <ColumnComponent size={1} />
                    <ColumnComponent size={2}>
                        <Button onClick={() => this.props.addButtonHandler()} iconLeft={Constant.ICON.ADD_CIRCLE_FILL}>
                            {this.getAddLabel()}
                        </Button>
                    </ColumnComponent>
                </RowComponent>
                <VerticalSpacing height={"20px"} />
                {this.getFilter()}
                <RowComponent>
                    <ColumnComponent size={12}>
                        <div className="fr-table fr-table--layout-fixed">
                            <table id="#table-referentiel">
                                {this.getThead()}
                                <tbody>{this.getItems()}</tbody>
                            </table>
                        </div>
                    </ColumnComponent>
                </RowComponent>
                {this.getPagerView()}
            </>
        )
    }
}
