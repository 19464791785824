import React, { ReactElement } from "react"

import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"
import Highlight from "../../../../generic/component/highlight/Highlight"

import FrontComponent from "../../FrontComponent"

export default class FrontAccountFinessUpdateMessageComponent extends FrontComponent<any, any> {
    getHtmlMessage(): ReactElement {
        return (
            <span>
                Pour modifier une information de la base Finess, vous pouvez écrire à :{" "}
                <a href={"mailto:monserviceclient.finess@esante.gouv.fr?Subject=Accolade - demande de modification"}>monserviceclient.finess@esante.gouv.fr</a> en précisant dans l'objet de votre
                courriel : Accolade - demande de notification.
            </span>
        )
    }

    render() {
        return (
            <RowComponent key={"row-edit-finance-1"}>
                <ColumnComponent size={1} />
                <ColumnComponent size={10}>
                    <Highlight html={this.getHtmlMessage()} />
                    <br />
                </ColumnComponent>
                <ColumnComponent size={1} />
            </RowComponent>
        )
    }
}
