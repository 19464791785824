import React, { Fragment, ReactElement } from "react"

import BackAccount from "../../model/BackAccount"

import Button from "../../../generic/component/button/Button"
import TextInputComponent from "../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"

import { Constant } from "../../constant/Constant"
import HttpBackAccountService from "../../service/http/HttpBackAccountService"
import Response from "../../service/http/response/Response"
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import BackOfficePage from "../BackOfficePage"
import BackOfficeModalActionComponent from "../referentiel/BackOfficeModalActionComponent"
import PagerComponent from "../../../generic/component/pager/PagerComponent";

interface UtilisateurManagePageState {
    accounts: BackAccount[] | null
    count: number
    currentPage: number
    successMessage: string | undefined
    errorMessage: string | undefined
}

export default abstract class BackOfficeAbstractUtilisateurManagePage extends BackOfficePage<any, UtilisateurManagePageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            accounts: null,
            count: 0,
            currentPage: 0,
            successMessage: undefined,
            errorMessage: undefined
        }
    }

    getThead(): React.ReactElement {
        return (
            <thead style={{ width: "100%" }}>
                <tr>
                    <td scope="col" style={{ width: "3px" }}></td>
                    <th scope="col" style={{ width: "15%" }}>
                        Nom
                    </th>
                    <th scope="col" style={{ width: "15%" }}>
                        Prénom
                    </th>
                    <th scope="col" style={{ width: "20%" }}>
                        Courriel
                    </th>
                    <th scope="col" style={{ width: "10%" }}>
                        Téléphone
                    </th>
                    <th scope="col" style={{ width: "30%" }}>
                        Informations
                    </th>
                    <th scope="col" style={{ width: "10%" }}>
                        Actions
                    </th>
                </tr>
            </thead>
        )
    }

    componentDidMount() {
        this.choosePage(0)
    }

    abstract getTitle(): string
    abstract choosePage(page: number): any

    getLine(account: BackAccount): ReactElement {
        return (
            <tr key={"key-" + account.id}>
                <td>{this.getStatutBar(account)}</td>
                <td>{account.nomInscription}</td>
                <td>{account.prenomInscription}</td>
                <td>{account.email}</td>
                <td>{account.telephoneInscription}</td>
                <td>{this.getInformations(account)}</td>
                <td align={"center"}>{this.getActions(account)}</td>
            </tr>
        )
    }

    getStatutBar(account: BackAccount): ReactElement {
        if (account.valid) {
            return <div style={{ width: "10px", height: "10px", borderRadius: "5px", background: "green" }}></div>
        } else {
            return <div style={{ width: "10px", height: "10px", borderRadius: "5px", background: "red" }}></div>
        }
    }

    getActions(account: BackAccount) {
        return (
            <Fragment>
                <RowComponent>
                    <ColumnComponent size={5}>{this.getNewPasswordAction(account)}</ColumnComponent>
                    <ColumnComponent size={1} />
                    <ColumnComponent size={5}>{this.getStatutAction(account)}</ColumnComponent>
                </RowComponent>
            </Fragment>
        )
    }

    getNewPasswordAction(account: BackAccount) {
        return (
            <Fragment>
                <Button secondary iconOnly={Constant.ICON.REFRESH_LINE} modal={"action-utilisateur-" + account.id}>
                    Nouveau mot de passe
                </Button>
                <BackOfficeModalActionComponent
                    id={"action-utilisateur-" + account.id}
                    title={"Générer un nouveau mot de passe"}
                    message={"Si vous confirmez un nouveau mot de passe va être générer et un mail informatif va être envoyé au contact"}
                    doAction={() => this.sendNewPassword(account.id!, account.type!)}
                />
            </Fragment>
        )
    }

    getStatutAction(account: BackAccount) {
        if (account.valid) {
            return (
                <Fragment>
                    <Button iconOnly={Constant.ICON.ACCOUNT_FILL} modal={"statut-utilisateur-" + account.id}></Button>
                    <BackOfficeModalActionComponent
                        id={"statut-utilisateur-" + account.id}
                        title={"Désactiver ce compte"}
                        message={"Si vous confirmez ce compte sera désactivé, l'utilisateur ne pourra plus se connecter"}
                        doAction={() => this.changeStatut(account.email!, account.type!, !account.valid)}
                    />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <Button iconOnly={Constant.ICON.ACCOUNT_FILL} modal={"statut-utilisateur-" + account.id}></Button>
                    <BackOfficeModalActionComponent
                        id={"statut-utilisateur-" + account.id}
                        title={"Réactiver ce compte"}
                        message={"Si vous confirmez ce compte sera réactivé, l'utilisateur pourra de nouveau se connecter"}
                        doAction={() => this.changeStatut(account.email!, account.type!, !account.valid)}
                    />
                </Fragment>
            )
        }
    }

    changeStatut(email: string, accountType: string, active: boolean) {
        new HttpBackAccountService().changeAccountStatut(email, accountType, active).then((response) => {
            const cast = new Response(response)
            if (cast.success) {
                this.choosePage(this.state.currentPage)
            }
        })
    }

    sendNewPassword(accountId: number, accountType: string) {
        new HttpBackAccountService().changeAccountPassword(accountId, accountType).then((response) => {
            const cast = new Response(response)
            if (cast.success) {
                this.setState({
                    successMessage: cast.successMessage,
                    errorMessage: cast.errorMessage
                })
            } else {
                this.setState({
                    successMessage: undefined,
                    errorMessage: cast.errorMessage
                })
            }
        })
    }

    getInformations(account: BackAccount) {
        const informations: ReactElement[] = []
        if (account.wrongPasswordCount != null && account.wrongPasswordCount > 0) {
            if (account.wrongPasswordCount > 1) {
                informations.push(
                    <p key="wrong-password" style={{ fontSize: "smaller" }}>
                        Login : {account.wrongPasswordCount} tentatives erronées
                    </p>
                )
            } else {
                informations.push(
                    <p key="wrong-password" style={{ fontSize: "smaller" }}>
                        Login : {account.wrongPasswordCount} tentative erronée
                    </p>
                )
            }
        }
        if (account.dateBlocage != null) {
            const newDateBlocage = new Date(account.dateBlocage)
            informations.push(
                <p key="date-blocage" style={{ fontSize: "smaller" }}>
                    Compte bloqué : {newDateBlocage.toLocaleString()}
                </p>
            )
        }
        if (account.dateLastLogin != null) {
            const newDateLastLogin = new Date(account.dateLastLogin)
            informations.push(
                <p key="last-login" style={{ fontSize: "smaller" }}>
                    Dernier login : {newDateLastLogin.toLocaleString()}
                </p>
            )
        }

        return informations
    }

    getLines(): ReactElement[] {
        const accounts: ReactElement[] = []
        if (this.state && this.state.accounts) {
            this.state.accounts.forEach((account: BackAccount) => {
                accounts.push(this.getLine(account))
            })
        }
        return accounts
    }

    getTbody(): React.ReactElement {
        return <tbody>{this.getLines()}</tbody>
    }

    getFilterValue(): any {
        const input = document.querySelector("#utilisateur-filter") as HTMLInputElement
        if (input.value == null || input.value === '') {
            return null
        } else {
            return input.value
        }
    }

    openInNewTab = (url: string | URL | undefined) => {
        window.open(url, "_blank", "noopener,noreferrer")
    }

    getUrl() {
        //console.log(this.constructor.name)
        switch (this.constructor.name) {
            case "BackOfficeMembreCnaListComponent":
                return "/back/csv/membres"
            case "BackOfficeContactFinanceurListComponent":
                return "/back/csv/contacts"
            case "BackOfficeUtilisateurFinanceurPage":
                return "/back/csv/contacts"
            case "BackOfficeInstructionListDemandeAgrementPage":
                return "/back/csv/demandes"
            case "BackOfficeUtilisateurAssociationPage":
                return "/back/csv/associations"
            case "BackOfficeMembreCnaPage":
                return "/back/csv/membres"
            // Vide pour le moment, si y a des changements : modifier l'url
            case "BackOfficeUtilisateurMembreCnaPage":
                return "/back/csv/membres"
            default:
                return "none"
        }
    }

    render(): JSX.Element {
        return (
            <ContainerComponent>
                <PrimaryContentBoxComponent
                    title={this.getTitle()}
                    rightComponent={
                        <RowComponent>
                            <ColumnComponent size={7} />
                            <ColumnComponent size={5}>
                                {this.getUrl() != "none" ? (
                                    <Button style={{ marginTop: "4px" }} onClick={() => this.openInNewTab("/accolade" + this.getUrl())} iconLeft={Constant.ICON.DOWNLOAD_FILL}>
                                        {"Télécharger"}
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </ColumnComponent>
                        </RowComponent>
                    }>
                    <RowComponent>
                        <ColumnComponent size={9}></ColumnComponent>
                        <ColumnComponent size={3}>
                            <TextInputComponent label={"Filtre (courriel)"} id={"utilisateur-filter"} onKeyUpHandler={() => this.choosePage(0)} />
                        </ColumnComponent>
                    </RowComponent>
                    <RowComponent>
                        <ColumnComponent size={12}>
                            <div className="fr-table fr-table--layout-fixed">
                                <table>
                                    {this.getThead()}
                                    {this.getTbody()}
                                </table>
                            </div>
                        </ColumnComponent>
                    </RowComponent>
                    <RowComponent>
                        <ColumnComponent size={3} />
                        <ColumnComponent size={6}>
                            <PagerComponent itemByPage={20} itemCount={this.state.count}
                                            currentPage={this.state.currentPage}
                                            choosePage={(page) => this.choosePage(page)} />
                        </ColumnComponent>
                        <ColumnComponent size={3} />
                    </RowComponent>
                </PrimaryContentBoxComponent>
            </ContainerComponent>
        )
    }
}
