import React, { ReactElement } from "react"

import HtmlTemplate from "../../../model/HtmlTemplate"

import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"
import Highlight from "../../../../generic/component/highlight/Highlight"
import ErrorMessageComponent from "../../../../generic/component/message/ErrorMessageComponent"
import RadioButtonData from "../../../../generic/component/radio/RadioButtonData"
import RadioButtonGroupComponent from "../../../../generic/component/radio/RadioButtonGroupComponent"
import RichTextEditor from "../../../../generic/component/richtexteditor/RichTextEditor"
import SelectComponent from "../../../../generic/component/select/SelectComponent"
import SelectOptionData from "../../../../generic/component/select/SelectOptionData"
import TagComponent from "../../../../generic/component/tag/TagComponent"
import TagGroup from "../../../../generic/component/tag/TagGroup"
import UtilService from "../../../../generic/service/UtilService"

import HttpHtmlTemplateService from "../../../service/http/HttpHtmlTemplateService"
import BackOfficeAbstractReferentielFormComponent from "../BackOfficeAbstractReferentielFormComponent"
import BackOfficeHtmlTemplateExtraDataState from "./BackOfficeHtmlTemplateExtraDataState"

export default class BackOfficeHtmlTemplateFormComponent extends BackOfficeAbstractReferentielFormComponent<HtmlTemplate, BackOfficeHtmlTemplateExtraDataState> {
    private HTML_TEMPLATE_CONTENT = "<p>Le contenu à éditer de mon modèle</p>"

    getHttpReferentielService(): HttpHtmlTemplateService {
        return new HttpHtmlTemplateService()
    }

    onComponentDidMount(item: HtmlTemplate) {
        // type de modèle : Mail ou Document
        this.getHttpReferentielService()
            .getTypes()
            .then((response) => {
                const setType = new Set<string>()
                response.content?.forEach((item: string) => {
                    setType.add(item)
                })

                const setTag = new Set<string>()

                // initialisation du contenu
                if (item) {
                    this.HTML_TEMPLATE_CONTENT = item.contenu!
                }

                // initialisation des tags
                if (item && item.keys) {
                    item.keys.forEach((key: string) => {
                        setTag.add(key)
                    })
                }

                // update state
                this.updatePartialState(null, setType, setTag, null, item)
            })

        // codes
        this.getHttpReferentielService()
            .getCodes()
            .then((response) => {
                const options: SelectOptionData[] = []
                response.content?.forEach((code: string) => {
                    options.push(new SelectOptionData(code, code, item != null && code === item.code))
                })
                this.updatePartialState(options, null, null, null, null)
            })
    }

    formToObject(id: number): HtmlTemplate {
        const htmlTemplate = new HtmlTemplate(this.props.objectId)
        const checkboxTypes: NodeListOf<Element> | null = document.querySelectorAll("input[name='html-template-type']")

        checkboxTypes.forEach((element: Element) => {
            if ((element as HTMLInputElement).checked) {
                htmlTemplate.type = (element as HTMLInputElement).value
            }
        })

        if (this.state && this.state.extra && this.state.extra.keyList) {
            htmlTemplate.keys = UtilService.getInstance().setToArray(this.state.extra.keyList)
        }

        htmlTemplate.titre = (document.querySelector("#html-template-titre") as HTMLInputElement).value
        if (this.state.extra) {
            htmlTemplate.contenu = this.HTML_TEMPLATE_CONTENT
        }

        htmlTemplate.code = (document.querySelector("#html-template-code") as HTMLSelectElement).value

        return htmlTemplate
    }

    getRadioType(): JSX.Element {
        const types: RadioButtonData[] = []
        if (this.state != null && this.state.dataList && this.state.dataList.size > 0) {
            this.state.dataList.forEach((item: string) => {
                const checked: boolean = this.getProperty("type").toString() === item.toString()
                const label = item.charAt(0) + item.substring(1, item.length).toLowerCase()
                types.push(new RadioButtonData(item, label, checked, "html-template-type", item))
            })
        }

        return <RadioButtonGroupComponent data={types} label={"Type"} id={"type-radio-buttons"} error={this.state?.errors?.has("type")} errorMessage={this.state?.errors?.get("type")} />
    }

    getTags(): ReactElement | undefined {
        const tags: ReactElement[] = []
        if (this.state && this.state.extra && this.state.extra.keyList) {
            this.state.extra.keyList.forEach((key: string) => {
                tags.push(<TagComponent key={key} label={key} onClickHandler={(key) => this.removeTag(key)} />)
            })
            if (tags.length > 0) {
                return <TagGroup>{tags}</TagGroup>
            }
        }
        return <div>Aucune clé</div>
    }

    getAddKeyForm() {
        return (
            <div>
                <TextInputComponent label={"Nouvelle clé"} id="new-key-for-template" />
                {this.getAddKeyErrorForm()}
                <Button secondary small onClick={() => this.addTag("new-key-for-template")}>
                    Ajouter une clé
                </Button>
            </div>
        )
    }

    getAddKeyErrorForm() {
        if (this.state && this.state.extra?.keyError) {
            return (
                <div>
                    <ErrorMessageComponent message={this.state.extra.keyError} />
                    &nbsp;
                </div>
            )
        }
    }

    addTag(id: string) {
        const input: HTMLInputElement = document.querySelector("#" + id) as HTMLInputElement
        const tagName = (document.querySelector("#" + id) as HTMLInputElement).value
        const errorTag = this.validationTag(tagName)

        let set = new Set<string>()
        if (this.state != null && this.state.extra != null && this.state.extra.keyList != undefined) {
            set = this.state.extra.keyList
        }

        if (errorTag == null) {
            set.add(tagName)
            input.value = ""
        }

        this.updatePartialState(null, null, set, errorTag, null)
    }

    validationTag(tag: string): string | null {
        if (this.state != null && this.state.extra != null && this.state.extra.keyList != undefined && this.state.extra.keyList.has(tag)) {
            return `La clé [${tag}] existe déjà`
        } else if (!tag.startsWith("__")) {
            return `La clé [${tag}] doit commencer par "__"`
        } else if (!tag.endsWith("__")) {
            return `La clé [${tag}] doit finir par "__"`
        } else if (tag.length < 7) {
            return `La clé [${tag}] est trop courte`
        } else if (tag.length > 29) {
            return `La clé [${tag}] est trop longue`
        } else {
            // \w = [_A-Za-z0-9]
            /* eslint-disable no-useless-escape */
            const regex = new RegExp("^\\w{4,35}$")
            if (!regex.test(tag)) {
                return `La clé [${tag}] ne respecte pas le format attendu`
            }
        }
        return null
    }

    removeTag(key: string) {
        if (this.state && this.state.extra && this.state.extra.keyList) {
            const set = this.state.extra?.keyList
            if (this.HTML_TEMPLATE_CONTENT.indexOf(key) != -1) {
                if (this.state) {
                    this.updatePartialState(null, null, set, `Veuillez supprimer le tag du contenu avant`, null)
                }
                return
            }
            set?.delete(key)
            this.updatePartialState(null, null, set, null, null)
        }
    }

    updatePartialState(codeList: SelectOptionData[] | null, typeList: Set<string> | null, keyList: Set<string> | null, keyError: string | null, item: HtmlTemplate | null) {
        const newState: { [k: string]: any } = { extra: {} }

        if (codeList != null) {
            newState.extra.codeList = codeList
        } else if (this.state && this.state.extra) {
            newState.extra.codeList = this.state.extra?.codeList
        }
        if (typeList != null) {
            newState.dataList = typeList
        } else if (this.state && this.state.dataList) {
            newState.dataList = this.state.dataList
        }
        if (keyList != null) {
            newState.extra.keyList = keyList
        } else if (this.state && this.state.extra) {
            newState.extra.keyList = this.state.extra?.keyList
        }
        if (keyError != null) {
            newState.extra.keyError = keyError
        }
        if (item != null) {
            newState.item = item
        }

        newState.extra.htmlContent = this.HTML_TEMPLATE_CONTENT

        this.setState(Object.assign(this.state != null ? this.state : {}, newState))
    }

    getDataSelect() {
        if (this.state && this.state.extra && this.state.extra.codeList) {
            return this.state.extra.codeList
        } else {
            return []
        }
    }

    getContent(): ReactElement | undefined {
        const create = this.props.objectId == null
        const edit = this.props.objectId !== null && this.state && this.state.item !== null
        if (create || edit) {
            return (
                <>
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getRadioType()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <SelectComponent
                                label="Code"
                                data={this.getDataSelect()}
                                id="html-template-code"
                                value={this.getProperty("code")}
                                error={this.state?.errors?.has("code")}
                                errorMessage={this.state?.errors?.get("code")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Titre"
                                id="html-template-titre"
                                value={this.getProperty("titre")}
                                error={this.state?.errors?.has("titre")}
                                errorMessage={this.state?.errors?.get("titre")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <div style={{ marginBottom: "10px" }}>
                                <label className="fr-label">Clés</label>
                            </div>
                            <RowComponent style={{ border: "1px dotted black", borderRadius: "10px", padding: "20px" }}>
                                <ColumnComponent size={3}>{this.getTags()}</ColumnComponent>
                                <ColumnComponent size={3}>{this.getAddKeyForm()}</ColumnComponent>
                                <ColumnComponent size={6}>
                                    <Highlight>
                                        Une clé doit respecter le format suivant :<br />
                                        <ul>
                                            <li>commencer par __</li>
                                            <li>ne contenir que des lettres majuscules ou le caractère _ (underscore = tiret bas du 8)</li>
                                            <li>finir par __</li>
                                        </ul>
                                        Ces clés seront ensuite remplacées dynamiquement par les valeurs qui vont bien.
                                        <br />
                                        Voici un exemple de contenu de template et sa résultante :<br />
                                        <br />
                                        <code style={{ fontSize: "14px" }}>Bonjour __CIVILITE__ __NOM__,</code>
                                        <br />
                                        <br />
                                        <code>Bonjour Mme Dupond,</code>
                                        <br />
                                    </Highlight>
                                </ColumnComponent>
                            </RowComponent>
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <RichTextEditor
                                onEditorChange={(text: string) => (this.HTML_TEMPLATE_CONTENT = text)}
                                id="htmlContent"
                                fieldName="Contenu"
                                initValue={this.HTML_TEMPLATE_CONTENT}
                                error={this.state?.errors?.has("contenu")}
                                errorMessage={this.state?.errors?.get("contenu")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={4} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.save()}>Enregistrer</Button>
                        </ColumnComponent>
                        <ColumnComponent middle size={2}>
                            <Button secondary onClick={() => this.props.showListHandler()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={4} />
                    </RowComponent>
                </>
            )
        }
    }
}
