import React, { Fragment } from "react"

import ContactFinanceur from "../../../model/ContactFinanceur"

import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import { Constant } from "../../../constant/Constant"
import HttpContactFinanceurService from "../../../service/http/HttpContactFinanceurService"
import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielListComponent from "../BackOfficeAbstractReferentielListComponent"
import BackOfficeModalActionComponent from "../BackOfficeModalActionComponent"
import BackOfficeModalDeleteComponent from "../BackOfficeModalDeleteComponent"

export default class BackOfficeContactFinanceurListComponent extends BackOfficeAbstractReferentielListComponent<ContactFinanceur> {
    protected getUsePager(): boolean {
        return true
    }

    protected getItemByPage(): number {
        return 20
    }

    getLine(item: ContactFinanceur, index: number): React.ReactElement {
        return (
            <tr key={"row-contact-financeur-" + item.id}>
                <td>{this.getStatutBar(item.active!)}</td>
                <td>{item.nom}</td>
                <td>{item.prenom}</td>
                <td>{item.email}</td>
                {/*
            <td>{item.telephone}</td>
            */}
                <td>{item.organisme?.nom}</td>
                <td>
                    <Button iconOnly={Constant.ICON.EDIT_LINE} onClick={() => this.props.editButtonHandler(item.id)}>
                        Edit
                    </Button>
                    &nbsp;&nbsp;
                    <Button secondary iconOnly={Constant.ICON.REFRESH_LINE} modal={"action-contact-financeur-" + item.id}>
                        Nouveau mot de passe
                    </Button>
                    &nbsp;&nbsp;
                    <Button iconOnly={Constant.ICON.DELETE_LINE} modal={"delete-contact-financeur-" + item.id}>
                        Supprimer
                    </Button>
                    <BackOfficeModalDeleteComponent
                        id={"delete-contact-financeur-" + item.id}
                        title={"Suppression du contact financeur : " + item.nom + " " + item.prenom}
                        message={"Vous confirmez la suppression de ce contact financeur ?"}
                        deleteButtonHandler={() => {
                            this.delete(item.id!)
                        }}
                    />
                    <BackOfficeModalActionComponent
                        id={"action-contact-financeur-" + item.id}
                        title={"Générer un nouveau mot de passe"}
                        message={"Si vous confirmer un nouveau mot de passe va être générer et un mail informatif va être envoyé au financeur"}
                        doAction={() => this.sendNewPassword(item.id!)}
                    />
                </td>
                <td></td>
            </tr>
        )
    }

    sendNewPassword(contactFinanceurId: number) {
        new HttpContactFinanceurService().changePassword(contactFinanceurId).then((response) => {
            if (response.success) {
                this.setState({
                    successMessage: response.successMessage,
                    errorMessage: response.errorMessage
                })
            } else {
                this.setState({
                    successMessage: undefined,
                    errorMessage: response.errorMessage
                })
            }
        })
    }

    getInputListener(): any {
        const input = document.querySelector("#global-filter") as HTMLInputElement
        if (input.value.length == 0 || input.value.length > 2) {
            this.state.filter?.set("filter", input.value)
            this.setState({
                updateFilter: (this.state.updateFilter ?? 0) + 1
            })
        }
    }

    getFilter(): React.ReactElement {
        return (
            <RowComponent>
                <ColumnComponent size={10} />
                <ColumnComponent size={2}>
                    <TextInputComponent label={"Filtre"} id={"global-filter"} onKeyUpHandler={() => this.getInputListener()} />
                </ColumnComponent>
            </RowComponent>
        )
    }

    getThead(): React.ReactElement {
        return (
            <thead style={{ width: "100%" }}>
                <tr>
                    <th scope="col" style={{ width: "5%" }}></th>
                    <th scope="col" style={{ width: "18%" }}>
                        Nom
                    </th>
                    <th scope="col" style={{ width: "18%" }}>
                        Prénom
                    </th>
                    <th scope="col" style={{ width: "23%" }}>
                        Courriel
                    </th>
                    <th scope="col" style={{ width: "18%" }}>
                        Organisme
                    </th>
                    <th scope="col" style={{ width: "18%" }}>
                        Actions
                    </th>
                </tr>
            </thead>
        )
    }

    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useContactFinanceurService()
    }

    getAddLabel(): string {
        return "Ajouter un contact"
    }
}
