import CardVerticalComponent from "../../../generic/component/card/CardVerticalComponent"

import { Constant } from "../../constant/Constant"
import FrontService from "../../service/FrontService"
import FrontComponent from "../FrontComponent"

export default class FrontNewAccountLinkComponent extends FrontComponent<any, any> {
    switchCreateAccount() {
        FrontService.getInstance().switchPage(Constant.DOM.FRONT.CREATE_ACCOUNT)
    }

    render() {
        return <CardVerticalComponent title={"Pas encore inscrit ?"} onClick={() => this.switchCreateAccount()} />
    }
}
