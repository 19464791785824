import React, { ChangeEvent, Component, ReactElement } from 'react';
import SecondaryContentBoxComponent from '../../shared/component/box/SecondaryContentBoxComponent';
import Button from '../../../generic/component/button/Button';
import ButtonGroupHorizontal from '../../../generic/component/button/group/ButtonGroupHorizontal';
import ControlledTextInputComponent from '../../../generic/component/form/input/ControlledTextInputComponent';
import BackOfficeComponent from '../BackOfficeComponent';
import AlertErrorComponent from '../../../generic/component/alert/AlertErrorComponent';
import AlertSuccessComponent from '../../../generic/component/alert/AlertSuccessComponent';
import HttpBackMaintenanceService from '../../service/http/HttpBackMaintenanceService';

interface BackOfficeMaintenanceEmailLoginProps {
    email: string
    response: ReactElement | null
    type : string
    newEmail : string
}

export default class BackOfficeMaintenanceEmailLogin extends BackOfficeComponent<any, BackOfficeMaintenanceEmailLoginProps> {

    EMAIL_ERROR_MESSAGE = "Veuillez entrer un email"
    UPDATE_PASSWORD = "Le mot de passe a bien été mis à jour"
    ACCOUNT_FOUND = "Compte trouvé, veuillez entrer le nouvel email"
    ACCOUNT_NOT_FOUND = "Compte non trouvé, veuillez vérifier l'email"
    ACCOUNT_UPDATED = "Email mis à jour"
    ACCOUNT_NOT_UPDATED = "Erreur lors de la mise à jour de l'email"
    ACCOUNT_ALREADY_EXIST = "Un compte avec cet email existe déjà"

    constructor(props: any) {
        super(props);
        this.state = {email: "", response: null, type: "search", newEmail: ""}
    }

    searchEmail():void{
        new HttpBackMaintenanceService().searchEmail(this.state.email).then((responsehttp: string) => {
            if (this.state.email == ""){
                this.setState({email: "", response: <AlertErrorComponent description={this.EMAIL_ERROR_MESSAGE} />})
            } else if (responsehttp == "ERREUR"){
                this.setState({email: "", response: <AlertErrorComponent description={this.ACCOUNT_NOT_FOUND} />})
            } else {
                this.setState({response: <AlertSuccessComponent description={this.ACCOUNT_FOUND} />, type: "update", newEmail: ""})
            }
        })
    }

    updateEmail():void{
        new HttpBackMaintenanceService().updateEmail(this.state.email, this.state.newEmail).then((responsehttp: string) => {
            if (this.state.email == ""){
                this.setState({email: "", response: <AlertErrorComponent description={this.EMAIL_ERROR_MESSAGE} />})
            } else if (responsehttp == "ERREUR"){
                this.setState({email: "", response: <AlertErrorComponent description={this.ACCOUNT_NOT_UPDATED} />})
            } else if (responsehttp == "EXIST"){
                this.setState({response: <AlertErrorComponent description={this.ACCOUNT_ALREADY_EXIST} />})
            } else {
                this.setState({email:"", newEmail:"", type:"search", response: <AlertSuccessComponent description={this.ACCOUNT_UPDATED} />})
            }
        })
        console.log("update email")
    }

    render() {
        return (
            <SecondaryContentBoxComponent title={"Changement d'email de login"}>
                <ControlledTextInputComponent
                    label={"Email du compte : "}
                    id="email"
                    value={this.state.email}
                    onChangeHandler={(event: ChangeEvent<HTMLInputElement>) => this.setState({...this.state, email: event.target.value})}
                />
                {this.state.type === "update" ?
                    <ControlledTextInputComponent
                        label={"Nouvel email : "}
                        id="newEmail"
                        value={this.state.newEmail}
                        onChangeHandler={(event: ChangeEvent<HTMLInputElement>) => this.setState({...this.state, newEmail: event.target.value})}
                    />
                    :
                    null
                }
                <div>{this.state.response}</div><br/>
                <ButtonGroupHorizontal>
                    {this.state.type === "update" ?
                        <Button onClick={() => this.updateEmail()}>
                            Mettre à jour l'email
                        </Button>
                        :
                        <Button onClick={() => this.searchEmail()}>
                            Rechercher un compte
                        </Button>
                    }
                </ButtonGroupHorizontal>
            </SecondaryContentBoxComponent>
        );
    }
}