import DnumComponent, { DnumProps } from "../DnumComponent"

interface TableCellProps extends DnumProps {
    colspan?: any
    rowspan?: any
}

export default class TableCellComponent extends DnumComponent<TableCellProps, any> {
    constructor(props: TableCellProps) {
        super(props)
    }

    render() {
        if (this.props.colspan && this.props.rowspan) {
            return (
                <td rowSpan={this.props.rowspan} colSpan={this.props.colspan} style={this.props.style}>
                    {this.props.children}
                </td>
            )
        } else if (this.props.colspan) {
            return (
                <td colSpan={this.props.colspan} style={this.props.style}>
                    {this.props.children}
                </td>
            )
        } else if (this.props.rowspan) {
            return (
                <td rowSpan={this.props.rowspan} style={this.props.style}>
                    {this.props.children}
                </td>
            )
        } else {
            return <td style={this.props.style}>{this.props.children}</td>
        }
    }
}
