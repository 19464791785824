import React from "react"

import HtmlTemplate from "../../../model/HtmlTemplate"

import Button from "../../../../generic/component/button/Button"

import { Constant } from "../../../constant/Constant"
import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielListComponent from "../BackOfficeAbstractReferentielListComponent"
import BackOfficeModalDeleteComponent from "../BackOfficeModalDeleteComponent"

export default class BackOfficeHtmlTemplateListComponent extends BackOfficeAbstractReferentielListComponent<HtmlTemplate> {
    getLine(item: HtmlTemplate, index: number): React.ReactElement {
        return (
            <tr key={"row-html-template-" + item.id}>
                <td>{item.type}</td>
                <td>{item.titre}</td>
                <td>{item.contenu!.length > 20 ? item.contenu?.substring(0, 20) + "..." : item.contenu}</td>
                <td>{this.getKeysView(item)}</td>
                <td>
                    <Button iconOnly={Constant.ICON.EDIT_LINE} onClick={() => this.props.editButtonHandler(item.id)}>
                        Edit
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button secondary iconOnly={Constant.ICON.DELETE_LINE} modal={"html-template-" + item.id}>
                        Supprimer
                    </Button>
                    <BackOfficeModalDeleteComponent
                        id={"html-template-" + item.id}
                        title={"Suppression du template : " + item.titre}
                        message={"Vous confirmez la suppression de ce template ?"}
                        deleteButtonHandler={() => {
                            this.delete(item.id!)
                        }}
                    />
                </td>
            </tr>
        )
    }

    getKeysView(item: HtmlTemplate) {
        if (item.keys != null) {
            if (item.keys.length == 0) {
                return "Aucune clé"
            } else if (item.keys.length == 1) {
                return "1 clé"
            } else {
                return item.keys.length + " clés"
            }
        } else {
            return "Aucune clé"
        }
    }

    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useHtmlTemplateService()
    }

    getAddLabel(): string {
        return "Ajouter un modèle"
    }

    getThead(): React.ReactElement {
        return (
            <thead style={{ width: "100%" }}>
                <tr>
                    <th scope="col" style={{ width: "15%" }}>
                        Type
                    </th>
                    <th scope="col" style={{ width: "20%" }}>
                        Titre
                    </th>
                    <th scope="col" style={{ width: "35%" }}>
                        Contenu
                    </th>
                    <th scope="col" style={{ width: "15%" }}>
                        Clés
                    </th>
                    <th scope="col" style={{ width: "15%" }}>
                        Actions
                    </th>
                </tr>
            </thead>
        )
    }
}
