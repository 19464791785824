import Account from "../../model/Account"
import AccountData from "../../model/AccountData"
import FinancementEtablissement from "../../model/FinancementEtablissement"
import OrganismeFinanceur from "../../model/OrganismeFinanceur"
import TypeFinanceur from "../../model/TypeFinanceur"

import FrontDemandeAgrementForm from "../../front/demande/FrontDemandeAgrementForm"
import FrontLoginForm from "../../front/home/FrontLoginForm"
import HttpService from "./HttpService"
import Response from "./response/Response"

export default class HttpAccountService extends HttpService {
    ACCOUNT_URI = "/front/account"
    FINESS_URI = "/front/finess"
    IDCC_URI = "/front/idcc"
    DEMANDE_URI = "/front/demande"
    ESMS_URI = "/front/esms"

    createAccount(account: Account) {
        return this.request<Response<any>>(this.ACCOUNT_URI + "/create", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(account)
        })
    }

    validAccount(loginKey: string) {
        return this.request<Response<any>>(this.ACCOUNT_URI + "/valid", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(loginKey)
        })
    }

    validKeyForPassword(key: string) {
        return this.request<Response<any>>(this.ACCOUNT_URI + "/valid-key-for-password", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(key)
        })
    }

    login(form: FrontLoginForm) {
        return this.request<Response<any>>(this.ACCOUNT_URI + "/login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(form)
        })
    }

    sendEmailForgotPassword(email: string) {
        return this.request<Response<any>>(this.ACCOUNT_URI + "/forgot-password", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(email)
        })
    }

    changePassword(password: string, confirmPassword: string) {
        const array = []
        array.push(password)
        array.push(confirmPassword)
        return this.request<Response<any>>(this.ACCOUNT_URI + "/change-password", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(array)
        })
    }

    searchFinessJuridique(finessEJ: string) {
        return this.request<Response<any>>(this.FINESS_URI + "?finessEJ=" + finessEJ, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        })
    }

    validSiren(finessEJ: string, siren: string) {
        return this.request<Response<any>>(this.FINESS_URI + "/valid?finessEJ=" + finessEJ + "&siren=" + siren, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.frontSessionExpired(response))
    }

    validIdcc(idcc: string) {
        return this.request<Response<any>>(this.IDCC_URI + "/valid?code=" + idcc, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        })
    }

    validContact(nom: string, prenom: string, email: string, telephone: string) {
        const contactObject = {
            nom: nom,
            prenom: prenom,
            email: email,
            telephone: telephone
        }
        return this.request<Response<any>>(this.ACCOUNT_URI + "/valid-contact", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(contactObject)
        })
    }

    updateContact(nom: string, prenom: string, email: string, telephone: string) {
        const contactObject = {
            nom: nom,
            prenom: prenom,
            email: email,
            telephone: telephone
        }
        return this.request<Response<any>>(this.ACCOUNT_URI + "/save-contact", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(contactObject)
        }).then((response) => this.frontSessionExpired(response))
    }

    validFinance(etp: string, masseSalarialeBrute: string, masseSalarialeChargee: string) {
        const financeObject = {
            etp: etp,
            masseSalarialeBrute: masseSalarialeBrute,
            masseSalarialeChargee: masseSalarialeChargee
        }
        return this.request<Response<any>>(this.ACCOUNT_URI + "/valid-finance", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(financeObject)
        })
    }

    saveFinance(etp: string, masseSalarialeBrute: string, masseSalarialeChargee: string) {
        const financeObject = {
            etp: etp,
            masseSalarialeBrute: masseSalarialeBrute,
            masseSalarialeChargee: masseSalarialeChargee
        }
        return this.request<Response<any>>(this.ACCOUNT_URI + "/save-finance", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(financeObject)
        }).then((response) => this.frontSessionExpired(response))
    }

    validAdresse(useFinessAdresse: boolean, number: string | null, street: string | null, postalCode: string | null, city: string | null) {
        const adresseObject = {
            numero: number,
            rue: street,
            codePostal: postalCode,
            commune: city
        }
        return this.request<Response<any>>(this.ACCOUNT_URI + "/valid-adresse?useFiness=" + useFinessAdresse, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(adresseObject)
        })
    }

    saveInformations() {
        return this.request<Response<any>>(this.ACCOUNT_URI + "/save-informations", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        })
    }

    getInformations() {
        return this.request<Response<AccountData>>(this.ACCOUNT_URI + "/get-informations", {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.frontSessionExpired(response))
    }

    updateEtablissementFinancement(finessET: string, financement: FinancementEtablissement) {
        return this.request<Response<AccountData>>(this.ACCOUNT_URI + "/etablissement/financement?finessET=" + finessET, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(financement)
        })
    }

    public getTypeFinanceursByEtablissement(etablissementIds: string[]) {
        return this.request<Response<TypeFinanceur[]>>(this.ACCOUNT_URI! + "/etablissements/type_financeurs", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(etablissementIds)
        })
    }
    public getOrganismeFinanceursByEtablissement(etablissementIds: string[]) {
        return this.request<Response<OrganismeFinanceur[]>>(this.ACCOUNT_URI! + "/etablissements/organisme_financeurs", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(etablissementIds)
        })
    }

    saveDemande(form: FrontDemandeAgrementForm, validation = false) {
        return this.request<Response<FrontDemandeAgrementForm>>(this.DEMANDE_URI + "/save?validation=" + validation, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(form)
        })
    }

    sendDemande(form: FrontDemandeAgrementForm) {
        return this.request<Response<FrontDemandeAgrementForm>>(this.DEMANDE_URI + "/send", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(form)
        })
    }

    getDemandes() {
        return this.request<Response<FrontDemandeAgrementForm[]>>(this.DEMANDE_URI + "/list", {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        })
    }

    getDemande(id: number) {
        return this.request<Response<FrontDemandeAgrementForm>>(this.DEMANDE_URI + "/" + id, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        })
    }

    getEtablissementOrganismesFinanceur(id: string | number) {
        return this.request<Response<OrganismeFinanceur[]>>(this.ESMS_URI + "/" + id + "/financeurs", {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        })
    }
}
