export default class CheckboxData {
    id: string
    label: string
    checked: boolean

    constructor(id: string, label: string, checked: boolean) {
        this.id = id
        this.label = label
        this.checked = checked
    }
}
