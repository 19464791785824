import React, { ReactElement } from "react"

import Button from "../../../generic/component/button/Button"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"

import { Constant } from "../../constant/Constant"
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import BackOfficePage from "../BackOfficePage"

interface ReferentielPageState {
    view: string
    id: number | null
    forceRefresh: number
}

class UseCase {
    public static ADD = "ADD"
    public static EDIT = "EDIT"
    public static LIST = "LIST"
    public static DELETE = "DELETE"
}

export default abstract class BackOfficeAbstractReferentielPage extends BackOfficePage<any, ReferentielPageState> {
    protected constructor(props: any) {
        super(props)
        this.setState({ forceRefresh: 0 })
    }

    addItem() {
        this.setState({ view: UseCase.ADD })
    }

    editItem(id: number | null) {
        this.setState({ view: UseCase.EDIT, id: id })
    }

    deleteItem(id: number | null) {
        this.setState({ forceRefresh: this.state.forceRefresh + 1, id: id })
        this.showList()
    }

    showList() {
        this.setState({ view: UseCase.LIST })
    }

    componentDidMount() {
        this.showList()
    }

    abstract getId(): string
    abstract getObjectTitle(): string
    abstract getFormComponent(itemId: number | null, showListMethod: () => void): ReactElement
    abstract getListComponent(forceRefresh: string, addButtonHandler: () => void, editButtonHandler: (id: number | null) => void, deleteButtonHandler: (id: number | null) => void): ReactElement

    getView() {
        if (this.state != null) {
            if (this.state.view === UseCase.ADD) {
                return this.getFormComponent(null, () => this.showList())
            } else if (this.state.view === UseCase.EDIT) {
                return this.getFormComponent(this.state.id, () => this.showList())
            } else {
                return this.getListComponent(
                    this.utilService.generateId(),
                    () => this.addItem(),
                    (id) => this.editItem(id),
                    (id) => this.deleteItem(id)
                )
            }
        }
    }

    getTitle() {
        if (this.state) {
            switch (this.state.view) {
                case UseCase.ADD:
                    return <span>{this.getObjectTitle()}&nbsp;&nbsp;&raquo;&nbsp;&nbsp;Création</span>
                case UseCase.EDIT:
                    return <span>{this.getObjectTitle()}&nbsp;&nbsp;&raquo;&nbsp;&nbsp;Edition</span>
                case UseCase.LIST:
                    return <span>{this.getObjectTitle()}&nbsp;&nbsp;&raquo;&nbsp;&nbsp;Liste</span>
            }
        }
        return "Error titre non défini"
    }

    openInNewTab = (url: string | URL | undefined) => {
        window.open(url, "_blank", "noopener,noreferrer")
    }

    getUrl() {
        //console.log(this.constructor.name)
        switch (this.constructor.name) {
            case "BackOfficeMembreCnaListComponent":
                return "/back/csv/membres"
            case "BackOfficeContactFinanceurListComponent":
                return "/back/csv/contacts"
            case "BackOfficeInstructionListDemandeAgrementPage":
                return "/back/csv/demandes"
            case "BackOfficeUtilisateurAssociationPage":
                return "/back/csv/associations"
            case "BackOfficeMembreCnaPage":
                return "/back/csv/membres"
            default:
                return "none"
        }
    }

    render(): JSX.Element {
        return (
            <ContainerComponent id={this.getId()}>
                <PrimaryContentBoxComponent
                    title={this.getTitle()}
                    rightComponent={
                        <RowComponent>
                            <ColumnComponent size={7} />
                            <ColumnComponent size={5}>
                                {this.getUrl() != "none" ? (
                                    <Button style={{ marginTop: "4px" }} onClick={() => this.openInNewTab(process.env.REACT_APP_CONTEXT_PATH + this.getUrl())} iconLeft={Constant.ICON.DOWNLOAD_FILL}>
                                        {"Télécharger"}
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </ColumnComponent>
                        </RowComponent>
                    }>
                    {this.getView()}
                </PrimaryContentBoxComponent>
            </ContainerComponent>
        )
    }
}
