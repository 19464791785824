import { sign } from "crypto"

import PersistentObject from "../../model/PersistentObject"
import Signature from "../../model/Signature"

import HttpReferentielService from "./HttpReferentielService"
import Response from "./response/Response"

export default class HttpReferentielSignatureService extends HttpReferentielService {
    constructor() {
        super()
        this.uri = this.SIGNATURE_URI
    }

    save<TYPE extends PersistentObject>(object: TYPE) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const formData = new FormData()
        const input = document.querySelector("#file-upload-signature-file") as HTMLInputElement
        formData.append("file", input.files![0])
        const signature = object as unknown as Signature
        const url = this.SIGNATURE_URI + "?id=" + signature.id + "&code=" + signature.code + "&libelle=" + signature.libelle + "&active=" + signature.active
        return this.request<Response<any>>(url, {
            method: object.id == null ? "POST" : "PUT",
            //headers: {'Content-Type': 'multipart/form-data'},
            body: formData
        })
    }
}
