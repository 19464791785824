import EspaceFinanceurComponent from "../EspaceFinanceurComponent";
import React, {ChangeEvent, Fragment, ReactElement} from "react";
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent";
import RowComponent from "../../../generic/component/grid/row/RowComponent";
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent";
import VerticalSpacing from "../../shared/component/space/VerticalSpacing";
import TextInputComponent from "../../../generic/component/form/input/TextInputComponent";
import SelectOptionData from "../../../generic/component/select/SelectOptionData";
import SelectComponent from "../../../generic/component/select/SelectComponent";
import BackOfficeDemandeAgrementForm from "../../back/common/BackOfficeDemandeAgrementForm";
import {Constant} from "../../constant/Constant";
import TypologieAccord from "../../model/TypologieAccord";
import IdentifiantConventionCollective from "../../model/IdentifiantConventionCollective";
import HttpReferentielService from "../../service/http/HttpReferentielService";
import {PageProps} from "../../../generic/component/grid/page/PageComponent";
import Response from "../../service/http/response/Response";
import PagerResponse from "../../shared/dto/PagerResponse";
import HttpEspaceFinanceurService from "../../service/http/HttpEspaceFinanceurService";
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent";
import Button from "../../../generic/component/button/Button";
import PagerComponent from "../../../generic/component/pager/PagerComponent";
import TableContainerComponent from "../../../generic/component/table/TableContainerComponent";
import TableHeaderComponent from "../../../generic/component/table/TableHeaderComponent";
import TableCellComponent from "../../../generic/component/table/TableCellComponent";
import LazyWrapperComponent from "../../shared/component/LazyWrapperComponent";
import BackOfficeModalShowDemandeComponent from "../../back/common/BackOfficeModalShowDemandeComponent";
import UiService from "../../service/ui/UiService";
import EspaceFinanceurModalShowDemandeComponent from "../demande/EspaceFinanceurModalShowDemandeComponent";

interface EspaceFinanceurToutesLesDemandesComponentState {
    demandes: BackOfficeDemandeAgrementForm[] | undefined
    count: number | null
    currentPage: number
    typologies: TypologieAccord[] | undefined
    typologieId: number | undefined | null
    conventionCollectives: IdentifiantConventionCollective[] | undefined
    conventionCollectiveCode: string | undefined | null
    type: string | undefined | null
    entiteJuridiqueFilter: string | undefined | null
    codePostal: string | undefined
}

interface EspaceFinanceurToutesLesDemandesComponentProps extends PageProps {
    etat?: string
}

export default class EspaceFinanceurToutesLesDemandesComponent extends EspaceFinanceurComponent<any, EspaceFinanceurToutesLesDemandesComponentState> {

    static ITEM_BY_PAGE = 10

    componentDidMount() {
        //this.choosePage(0)
        new HttpReferentielService()
            .useTypologieAccordService()
            .getAll<TypologieAccord>()
            .then((response) => {
                this.setState({ typologies: response.content })
            })
        new HttpReferentielService()
            .useIdentifiantConventionCollectiveService()
            .getAll<IdentifiantConventionCollective>()
            .then((response) => {
                this.setState({ conventionCollectives: response.content })
            })
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<EspaceFinanceurToutesLesDemandesComponentState>, snapshot?: any) {
        if (this.state?.count == null && this.state.typologies!=null && this.state.conventionCollectives!=null) {
            this.choosePage(this.state.currentPage)
        }
    }

    getSelectType() {
        const types: SelectOptionData[] = []
        types.push(new SelectOptionData("", "Type", false))
        types.push(new SelectOptionData("ACCORD", "Accord", false))
        types.push(new SelectOptionData("DUE", "DUE", false))
        return <SelectComponent label={"Type"} data={types} style={{ width: "100%" }} id={"select-type-filter"} onChange={(event) => this.onChangeType(event)} />
    }

    getSelectTypologie() {
        if (this.state && this.state.typologies) {
            const list: SelectOptionData[] = []
            list.push(new SelectOptionData("", "Thématique", false))
            this.state.typologies.forEach((typologie) => {
                const option = new SelectOptionData(typologie.id!.toString(), typologie.libelle!, false)
                list.push(option)
            })
            return <SelectComponent id={"select-typologie-filter"} onChange={(event) => this.onChangeTypologie(event)} style={{ width: "100%" }} label={"Thématique"} data={list} />
        }
        return <span></span>
    }

    onChangeType(event: ChangeEvent<HTMLSelectElement>) {
        this.choosePage(0)
    }

    onChangeTypologie(event: ChangeEvent<HTMLSelectElement>) {
        this.choosePage(0)
    }

    getSelectConventionCollective() {
        if (this.state && this.state.conventionCollectives) {
            const list: SelectOptionData[] = []
            list.push(new SelectOptionData("", "IDCC", false))
            this.state.conventionCollectives.forEach((idcc) => {
                if (idcc.active) {
                    const option = new SelectOptionData(idcc.code!.toString(), idcc.label!, false)
                    list.push(option)
                }
            })
            return <SelectComponent label={"IDCC"} data={list} onChange={(event) => this.onChangeConventionCollective(event)} style={{ width: "100%" }} id={"select-idcc-filter"} />
        }
        return <span></span>
    }

    onChangeConventionCollective(event: ChangeEvent<HTMLSelectElement>) {
        this.choosePage(0)
    }

    getEtablissements(demande: BackOfficeDemandeAgrementForm) {
        if ((demande.etablissements?.length ?? 0) > 1) {
            return <small>{demande.etablissements?.length} établissements concernés</small>
        } else {
            return <small>{demande.etablissements?.length} établissement concerné</small>
        }
    }

    getEtatLabel(demande: BackOfficeDemandeAgrementForm) {
        switch (demande.etat) {
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__SOUMISE_A_INSTRUCTION:
                return "Transmise"
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__A_TRAITER:
                return "A traiter"
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR:
                return "Attente employeur"
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA:
                return "Prochaine CNA"
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA:
                return "Attente CNA"
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR:
                return "Attente financeur " + (demande.demandeAvisDone ?? 0) + "/" + demande.demandeAvisTotal
            case Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_AGREE:
                return "Agréée"
            case Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_REFUSEE:
                return "Refusée"
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__SUPPRIMER:
                return "Supprimée"
            default:
                return "-"
        }
    }

    getCodePostalEventHandler(): any {
        const input = document.querySelector("#entite-juridique-filter") as HTMLInputElement
        if (input.value == null || input.value.length == 0 || input.value.length > 1) {
            this.choosePage(0)
        }
    }

    getEntiteJuridiqueEventHandler(): any {
        const input = document.querySelector("#entite-juridique-filter") as HTMLInputElement
        if (input.value == null || input.value.length == 0 || input.value.length > 2) {
            this.choosePage(0)
        }
    }

    getFiltre(): ReactElement {
        return (
            <Fragment>
                <ContainerComponent fluid>
                    <RowComponent key={"line-filter-0"} style={{ width: "100%" }}>
                        <ColumnComponent size={3}>{this.getSelectType()}</ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={8}>{this.getSelectConventionCollective()}</ColumnComponent>
                    </RowComponent>
                    <VerticalSpacing height={"30px"} />
                    <RowComponent key={"line-filter-1"} style={{ width: "100%" }}>
                        <ColumnComponent size={6}>{this.getSelectTypologie()}</ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={2}>
                            <TextInputComponent label="Code postal EJ" placeholder="2 car. mini." id="code-postal-ej" onKeyUpHandler={() => this.getCodePostalEventHandler()} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={2}>
                            <TextInputComponent
                                placeholder="3 car. mini."
                                style={{ width: "100%" }}
                                id={"entite-juridique-filter"}
                                onKeyUpHandler={() => this.getEntiteJuridiqueEventHandler()}
                                label="Raison sociale EJ"
                            />
                        </ColumnComponent>
                    </RowComponent>
                </ContainerComponent>
            </Fragment>
        )
    }

    choosePage(pageNumber: number) {
        let typologieId: number | null = null
        let conventionCollectiveCode: string | null = null

        // typologie / thematique
        if (this.state && this.state.typologies) {
            const selectTypologieComponent = document.querySelector("#select-typologie-filter") as HTMLInputElement
            const typologieDefined = selectTypologieComponent.value !== "" && !isNaN(Number(selectTypologieComponent.value))
            typologieId = typologieDefined ? Number.parseInt(selectTypologieComponent.value) : null
        }

        // id convention collective
        if (this.state && this.state.conventionCollectives) {
            const selectConventionCollectiveComponent = document.querySelector("#select-idcc-filter") as HTMLInputElement
            const conventionCollectiveDefined = selectConventionCollectiveComponent.value !== "" && !isNaN(Number(selectConventionCollectiveComponent.value))
            conventionCollectiveCode = conventionCollectiveDefined ? selectConventionCollectiveComponent.value : null
        }

        // type
        const selectTypeComponent = document.querySelector("#select-type-filter") as HTMLInputElement
        const typeValue = selectTypeComponent.value !== "" ? selectTypeComponent.value : null

        // entite juridique
        const inputEntiteJuridique = document.querySelector("#entite-juridique-filter") as HTMLInputElement
        const entiteJuridiqueFilter = inputEntiteJuridique.value

        const codePostalEntiteJuridique = document.querySelector("#code-postal-ej") as HTMLInputElement
        const codePostal = codePostalEntiteJuridique.value

        //console.log("Choose page : "+pageNumber)
        new HttpEspaceFinanceurService()
            .getDemandes(
                null,
                entiteJuridiqueFilter,
                typeValue,
                conventionCollectiveCode,
                typologieId,
                codePostal,
                pageNumber,
                EspaceFinanceurToutesLesDemandesComponent.ITEM_BY_PAGE
            )
            .then((response) => {
                const cast = new Response<PagerResponse<BackOfficeDemandeAgrementForm>>(response)
                if (cast.success) {
                    this.setState({
                        demandes: cast.content!.list!,
                        count: cast.content!.count!,
                        currentPage: pageNumber,
                        conventionCollectiveCode: conventionCollectiveCode,
                        type: typeValue,
                        typologieId: typologieId,
                        entiteJuridiqueFilter: entiteJuridiqueFilter,
                        codePostal: codePostal
                    })
                }
            })
    }

    getHeader(): ReactElement {
        return (
            <tr key="header-line-0">
                <TableHeaderComponent>&nbsp;</TableHeaderComponent>
                <TableHeaderComponent>Type</TableHeaderComponent>
                <TableHeaderComponent>Entité juridique</TableHeaderComponent>
                <TableHeaderComponent>Code postal</TableHeaderComponent>
                <TableHeaderComponent>IDCC</TableHeaderComponent>
                <TableHeaderComponent>Thématique</TableHeaderComponent>
                <TableHeaderComponent>
                    <Fragment>Dernière transmission</Fragment>
                </TableHeaderComponent>
                <TableHeaderComponent>Etat</TableHeaderComponent>
                <TableHeaderComponent>Coût</TableHeaderComponent>
            </tr>
        )
    }

    getDateTransmission(demande: BackOfficeDemandeAgrementForm) {
        if (demande.dateUpdateFromFront != null) {
            return UiService.formatServerDateToHumanNumericDate(demande.dateUpdateFromFront)
        }
    }

    getButton(demande: BackOfficeDemandeAgrementForm, index: number) {
        return (
            <Button key={"clickItem" + index} modal={"espace-financeur-demande-" + demande.id}>
                {(this.state.currentPage ?? 0) * EspaceFinanceurToutesLesDemandesComponent.ITEM_BY_PAGE + (index + 1)}
            </Button>
        )
    }

    forceRefresh() {
        this.setState({ count: -1 })
    }

    getContent() {
        const tbody: ReactElement[] = []
        if (this.state && this.state.demandes && this.state.demandes.length > 0) {
            let h = 0
            this.state.demandes.forEach((demande, index) => {
                h = 0

                tbody.push(
                    <tr key={"demande-" + demande.id + "-" + index}>
                        <TableCellComponent key={"column-" + index + "-" + h++}>
                            <LazyWrapperComponent source={this.getButton(demande, index)}>
                                <EspaceFinanceurModalShowDemandeComponent
                                    demandeId={demande.id!}
                                    typologies={this.state.typologies}
                                    showActionOrStatusRow={true}
                                    prefixId={"espace-financeur"}
                                    parentMethod={() => this.forceRefresh()}
                                />
                            </LazyWrapperComponent>
                        </TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++} style={{ textAlign: "center" }}>
                            {demande.typeDemande == "ACCORD" ? "Accord" : demande.typeDemande}
                            <br />
                            {demande.id}
                        </TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++}>
                            {demande.entiteJuridique}
                            <br />
                            {this.getEtablissements(demande)}
                        </TableCellComponent>
                        <TableCellComponent>{demande.entiteJuridiqueCodePostal}</TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++}>
                            {demande.idccCode}
                        </TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++}>
                            {demande.typologieAccord?.libelle}
                        </TableCellComponent>
                        <TableCellComponent key={"column-" + index + "-" + h++}>
                            {this.getDateTransmission(demande)}
                        </TableCellComponent>
                        <TableCellComponent>{this.getEtatLabel(demande)}</TableCellComponent>
                        <TableCellComponent style={{ textAlign: "center" }}>{demande.financementTotal ? demande.financementTotal + " €" : "-"}</TableCellComponent>
                    </tr>
                )
            })
        }

        return tbody
    }

    render() {
        //console.log("render")
        return (
            <>
                <ContainerComponent id={Constant.DOM.ESPACE_FINANCEUR.HOME_PAGE}>
                    <PrimaryContentBoxComponent
                        title={"Demandes d'agrément"}
                        rightComponent={
                            <RowComponent>

                            </RowComponent>
                        }>
                        <div style={{ padding: "20px" }}>{this.getFiltre()}</div>
                        <br />

                        <TableContainerComponent headers={[this.getHeader()]} cells={this.getContent()} />

                        <div style={{ display: "flex", justifyContent: "center" }}>

                            <PagerComponent
                                itemByPage={EspaceFinanceurToutesLesDemandesComponent.ITEM_BY_PAGE}
                                itemCount={this.state?.count ?? 0}
                                currentPage={this.state?.currentPage ?? 0}
                                choosePage={(page) => this.choosePage(page)}
                            />

                        </div>
                    </PrimaryContentBoxComponent>
                </ContainerComponent>
            </>
        )
    }
}
