import React, { ReactElement } from "react"

import {DnumProps} from "../../../generic/component/DnumComponent";
import TypologieAccord from "../../model/TypologieAccord";
import BackOfficeDemandeAgrementForm from "../../back/common/BackOfficeDemandeAgrementForm";
import Instruction from "../../model/Instruction";
import FullAvisFinanceur from "../../model/FullAvisFinanceur";
import BackOfficePage from "../../back/BackOfficePage";
import EspaceFinanceurDemandeDetailsComponent from "./EspaceFinanceurDemandeDetailsComponent";
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent";
import RowComponent from "../../../generic/component/grid/row/RowComponent";
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent";
import VerticalSpacing from "../../shared/component/space/VerticalSpacing";
import ErrorMessageComponent from "../../../generic/component/message/ErrorMessageComponent";
import HttpEspaceFinanceurService from "../../service/http/HttpEspaceFinanceurService";
import DemandeDetails from "../../model/DemandeDetails";
import {Constant} from "../../constant/Constant";
import BadgeSuccessComponent from "../../../generic/component/badge/BadgeSuccessComponent";
import BadgeWarningComponent from "../../../generic/component/badge/BadgeWarningComponent";
import SecondaryContentBoxComponent from "../../shared/component/box/SecondaryContentBoxComponent";
import ButtonPdf from "../../../generic/component/button/pdf/ButtonPdf";
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent";

interface EspaceFinanceurDemandeDetailsPageProps extends DnumProps {
    demandeId: number
    title?: string | ReactElement
    goBackButton: boolean
    parentMethod?: () => void
    typologies: TypologieAccord[] | undefined
}

interface EspaceFinanceurDemandeDetailsPageState {
    demande: BackOfficeDemandeAgrementForm | undefined
    instruction: Instruction | undefined
    showConfirmNonComplete: boolean
    showConfirmComplete: boolean
    showConfirmDelete: boolean
    showConfirmProchaineCNA: boolean
    showConfirmEnAttenteCNA: boolean
    errorMessage: string | undefined
    avisList: FullAvisFinanceur<null>[] | null
}

export default class EspaceFinanceurDemandeDetailsPage extends BackOfficePage<EspaceFinanceurDemandeDetailsPageProps, EspaceFinanceurDemandeDetailsPageState> {
    constructor(props: EspaceFinanceurDemandeDetailsPageProps) {
        super(props)
        this.state = {
            demande: undefined,
            instruction: undefined,
            showConfirmNonComplete: false,
            showConfirmComplete: false,
            showConfirmDelete: false,
            showConfirmProchaineCNA: false,
            showConfirmEnAttenteCNA: false,
            errorMessage: undefined,
            avisList: null
        }
    }

    componentDidMount() {
        new HttpEspaceFinanceurService().getDemande(this.props.demandeId!).then((response) => {
            this.setState({ demande: response?.content })
            this.loadDetails()
        })
    }

    loadDetails() {
        new HttpEspaceFinanceurService().getDetailsByDemande(this.props.demandeId!).then((response) => {
            if (response!.success && response!.content != null) {
                const details = response?.content as DemandeDetails
                if (details) {
                    this.setState({
                        avisList: details?.avis
                    })
                }
            }
        })
    }

    getInstructionComponent() {
        if (this.state && this.state.demande) {
            return (
                <EspaceFinanceurDemandeDetailsComponent
                    demande={this.state.demande}
                    typologies={this.props.typologies}
                    key={"demande-" + this.props.demandeId + "-component"}
                    avisList={this.state?.avisList}
                />
            )
        }
    }

    getErrorRow() {
        if (this.state && this.state.errorMessage) {
            return (
                <RowComponent>
                    <ColumnComponent size={3} />
                    <ColumnComponent size={6}>
                        <ErrorMessageComponent message={this.state.errorMessage} />
                    </ColumnComponent>
                    <ColumnComponent size={3} />
                </RowComponent>
            )
        }
    }

    getStatusRow() {
        if (this.state.demande?.etat == Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_AGREE) {
            return <BadgeSuccessComponent label={"Demande agréée"} />
        } else if (this.state.demande?.etat == Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_REFUSEE) {
            return <BadgeWarningComponent label={"Demande refusée"} />
        } else {
            return <h5>{this.getEtat(this.state.demande?.etat ?? "Non défini")}</h5>
        }
    }

    getActionRowOrStatusRow() {
        return (
            <SecondaryContentBoxComponent title="Statut de la demande">
                <VerticalSpacing height={"20px"} />
                {this.getStatusRow()}
                <VerticalSpacing height={"20px"} />
            </SecondaryContentBoxComponent>
        )
    }

    getEtat(etat: string | null): ReactElement {
        switch (etat) {
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_CNA:
                return <span>En attente CNA</span>
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__PROCHAINE_CNA:
                return <span>Prochaine CNA</span>
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__SOUMISE_A_INSTRUCTION:
                return <span>Soumise à instruction</span>
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__SUPPRIMEE:
                return <span>Supprimée par le demandeur</span>
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__EN_ATTENTE_AVIS_FINANCEUR:
                return <span>En attente avis financeur</span>
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__A_TRAITER:
                return <span>A traiter</span>
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__RETOUR_EMPLOYEUR:
                return <span>Retour employeur</span>
            case Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_AGREE:
                return <span>Décision agréée</span>
            case Constant.STATUS.DEMANDE.DEMANDE__CNA__DECISION_REFUSEE:
                return <span>Décision refusée</span>
            case Constant.STATUS.DEMANDE.DEMANDE__BACK__SUPPRIMER:
                return <span>Supprimée</span>
            case Constant.STATUS.DEMANDE.DEMANDE__FRONT__EN_COURS_DE_DEPOT:
                return <span>En cours de dépôt</span>
            case Constant.STATUS.DEMANDE.DEMANDE__SYSTEM__AGREEE_IMPLICITEMENT:
                return <span>Agréer SVA</span>
        }
        return <span>???</span>
    }

    openInNewTab(url: string | URL | undefined) {
        window.open(url, "_blank", "noopener,noreferrer")
    }

    render(): JSX.Element {
        const rightComponent = (
            <RowComponent>
                <ColumnComponent size={6}></ColumnComponent>
                <ColumnComponent size={6}>
                    <ButtonPdf secondary small onClick={() => this.openInNewTab("/accolade/back/pdf/" + this.props.demandeId)} />
                </ColumnComponent>
            </RowComponent>
        )

        return (
            <ContainerComponent id={Constant.DOM.BACK_OFFICE.AGREMENT__TOUTES_LES_DEMANDES}>
                <PrimaryContentBoxComponent title={this.props.title ? this.props.title : "Instruction de la demande"} rightComponent={rightComponent}>
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getInstructionComponent()}</ColumnComponent>
                        <ColumnComponent size={1}></ColumnComponent>
                    </RowComponent>
                    <VerticalSpacing height="55px" />
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getActionRowOrStatusRow()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <VerticalSpacing height="50px" />
                </PrimaryContentBoxComponent>
            </ContainerComponent>
        )
    }
}
