import { ReactElement } from "react"

import AccountData from "../../../model/AccountData"
import Adresse from "../../../model/Adresse"
import Contact from "../../../model/Contact"
import FinancementJuridique from "../../../model/FinancementJuridique"
import FinessEtablissement from "../../../model/FinessEtablissement"
import FinessJuridique from "../../../model/FinessJuridique"

import AlertInfoComponent from "../../../../generic/component/alert/AlertInfoComponent"
import AlertWarningComponent from "../../../../generic/component/alert/AlertWarningComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"
import TabComponent from "../../../../generic/component/tab/TabComponent"
import TabData from "../../../../generic/component/tab/TabData"

import HttpAccountService from "../../../service/http/HttpAccountService"
import Response from "../../../service/http/response/Response"
import PrimaryContentBoxComponent from "../../../shared/component/box/PrimaryContentBoxComponent"
import FrontPage from "../../FrontPage"
import FrontAccountDemandesComponent from "./demande/FrontAccountDemandesComponent"
import FrontAccountAdresseComponent from "./ej/FrontAccountAdresseComponent"
import FrontAccountContactComponent from "./ej/FrontAccountContactComponent"
import FrontAccountFinancementComponent from "./ej/FrontAccountFinancementComponent"
import FrontAccountFinessJuridiqueComponent from "./ej/FrontAccountFinessJuridiqueComponent"
import FrontAccountEtablissementsComponent from "./et/FrontAccountEtablissementsComponent"

interface FrontShowAccountPageState {
    finessJurique: FinessJuridique | null
    etablissements: FinessEtablissement[] | null
    contact: Contact | null
    adresse: Adresse | null
    financement: FinancementJuridique | null
    data: AccountData | null
    forceRefresh: number
}

export default class FrontShowAccountPage extends FrontPage<any, FrontShowAccountPageState> {
    constructor(props: any) {
        super(props)
        this.state = {
            data: null,
            financement: null,
            finessJurique: null,
            etablissements: null,
            contact: null,
            adresse: null,
            forceRefresh: 0
        }
    }
    refresh() {
        const response = new HttpAccountService().getInformations()

        response.then((response) => {
            const cast = new Response<AccountData>(response)
            if (cast.success) {
                const financement = new FinancementJuridique()
                if (cast.content && cast.content.finessJuridique) {
                    financement.etp = cast.content!.finessJuridique?.etp
                    financement.masseSalarialeBrute = cast.content!.finessJuridique?.masseSalarialeBrute
                    financement.masseSalarialeChargee = cast.content!.finessJuridique?.masseSalarialeChargee
                }

                this.setState({
                    finessJurique: cast.content!.finessJuridique,
                    etablissements: cast.content!.etablissements,
                    contact: cast.content!.contact,
                    adresse: cast.content!.adresse,
                    financement: financement,
                    data: cast.content!,
                    forceRefresh: this.state.forceRefresh + 1
                })
            }
        })
    }

    componentDidMount() {
        this.refresh()
    }

    getTabData(): TabData[] {
        const data: TabData[] = []

        const countEtablissement = this.state && this.state.etablissements ? this.state.etablissements.length : 0

        const refreshFunction = () => {
            this.refresh()
        }

        const entite = new TabData()
        entite.label = "Entité juridique"
        entite.content = <FrontAccountFinessJuridiqueComponent finessJuridique={this.state.finessJurique!} idcc={this.state.data?.idcc} idccs={this.state.data?.idccs} />
        entite.key = "entite-juridique"
        data.push(entite)

        const etablissements = new TabData()
        etablissements.label = countEtablissement > 1 ? "Etablissements" : "Etablissement"
        etablissements.content = <FrontAccountEtablissementsComponent etablissements={this.state.etablissements!} refreshFunction={refreshFunction} />
        entite.key = "etablissements"
        data.push(etablissements)

        const demandes = new TabData()
        demandes.label = "Demandes"
        demandes.content = (
            <FrontAccountDemandesComponent entiteJuridique={this.state.finessJurique!} etablissements={this.state.etablissements!} accountData={this.state.data!} refreshFunction={refreshFunction} />
        )
        entite.key = "demandes-" + Math.random()
        data.push(demandes)

        const financement = new TabData()
        financement.label = "Financement"
        financement.content = <FrontAccountFinancementComponent financement={this.state.financement!} />
        entite.key = "financement"
        data.push(financement)

        const adresse = new TabData()
        adresse.label = "Adresse"
        adresse.content = <FrontAccountAdresseComponent adresse={this.state.adresse!} />
        adresse.key = "adresse"
        data.push(adresse)

        const contact = new TabData()
        contact.label = "Contact"
        contact.content = <FrontAccountContactComponent contact={this.state.contact!} />
        contact.key = "contact"
        data.push(contact)

        return data
    }

    manageIdccUndefined() {
        if (this.state?.data?.idcc?.code === "9999") {
            return (
                <div id={"manage-idcc-undefined"}>
                    <RowComponent style={{ margin: "12px" }}>
                        <ColumnComponent size={2} />
                        <ColumnComponent size={8}>
                            <AlertWarningComponent small description={'Pourriez-vous redéfinir votre IDCC (onglet "Entité juridique")'} />
                        </ColumnComponent>
                        <ColumnComponent size={2} />
                    </RowComponent>
                </div>
            )
        }
    }

    manageFinancementUndefined() {
        if (this.state?.data?.finessJuridique?.etp == 9999 || this.state?.data?.finessJuridique?.masseSalarialeChargee == 999999 || this.state?.data?.finessJuridique?.masseSalarialeBrute == 999999) {
            return (
                <div id={"manage-financement-undefined"}>
                    <RowComponent style={{ margin: "12px" }}>
                        <ColumnComponent size={2} />
                        <ColumnComponent size={8}>
                            <AlertWarningComponent small description={'Pourriez-vous redéfinir vos informations de financement (onglet "Financement")'} />
                        </ColumnComponent>
                        <ColumnComponent size={2} />
                    </RowComponent>
                </div>
            )
        }
    }

    render() {
        if (this.state) {
            return (
                <PrimaryContentBoxComponent title={`Mon compte`}>
                    {this.manageIdccUndefined()}
                    {this.manageFinancementUndefined()}
                    <RowComponent>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TabComponent data={this.getTabData()} style={{ zIndex: "auto" }} />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                </PrimaryContentBoxComponent>
            )
        } else {
            return <PrimaryContentBoxComponent title={`Mon compte`}></PrimaryContentBoxComponent>
        }
    }
}
