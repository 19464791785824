import React, { ReactElement } from "react"

import { DnumProps } from "../../../../generic/component/DnumComponent"
import ContainerComponent from "../../../../generic/component/grid/container/ContainerComponent"
import HeadBand6Component from "../../../../generic/component/headband/HeadBand6Component"

import AccoladeComponent from "../../../../accolade/AccoladeComponent"

import { Constant } from "../../../constant/Constant"

interface ContentBoxProps extends DnumProps {
    title: string | ReactElement
}

export default abstract class SecondaryContentBoxComponent<P extends ContentBoxProps, S> extends AccoladeComponent<P, S> {
    render() {
        const headBand = {
            padding: 0,
            margin: 0,
            background: Constant.COLOR.LIGHT_RED_MARIANNE,
            color: Constant.COLOR.RED_MARIANNE,
            paddingLeft: "30px !important"
        }

        const contentContainer = {
            background: "white",
            padding: "25px"
        }

        return (
            <ContainerComponent style={{ border: "1px dotted black", padding: "0px", margin: "0px" }}>
                <div style={{ paddingLeft: "25px", background: headBand.background }}>
                    <HeadBand6Component label={this.props.title} style={headBand} />
                </div>
                <ContainerComponent style={contentContainer}>{this.props.children}</ContainerComponent>
            </ContainerComponent>
        )
    }
}
