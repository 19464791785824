import React from "react"

import { Constant } from "../../../constant/Constant"
import BackOfficeAbstractReferentielPage from "../BackOfficeAbstractReferentielPage"
import BackOfficeTypeAccordFormComponent from "./BackOfficeTypeAccordFormComponent"
import BackOfficeTypeAccordListComponent from "./BackOfficeTypeAccordListComponent"

export default class BackOfficeTypeAccordPage extends BackOfficeAbstractReferentielPage {
    getObjectTitle(): string {
        return "Types d'accord"
    }

    getFormComponent(itemId: number | null, showListMethod: () => void): React.ReactElement {
        return <BackOfficeTypeAccordFormComponent objectId={itemId} showListHandler={showListMethod} />
    }

    getListComponent(forceRefresh: string, addButtonHandler: () => void, editButtonHandler: (id: number | null) => void, deleteButtonHandler: (id: number | null) => void): React.ReactElement {
        return <BackOfficeTypeAccordListComponent forceRefresh={forceRefresh} addButtonHandler={addButtonHandler} editButtonHandler={editButtonHandler} deleteButtonHandler={deleteButtonHandler} />
    }

    getId(): string {
        return Constant.DOM.BACK_OFFICE.REFERENTIEL__TYPE_ACCORD
    }
}
