import React, { ChangeEvent, Fragment, ReactElement } from "react"

import AccountData from "../../model/AccountData"
import Cout from "../../model/Cout"
import File from "../../model/File"
import FileType from "../../model/FileType"
import FinessEtablissement from "../../model/FinessEtablissement"

import { DnumProps } from "../../../generic/component/DnumComponent"
import AlertSuccessComponent from "../../../generic/component/alert/AlertSuccessComponent"
import AlertWarningComponent from "../../../generic/component/alert/AlertWarningComponent"
import Button from "../../../generic/component/button/Button"
import ButtonGroupHorizontal from "../../../generic/component/button/group/ButtonGroupHorizontal"
import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"
import ErrorMessageComponent from "../../../generic/component/message/ErrorMessageComponent"

import { Constant } from "../../constant/Constant"
import FrontService from "../../service/FrontService"
import FormService from "../../service/form/FormService"
import HttpAccountService from "../../service/http/HttpAccountService"
import HttpFrontFileService from "../../service/http/HttpFrontFileService"
import Response from "../../service/http/response/Response"
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import VerticalSpacing from "../../shared/component/space/VerticalSpacing"
import FrontPage from "../FrontPage"
import FrontDemandeAgrementForm from "./FrontDemandeAgrementForm"
import FrontDemandeAgrementVerticalMenu from "./FrontDemandeAgrementVerticalMenu"
import FrontDemandeAgrementFormStep0AComponent from "./step/step0/FrontDemandeAgrementFormStep0AComponent"
import FrontDemandeAgrementFormStep0BComponent from "./step/step0/FrontDemandeAgrementFormStep0BComponent"
import FrontDemandeAgrementFormStep0CComponent from "./step/step0/FrontDemandeAgrementFormStep0CComponent"
import FrontDemandeAgrementFormStep1Component from "./step/step1/FrontDemandeAgrementFormStep1Component"
import FrontDemandeAgrementFormStep2AComponent from "./step/step2/FrontDemandeAgrementFormStep2AComponent"
import FrontDemandeAgrementFormStep2BComponent from "./step/step2/FrontDemandeAgrementFormStep2BComponent"
import FrontDemandeAgrementFormStep3Component from "./step/step3/FrontDemandeAgrementFormStep3Component"

interface FrontDemandeAgrementFormState {
    menuIndex: number
    informations: AccountData | undefined
    errors: Map<string, string> | undefined
    currentId: number | undefined | null
    saved: boolean | undefined
    sent: boolean | undefined
    current: FrontDemandeAgrementForm | undefined
    edition: boolean | undefined
    action: string | undefined
    typeDemande: string | null | undefined
    dataValidatedBeforeSubmission: boolean
    fileAccordDue: File | undefined
    pieceComplementaire1: File | undefined
    pieceComplementaire2: File | undefined
    pieceComplementaire3: File | undefined
    pieceComplementaire4: File | undefined
    pieceComplementaire5: File | undefined
    forceRefresh: number
    exception: boolean
}

interface FrontDemandeAgrementFormProps extends DnumProps {
    currentId: number | undefined | any
    edition: boolean | undefined
}

export default class FrontDemandeAgrementFormPage extends FrontPage<FrontDemandeAgrementFormProps, FrontDemandeAgrementFormState> {
    public static FORM_CONTAINER_ID = "demandeAgrementFormContainerStep"

    public static FORM_CONTAINER_STEP_0_ID = "demandeAgrementFormContainerStep0"
    public static FORM_CONTAINER_STEP_1_ID = "demandeAgrementFormContainerStep1"
    public static FORM_CONTAINER_STEP_2_ID = "demandeAgrementFormContainerStep2"
    public static FORM_CONTAINER_STEP_3_ID = "demandeAgrementFormContainerStep3"

    constructor(props: FrontDemandeAgrementFormProps) {
        super(props)

        this.state = {
            forceRefresh: 0,
            menuIndex: 0,
            dataValidatedBeforeSubmission: false,
            currentId: this.props.currentId,
            edition: this.props.edition,
            informations: undefined,
            errors: undefined,
            saved: undefined,
            sent: undefined,
            current: undefined,
            fileAccordDue: undefined,
            action: undefined,
            typeDemande: undefined,
            pieceComplementaire1: undefined,
            pieceComplementaire2: undefined,
            pieceComplementaire3: undefined,
            pieceComplementaire4: undefined,
            pieceComplementaire5: undefined,
            exception: false
        }
    }

    componentDidMount() {
        new HttpAccountService().getInformations().then((response) => {
            this.setState({
                informations: response!.content,
                saved: false,
                sent: false
            })
        })
        if (this.props.currentId != null) {
            new HttpAccountService().getDemande(this.props.currentId).then((response) => {
                this.setState({ current: response.content, typeDemande: response.content?.typeDemande })
            })
            // fichier ACCORD ou DUE
            new HttpFrontFileService().getFile(File.TYPE.ACCORD_DUE, this.props.currentId).then((response) => {
                this.setState({ fileAccordDue: response.content?.id != null ? response.content : undefined })
            })
            // fichiers pièces jointes complémentaires
            new HttpFrontFileService().getFile(File.TYPE.PIECE_COMPLEMENTAIRE_1, this.props.currentId).then((response) => {
                this.setState({ pieceComplementaire1: response.content?.id != null ? response.content : undefined })
            })
            new HttpFrontFileService().getFile(File.TYPE.PIECE_COMPLEMENTAIRE_2, this.props.currentId).then((response) => {
                this.setState({ pieceComplementaire2: response.content?.id != null ? response.content : undefined })
            })
            new HttpFrontFileService().getFile(File.TYPE.PIECE_COMPLEMENTAIRE_3, this.props.currentId).then((response) => {
                this.setState({ pieceComplementaire3: response.content?.id != null ? response.content : undefined })
            })
            new HttpFrontFileService().getFile(File.TYPE.PIECE_COMPLEMENTAIRE_4, this.props.currentId).then((response) => {
                this.setState({ pieceComplementaire4: response.content?.id != null ? response.content : undefined })
            })
            new HttpFrontFileService().getFile(File.TYPE.PIECE_COMPLEMENTAIRE_5, this.props.currentId).then((response) => {
                this.setState({ pieceComplementaire5: response.content?.id != null ? response.content : undefined })
            })
        }
    }

    getForm(): FrontDemandeAgrementForm {
        const form = new FrontDemandeAgrementForm()

        // part 1
        form.typeDemande = FormService.getInstance().getRadioButtonsValue("typeDemande")

        const inputNumeroDossier = document.querySelector("#numero-dossier") as HTMLInputElement
        if (inputNumeroDossier != null) {
            form.numeroDossier = inputNumeroDossier.value
        }

        const inputDateDepot = document.querySelector("#date-depot") as HTMLInputElement
        if (inputDateDepot != null) {
            form.dateDepot = inputDateDepot.value
        }

        form.dateSignature = (document.querySelector("#date-signature") as HTMLInputElement).value
        form.etablissementIds =
            this.state.current?.etablissements?.map((etablissement: FinessEtablissement) => {
                return etablissement.id!.toString()
            }) ?? null

        // part 2
        form.emailContact = (document.querySelector("#contact-email") as HTMLInputElement).value
        form.masseSalarialeChargee = (document.querySelector("#masse-salariale-chargee") as HTMLInputElement).value
        form.etpConcernes = (document.querySelector("#etp") as HTMLInputElement).value

        // part 3 typologie
        form.typologieAccordId = (document.querySelector("#select-typologie") as HTMLInputElement).value

        // part 4
        form.explication = (document.querySelector("#explication") as HTMLInputElement).value

        // part 5
        const checkbox = document.querySelector("[name='sans-impact-financier']") as HTMLInputElement
        form.sansImpactFinancier = checkbox.checked
        form.financement = this.getFinancement()

        // ID
        if (this.state && this.state.currentId) {
            form.id = this.state?.currentId
        }

        return form
    }

    getFinancement(): Cout[] {
        const couts: Cout[] = []
        const years = [0, 1, 2, 3]
        const setFinanceurIds = new Set<number>()
        this.state.current?.etablissements?.forEach((etablissement) => {
            if (etablissement.financeurs != null && etablissement.financeurs.length > 0) {
                etablissement.financeurs.forEach((financeur) => {
                    if (!setFinanceurIds.has(financeur.id!)) {
                        years.forEach((annee) => {
                            const cout = new Cout()
                            cout.setId(financeur.id!)
                            cout.setAnnee(annee)
                            cout.setResourcesPropres(this.getInputValue("#cell-" + annee + "-" + financeur.id + "-" + FrontDemandeAgrementFormStep1Component.RESOURCES_PROPRES_KEY))
                            cout.setDotationsSubventions(this.getInputValue("#cell-" + annee + "-" + financeur.id + "-" + FrontDemandeAgrementFormStep1Component.DOTATIONS_SUBVENTIONS_KEY))
                            cout.setMesuresNouvelles(this.getInputValue("#cell-" + annee + "-" + financeur.id + "-" + FrontDemandeAgrementFormStep1Component.MESURES_NOUVELLES_KEY))
                            couts.push(cout)
                        })
                    }
                    setFinanceurIds.add(financeur.id!)
                })
            }
        })

        years.forEach((year) => {
            const coutPourInfo = new Cout()
            coutPourInfo.setAnnee(year)
            coutPourInfo.setPourInfoCnav(this.getInputValue("#cell-" + year + "-" + FrontDemandeAgrementFormStep1Component.POUR_INFO_CNAV))
            coutPourInfo.setPourInfoCaf(this.getInputValue("#cell-" + year + "-" + FrontDemandeAgrementFormStep1Component.POUR_INFO_CAF))
            couts.push(coutPourInfo)
        })

        return couts
    }

    getInputValue(id: string): number | null {
        let input = document.querySelector(id) as HTMLInputElement

        if (input == null) {
            input = document.querySelector(id.substring(1))!
        }

        if (input?.value != null) {
            try {
                if (!isNaN(Number.parseInt(input.value))) {
                    return Number.parseInt(input.value)
                }
            } catch (exception: any) {
                // TODO
                console.info(exception.message)
            }
        }
        return null
    }

    async manageResponse(response: Response<FrontDemandeAgrementForm>, validation?: boolean) {
        if (response.success && response.errors?.length == 0) {
            const id = response.content?.id as number

            // send accord file
            const inputFile = document.querySelector("#file-upload-file-accord-due") as HTMLInputElement
            const accordDueResponse = await new HttpFrontFileService().sendFile(id, inputFile, FileType.ACCORD_DUE, "fileAccordDue")

            // piece complementaire 1
            const inputPieceComplementaire1 = document.querySelector("#file-upload-PIECE_COMPLEMENTAIRE_1") as HTMLInputElement
            const pieceComplementaire1Response = await new HttpFrontFileService().sendFile(id, inputPieceComplementaire1, FileType.PIECE_COMPLEMENTAIRE_1, FileType.PIECE_COMPLEMENTAIRE_1 + "-error")

            // piece complementaire 2
            const inputPieceComplementaire2 = document.querySelector("#file-upload-PIECE_COMPLEMENTAIRE_2") as HTMLInputElement
            const pieceComplementaire2Response = await new HttpFrontFileService().sendFile(id, inputPieceComplementaire2, FileType.PIECE_COMPLEMENTAIRE_2, FileType.PIECE_COMPLEMENTAIRE_2 + "-error")

            // piece complementaire 1
            const inputPieceComplementaire3 = document.querySelector("#file-upload-PIECE_COMPLEMENTAIRE_3") as HTMLInputElement
            const pieceComplementaire3Response = await new HttpFrontFileService().sendFile(id, inputPieceComplementaire3, FileType.PIECE_COMPLEMENTAIRE_3, FileType.PIECE_COMPLEMENTAIRE_3 + "-error")

            // piece complementaire 4
            const inputPieceComplementaire4 = document.querySelector("#file-upload-PIECE_COMPLEMENTAIRE_4") as HTMLInputElement
            const pieceComplementaire4Response = await new HttpFrontFileService().sendFile(id, inputPieceComplementaire4, FileType.PIECE_COMPLEMENTAIRE_4, FileType.PIECE_COMPLEMENTAIRE_4 + "-error")

            // piece complementaire 5
            const inputPieceComplementaire5 = document.querySelector("#file-upload-PIECE_COMPLEMENTAIRE_5") as HTMLInputElement
            const pieceComplementaire5Response = await new HttpFrontFileService().sendFile(id, inputPieceComplementaire5, FileType.PIECE_COMPLEMENTAIRE_5, FileType.PIECE_COMPLEMENTAIRE_5 + "-error")

            const errors = this.mergeErrorMap(
                new Map<string, string>(),
                accordDueResponse != undefined ? accordDueResponse.getErrorMap() : new Map<string, string>(),
                pieceComplementaire1Response != undefined ? pieceComplementaire1Response.getErrorMap() : new Map<string, string>(),
                pieceComplementaire2Response != undefined ? pieceComplementaire2Response?.getErrorMap() : new Map<string, string>(),
                pieceComplementaire3Response != undefined ? pieceComplementaire3Response?.getErrorMap() : new Map<string, string>(),
                pieceComplementaire4Response != undefined ? pieceComplementaire4Response?.getErrorMap() : new Map<string, string>(),
                pieceComplementaire5Response != undefined ? pieceComplementaire5Response?.getErrorMap() : new Map<string, string>()
            )

            this.setState({
                currentId: id,
                saved: errors.size == 0,
                errors: errors,
                current: response.content,
                fileAccordDue: accordDueResponse?.content!.id != null ? accordDueResponse.content : this.state.fileAccordDue,
                pieceComplementaire1: pieceComplementaire1Response?.content!.id != null ? pieceComplementaire1Response.content : this.state.pieceComplementaire1,
                pieceComplementaire2: pieceComplementaire2Response?.content!.id != null ? pieceComplementaire2Response.content : this.state.pieceComplementaire2,
                pieceComplementaire3: pieceComplementaire3Response?.content!.id != null ? pieceComplementaire3Response.content : this.state.pieceComplementaire3,
                pieceComplementaire4: pieceComplementaire4Response?.content!.id != null ? pieceComplementaire4Response.content : this.state.pieceComplementaire4,
                pieceComplementaire5: pieceComplementaire5Response?.content!.id != null ? pieceComplementaire5Response.content : this.state.pieceComplementaire5
            })
        } else {
            this.setState({ saved: false, errors: response.getErrorMap() })
        }
    }

    async sendAccordDue(input: HTMLInputElement) {
        if (this.state && this.state.current) {
            const img = document.querySelector("#figure-" + FileType.ACCORD_DUE) as HTMLImageElement
            img.style.display = "block"

            const fileResponse = await new HttpFrontFileService().sendFile(this.state.current.id!, input, FileType.ACCORD_DUE, FileType.ACCORD_DUE + "-error")
            const cast = new Response<File>(fileResponse)

            img.style.display = "none"

            if (cast.hasError()) {
                this.setState({ errors: cast.getErrorMap() })
            } else {
                this.setState({ fileAccordDue: cast.content })
            }
        }
    }

    async sendPieceComplementaire(input: HTMLInputElement) {
        let fileType = ""
        if (input.id.endsWith("1")) {
            fileType = FileType.PIECE_COMPLEMENTAIRE_1
        } else if (input.id.endsWith("2")) {
            fileType = FileType.PIECE_COMPLEMENTAIRE_2
        } else if (input.id.endsWith("3")) {
            fileType = FileType.PIECE_COMPLEMENTAIRE_3
        } else if (input.id.endsWith("4")) {
            fileType = FileType.PIECE_COMPLEMENTAIRE_4
        } else {
            fileType = FileType.PIECE_COMPLEMENTAIRE_5
        }
        if (this.state && this.state.current) {
            const img = document.querySelector("#figure-" + fileType) as HTMLImageElement
            img.style.display = "block"
            const fileResponse = await new HttpFrontFileService().sendFile(this.state.current.id!, input, fileType, fileType + "-error")
            const cast = new Response<File>(fileResponse)
            img.style.display = "none"
            if (cast.hasError()) {
                this.setState({ errors: cast.getErrorMap() })
            } else {
                if (input.id.endsWith("1")) {
                    this.setState({ pieceComplementaire1: cast.content })
                } else if (input.id.endsWith("2")) {
                    this.setState({ pieceComplementaire2: cast.content })
                } else if (input.id.endsWith("3")) {
                    this.setState({ pieceComplementaire3: cast.content })
                } else if (input.id.endsWith("4")) {
                    this.setState({ pieceComplementaire4: cast.content })
                } else {
                    this.setState({ pieceComplementaire5: cast.content })
                }
            }
        }
    }

    mergeErrorMap(...maps: Map<string, string>[]) {
        const merge = new Map<string, string>()
        maps.forEach((map) => {
            if (map != undefined) {
                map.forEach((value: string, key: string) => {
                    merge.set(key, value)
                })
            }
        })
        return merge
    }

    setTypeDemande(event: ChangeEvent<HTMLInputElement>) {
        this.setState({ typeDemande: event.currentTarget.value })
    }

    submitForm(validation: boolean) {
        const form = this.getForm()
        this.setState({ saved: false, sent: false })
        new HttpAccountService().saveDemande(form, validation).then((response) => {
            const cast = new Response<FrontDemandeAgrementForm>(response)
            this.manageResponse(cast, validation)
        })
    }

    submitDemande() {
        const form = this.getForm()
        this.setState({ saved: false, sent: false })
        new HttpAccountService().sendDemande(form).then((response) => {
            const cast = new Response<FrontDemandeAgrementForm>(response)
            if (cast.errors == null || cast.errors.length == 0) {
                FrontService.getInstance().switchPage(Constant.DOM.FRONT.SHOW_ACCOUNT)
            } else {
                this.setState({ saved: false, sent: false, exception: true })
            }
        })
    }

    deleteFile(file: File | undefined, fileType: string) {
        if (file != undefined) {
            new HttpFrontFileService().deleteFile(file.key!).then((response) => {
                if (response.success) {
                    switch (fileType) {
                        case FileType.ACCORD_DUE:
                            this.setState({ fileAccordDue: undefined, saved: undefined })
                            break
                        case FileType.PIECE_COMPLEMENTAIRE_1:
                            this.setState({ pieceComplementaire1: undefined, saved: undefined })
                            break
                        case FileType.PIECE_COMPLEMENTAIRE_2:
                            this.setState({ pieceComplementaire2: undefined, saved: undefined })
                            break
                        case FileType.PIECE_COMPLEMENTAIRE_3:
                            this.setState({ pieceComplementaire3: undefined, saved: undefined })
                            break
                        case FileType.PIECE_COMPLEMENTAIRE_4:
                            this.setState({ pieceComplementaire4: undefined, saved: undefined })
                            break
                        case FileType.PIECE_COMPLEMENTAIRE_5:
                            this.setState({ pieceComplementaire5: undefined, saved: undefined })
                            break
                    }
                }
            })
        }
    }

    getGlobalErrorMessage() {
        // derniere etape, on affiche pas les erreurs
        if (this.state && this.state.menuIndex != 3) {
            if (this.state.errors && this.state.errors.size > 0) {
                console.info("tses :: " + this.state.errors.size)

                if (this.state.errors.size > 1) {
                    return (
                        <Fragment key="one-error-fragment">
                            <VerticalSpacing height="10px" />
                            <ErrorMessageComponent message={"Votre formulaire contient " + this.state.errors.size + " erreurs"} />
                            <VerticalSpacing height="20px" />
                        </Fragment>
                    )
                } else if (this.state.errors.size > 0) {
                    return (
                        <Fragment key="many-errors-fragment">
                            <VerticalSpacing height="10px" />
                            <ErrorMessageComponent message={"Votre formulaire contient " + this.state.errors.size + " erreur"} />
                            <VerticalSpacing height="20px" />
                        </Fragment>
                    )
                }
            } else if (this.state.saved) {
                return (
                    <Fragment key="no-error-fragment">
                        <VerticalSpacing height="10px" />
                        <AlertSuccessComponent title="Formulaire enregistré" />
                        <VerticalSpacing height="20px" />
                    </Fragment>
                )
            }
        }
    }

    getExceptionMessage() {
        if (this.state && this.state.exception) {
            return <AlertWarningComponent title={"Erreur : le formulaire n'est pas complet"} />
        }
    }

    clickNext(htmlId: string) {
        this.setState({ saved: false, sent: false, dataValidatedBeforeSubmission: false })
        if (htmlId == FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_3_ID && this.state.edition) {
            this.submitForm(true)
        } else {
            this.submitForm(false)
        }
        this.onClickPreviousOrNext(htmlId)
    }

    clickPrevious(htmlId: string) {
        this.setState({ saved: false, sent: false, dataValidatedBeforeSubmission: false })
        this.onClickPreviousOrNext(htmlId)
    }

    onClickPreviousOrNext(htmlId: string) {
        // hide all
        document.getElementById(FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_0_ID)!.style.display = "none"
        document.getElementById(FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_1_ID)!.style.display = "none"
        document.getElementById(FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_2_ID)!.style.display = "none"
        document.getElementById(FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_3_ID)!.style.display = "none"
        // show selected
        document.getElementById(htmlId)!.style.display = "block"

        const index = htmlId.charAt(htmlId.length - 1)
        this.setState({
            menuIndex: Number.parseInt(index),
            forceRefresh: this.state.forceRefresh + 1
        })
    }

    getButtons(edition: boolean, previousHtmlId: string | null, saveButton: boolean, sendButton: boolean, nextHtmlId: string | null): ReactElement[] {
        const buttons: ReactElement[] = []
        if (previousHtmlId != null) {
            buttons.push(
                <Button key="previous" onClick={() => this.clickPrevious(previousHtmlId)} iconLeft={Constant.ICON.ARROW_LEFT_S_LINE_DOUBLE} medium group>
                    Précédent
                </Button>
            )
        } else {
            buttons.push(
                <Button key="previous" disabled iconLeft={Constant.ICON.ARROW_LEFT_S_LINE_DOUBLE} medium group>
                    Précédent
                </Button>
            )
        }
        if (saveButton && (edition ?? false)) {
            buttons.push(
                <Button key="save" onClick={() => this.submitForm(false)} secondary iconLeft={Constant.ICON.SAVE_FILL} medium group>
                    Sauvegarder
                </Button>
            )
        }
        if (sendButton && (edition ?? false)) {
            if (this.state && this.state.dataValidatedBeforeSubmission) {
                buttons.push(
                    <Button key="send" onClick={() => this.submitDemande()} secondary iconLeft={Constant.ICON.CHECK_LINE} medium group>
                        Transmettre
                    </Button>
                )
            } else {
                buttons.push(
                    <Button key="send" disabled onClick={() => this.submitDemande()} secondary iconLeft={Constant.ICON.CHECK_LINE} medium group>
                        Transmettre
                    </Button>
                )
            }
        }
        if (nextHtmlId != null) {
            buttons.push(
                <Button key="next" onClick={() => this.clickNext(nextHtmlId)} iconLeft={Constant.ICON.ARROW_RIGHT_S_LINE_DOUBLE} medium group>
                    Suivant
                </Button>
            )
        } else {
            buttons.push(
                <Button key="next" disabled iconLeft={Constant.ICON.ARROW_RIGHT_S_LINE_DOUBLE} medium group>
                    Suivant
                </Button>
            )
        }
        return buttons
    }

    manageValidationCheckbox() {
        const validInformations = document.querySelector("input[name='valid-informations']") as HTMLInputElement
        //console.log("validInformations.checked "+validInformations.checked)
        this.setState({ dataValidatedBeforeSubmission: validInformations.checked })
    }

    render(): JSX.Element {
        return (
            <PrimaryContentBoxComponent title="Nouvelle demande d'agrément">
                <RowComponent>
                    <ColumnComponent size={3}>
                        <FrontDemandeAgrementVerticalMenu index={this.state?.menuIndex} />
                    </ColumnComponent>
                    <ColumnComponent size={9}>
                        {this.getGlobalErrorMessage()}
                        <div id={FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_0_ID} style={{ display: "block" }}>
                            <FrontDemandeAgrementFormStep0AComponent
                                onTypeDemandeSelected={(event) => this.setTypeDemande(event)}
                                informations={this.state?.informations}
                                demandeInProgress={this.state.current}
                                errors={this.state.errors}
                            />
                            <VerticalSpacing height={"20px"} />
                            <FrontDemandeAgrementFormStep0BComponent informations={this.state?.informations} demandeInProgress={this.state.current} errors={this.state?.errors} />
                            <VerticalSpacing height={"20px"} />
                            <FrontDemandeAgrementFormStep0CComponent informations={this.state?.informations} errors={this.state?.errors} demandeInProgress={this.state.current} />
                            <VerticalSpacing height={"15px"} />
                            <ButtonGroupHorizontal style={{ textAlign: "center" }}>
                                {this.getButtons(this.state?.edition ?? false, null, true, false, FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_1_ID)}
                            </ButtonGroupHorizontal>
                        </div>
                        <div id={FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_1_ID} style={{ display: "none" }}>
                            <FrontDemandeAgrementFormStep1Component
                                informations={this.state?.informations}
                                demandeInProgress={this.state.current}
                                dateUpdate={new Date()}
                                errors={this.state?.errors}
                            />
                            <VerticalSpacing height={"15px"} />
                            <ButtonGroupHorizontal style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.getButtons(
                                    this.state?.edition ?? false,
                                    FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_0_ID,
                                    true,
                                    false,
                                    FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_2_ID
                                )}
                            </ButtonGroupHorizontal>
                        </div>
                        <div id={FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_2_ID} style={{ display: "none" }}>
                            <FrontDemandeAgrementFormStep2AComponent
                                informations={this.state?.informations}
                                demandeInProgress={this.state.current}
                                fileAccordDue={this.state.fileAccordDue}
                                typeDemande={this.state.typeDemande}
                                sendFileDirectly={(input: HTMLInputElement) => this.sendAccordDue(input)}
                                deleteFileFunction={(file, fileType) => this.deleteFile(file, fileType)}
                                errors={this.state?.errors}
                            />
                            <VerticalSpacing height={"20px"} />
                            <FrontDemandeAgrementFormStep2BComponent
                                informations={this.state?.informations}
                                sendFileDirectly={(input) => this.sendPieceComplementaire(input)}
                                pieceComplementaire1={this.state.pieceComplementaire1}
                                pieceComplementaire2={this.state.pieceComplementaire2}
                                pieceComplementaire3={this.state.pieceComplementaire3}
                                pieceComplementaire4={this.state.pieceComplementaire4}
                                pieceComplementaire5={this.state.pieceComplementaire5}
                                deleteFileFunction={(file, fileType) => this.deleteFile(file, fileType)}
                                demandeInProgress={this.state.current}
                                errors={this.state?.errors}
                            />
                            <VerticalSpacing height={"15px"} />
                            <ButtonGroupHorizontal style={{ textAlign: "center" }}>
                                {this.getButtons(
                                    this.state?.edition ?? false,
                                    FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_1_ID,
                                    true,
                                    false,
                                    FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_3_ID
                                )}
                            </ButtonGroupHorizontal>
                        </div>
                        <div id={FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_3_ID} style={{ display: "none" }}>
                            <FrontDemandeAgrementFormStep3Component
                                errors={this.state?.errors}
                                fileAccordDue={this.state.fileAccordDue}
                                pieceComplementaire1={this.state.pieceComplementaire1}
                                pieceComplementaire2={this.state.pieceComplementaire2}
                                pieceComplementaire3={this.state.pieceComplementaire3}
                                pieceComplementaire4={this.state.pieceComplementaire4}
                                pieceComplementaire5={this.state.pieceComplementaire5}
                                demandeInProgress={this.state.current}
                                manageValidationCheckbox={() => this.manageValidationCheckbox()}
                                informations={this.state.informations}
                            />
                            <ButtonGroupHorizontal style={{ textAlign: "center", marginTop: "15px" }}>
                                <RowComponent style={{ marginBottom: "24px" }}>
                                    <ColumnComponent size={2} />
                                    <ColumnComponent size={8}>{this.getExceptionMessage()}</ColumnComponent>
                                    <ColumnComponent size={2} />
                                </RowComponent>
                                {this.getButtons(this.state?.edition ?? false, FrontDemandeAgrementFormPage.FORM_CONTAINER_STEP_2_ID, false, true, null)}
                            </ButtonGroupHorizontal>
                        </div>
                    </ColumnComponent>
                </RowComponent>
            </PrimaryContentBoxComponent>
        )
    }
}
