import { Fragment } from "react"

import Button from "../../../generic/component/button/Button"
import TextInputComponent from "../../../generic/component/form/input/TextInputComponent"
import HeadBand6Component from "../../../generic/component/headband/HeadBand6Component"
import LinkComponent from "../../../generic/component/link/LinkComponent"
import ErrorMessageComponent from "../../../generic/component/message/ErrorMessageComponent"
import SuccessMessageComponent from "../../../generic/component/message/SuccessMessageComponent"

import { Constant } from "../../constant/Constant"
import Icon from "../../constant/Icon"
import FrontService from "../../service/FrontService"
import HttpAccountService from "../../service/http/HttpAccountService"
import Response from "../../service/http/response/Response"
import VerticalSpacing from "../../shared/component/space/VerticalSpacing"
import FrontComponent from "../FrontComponent"
import FrontLoginForm from "./FrontLoginForm"

interface FrontLoginFormComponentState {
    errorMessage?: string | undefined
    successMessage?: string | undefined
    errors?: Map<string, string> | undefined
    view: string | null
}

export default class FrontLoginFormComponent extends FrontComponent<any, FrontLoginFormComponentState> {
    httpService: HttpAccountService = new HttpAccountService()

    VIEW_FORGOT_PASSWORD = "forgotPasswordView"
    VIEW_CODE_ACTIVATION = "codeActivationView"
    VIEW_CODE_ACTIVATION_FORGOT_PASSWORD = "setCodeToChangePasswordView"
    VIEW_PASSWORD_CHANGED = "viewPasswordChanged"
    VIEW_SET_PASSWORD = "setPasswordView"

    switchToLogin() {
        FrontLoginFormComponent.applicationService.setUserConnected()
        FrontService.getInstance().switchPage(Constant.DOM.FRONT.SHOW_ACCOUNT)
    }

    switchToFiness() {
        FrontService.getInstance().switchPage(Constant.DOM.FRONT.SET_DETAILS_ACCOUNT)
    }

    getLoginForm() {
        const loginForm = new FrontLoginForm()
        loginForm.email = (document.querySelector("#account-login-email") as HTMLInputElement).value
        loginForm.password = (document.querySelector("#account-login-password") as HTMLInputElement).value
        return loginForm
    }

    applyMotPasseOublie() {
        this.setState({ view: this.VIEW_FORGOT_PASSWORD, errorMessage: undefined })
    }

    doLogin() {
        const response = this.httpService.login(this.getLoginForm())

        response.then((frontResponse) => {
            const cast = new Response(frontResponse)

            if (cast.errorMessage) {
                this.setState({ errorMessage: frontResponse.errorMessage })
            } else if (cast.hasError()) {
                const error = cast.errors![0]

                if (error.errorKey === "finessNotDefined") {
                    this.switchToFiness()
                } else if (error.errorKey === "accountLocked" || error.errorKey === "passwordError" || error.errorKey === "login") {
                    this.setState({ errorMessage: error.errorMessage! })
                } else if (error.errorKey === "tempKey" || error.errorKey === "tempKeyGenerated" || error.errorKey === "codeActivationResult") {
                    this.setState({ view: this.VIEW_CODE_ACTIVATION, errorMessage: error.errorMessage! })
                }
            } else {
                this.switchToLogin()
            }
        })
    }

    validAccount() {
        const input = document.querySelector("#account-activation-code-2") as HTMLInputElement
        this.httpService.validAccount(input.value).then((response) => {
            const cast = new Response(response)
            if (cast.success) {
                this.setState({
                    view: null,
                    successMessage: "Vous pouvez maintenant vous connecter",
                    errorMessage: undefined
                })
            } else {
                this.setState({ errorMessage: cast.errors![0].errorMessage! })
            }
        })
    }

    validAccountToChangePassword() {
        //console.log("valid account to change password")
        const input = document.querySelector("#account-activation-code-3") as HTMLInputElement
        //console.log("input :::: "+input)
        this.httpService.validKeyForPassword(input.value).then((response) => {
            const cast = new Response(response)
            if (cast.success) {
                this.setState({
                    view: this.VIEW_SET_PASSWORD,
                    errorMessage: undefined
                })
            } else {
                this.setState({ errorMessage: cast.errors![0].errorMessage! })
            }
        })
    }

    sendEmailForgotPassword() {
        const email = (document.querySelector("#login-forgot-password") as HTMLInputElement).value
        this.httpService.sendEmailForgotPassword(email).then((response) => {
            const cast = new Response(response)
            if (cast.success) {
                this.setState({
                    view: this.VIEW_CODE_ACTIVATION_FORGOT_PASSWORD,
                    errorMessage: undefined
                })
            } else {
                this.setState({ errorMessage: cast.errors![0].errorMessage! })
            }
        })
    }

    changePassword() {
        //console.log("change password")
        const password = (document.querySelector("#account-change-password") as HTMLInputElement).value
        const confirmPassword = (document.querySelector("#account-confirm-change-password") as HTMLInputElement).value

        this.httpService.changePassword(password, confirmPassword).then((response) => {
            const cast = new Response(response)
            if (cast.success) {
                this.setState({
                    view: this.VIEW_PASSWORD_CHANGED,
                    errorMessage: undefined
                })
            } else {
                this.setState({ errorMessage: cast.errors![0].errorMessage! })
            }
        })
    }

    getErrorMessageView() {
        if (this.state && this.state.errorMessage) {
            return (
                <Fragment>
                    <ErrorMessageComponent message={this.state.errorMessage} />
                    <br />
                </Fragment>
            )
        }
    }

    getSuccessMessageView() {
        if (this.state && this.state.successMessage) {
            return (
                <Fragment>
                    <SuccessMessageComponent message={this.state.successMessage} />
                    <br />
                </Fragment>
            )
        }
    }

    getContent() {
        const displayCodeActivation = this.state && this.state.view === this.VIEW_CODE_ACTIVATION
        const displayMotPasseOublie = this.state && this.state.view === this.VIEW_FORGOT_PASSWORD
        const displaySetMotPasse = this.state && this.state.view === this.VIEW_SET_PASSWORD
        const displayCodeActivationToChangePassword = this.state && this.state.view === this.VIEW_CODE_ACTIVATION_FORGOT_PASSWORD
        const displayPasswordChanged = this.state && this.state.view === this.VIEW_PASSWORD_CHANGED

        //console.log(displaySetMotPasse+" "+displayMotPasseOublie+" "+displayCodeActivation+" "+displayCodeActivationToChangePassword)

        // il y avait un problème je renvoyais un contenu ou l'autre seulement
        // la valeur du premier input text était reprise

        if (displayPasswordChanged) {
            return (
                <Fragment key="frontLoginFormComponent-1">
                    <SuccessMessageComponent message="Vous avez défini un nouveau mot de passe. Vous pouvez maintenant vous identifier." />
                    <VerticalSpacing height={"25px"} />
                    <LinkComponent target="_blank" href="/" label="Me connecter" icon={Icon.ARROW_RIGHT_LINE} />
                </Fragment>
            )
        } else if (displaySetMotPasse) {
            //console.log("dsmp")
            return (
                <Fragment key="frontLoginFormComponent-2">
                    <br />
                    <TextInputComponent name="change-password" label="Nouveau mot de passe" id="account-change-password" password />
                    <TextInputComponent name="confirm-change-password" label="Confirmation du mot de passe" id="account-confirm-change-password" password />
                    {this.getErrorMessageView()}
                    <Button key={"test"} onClick={() => this.changePassword()} style={{ width: "100%" }}>
                        ENREGISTRER
                    </Button>
                </Fragment>
            )
        } else if (displayCodeActivationToChangePassword) {
            //console.log("dcatcp")
            return (
                <Fragment key="frontLoginFormComponent-3">
                    <br />
                    <TextInputComponent name="code-activation" label="Code d'activation reçu par email" id="account-activation-code-3" />
                    {this.getErrorMessageView()}
                    <Button key={"pomme"} onClick={() => this.validAccountToChangePassword()} style={{ width: "100%" }}>
                        VALIDER
                    </Button>
                </Fragment>
            )
        } else if (displayCodeActivation) {
            //console.log("dca")
            return (
                <Fragment key="frontLoginFormComponent-4">
                    <div style={{ padding: "10px" }}>
                        <TextInputComponent name="code-activation" label="Code d'activation" id="account-activation-code-2" />
                        {this.getErrorMessageView()}
                        <Button onClick={() => this.validAccount()} style={{ width: "100%" }}>
                            VALIDER
                        </Button>
                    </div>
                </Fragment>
            )
        } else if (displayMotPasseOublie) {
            //console.log("dmpo")
            return (
                <Fragment key="frontLoginFormComponent-5">
                    <div style={{ padding: "10px" }}>
                        <TextInputComponent label="Email" id="login-forgot-password" />
                        <Button onClick={() => this.sendEmailForgotPassword()} style={{ width: "100%" }}>
                            Recevoir mon code
                        </Button>
                        {this.getErrorMessageView()}
                    </div>
                </Fragment>
            )
        } else {
            //console.log("default")
            return (
                <Fragment key="frontLoginFormComponent-5">
                    <div style={{ padding: "10px" }}>
                        <TextInputComponent label="Email" id="account-login-email" />
                        <TextInputComponent label="Mot de passe" id="account-login-password" password />
                        {this.getSuccessMessageView()}
                        {this.getErrorMessageView()}
                        <Button onClick={() => this.doLogin()} style={{ width: "100%" }}>
                            SE CONNECTER
                        </Button>
                    </div>
                    <div style={{ padding: "10px", textAlign: "center" }}>
                        <a href="#" onClick={() => this.applyMotPasseOublie()}>
                            Mot de passe oublié
                        </a>
                    </div>
                </Fragment>
            )
        }
    }

    render() {
        return (
            <Fragment>
                <HeadBand6Component label="CONNEXION" style={{ background: Constant.COLOR.LIGHT_RED_MARIANNE, color: Constant.COLOR.RED_MARIANNE }} center />
                {this.getContent()}
            </Fragment>
        )
    }
}
