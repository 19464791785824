import React from "react"
import ReactDOM from "react-dom"

import BackOfficeMainPage from "./fr/gouv/dnum/accolade/back/main/BackOfficeMainPage"
import EspaceFinanceurMainPage from "./fr/gouv/dnum/accolade/espacefinanceur/main/EspaceFinanceurMainPage"
import FrontMainPage from "./fr/gouv/dnum/accolade/front/main/FrontMainPage"

import Page404 from "./Page404"
import "./index.css"

ReactDOM.render(<React.StrictMode>{getContent()}</React.StrictMode>, document.getElementById("root"))

function getContent() {
    if (window.location.pathname === "" || window.location.pathname === "/" || window.location.pathname === "/accolade" || window.location.pathname === "/accolade/") {
        return <FrontMainPage />
    } else if (window.location.pathname.endsWith("/admin")) {
        return <BackOfficeMainPage />
    } else if (window.location.pathname.endsWith("/espace_financeur")) {
        return <EspaceFinanceurMainPage />
    } else {
        return <Page404 />
    }
}
