import React, { MouseEventHandler } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface CardVerticalProps extends DnumProps {
    title?: string
    image?: string
    description?: string
    smallLabel?: string
    onClick?: MouseEventHandler
}

export default class CardVerticalComponent extends DnumComponent<CardVerticalProps, any> {
    constructor(props: CardVerticalProps) {
        super(props)
    }

    getTitle() {
        if (this.props.title) {
            return (
                <h4 className="fr-card__title">
                    <a href="#link" className="fr-card__link">
                        {this.props.title}
                    </a>
                </h4>
            )
        }
    }

    getImage() {
        if (this.props.image) {
            return (
                <div className="fr-card__img">
                    <img src={this.props.image} className="fr-responsive-img" alt="" />
                </div>
            )
        }
    }

    getDescription() {
        if (this.props.description) {
            return <p className="fr-card__desc">{this.props.description}</p>
        }
    }

    getSmallLabel() {
        if (this.props.smallLabel) {
            return <p className="fr-card__detail">{this.props.smallLabel}</p>
        }
    }

    render() {
        return (
            <div role="link" tabIndex={0} className="fr-card fr-enlarge-link" onClick={this.props.onClick}>
                <div className="fr-card__body">
                    {this.getTitle()}
                    {this.getDescription()}
                    {this.getSmallLabel()}
                </div>
                {this.getImage()}
            </div>
        )
    }
}
