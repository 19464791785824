import React, { Fragment } from "react"

import FinessJuridique from "../../../../model/FinessJuridique"
import IdentifiantConventionCollective from "../../../../model/IdentifiantConventionCollective"

import { DnumProps } from "../../../../../generic/component/DnumComponent"
import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"
import SelectComponent from "../../../../../generic/component/select/SelectComponent"
import SelectOptionData from "../../../../../generic/component/select/SelectOptionData"

import HttpAssociationAccountService from "../../../../service/http/HttpAssociationAccountService"
import Response from "../../../../service/http/response/Response"
import FrontComponent from "../../../FrontComponent"
import FrontAccountFinessUpdateMessageComponent from "../FrontAccountFinessUpdateMessageComponent"

interface FrontAccountFinessJuridiqueProps extends DnumProps {
    finessJuridique: FinessJuridique | null
    idccs: IdentifiantConventionCollective[] | undefined | null
    idcc: IdentifiantConventionCollective | undefined | null
}

export default class FrontAccountFinessJuridiqueComponent extends FrontComponent<FrontAccountFinessJuridiqueProps, any> {
    getOptions(): SelectOptionData[] {
        const data: SelectOptionData[] = []
        this.props.idccs?.forEach((idcc) => {
            data.push(new SelectOptionData(idcc.code!.toString(), idcc.code + " " + idcc.label, idcc.code == this.props.idcc?.code))
        })
        return data
    }

    getIdccLine() {
        if (this.props.idcc?.code === "9999") {
            return (
                <RowComponent>
                    <ColumnComponent size={2}>
                        <br />
                        IDCC :
                    </ColumnComponent>
                    <ColumnComponent size={9}>
                        <SelectComponent label={""} onChange={(event) => this.onChangeIdcc(event)} value={this.props.idcc?.code ?? "9999"} data={this.getOptions()} />
                    </ColumnComponent>
                </RowComponent>
            )
        } else {
            return (
                <RowComponent>
                    <ColumnComponent size={2}>IDCC :</ColumnComponent>
                    <ColumnComponent size={9}>{this.props.idcc?.label}</ColumnComponent>
                </RowComponent>
            )
        }
    }

    onChangeIdcc(event: React.ChangeEvent<HTMLSelectElement>) {
        const response = new HttpAssociationAccountService().updateIdcc(event.target.value)

        response.then((frontResponse) => {
            const cast = new Response(frontResponse)

            if (cast.success) {
                if (event.target.value === "9999") {
                    // nothing to do
                } else {
                    const element = document.querySelector("#manage-idcc-undefined") as HTMLElement
                    if (element != null) {
                        element.style.display = "none"
                    }
                }
            }
        })
    }

    render() {
        return (
            <Fragment>
                <FrontAccountFinessUpdateMessageComponent />
                <RowComponent>
                    <ColumnComponent size={2}>Raison sociale :</ColumnComponent>
                    <ColumnComponent size={9}>{this.props.finessJuridique?.raisonSociale}</ColumnComponent>
                </RowComponent>
                <br />
                <RowComponent>
                    <ColumnComponent size={2}>Numéro FINESS :</ColumnComponent>
                    <ColumnComponent size={9}>{this.props.finessJuridique?.numeroFinessEntiteJuridique}</ColumnComponent>
                </RowComponent>
                <br />
                <RowComponent>
                    <ColumnComponent size={2}>Numéro SIREN :</ColumnComponent>
                    <ColumnComponent size={9}>{this.props.finessJuridique?.siren}</ColumnComponent>
                </RowComponent>
                <br />
                {this.getIdccLine()}
            </Fragment>
        )
    }
}
