import BackAccount from "../../model/BackAccount"
import FinanceurSettings from "../../model/FinanceurSettings"

import BackOfficeLoginForm from "../../back/login/BackOfficeLoginForm"
import HttpService from "./HttpService"
import Response from "./response/Response"
import PagerResponse from "../../shared/dto/PagerResponse";

export default class HttpBackAccountService extends HttpService {
    BACK_ACCOUNT_URI = "/back/admin/account"

    BACK_ACCOUNT_LOGIN_URI = "/back/login"

    BACK_CONNECTED_URI = "/back/status"

    BACK_FINANCEUR_SETTINGS_URI = "/back/financeur/settings"

    getListAssociationAccount(page: number, size: number, filter: string | null) {
        return this.request<Response<PagerResponse<BackAccount[]>>>(this.BACK_ACCOUNT_URI + "/association?"+this.getParams(page, size, filter), {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    getListFinanceurAccount(page: number, size: number, filter: string | null) {
        return this.request<Response<PagerResponse<BackAccount[]>>>(this.BACK_ACCOUNT_URI + "/financeur?"+this.getParams(page, size, filter), {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    getListMembreCnaAccount(page: number, size: number, filter: string | null) {
        return this.request<Response<PagerResponse<BackAccount[]>>>(this.BACK_ACCOUNT_URI + "/membre_cna?"+this.getParams(page, size, filter), {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    getParams(page: number, size: number, filter: string | null): string {
        let params = "page="+page+"&size="+size;
        if (filter!=null) {
            params += "&filter="+filter
        }
        return params
    }

    changeAccountPassword(accountId: number, accountType: string) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.request<Response<string[]>>(this.BACK_ACCOUNT_URI + "/" + accountType + "/change-password?id=" + accountId, {
            method: "PUT"
        }).then((response) => this.backSessionExpired(response))
    }

    changeAccountStatut(email: string, accountType: string, active: boolean) {
        return this.request<Response<boolean>>(this.BACK_ACCOUNT_URI + "/" + accountType + "/change-statut?email=" + email + "&active=" + active, {
            method: "PUT"
        }).then((response) => this.backSessionExpired(response))
    }

    login(form: BackOfficeLoginForm) {
        return this.request<Response<any>>(this.BACK_ACCOUNT_LOGIN_URI, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(form)
        })
    }

    isConnected() {
        return this.request<Response<boolean>>(this.BACK_CONNECTED_URI, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    getFinanceurSettings() {
        return this.request<Response<FinanceurSettings>>(this.BACK_FINANCEUR_SETTINGS_URI, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        })
    }

    updateFinanceurSettings(ids: number[]) {
        return this.request<Response<boolean>>(this.BACK_FINANCEUR_SETTINGS_URI + "/type_etablissement", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(ids)
        })
    }
}
