import HttpReferentielService from "./HttpReferentielService"
import Response from "./response/Response"

export default class HttpMembreCnaService extends HttpReferentielService {
    constructor() {
        super()
        this.uri = this.MEMBRE_CNA_URI
    }

    public changePassword(membreCnaId: number) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.request<Response<string[]>>(this.uri! + "/change-password?id=" + membreCnaId, { method: "PUT" })
    }
}
