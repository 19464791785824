import { ChangeEvent, ReactElement } from "react";
import Button from "../../../generic/component/button/Button";
import HttpBackMaintenanceService from "../../service/http/HttpBackMaintenanceService";
import ControlledTextInputComponent from "../../../generic/component/form/input/ControlledTextInputComponent";
import SecondaryContentBoxComponent from "../../shared/component/box/SecondaryContentBoxComponent";
import { Constant } from "../../constant/Constant";
import BackOfficeComponent from "../BackOfficeComponent";
import ButtonGroupHorizontal from "../../../generic/component/button/group/ButtonGroupHorizontal";
import AlertErrorComponent from "../../../generic/component/alert/AlertErrorComponent";
import AlertSuccessComponent from "../../../generic/component/alert/AlertSuccessComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import LoadingComponent from "../../../generic/component/loading/LoadingComponent";

interface BackOfficeMaintenanceComponentProps {
    email: ReactElement | null
    finessNumber: string
    message: ReactElement |null
}

export default class BackOfficeMaintenanceWithFinessEJComponent extends BackOfficeComponent<any ,BackOfficeMaintenanceComponentProps> {

    RAFRAICHIR_SUCCESS_MESSAGE = "Les données ont bien été rafraichies"
    RAFRAICHIR_ERROR_MESSAGE = "Erreur : les données n'ont pas été rafraichies"
    NUM_FINESS_ERROR_MESSAGE = "Veuillez entrer un numéro"
    NUM_FINESS_INEXISTANT_ERROR_MESSAGE ="Le numéro saisi est inexistant"

    protected constructor(props: any) {
        super(props)
        this.state = {email: null, finessNumber: "", message: null}
    }

    getId(): string {
        return Constant.DOM.BACK_OFFICE.MAINTENANCE_MENU;
    }

    getEmailFromFiness():void{
        new HttpBackMaintenanceService().getEmailFromFiness(this.state.finessNumber).then((response: string) => {
            if(this.state.finessNumber == ""){
                this.setState({email: null, finessNumber:"", message: <AlertErrorComponent description={this.NUM_FINESS_ERROR_MESSAGE}></AlertErrorComponent>})
            }
            else if (response == "ERREUR"){
                this.setState({email: null, finessNumber:"", message: <AlertErrorComponent description={this.NUM_FINESS_ERROR_MESSAGE + " valide"}></AlertErrorComponent>})
            }
            else {
                this.setState({ email: <AlertSuccessComponent description={"Email : " + response}></AlertSuccessComponent>, finessNumber:"", message: null})
            }
        })
    }

    isFinesseEJ():boolean{
        let condition = false
        for (let i=0;i<this.state.finessNumber.length; i++){
            const code = this.state.finessNumber.charCodeAt(i)
            if (!(code >= 65 && code <= 90) && !(code >= 97 && code <= 122)){
                condition = true
            }
            else{
                condition = false
            }
        }
        return condition
    }

    refreshFinessData():void{
        if (this.isFinesseEJ() === true){
            this.setState({message: <LoadingComponent/>})
            new HttpBackMaintenanceService().refreshFinessData(this.state.finessNumber).then((response: string) => {
                if(response == "OK"){
                    this.setState({email: null, finessNumber:"", message: <AlertSuccessComponent description={this.RAFRAICHIR_SUCCESS_MESSAGE}></AlertSuccessComponent>})
                }
                else if (response == "KO") {
                    this.setState({email: null, finessNumber:"", message: <AlertErrorComponent description={this.NUM_FINESS_INEXISTANT_ERROR_MESSAGE}></AlertErrorComponent>})

                }
                else {
                    this.setState({email: null, finessNumber:"", message: <AlertErrorComponent description={this.RAFRAICHIR_ERROR_MESSAGE}></AlertErrorComponent>})
                }
            })
        }
        else if(this.state.finessNumber == ""){
            this.setState({email: null, finessNumber:"", message: <AlertErrorComponent description={this.NUM_FINESS_ERROR_MESSAGE}></AlertErrorComponent>})
        }
        else {
            this.setState({email: null, finessNumber:"", message: <AlertErrorComponent description={this.NUM_FINESS_ERROR_MESSAGE + " valide"}></AlertErrorComponent>})
        }
    }


    render():ReactElement{
        return (
                    <SecondaryContentBoxComponent title={"Par rapport au FINESS juridique"}>
                        <ControlledTextInputComponent
                            label={"FINESS juridique :"}
                            id="finesseEJ"
                            value={this.state.finessNumber}
                            onChangeHandler={(event: ChangeEvent<HTMLInputElement>) => this.setState({...this.state, finessNumber: event.target.value})}
                        />
                        <div>
                            {this.state.email}
                            {this.state.message}
                        </div><br/>
                        <ButtonGroupHorizontal >
                            <Button onClick={() => this.getEmailFromFiness()}>
                                Rechercher un email
                            </Button>
                            <Button onClick={() => this.refreshFinessData()}>
                                Rafraîchir les données (EJ + ETs)
                            </Button>
                        </ButtonGroupHorizontal>
                    </SecondaryContentBoxComponent>
        )
    }
}
