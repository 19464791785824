import { ReactElement } from "react"

import TypeAccord from "../../../model/TypeAccord"

import Button from "../../../../generic/component/button/Button"
import TextInputComponent from "../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../generic/component/grid/column/ColumnComponent"
import RowComponent from "../../../../generic/component/grid/row/RowComponent"

import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielFormComponent from "../BackOfficeAbstractReferentielFormComponent"

export default class BackOfficeTypeAccordFormComponent extends BackOfficeAbstractReferentielFormComponent<TypeAccord, null> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().useTypeAccordService()
    }

    formToObject(id: number): TypeAccord {
        const typeAccord = new TypeAccord(this.props.objectId)
        typeAccord.code = (document.querySelector("#type-accord-code") as HTMLInputElement).value
        typeAccord.libelle = (document.querySelector("#type-accord-libelle") as HTMLInputElement).value
        typeAccord.active = (document.querySelector("input[name='type-accord-actif']") as HTMLInputElement).checked
        return typeAccord
    }

    getActiveCheckbox(): ReactElement {
        const condition = this.state && this.state.item && this.state.item.active
        return this.getCheckbox("type-accord-actif", "Actif", condition!)
    }

    getContent(): ReactElement | undefined {
        const create = this.props.objectId == null
        const edit = this.props.objectId !== null && this.state && this.state.item !== null
        if (create || edit) {
            return (
                <>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Code"
                                id="type-accord-code"
                                value={this.getProperty("code")}
                                error={this.state?.errors?.has("code")}
                                errorMessage={this.state?.errors?.get("code")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>
                            <TextInputComponent
                                label="Libellé"
                                id="type-accord-libelle"
                                value={this.getProperty("libelle")}
                                error={this.state?.errors?.has("libelle")}
                                errorMessage={this.state?.errors?.get("libelle")}
                            />
                        </ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={1} />
                        <ColumnComponent size={10}>{this.getActiveCheckbox()}</ColumnComponent>
                        <ColumnComponent size={1} />
                    </RowComponent>
                    <RowComponent style={{ marginBottom: "24px" }}>
                        <ColumnComponent size={4} />
                        <ColumnComponent size={2}>
                            <Button onClick={() => this.save()}>Enregistrer</Button>
                        </ColumnComponent>
                        <ColumnComponent middle size={2}>
                            <Button secondary onClick={() => this.props.showListHandler()}>
                                Annuler
                            </Button>
                        </ColumnComponent>
                        <ColumnComponent size={4} />
                    </RowComponent>
                </>
            )
        }
    }
}
