import React, { Fragment, ReactElement } from "react"

import zxcvbn from "zxcvbn"

import { Constant } from "../../../../accolade/constant/Constant"

import DnumComponent, { DnumProps } from "../../DnumComponent"
import { DnumErrorHandlerProps } from "../../DnumErrorHandlerProps"
import ErrorMessageComponent from "../../message/ErrorMessageComponent"
import TextInputDataListItem from "./TextInputDataListItem"

interface PasswordInputState {
    inputValue: string | undefined
}

interface PasswordInputProps extends DnumProps, DnumErrorHandlerProps {
    label: string
    id: string
    description?: string
    password?: any
    icon?: null
    value?: string
    readonly?: any
    placeholder?: string
    name?: string
    dataList?: TextInputDataListItem[]
    valid?: string | any
}

export default class PasswordInputComponent extends DnumComponent<PasswordInputProps, PasswordInputState> {
    static MIN = 0.0
    static MAX = 12.0
    static OPTIMUM_SCORE = 12
    static HIGH_SCORE = 11
    static LOW_SCORE = 7
    static SPECIAL_CHARS = "!@#$%^&*"
    static PASSWORD_COMPLEXITY: ReactElement = (
        <Fragment>
            <span>
                De 12 à 30 caractères le mot de passe doit contenir au moins :<br />
                <ul style={{ marginLeft: "180px" }}>
                    <li>un caractère spécial parmi {PasswordInputComponent.SPECIAL_CHARS}</li>
                    <li>un caractère majuscule</li>
                    <li>un caractère minuscule</li>
                    <li>un chiffre</li>
                </ul>
            </span>
        </Fragment>
    )

    private className = "fr-input-wrap"
    private textClassName = "fr-label"
    private htmlFor = "text-input-icon"

    constructor(props: PasswordInputProps) {
        super(props)
        this.state = {
            inputValue: undefined
        }
    }

    getTexts() {
        if (this.props.error == true) {
            this.htmlFor = "text-input-error"
        } else if (this.props.valid) {
            this.htmlFor = "text-input-valid"
        }

        if (this.props.description) {
            if (this.props.label) {
                return (
                    <label className={this.textClassName} htmlFor={this.htmlFor}>
                        {this.props.label}
                        <span className="fr-hint-text">{this.props.description}</span>
                    </label>
                )
            }
            return (
                <label className={this.textClassName} htmlFor={this.htmlFor}>
                    <span className="fr-hint-text">{this.props.description}</span>
                </label>
            )
        } else {
            return (
                <label className={this.textClassName} htmlFor={this.htmlFor}>
                    {this.props.label}
                </label>
            )
        }
    }

    getPasswordResult() {
        const score = this.getScore()
        return (
            <Fragment>
                <meter
                    id="progressBar"
                    style={{ width: "100%", margin: "0px" }}
                    min={PasswordInputComponent.MIN}
                    max={PasswordInputComponent.MAX}
                    low={PasswordInputComponent.LOW_SCORE}
                    high={PasswordInputComponent.HIGH_SCORE}
                    optimum={PasswordInputComponent.OPTIMUM_SCORE}
                    value={score}></meter>
                {this.getPasswordResultMessage(score)}
            </Fragment>
        )
    }

    getPasswordResultMessage(score: number): ReactElement | undefined {
        if (score > 0 && score < 12) {
            return <ErrorMessageComponent htmlMessage={PasswordInputComponent.PASSWORD_COMPLEXITY} />
        }
    }

    getScore(): number {
        if (this.state) {
            if (this.state.inputValue == null) {
                return 0
            }
            let score = 0
            if (this.state.inputValue) {
                const pwd = this.state.inputValue
                // longueur => max score = +[5]
                switch (pwd.length) {
                    case 0:
                    case 1: {
                        score += 0
                        break
                    }
                    case 2:
                    case 3: {
                        score += 1
                        break
                    }
                    case 4:
                    case 5: {
                        score += 2
                        break
                    }
                    case 6:
                    case 7:
                    case 8: {
                        score += 3
                        break
                    }
                    case 9:
                    case 10:
                    case 11: {
                        score += 4
                        break
                    }
                    default: {
                        score += 5
                    }
                }

                //console.log(pwd.length+" => "+score)

                // lowercase => score +[1]
                if (pwd.match("(?=.*[a-z])")) {
                    score += 1
                    console.debug("lowercase +1")
                }
                // uppercase => score +[1]
                if (pwd.match("(?=.*[A-Z])")) {
                    score += 1
                    console.debug("uppercase +1")
                }
                // numeric => score +[1]
                if (pwd.match("(?=.*\\d)")) {
                    score += 1
                    console.debug("numeric +1")
                }
                // special char +[1]
                const regex = "[" + PasswordInputComponent.SPECIAL_CHARS + "]"
                if (this.state.inputValue.match(regex)) {
                    score += 1
                    console.debug("special +1")
                }
                // score zxcvbn score +[3]
                const scoreZxcvbn = zxcvbn(this.state.inputValue).score
                switch (scoreZxcvbn) {
                    case 0:
                    case 1:
                    case 2: {
                        score += 1
                        console.debug("zxcvbn +1")
                        break
                    }
                    case 3: {
                        score += 2
                        console.debug("zxcvbn +2")
                        break
                    }
                    default: {
                        score += 3
                        console.debug("zxcvbn +3")
                    }
                }
            }
            return score
        } else {
            return 0
        }
    }

    onChange: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
        this.setState({ inputValue: event.currentTarget.value })
    }

    getDataList(id: string) {
        if (this.props.dataList && this.props.dataList.length > 0) {
            const list: ReactElement[] = []
            this.props.dataList.forEach(function (item: TextInputDataListItem) {
                list.push(<option key={item.label!} label={item.label!} value={item.value!} />)
            })
            return <datalist id={id}>{list}</datalist>
        }
    }

    getInput() {
        let inputClassName = "fr-input"
        let messageAttachedProp = {}
        let dataListProp = {}
        if (this.props.error === true) {
            this.className += " fr-input--error"
            messageAttachedProp = { "aria-describedby": "text-input-error-desc-error" }
            inputClassName += " fr-input--error"
        } else if (this.props.valid) {
            this.className += " fr-input--valid"
            messageAttachedProp = { "aria-describedby": "text-input-valid-desc-valid" }
            inputClassName += " fr-input--valid"
        }

        const dataListId = this.generateId("data-list")
        if (this.props.dataList) {
            dataListProp = { list: dataListId }
        }

        let name = "text-input-icon"
        if (this.props.name) {
            name = this.props.name
        }

        if (this.props.icon) {
            // TODO change defaut icone
            this.className += ` ${Constant.ICON.EDIT_LINE}`
        }

        return (
            <div className={this.className}>
                <input
                    placeholder={this.props.placeholder}
                    className={inputClassName}
                    autoComplete="off"
                    autoCorrect="off"
                    aria-autocomplete="none"
                    type="password"
                    id={this.props.id}
                    readOnly={this.props.readonly != null}
                    {...messageAttachedProp}
                    {...dataListProp}
                    defaultValue=""
                    name={name}
                    onChange={this.onChange}
                    value={this.state.inputValue}
                    maxLength={30}
                />
                {this.getDataList(dataListId)}
            </div>
        )
    }

    render() {
        if (this.props.error == true) {
            return (
                <div className="fr-input-group fr-input-group--error">
                    {this.getTexts()}
                    {this.getInput()}
                    {this.getPasswordResult()}
                </div>
            )
        } else if (this.props.valid) {
            return (
                <div className="fr-input-group fr-input-group--valid">
                    {this.getTexts()}
                    {this.getInput()}
                    {this.getPasswordResult()}
                </div>
            )
        } else {
            return (
                <div className="fr-input-group">
                    {this.getTexts()}
                    {this.getInput()}
                    {this.getPasswordResult()}
                </div>
            )
        }
    }
}
