import PersistentObject from "./PersistentObject"

export default class TypeFinanceur implements PersistentObject {
    id: number | null = null
    nom: string | null = null
    system: boolean | null = null

    constructor(id: number | null | any) {
        this.id = id
    }
}
