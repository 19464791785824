export default class IdentifiantConventionCollective {
    id: number | null = null
    label: string | null = null
    code: string | null = null
    active: boolean | null = null

    constructor(id: number | null | any) {
        this.id = id
    }
}
