export default class KeyFront {
    static DEMANDE_ID_PARAM = "demandeIdParam"

    static EDITION_PARAM = "editionModeParam"

    static DEMANDE_OBJECT_PARAM = "demandeObjectParam"

    static FILE_ACCORD_DUE_PARAM = "fileAccordDueParam"

    static OTHERS_FILES_PARAM = "othersFilesParam"

    static ACCOUNT_INFORMATIONS_PARAM = "accountInformationsParam"
}
