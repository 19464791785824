import React from "react"

import PieceExigible from "../../../model/PieceExigible"

import Button from "../../../../generic/component/button/Button"

import { Constant } from "../../../constant/Constant"
import HttpReferentielService from "../../../service/http/HttpReferentielService"
import BackOfficeAbstractReferentielListComponent from "../BackOfficeAbstractReferentielListComponent"
import BackOfficeModalDeleteComponent from "../BackOfficeModalDeleteComponent"

export default class BackOfficePieceExigibleListComponent extends BackOfficeAbstractReferentielListComponent<PieceExigible> {
    getHttpReferentielService(): HttpReferentielService {
        return new HttpReferentielService().usePieceExigibleService()
    }

    getAddLabel(): string {
        return "Ajouter une pièce"
    }

    getThead(): React.ReactElement {
        return (
            <thead style={{ width: "100%" }}>
                <tr>
                    <th scope="col" style={{ width: "5%" }}></th>
                    <th scope="col" style={{ width: "15%" }}>
                        Code
                    </th>
                    <th scope="col" style={{ width: "50%" }}>
                        Libellé
                    </th>
                    <th scope="col" style={{ width: "30%" }}>
                        Actions
                    </th>
                </tr>
            </thead>
        )
    }

    getLine(item: PieceExigible, index: number): React.ReactElement {
        return (
            <tr key={"row-piece-exigible-" + item.id}>
                <td>{this.getStatutBar(item.active!)}</td>
                <td>{item.code}</td>
                <td>{item.libelle}</td>
                <td>
                    <Button iconOnly={Constant.ICON.EDIT_LINE} onClick={() => this.props.editButtonHandler(item.id)}>
                        Edit
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button secondary iconOnly={Constant.ICON.DELETE_LINE} modal={"piece-exigible-" + item.id}>
                        Supprimer
                    </Button>
                    <BackOfficeModalDeleteComponent
                        id={"piece-exigible-" + item.id}
                        title={"Suppression de la pièce exigible : " + item.code}
                        message={"Vous confirmez la suppression de cette pièce exigible ?"}
                        deleteButtonHandler={() => {
                            this.delete(item.id!)
                        }}
                    />
                </td>
            </tr>
        )
    }
}
