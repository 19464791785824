export default class VerticalMenuItemData {
    public label: string | null = null
    public id?: string | null = null
    public clickable?: boolean | null

    constructor(label: string, id?: string, clickable?: boolean) {
        this.label = label
        this.id = id
        this.clickable = clickable
    }
}
