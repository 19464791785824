import React, { Fragment } from "react"

import ColumnComponent from "../../../generic/component/grid/column/ColumnComponent"
import ContainerComponent from "../../../generic/component/grid/container/ContainerComponent"
import { PageProps } from "../../../generic/component/grid/page/PageComponent"
import RowComponent from "../../../generic/component/grid/row/RowComponent"

import { Constant } from "../../constant/Constant"
import PrimaryContentBoxComponent from "../../shared/component/box/PrimaryContentBoxComponent"
import BackOfficePage from "../BackOfficePage"
import BackOfficeInformationPanelComponent from "./BackOfficeInformationPanelComponent"
import BackOfficeLoginFormComponent from "./BackOfficeLoginFormComponent"

export default class BackOfficeLoginPage extends BackOfficePage<PageProps, any> {
    render() {
        return (
            <Fragment>
                <ContainerComponent id={Constant.DOM.BACK_OFFICE.LOGIN_PAGE}>
                    <PrimaryContentBoxComponent title={`Bienvenue sur ${Constant.TEXT.BACK_OFFICE.APPLICATION_TITLE}`}>
                        <RowComponent>
                            <ColumnComponent size={8}>
                                <BackOfficeInformationPanelComponent />
                            </ColumnComponent>
                            <ColumnComponent size={4}>
                                <RowComponent center>
                                    <ColumnComponent size={2} />
                                    <ColumnComponent size={9}>
                                        <ContainerComponent style={{ border: "1px dotted" }}>
                                            <BackOfficeLoginFormComponent />
                                        </ContainerComponent>
                                    </ColumnComponent>
                                    <ColumnComponent size={1} />
                                </RowComponent>
                            </ColumnComponent>
                        </RowComponent>
                    </PrimaryContentBoxComponent>
                </ContainerComponent>
            </Fragment>
        )
    }
}
