import React, { ChangeEvent, ReactElement } from "react"
import ReactTooltip from "react-tooltip"

import { decode } from "html-entities"

import FinessEtablissement from "../../../../model/FinessEtablissement"
import TypeAccord from "../../../../model/TypeAccord"

import DateInputComponent from "../../../../../generic/component/form/input/DateInputComponent"
import TextInputComponent from "../../../../../generic/component/form/input/TextInputComponent"
import ColumnComponent from "../../../../../generic/component/grid/column/ColumnComponent"
import ContainerComponent from "../../../../../generic/component/grid/container/ContainerComponent"
import RowComponent from "../../../../../generic/component/grid/row/RowComponent"
import RadioButtonData from "../../../../../generic/component/radio/RadioButtonData"
import RadioButtonGroupComponent from "../../../../../generic/component/radio/RadioButtonGroupComponent"

import { Constant } from "../../../../constant/Constant"
import UiService from "../../../../service/ui/UiService"
import SecondaryContentBoxComponent from "../../../../shared/component/box/SecondaryContentBoxComponent"
import FrontComponent from "../../../FrontComponent"
import FrontDemandeAgrementFormStepProps from "../FrontDemandeAgrementFormStepProps"

interface FrontDemandeAgrementFormStep0AState {
    typesAccord: TypeAccord[] | undefined
    typeDemande: string | undefined
}

interface FrontDemandeAgrementFormStep0AProps extends FrontDemandeAgrementFormStepProps {
    onTypeDemandeSelected: (event: ChangeEvent<HTMLInputElement>) => void
}

export default class FrontDemandeAgrementFormStep0AComponent extends FrontComponent<FrontDemandeAgrementFormStep0AProps, FrontDemandeAgrementFormStep0AState> {
    constructor(props: FrontDemandeAgrementFormStep0AProps) {
        super(props)
        this.state = {
            typesAccord: undefined,
            typeDemande: undefined
        }
    }

    getEtablissementsConcernes() {
        const concernes: ReactElement[] = []

        this.props.demandeInProgress?.etablissements?.forEach((etablissement: FinessEtablissement, indexObject: number) => {
            concernes.push(
                <li key={"concerne-" + etablissement.id}>
                    <div style={{ paddingTop: "15px" }}>
                        {etablissement.categorieLibelle}
                        <br />
                        {etablissement.raisonSociale}
                        <br />
                        SIRET : {etablissement.siret ?? "Non renseigné"}
                    </div>
                </li>
            )
        })

        return concernes
    }

    getTitleEtablissementsConcernes() {
        const count: number = this.props.demandeInProgress?.etablissements?.length ?? 0
        if (count > 1) {
            return <span>{count} établissements concernés : </span>
        } else if (count == 1) {
            return <span>1 établissement concerné : </span>
        }
    }

    setLocalTypeDemande(event: ChangeEvent<HTMLInputElement>) {
        this.props.onTypeDemandeSelected(event)
        this.setState({ typeDemande: event.currentTarget.value })
    }

    getSecondLineForm() {
        const label = (
            <div>
                N° de dossier <span id="info-numero_daccord" data-html={true} data-tip={"Reprise du numéro Téléaccord"} className={Constant.ICON.INFO_LINE} aria-hidden={true}></span>
            </div>
        )
        if ((this.state.typeDemande == null && this.props.demandeInProgress?.typeDemande === "ACCORD") || (this.state && this.state.typeDemande === "ACCORD")) {
            return (
                <RowComponent style={{ marginBottom: "34px" }}>
                    <ColumnComponent size={5}>
                        <TextInputComponent
                            label={label}
                            id="numero-dossier"
                            value={decode(this.props.demandeInProgress?.numeroDossier)}
                            errorMessage={this.props.errors?.get("numero-dossier")}
                            error={this.props.errors?.has("numero-dossier")}
                        />
                        <ReactTooltip />
                    </ColumnComponent>
                    <ColumnComponent size={2}></ColumnComponent>
                    <ColumnComponent size={5}>
                        <DateInputComponent
                            label="Date de dépôt"
                            id="date-depot"
                            errorMessage={this.props.errors?.get("date-depot")}
                            error={this.props.errors?.has("date-depot")}
                            value={UiService.formatServerDateToDatePicker(this.props.demandeInProgress?.dateDepot)}
                        />
                    </ColumnComponent>
                </RowComponent>
            )
        }
    }

    render() {
        const radios: RadioButtonData[] = [
            new RadioButtonData("ACCORD", "Accord collectif / d'entreprise", "ACCORD" === this.props.demandeInProgress?.typeDemande, "typeDemande", "ACCORD"),
            new RadioButtonData("DUE", "Décision unilatérale de l'employeur", "DUE" === this.props.demandeInProgress?.typeDemande, "typeDemande", "DUE")
        ]

        return (
            <>
                <SecondaryContentBoxComponent title="Données générales de l'accord">
                    <ContainerComponent>
                        <RowComponent style={{ marginBottom: "24px" }}>
                            <ColumnComponent size={12}>
                                <RadioButtonGroupComponent
                                    data={radios}
                                    onChange={(event) => this.setLocalTypeDemande(event)}
                                    label={"Type de demande"}
                                    errorMessage={this.props.errors?.get("type-demande")}
                                    error={this.props.errors?.has("type-demande")}
                                    id="type-demande"
                                />
                            </ColumnComponent>
                        </RowComponent>
                        {this.getSecondLineForm()}
                        <RowComponent style={{ marginBottom: "34px" }}>
                            <ColumnComponent size={5}>
                                <DateInputComponent
                                    label="Date de signature"
                                    id="date-signature"
                                    errorMessage={this.props.errors?.get("date-signature")}
                                    error={this.props.errors?.has("date-signature")}
                                    value={UiService.formatServerDateToDatePicker(this.props.demandeInProgress?.dateSignature)}
                                />
                            </ColumnComponent>
                            <ColumnComponent size={2}></ColumnComponent>
                            <ColumnComponent size={5}></ColumnComponent>
                        </RowComponent>
                        <RowComponent style={{ marginBottom: "24px" }}>
                            <ColumnComponent size={12}>
                                <span>Convention collective appliquée : {this.props.informations?.idcc?.label?.toString()}</span>
                            </ColumnComponent>
                        </RowComponent>
                        <RowComponent>
                            <ColumnComponent size={12}>
                                <RowComponent>
                                    <ColumnComponent size={12}>{this.getTitleEtablissementsConcernes()}</ColumnComponent>
                                </RowComponent>
                                <ul style={{ listStyleType: "square" }}>{this.getEtablissementsConcernes()}</ul>
                            </ColumnComponent>
                        </RowComponent>
                    </ContainerComponent>
                </SecondaryContentBoxComponent>
            </>
        )
    }
}
