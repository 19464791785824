import React, { Component, ReactElement } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"

interface HeaderProps extends DnumProps {
    siteName: string | ReactElement
    siteDetails?: string
    headContentLinks?: string
    contentLinks?: Component[]
    toolLinks?: Element[]
    navLinks?: Component[]
    marianneText?: string
    marianneTextFirstPart?: string
    marianneTextSecondPart?: string
}

export default class HeaderComponent extends DnumComponent<HeaderProps, any> {
    constructor(props: HeaderProps) {
        super(props)
    }

    getMarianneText() {
        if (this.props.marianneText) {
            return this.props.marianneText
        }
        return (
            <>
                {this.props.marianneTextFirstPart}
                <br />
                {this.props.marianneTextSecondPart}
            </>
        )
    }

    test() {
        if (this.props.contentLinks) {
            return (
                <div className="fr-footer__content">
                    <p className="fr-footer__content-desc">{this.props.headContentLinks}</p>
                    <ul className="fr-footer__content-list">
                        {this.props.contentLinks.map((item, index) => (
                            <li key={"content-link-" + index} className="fr-footer__content-item">
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
            )
        }
    }

    getToolLinks() {
        if (this.props.toolLinks) {
            return (
                <div className="fr-header__tools">
                    <div className="fr-header__tools-links">
                        <ul className="fr-links-group">
                            {this.props.toolLinks.map((item, index) => (
                                <li key={"tool-link-" + index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )
        }
    }

    getNavLinks() {
        if (this.props.navLinks) {
            const navLinks = []
            for (const link in this.props.navLinks) {
                navLinks.push(<li className="fr-nav__item">{link}</li>)
            }
            return navLinks
        }
    }

    getHomeLink() {
        return window.location.pathname
        /*
        console.log("WLP :: "+window.location.pathname)
        switch (window.location.pathname) {
            case "/sidemag/financeur":
            case "/sidemag":
            case "/sidemag/admin":
                return window.location.pathname
            default:
                return "/"
        }*/
    }

    render() {
        return (
            <header role="banner" className="fr-header">
                <div className="fr-header__body">
                    <div className="fr-container">
                        <div className="fr-header__body-row">
                            <div className="fr-header__brand fr-enlarge-link">
                                <div className="fr-header__brand-top">
                                    <div className="fr-header__logo">
                                        <p className="fr-logo">{this.getMarianneText()}</p>
                                    </div>
                                    <div className="fr-header__navbar">
                                        <button className="fr-btn--menu fr-btn" data-fr-opened="false" aria-controls="modal-833" aria-haspopup="menu" title="Menu" id="fr-btn-menu-mobile">
                                            Menu
                                        </button>
                                    </div>
                                </div>
                                <div className="fr-header__service">
                                    <a href={this.getHomeLink()} title="Accueil - Ministère des Affaires Sociales - Demande d'agrément des accords">
                                        <p className="fr-header__service-title">{this.props.siteName}</p>
                                    </a>
                                    <p className="fr-header__service-tagline">{this.props.siteDetails}</p>
                                </div>
                            </div>
                            <div className="fr-header__tools">
                                <div className="fr-header__tools-links">
                                    <ul className="fr-links-group">{this.getToolLinks()}</ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fr-header__menu fr-modal" id="modal-833" aria-labelledby="fr-btn-menu-mobile">
                    <div className="fr-container">
                        <button className="fr-link--close fr-link" aria-controls="modal-833">
                            Fermer
                        </button>
                        <div className="fr-header__menu-links"></div>
                        <nav className="fr-nav" id="navigation-832" role="navigation" aria-label="Menu principal">
                            <ul className="fr-nav__list">{this.getNavLinks()}</ul>
                        </nav>
                    </div>
                </div>
                {this.props.children}
            </header>
        )
    }
}
