export default class HttpAdresseService {
    serverUrl: string

    constructor() {
        this.serverUrl = "https://api-adresse.data.gouv.fr/search/?q="
    }

    search(adresse: string) {
        return fetch(this.serverUrl + adresse + "&limit=8", {
            method: "GET"
        }).then((response) => {
            if (response.ok) {
                return response.json().then((response) => response)
            } else {
                return response.json().then((error) => error)
            }
        })
    }
}
