import React from "react"

import { Constant } from "../../../constant/Constant"
import BackOfficeAbstractReferentielPage from "../BackOfficeAbstractReferentielPage"
import BackOfficeContactFinanceurFormComponent from "./BackOfficeContactFinanceurFormComponent"
import BackOfficeContactFinanceurListComponent from "./BackOfficeContactFinanceurListComponent"

export default class BackOfficeContactFinanceurPage extends BackOfficeAbstractReferentielPage {
    getObjectTitle(): string {
        return "Contacts des autorités de tarification"
    }

    getFormComponent(itemId: number | null, showListMethod: () => void): React.ReactElement {
        return <BackOfficeContactFinanceurFormComponent objectId={itemId} showListHandler={showListMethod} />
    }

    getListComponent(forceRefresh: string, addButtonHandler: () => void, editButtonHandler: (id: number | null) => void, deleteButtonHandler: (id: number | null) => void): React.ReactElement {
        return (
            <BackOfficeContactFinanceurListComponent forceRefresh={forceRefresh} addButtonHandler={addButtonHandler} editButtonHandler={editButtonHandler} deleteButtonHandler={deleteButtonHandler} />
        )
    }

    getId(): string {
        return Constant.DOM.BACK_OFFICE.REFERENTIEL__CONTACT_FINANCEUR
    }
}
