import React, { ReactElement } from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"
import CheckboxComponent from "./CheckboxComponent"
import CheckboxData from "./CheckboxData"

interface CheckboxGroupProps extends DnumProps {
    legend?: string
    data: CheckboxData[]
    description?: null
    horizontal?: any
    vertical?: any
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    formId?: string
}
export default class CheckboxGroupComponent extends DnumComponent<CheckboxGroupProps, any> {
    constructor(props: CheckboxGroupProps) {
        super(props)
    }

    getCheckboxes(): ReactElement[] {
        const checkboxes: ReactElement[] = []
        this.props.data.map((item: CheckboxData) => {
            checkboxes.push(<CheckboxComponent key={`cb-key-${item.id}`} label={item.label} id={item.id} formId={this.props.formId} checked={item.checked} onChange={this.props.onChange} />)
        })
        return checkboxes
    }

    getLegend() {
        if (this.props.legend) {
            if (this.props.vertical) {
                return (
                    <legend className="fr-fieldset__legend fr-text--regular" id="checkboxes-hint-legend">
                        {this.props.legend}
                        <span className="fr-hint-text">{this.props.description}</span>
                    </legend>
                )
            } else {
                return (
                    <legend className="fr-fieldset__legend fr-text--regular" id="checkboxes-inline-legend">
                        {this.props.legend}
                    </legend>
                )
            }
        }
    }

    render() {
        if (this.props.vertical) {
            return (
                <div className="fr-form-group">
                    <fieldset className="fr-fieldset">
                        {this.getLegend()}
                        <div className="fr-fieldset__content">{this.getCheckboxes()}</div>
                    </fieldset>
                </div>
            )
        } else {
            // defaut => horizontal
            return (
                <div className="fr-form-group">
                    <fieldset className="fr-fieldset fr-fieldset--inline">
                        <legend className="fr-fieldset__legend fr-text--regular" id="checkboxes-inline-legend">
                            {this.props.legend}
                        </legend>
                        <div className="fr-fieldset__content">{this.getCheckboxes()}</div>
                    </fieldset>
                </div>
            )
        }
    }
}
