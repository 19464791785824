export default class UiService {
    public static getRadioButtonsValue = function (nameAttributeValue: string) {
        let result = null

        try {
            const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('[type="radio"][name="' + nameAttributeValue + '"]')
            inputs.forEach((item) => {
                if (item.checked) {
                    result = item.value
                }
            })
        } catch (e: any) {
            console.log("Exception à la recherche de la valeur des radios [" + nameAttributeValue + "]")
        }

        return result
    }

    public static padTo2Digits(num: number): string {
        return num.toString().padStart(2, "0")
    }

    public static formatServerDateToDatePicker(value: string | null | undefined): string {
        if (value != null) {
            const date = new Date(Number.parseInt(value))
            if (date != null) {
                return [date.getFullYear(), this.padTo2Digits(date.getMonth() + 1), this.padTo2Digits(date.getDate())].join("-")
            }
        }
        return ""
    }

    public static formatDatePickerToHumanDate(value: string | null | undefined): string {
        if (value != null) {
            // 2025-04-24
            return value.substring(8) + " " + this.getMonth(Number.parseInt(value.substring(5, 7))) + " " + value.substring(0, 4)
        }
        return ""
    }

    public static formatServerDateToHumanDate(value: string | null | undefined): string {
        if (value != null) {
            const date = new Date(Number.parseInt(value))
            if (date != null) {
                return [this.padTo2Digits(date.getDate()), this.getMonth(date.getMonth()), date.getFullYear()].join(" ")
            }
        }
        return ""
    }

    public static formatDateToHumanDate(date: Date | null | undefined): string {
        if (date != null) {
            return [this.padTo2Digits(date.getDate()), this.getMonth(date.getMonth()), date.getFullYear()].join(" ")
        }
        return ""
    }

    public static formatServerDateToHumanNumericDate(value: string | null | undefined): string {
        if (value != null) {
            const date = new Date(Number.parseInt(value))
            if (date != null) {
                return [this.padTo2Digits(date.getDate()), "/", this.getNumericMonth(date.getMonth()), "/", date.getFullYear().toString().substring(2)].join("")
            }
        }
        return ""
    }

    public static formatServerDateToShortHumanNumericDate(value: string | null | undefined): string {
        if (value != null) {
            const date = new Date(Number.parseInt(value))
            if (date != null) {
                return [this.padTo2Digits(date.getDate()), "/", this.getNumericMonth(date.getMonth())].join("")
            }
        }
        return ""
    }

    public static getMonth(month: number, firstLetterUppercase = false) {
        switch (month) {
            case 0:
                return firstLetterUppercase ? "Janvier" : "janvier"
            case 1:
                return firstLetterUppercase ? "Février" : "février"
            case 2:
                return firstLetterUppercase ? "Mars" : "mars"
            case 3:
                return firstLetterUppercase ? "Avril" : "avril"
            case 4:
                return firstLetterUppercase ? "Mai" : "mai"
            case 5:
                return firstLetterUppercase ? "Juin" : "juin"
            case 6:
                return firstLetterUppercase ? "Juillet" : "juillet"
            case 7:
                return firstLetterUppercase ? "Août" : "août"
            case 8:
                return firstLetterUppercase ? "Septembre" : "septembre"
            case 9:
                return firstLetterUppercase ? "Octobre" : "octobre"
            case 10:
                return firstLetterUppercase ? "Novembre" : "novembre"
            case 11:
                return firstLetterUppercase ? "Décembre" : "décembre"
        }
    }

    public static getNumericMonth(month: number) {
        switch (month) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
                return "0" + (month + 1)
            case 9:
            case 10:
            case 11:
                return month + 1
        }
    }
}
