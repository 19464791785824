import React, { Fragment } from "react"

import BackOfficeComponent from "../../back/BackOfficeComponent"

interface BackOfficeLazyWrapperComponentProps {
    source: JSX.Element
}

interface BackOfficeLazyWrapperComponentState {
    interestingToLoad: boolean
}

export default class LazyWrapperComponent extends BackOfficeComponent<BackOfficeLazyWrapperComponentProps, BackOfficeLazyWrapperComponentState> {
    constructor(props: BackOfficeLazyWrapperComponentProps) {
        super(props)
        this.state = {
            interestingToLoad: false
        }
    }

    render() {
        if (this.state?.interestingToLoad) {
            return (
                <Fragment>
                    {this.props.source}
                    {this.props.children}
                </Fragment>
            )
        } else {
            return <div onMouseOver={() => this.setState({ interestingToLoad: true })}>{this.props.source}</div>
        }
    }
}
