import Dashboard from "../../model/Dashboard"

import BackOfficeDemandeAgrementForm from "../../back/common/BackOfficeDemandeAgrementForm"
import PagerResponse from "../../shared/dto/PagerResponse"
import HttpService from "./HttpService"
import Response from "./response/Response"

export default class HttpBackDemande extends HttpService {
    BACK_GESTION_DEMANDE_URI = "/gestion/demande"

    getDemandeByStatuts(statuts: string[]) {
        const params = new URLSearchParams()
        this.addStatutsParams(statuts, params)
        return this.request<Response<number>>(this.BACK_GESTION_DEMANDE_URI + "/by_statut?" + params.toString(), {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    getDashboard() {
        return this.request<Response<Dashboard>>(this.BACK_GESTION_DEMANDE_URI + "/dashboard", {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    getDemande(id: number) {
        return this.request<Response<BackOfficeDemandeAgrementForm>>(this.BACK_GESTION_DEMANDE_URI + "/" + id, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    getDemandes(
        cnaId: number | null,
        raisonSocialeFilter: string | null,
        statuts: string[] | null | undefined,
        type: string | null,
        idcc: string | null,
        idTypologie: number | null,
        codePostal: string | null,
        idTypeFinanceur: string | null,
        page: number,
        size: number
    ) {
        //console.log("TyPo ID : "+idTypologie)
        //console.log("sTatUTS : "+statuts)

        const params = new URLSearchParams()
        this.addStatutsParams(statuts, params)

        if (cnaId != null) {
            params.set("cnaId", cnaId.toString())
        }
        if (raisonSocialeFilter != null) {
            params.set("raisonSociale", raisonSocialeFilter)
        }
        if (type != null) {
            params.set("type", type)
        }
        if (idcc != null) {
            params.set("idcc", idcc.toString())
        }
        if (idTypologie != null) {
            params.set("idThematique", idTypologie.toString())
        }
        if (codePostal != null) {
            params.set("codePostal", codePostal)
        }
        if (idTypeFinanceur != null) {
            params.set("idTypeFinanceur", idTypeFinanceur)
        }
        if (page != null) {
            params.set("page", page.toString())
        }
        if (size != null) {
            params.set("size", size.toString())
        }
        return this.request<Response<PagerResponse<BackOfficeDemandeAgrementForm>>>(this.BACK_GESTION_DEMANDE_URI + "/list?" + params.toString(), {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }

    addStatutsParams(statuts: string[] | null | undefined, params: URLSearchParams) {
        if (statuts != null) {
            statuts.forEach((param) => {
                params.set("statut", param)
            })
        }
    }

    deleteCnaId(id: number) {
        return this.request<Response<number>>(this.BACK_GESTION_DEMANDE_URI + "/"+ id +"/delete_cna", {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            credentials: "include"
        }).then((response) => this.backSessionExpired(response))
    }
}
