import { Editor } from "@tinymce/tinymce-react"
import React from "react"

import DnumComponent, { DnumProps } from "../DnumComponent"
import { DnumErrorHandlerProps } from "../DnumErrorHandlerProps"

interface RichTextEditorProps extends DnumProps, DnumErrorHandlerProps {
    onEditorChange: (content: string) => void
    initValue?: string
    fieldName: string
}

interface RichTextEditorState {
    fake: any
}

export default class RichTextEditor extends DnumComponent<RichTextEditorProps, RichTextEditorState> {
    richContentValue: string | null = null

    constructor(props: RichTextEditorProps) {
        super(props)
    }

    getOnEditorChangeMethod(content: string) {
        if (this.props.onEditorChange != null) {
            this.richContentValue = content
            this.props.onEditorChange(content)
        } else {
            this.richContentValue = content
        }
    }

    getErrorMessage() {
        if (this.props.errorMessage !== null && this.props.errorMessage !== undefined) {
            return (
                <p id="text-input-error-desc-error" className="fr-error-text">
                    {this.props.errorMessage}
                </p>
            )
        }
    }

    render() {
        const localId = this.props.id != null ? this.props.id : this.generateId("tiny-mce-")
        const localOnEditorChange = (content: string) => this.getOnEditorChangeMethod(content)

        let className = { className: "fr-input-group" }
        if (this.props.error) {
            className = { className: "fr-input-group fr-input-group--error" }
        }

        return (
            <div {...className}>
                <div style={{ marginBottom: "10px" }}>
                    <label className="fr-label">{this.props.fieldName}</label>
                </div>
                {this.getErrorMessage()}
                <Editor
                    apiKey="xlyzeu57x398o67mmimt54wqqu0vcb7h4vzl80sioxb4zj3t"
                    id={localId}
                    initialValue={this.props.initValue}
                    onEditorChange={(content) => localOnEditorChange(content)}
                    onPostRender={(value, paramEditor) => console.log(typeof value + "\n" + typeof paramEditor)}
                    init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                            "advlist",
                            "anchor",
                            "autolink",
                            "charmap",
                            "code",
                            "fullscreen",
                            "help",
                            "image",
                            "insertdatetime",
                            "link",
                            "lists",
                            "media",
                            "preview",
                            "searchreplace",
                            "table",
                            "visualblocks"
                        ],
                        toolbar: "undo redo | styles | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                        content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        directionality: "ltr",
                        relative_urls: false,
                        convert_urls: false,
                        default_link_target: "_blank",
                        link_default_protocol: "https"
                    }}
                />
            </div>
        )
    }
}
